import {
  getInteriorCodeFromOptions,
  getLocalOptionsFromAllOptions,
  getVarnishCodeFromOptions,
  HashMap,
  IInterior,
  IOrder,
  IOrderFromFactoryDetails,
  IPimVehicleModel,
  IReference,
  IVarnish,
  IVehicleConfiguration,
  IVehicleEquipment,
  IVehicleInput,
  IVehicleOptionInput,
  MAKE,
} from 'next-common';
import { IOptions } from 'sales-common';
import { IOptionMetaData, IVehicleStock, getTTCodeStatusName, DELIMETER  } from '../../common';
import { IVehicleCountBySeller, IVehicleFactoryItem } from '../../components/sales/sales-vehicle-picker-page';
import { mapFactoryItemToVehicleOptionInput, mapVehicleInventoryItemToVehicleOptionInputForPickerPage } from './option';
import {
  IVehicleStockWithSegregatedTypes,
  vehicleSegregationWorkerHelper,
} from '../../components/sales/sales-wizard/vehicleSegregationWorker';
import { difference, remove } from 'lodash';

export const mapFactoryVehicleToVehicleInput = (selectedVehicles, item: IVehicleConfiguration): IVehicleInput => {
  const model = {
    code: null,
    description: null,
    name: item?.model?.name,
    type: item?.salesPackages?.[0]?.name ?? null,
    version: null,
  };

  const orderFromFactoryDetails = {
    colorId: selectedVehicles?.colorId,
    color: selectedVehicles?.color,
    colorPrice: selectedVehicles?.colorPrice,
    interiorId: selectedVehicles?.interiorId,
    interiorCode: selectedVehicles?.interiorCode,
    interiorPrice: selectedVehicles?.interiorPrice,
    expectedProductionDate: selectedVehicles?.expectedProductionDate,
    totalPrice: selectedVehicles?.totalPrice,
  };
  return {
    make: item?.model?.make,
    model,
    serialNo: '',
    series: item?.model?.series,
    vin: '',
    orderFromFactoryDetails,
  };
};

export const getVarnishPrice = (varnish: IVarnish): number => {
  // If the varnish cannot be found in PIM, we don't know the correct price. Set the price to -1 to signal this.
  if (!varnish) {
    return -1;
  }

  // If the varnish is recommended, the price is already included in the vehicle base price.
  if (varnish?.isRecommended) {
    return 0;
  }

  return varnish?.additionalPriceExcludingVat;
};

export const getInteriorPrice = (interior: IInterior): number => {
  if (!interior) {
    return -1;
  }

  if (interior?.isRecommended) {
    return 0;
  }

  return interior?.additionalPriceExcludingVat;
};

export const getFactoryVehicleInput = (
  selectedVehicles: IVehicleFactoryItem,
  vehicle: IVehicleConfiguration,
): IVehicleInput => mapFactoryVehicleToVehicleInput(selectedVehicles, vehicle);

export const getOptionInputsForPickerPage = (
  order: IOrder,
  selectedAvlableVehicle: IVehicleStock,
  vehicleConfig: IVehicleConfiguration,
  optionMetaData?: IOptionMetaData,
): IVehicleOptionInput[] =>
  selectedAvlableVehicle
    ? [
        mapVehicleInventoryItemToVehicleOptionInputForPickerPage(
          selectedAvlableVehicle,
          order,
          vehicleConfig,
          optionMetaData,
        ),
      ]
    : [];

export const getFactoryOptionInputs = (
  order: IOrder,
  selectedVehicles: IVehicleFactoryItem,
  vehicleConfig: IVehicleConfiguration,
): IVehicleOptionInput => mapFactoryItemToVehicleOptionInput(selectedVehicles, order, vehicleConfig);

export const mapVehicleToFactoryItem = (vehicleItem: IOrderFromFactoryDetails): any[] => {
  const color = vehicleItem?.color ? vehicleItem?.color : null;
  const colorPrice = vehicleItem?.colorPrice ? vehicleItem?.colorPrice : 0;
  const interiorCode = vehicleItem?.interiorCode ? vehicleItem?.interiorCode : null;
  const interiorPrice = vehicleItem?.interiorPrice ? vehicleItem?.interiorPrice : 0;
  const expectedProductionDate = vehicleItem?.expectedProductionDate ? vehicleItem?.expectedProductionDate : null;
  const totalPrice = vehicleItem?.totalPrice ? vehicleItem?.totalPrice : 0;

  return [
    {
      color,
      colorPrice,
      interiorCode,
      interiorPrice,
      expectedProductionDate,
      totalPrice,
    },
  ];
};

//create vehicles count for all the sellers.
export const getVehicleCountBySeller = (
  availableVehilclesExcludingLocationFilter: IVehicleStock[],
  { isImporterLocation, isOwnLocation },
): IVehicleCountBySeller => {
  const count = availableVehilclesExcludingLocationFilter?.reduce(
    (last, curr) => {
      if (isImporterLocation(curr?.locationId)) {
        return { ...last, port: { importer: last?.port['importer'] + 1, vehicles: [...last.port['vehicles'], curr] } };
      } else if (isOwnLocation(curr?.locationId)) {
        return { ...last, own: { own: last?.own['own'] + 1, vehicles: [...last.own['vehicles'], curr] } };
      } else {
        return { ...last, other: { dealer: last?.other['dealer'] + 1, vehicles: [...last.other['vehicles'], curr] } };
      }
    },
    {
      own: {
        own: 0,
        vehicles: [],
      },
      port: {
        importer: 0,
        vehicles: [],
      },
      other: {
        dealer: 0,
        vehicles: [],
      },
    },
  );

  return count;
};

export const getEquipmentsPrice = (order: IOrder): number => {
  if (!order?.equipments?.length) return 0;
  return order?.equipments?.reduce((total, equipment) => total + (equipment?.priceExcludingVat || 0), 0) ?? 0;
};

export const hasVarnishChanged = (
  updatedVehicleStock: IVehicleStock,
  order: IOrder,
  skuDifference: string[],
  setSku,
): boolean => {
  if (order?.options?.[0]) {
    const varnishCode: string = getVarnishCodeFromOptions(order?.options);
    if (varnishCode !== updatedVehicleStock?.colorId) {
      setSku([...skuDifference, updatedVehicleStock?.colorId]);
      return true;
    }
  }
  return false;
};

export const hasInteriorChanged = (
  updatedVehicleStock: IVehicleStock,
  order: IOrder,
  skuDifference: string[],
  setSku,
): boolean => {
  if (order?.options?.[0]) {
    const seperatedPricedOptions: string[] = updatedVehicleStock?.priceOptions?.split(DELIMETER);
    const interiorCode: string = getInteriorCodeFromOptions(order?.options);
    if (!seperatedPricedOptions?.includes(order?.options?.[0]?.packageInteriorId)) {
      if (interiorCode !== updatedVehicleStock?.interiorCode) {
        setSku([...skuDifference, updatedVehicleStock?.interiorCode]);
        return true;
      }
    }
  }
  return false;
};

export const getEquipmentSkuArray = (order: IOrder): string[] => {
  const savedPriceOptionArray: string[] = [];
  if (order?.equipments) {
    order?.equipments?.map((equipment) => {
      savedPriceOptionArray?.push(equipment?.sku);
    });
  }

  return savedPriceOptionArray;
};

export const filterVehicleSkuFromPriceOption = (priceOptions: string[], order: IOrder): string[] => {
  return priceOptions?.filter((priceOption) => {
    const skuFound = order?.orderReference?.pimCode?.includes(priceOption);
    if (skuFound) {
      return false;
    }
    return true;
  });
};

export const getPricedOptions = (priceOption: string, make: string) => {
  const splittedPricedOptions = priceOption?.split(DELIMETER) ?? [];
  if (make === MAKE.Peugeot) {
    // Ignore priced option with 2 characters
    return splittedPricedOptions?.filter((option) => option?.length > 2);
  }
  return splittedPricedOptions;
};

export const hasPriceOptionsChanged = (
  updatedVehicleStock: IVehicleStock,
  order: IOrder,
  skuDifferences: string[],
  setSku,
): boolean => {
  const equipmentArray: string[] = getEquipmentSkuArray(order);
  const localOptions = getLocalOptionsFromAllOptions(
    updatedVehicleStock?.vehicleStatus?.vehicleStatus?.option as IOptions[],
  );
  const pCodes = localOptions?.map((option) => option?.optionId);
  const savedPriceOptionsArray: string[] = [
    ...equipmentArray,
    getInteriorCodeFromOptions(order?.options),
    getVarnishCodeFromOptions(order?.options),
    order?.options?.[0]?.packageInteriorId,
  ];
  const seperatedPricedOptions: string[] = getPricedOptions(
    updatedVehicleStock?.priceOptions,
    updatedVehicleStock?.brand,
  );
  const vehicleStockDifference: string[] = seperatedPricedOptions?.filter(
    (priceOption) => !savedPriceOptionsArray?.includes(priceOption),
  );
  let savedConfigurationDifference: string[] = equipmentArray?.filter(
    (savedPriceOption) => !seperatedPricedOptions?.includes(savedPriceOption),
  );
  if (savedConfigurationDifference?.length) {
    const orderEquipment: IVehicleEquipment[] = order?.equipments?.filter(
      (equipment) => savedConfigurationDifference?.includes(equipment?.sku) && equipment?.priceExcludingVat === 0,
    );
    if (orderEquipment?.length) {
      const optionCodes: string[] = updatedVehicleStock?.optionCode?.split(DELIMETER);
      const savedEquipments = orderEquipment
        ?.map((savedEquipment) => {
          if (optionCodes?.includes(savedEquipment?.sku)) {
            return savedEquipment?.sku;
          }
        })
        .filter(Boolean);
      savedConfigurationDifference = savedConfigurationDifference?.filter(
        (configuration) => !savedEquipments?.includes(configuration),
      );
    }
  }
  const skuDifference: string[] = filterVehicleSkuFromPriceOption(vehicleStockDifference, order);
  const finalSku = difference(
    [...skuDifferences, ...(skuDifference || []), ...(savedConfigurationDifference || [])],
    pCodes,
  );  
  if (updatedVehicleStock?.brand === MAKE.Peugeot) {
    const IgnoreCharacterLength = 2;
    remove(finalSku, function(sku) {      
      return sku?.length <= IgnoreCharacterLength;   
    });
  }
  setSku(finalSku);
  if (finalSku?.length) {
    return true;
  }
  return false;
};

export const checkIfVehicleConfigurationHasChanged = (
  updatedVehicleStock: IVehicleStock,
  order: IOrder,
  skuDifference: string[],
  setSku,
): boolean => {
  return (
    hasVarnishChanged(updatedVehicleStock, order, skuDifference, setSku) ||
    hasInteriorChanged(updatedVehicleStock, order, skuDifference, setSku) ||
    hasPriceOptionsChanged(updatedVehicleStock, order, skuDifference, setSku)
  );
};
export const validateCarDataFromPIM = (orderReference: IReference, pimData: IPimVehicleModel[]): boolean => {
  let result: boolean[] = [];
  if (pimData?.length) {
    result = pimData.map((config) => config?.sku === orderReference?.pimCode);
  }
  return result?.includes(true);
};

const filteringOurExactAndNonExactMatch = (
  filteringVehicle: IVehicleStockWithSegregatedTypes[],
  selectedColorId: string,
  selectedInteriorId: string,
  selectedSkus: string[],
  offerMake: string,
  modelOptions: HashMap
) => {
  const exactMatchList = [],
    nonExactMatchList = [];
  filteringVehicle?.forEach((vehicle) => {
    const { optionsCheckfromSku, filterPackageFromPriceOptions } = vehicleSegregationWorkerHelper();
    const packageOption = optionsCheckfromSku(vehicle?.priceOptions, modelOptions);
    const priceOptions = filterPackageFromPriceOptions(vehicle?.priceOptions, packageOption);
    const colorInteriorMatch =
      vehicle?.colorId === selectedColorId &&
      (vehicle?.interiorCode === selectedInteriorId || priceOptions?.includes(selectedInteriorId));
    let filterSelectedSku = difference(priceOptions, selectedSkus);
    if (offerMake === MAKE.Peugeot) {
      const colorCode = vehicle?.colorId?.substring?.(0, 2)?.toLocaleUpperCase();
      const remainingSKU = filterSelectedSku?.length === 1 && filterSelectedSku?.[0];
      if (remainingSKU) {
        if (colorCode === remainingSKU?.toLocaleUpperCase()) {
          filterSelectedSku = [];
        }
      }
    }
    if (!filterSelectedSku?.length && colorInteriorMatch) {
      exactMatchList.push(vehicle);
    } else {
      nonExactMatchList.push(vehicle);
    }
  });
  return { exactMatchList, nonExactMatchList };
};

export const getExactAndNonExactMatchSpecificSeller = (
  selectedSkus: string[],
  vehicleCountBySeller: IVehicleCountBySeller,
  selectedColorId: string,
  selectedInteriorId: string,
  offerMake: string,
  modelOptions: HashMap
) => {
  const filteringVehicle = [...(vehicleCountBySeller?.other?.vehicles || [])];
  const { exactMatchList, nonExactMatchList } = filteringOurExactAndNonExactMatch(
    filteringVehicle,
    selectedColorId,
    selectedInteriorId,
    selectedSkus,
    offerMake,
    modelOptions
  );
  return { exactMatchSpecificSeller: exactMatchList, nonExactMatchSpecificSeller: nonExactMatchList };
};

export const getExactAndNonExactMatchVehicleList = (
  selectedSkus: string[],
  vehicleCountBySeller: IVehicleCountBySeller,
  selectedColorId: string,
  selectedInteriorId: string,
  offerMake: string,
  modelOptions: HashMap
) => {
  const filteringVehicle = [
    ...(vehicleCountBySeller?.own?.vehicles || []),
    ...(vehicleCountBySeller?.port?.vehicles || []),
  ];
  const { exactMatchList, nonExactMatchList } = filteringOurExactAndNonExactMatch(
    filteringVehicle,
    selectedColorId,
    selectedInteriorId,
    selectedSkus,
    offerMake,
    modelOptions
  );
  return { exactMatchVehicleList: exactMatchList, nonExactMatchVehicleList: nonExactMatchList };
};

export const getStatusByTTCode = (trackTraceCode: number, vehicleBrand: string) => {
  return getTTCodeStatusName(vehicleBrand, trackTraceCode);
};
//This function only for mocks after change delimeters
export const changeDelimeter = (value = '', from = "-", to = "^") => {
  const regex = new RegExp(from, "g");
  const newValue = value?.replace(regex, to);
  return newValue;
}
