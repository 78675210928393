import { useQuery } from '@apollo/client';
import { IBosOrderDraft } from 'next-common';
import { ERROR_POLICY } from '../constants';
import { getVehicleOrderDraft } from '../../graphql';

export interface IBosOrderDraftResult {
  orderDraft: IBosOrderDraft | null; 
  draftLoading: boolean;
}

export const useVehicleOrderDraft = (orderId: string | null): IBosOrderDraftResult => {
  const { data, loading } = useQuery(getVehicleOrderDraft, {
    variables: { orderId },
    skip: !orderId,
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'network-only',
  });

  return { orderDraft: data?.getVehicleOrderDraft ?? null, draftLoading: loading };
};