import React, { useEffect, useState } from 'react';
import { OrderAccordion } from '../../sales-common/OrderAccordion';
import { AccessoryChangeAction, IAccessoryChange, ICustomAccessoryProps } from '../AccessoryTool';
import { AccessoriesStyled, AccessorySetStyled } from '../VehicleAccessoriesPage.styled';
import { priceFormatter } from '../../../../utils';
import { ContentTransformerWrapper } from '../../sales-vehicle-picker-page/ResultGrid/OrderAccordian/VehicleSelectableDescription';
import { SingleDetails } from '@next-components/single-details';
import placeholderImage from '../../../../assets/images/placeholder.png';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { ModalWrapper } from '../../../common-components/ModalWrapper';

interface IAccessorySetProps {
  loading: boolean;
  orderId: string;
  accessories: Partial<ICustomAccessoryProps>;
  changes: IAccessoryChange[];
  defaultOpenAccessories?: string[];
  onAddAccessory(id: string, accessoryData?: Partial<ICustomAccessoryProps>): void;
  onRemoveAccessory(id: string, accessory: ICustomAccessoryProps): void;
}

export const AccessorySet = (props: IAccessorySetProps) => {
  const [accessories, setAccessories] = useState<Partial<ICustomAccessoryProps>>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [accessoryName, setAccessoryName] = useState<string>(null);
  const [description, setDescription] = useState<JSX.Element>(null);
  const [accessoryImage, setAccessoryImages] = useState(null);
  const [sku, setSku] = useState<string>(null);

  useEffect(() => {
    setAccessories(props.accessories);
  }, [props.accessories]);

  useEffect(() => {
    if (!props.changes?.length) {
      setAccessories(props?.accessories);
    }
  }, [props.changes]);

  const handleAccessory = (event, accessory: ICustomAccessoryProps) => {
    if (event) {
      props.onAddAccessory(accessory?.id, accessory);
    } else {
      props.onRemoveAccessory(accessory?.id, accessory);
    }
  };

  const displayAccessoryModal = (props: Partial<ICustomAccessoryProps>) => {
    const description: JSX.Element = ContentTransformerWrapper(props?.description);
    const accessoryImage = { alt: 'Bilde mangler', src: props?.images?.url ?? placeholderImage };
    setShowPopup(!showPopup);
    setDescription(description);
    setAccessoryImages(accessoryImage);
    setAccessoryName(props?.name);
    setSku(props?.sku);
  };

  return (
    <AccessorySetStyled>
      {accessories &&
        Object.keys(accessories)?.map((category) => {
          const accessorySet: ICustomAccessoryProps[] = accessories[category];
          const isOpen = props?.defaultOpenAccessories?.includes(category);
          return (
            <OrderAccordion key={category} label={category} isOpen={isOpen}>
              <AccessoriesStyled className="AccessoriesStyled">
                {accessorySet?.map((accessory) => {
                  const change = props.changes?.find((change) => change?.accessoryId === accessory?.id);
                  const isSelected = (!change && accessory?.isSelected) || change?.action === AccessoryChangeAction.ADD;
                  const tireDescriptionData = {
                    description: [
                      { title: 'Pris inkl. mva', value: priceFormatter(accessory?.price ?? 0) },
                      { title: 'Pris eks. mva', value: priceFormatter(accessory?.priceExcludingVat ?? 0) },
                    ],
                  };
                  return (
                    <Card
                      key={accessory?.id}
                      id={accessory?.id ?? ''}
                      title={accessory?.name ?? ''}
                      type={CardType.BosCard}
                      updateSelected={(event) => handleAccessory(event, accessory)}
                      selected={isSelected}
                      bosDescription={tireDescriptionData?.description}
                      displayTag
                      tagValue={accessory?.sku ?? ''}
                      isDisabled={props?.loading ?? false}
                      onTagClick={() => displayAccessoryModal(accessory)}
                    />
                  );
                })}
              </AccessoriesStyled>
            </OrderAccordion>
          );
        })}
      {showPopup && (
        <ModalWrapper isActive={showPopup}>
          <SingleDetails
            onCloseClick={() => setShowPopup(!showPopup)}
            active={showPopup}
            details={
              <div className="tagContainer">
                <div>
                  <small className="smallText tagClass">{sku ?? ''}</small>
                </div>
                <p className="title">{accessoryName}</p>
                <label className="description">{description}</label>
              </div>
            }
            img={accessoryImage}
          />
        </ModalWrapper>
      )}
    </AccessorySetStyled>
  );
};
