import * as React from 'react';
import { DynamicListStyled, VehicleModelDetails } from '../DynamicListStock.styled';
import { IDynamicDetailItemProps } from '../IDynamicListStock';
import { ITilleggSelectable, IVehicleListItems, saleAllCarsMbImageToken } from '../../../../common';
import { Button } from '@next-components/button';
import { VehicleDetailStyled } from './VehicleDetail.styled';
import { VehicleListDetailStockStyled } from './VehicleListStock.styled';
import { formatNumber } from 'sales-common';
import { Icon, IconType } from '@next-components/bos-icon';
import { displayDataForVehicleDetails } from '../../../sales/sales-vehicle-picker-page/VehicleListDialog/VehicleListFormatter';

// VehicleDetail expanded field content type
interface IVehicleDetailListItems extends IVehicleListItems {
  interiorName?: string;
  modelDesc?: string;
  totalPrice?: number;
  locationName?: string;
}

export const VehicleDetail = (props: IDynamicDetailItemProps) => {
  const vehicleDetailsData: IVehicleDetailListItems = props?.data;
  const [loading, setLoading] = React.useState<boolean>(props?.isLoading ?? false);

  React.useEffect(() => {
    setLoading(props?.isLoading)
  }, [props?.isLoading])

  /**
   * Take argument of skuItemlist and return the html
   * @param skuItemList Array
   * @returns ReactNode (Html Element)
   */
  const renderSkuField = (skuItemList: ITilleggSelectable[]): React.ReactNode => {
    if (skuItemList?.length === 0) {
      return <span>-</span>;
    } else {
      return skuItemList?.map((item: ITilleggSelectable) => {
        return (
          <div className="gridListItem" key={item?.sku}>
            <div className="itemValue">
              {displayDataForVehicleDetails({
                sku: item?.sku,
                name: item?.name,
                isSelected: item?.isSelected,
              })}
            </div>
          </div>
        );
      });
    }
  };
  
  const onSubmitClick = () => {
    if (!props?.isLoading) {
      props.onButtonClickDetailPage(props?.data)
    }
  }

  const onClickRemoveVehicle = () => {
    if(!props?.isLoading) {
      props?.removeVehicle();
    }
  }


  return (
    <VehicleDetailStyled>
      <DynamicListStyled className="vehicleDetailContainer">
        
      <VehicleListDetailStockStyled>
          <VehicleModelDetails>
              <div className="gridListItem modelDescription">
                <h3>{vehicleDetailsData?.modelDesc || ''}</h3>
              </div>
              <div className="gridListItem">
               <label className="labelTitle">Serienummer: </label>
               <span className="itemValue"> {vehicleDetailsData?.serialNumber || '-'}</span>
              </div>
              {vehicleDetailsData.vinNumber && (
               <div className="gridListItem">
                 <label className="labelTitle">VIN: </label>
                 <span className="itemValue"> {vehicleDetailsData?.vinNumber || '-'}</span>
               </div>
             )}
              {vehicleDetailsData.oemOrderNo && (
                <div className="gridListItem">
                  <label className="labelTitle">OEM:</label>
                  <span className="itemValue"> {vehicleDetailsData?.oemOrderNo || '-'}</span>
                </div>
              )}
              <div className="gridListItem">
                <label className="labelTitle">Prod.dato: </label>
                <span className="itemValue"> {vehicleDetailsData?.prod || '-'}</span>
              </div>
              <div className="gridListItem">
                <label className="labelTitle">Brukskode: </label>
                <span className="itemValue"> {vehicleDetailsData?.applicationCode || '-'}</span>
              </div>
            </VehicleModelDetails>
            <div className="divider"></div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            {vehicleDetailsData?.image?.url && (
              <div className="gridListItem">
                <div className="imageContainer">
                  <img
                    src={`${vehicleDetailsData?.image?.url}${saleAllCarsMbImageToken}`}
                    alt="no-vehicle"
                    width="240px"
                    height="147px"
                  />
                </div>
              </div>
            )}
            <div className="gridListItem ">
              <label className="labelTitle">Farge</label>
              <div className="itemValue">
                {vehicleDetailsData?.fargeFormatter?.map((item: ITilleggSelectable) => {
                  if (item?.sku) {
                    if (props?.filter?.colorId?.includes(item?.sku)) {
                      item.isSelected = true;
                    } else {
                      item.isSelected = false;
                    }
                  }
                  return renderSkuField([item]);
                })}
              </div>
            </div>
            <div className="gridListItem">
              <label className="labelTitle">Interiør</label>
              <div className="itemValue">
                {vehicleDetailsData?.interiorFormatter?.map((item: ITilleggSelectable) => {
                  if (item?.sku) {
                    if (props?.filter?.interiorCode?.includes(item?.sku)) {
                      item.isSelected = true;
                    } else {
                      item.isSelected = false;
                    }
                  }
                  return renderSkuField([item]);
                })}
              </div>
            </div>
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer ekstrautstyrContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Ekstrautstyr</label>
            </div>
            {vehicleDetailsData?.exteriorSelectableFormatter?.map((item: ITilleggSelectable) => {
              if (item?.sku) {
                if (props?.filter?.exteriorSelectableFormatter?.includes(item?.sku)) {
                  item.isSelected = true;
                } else {
                  item.isSelected = false;
                }
              }
              return renderSkuField([item]);
            })}
          </div>
          <div className="gridListItemContainer utstyrspakkeContainer">
            <div className="gridListItem ">
              <label className="labelTitle">Status</label>
            </div>
            <div className="gridListItem">
              <div className="itemValue">
                {displayDataForVehicleDetails({
                  sku: vehicleDetailsData?.tt?.toString(),
                  name: vehicleDetailsData?.statusName,
                  isSelected:
                    props?.filter?.trackTraceCode?.filter((trackTraceCode: string) =>
                      trackTraceCode?.includes(vehicleDetailsData?.statusName),
                    ) !== undefined,
                })}
              </div>
            </div>
            <div className="gridListItem">
              <div className="itemValue">
                {displayDataForVehicleDetails({
                  sku: vehicleDetailsData?.locationId,
                  name: vehicleDetailsData?.locationName,
                  isSelected:
                    props?.filter?.locationId?.filter((location: string) =>
                      location?.includes(vehicleDetailsData?.locationId),
                    ) !== undefined,
                })}
              </div>
            </div>
            <div className="gridListItem price Container">
              <p>Veil pris inkl. mva og avgifter</p>
              <h3 className="priceValue"> {formatNumber(vehicleDetailsData?.totalPrice)} kr</h3>
            </div>
            <div className="gridListItem">
              {props?.selected ? (
                <Button
                  variant="secondary"
                  className="red"
                  isLoading={loading}
                  disabled={props?.data?.disabled || props.isVehicleReserved}
                  onClick={() => onClickRemoveVehicle()}>
                  <span>
                    <Icon icon={IconType.TrashOutline} height={24} width={24} color="red" /> Fjern valgt bil{' '}
                  </span>
                </Button>
              ) : (
                <Button
                  className="vehicleDetailBtn"
                  isLoading={loading}
                  disabled={props?.data?.isDisable || props.isVehicleReserved}
                  onClick={() => onSubmitClick()}>
                  Velg denne bilen
                </Button>
              )}
            </div>
          </div>
        </VehicleListDetailStockStyled>
      </DynamicListStyled>
    </VehicleDetailStyled>
  );
};
