import styled from 'styled-components';

export const ToastAreaStyled = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  position: fixed;
  bottom: 3rem;
  width: 40rem;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  > div {
    margin: 0 auto;
  }
  &.errorMessageOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.25);
    bottom: 0;
    > div {
      margin: 0 auto 1rem;
    }
  }
`;
