import { bosColor } from "@next-components/common-styles";

const baseStyle = {
    paddingRight: 3,
    fontWeight: 'normal', 
    color: bosColor.black   
};


const generateSubHeaderStyles = (fontSize = 7) => ({
    ...baseStyle,
    fontSize,
});


const generateFieldStyles = (fontSize = 7) => ({
    'kunde': generateSubHeaderStyles(fontSize),
    'telefon': generateSubHeaderStyles(fontSize),
    'epost': generateSubHeaderStyles(fontSize),
    'adresse': generateSubHeaderStyles(fontSize),
    'gyldigtil': generateSubHeaderStyles(fontSize),
    'orgnumber': generateSubHeaderStyles(fontSize),
    'kontaktperson': generateSubHeaderStyles(fontSize),
    'telefonkontaktperson':generateSubHeaderStyles(fontSize),
    'tilbudsdato': generateSubHeaderStyles(fontSize)
});

export const SubHeaderMakeStyle = {
    'DEFAULT': {
        'SRT': generateFieldStyles(7),  
        'MER': generateFieldStyles(7),
        'KIA': generateFieldStyles(7),
        'PEU': generateFieldStyles(7),
    },
    'INDEPENDENT_DEALER': {
        'SRT': generateFieldStyles(8), 
        'MER': generateFieldStyles(8),
        'KIA': generateFieldStyles(8),
        'PEU': generateFieldStyles(7),  
    },
};
