import classNames from 'classnames';
import React, { useState } from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { copyWithIFSUrl, getVoucherDate, uniqueByAccountStart } from '../../../../../utils';
import { IBosApiVehicleMarginCalculationSales, IBosApiVehicleMarginCalculationActuals } from 'next-common';
import { formatNumber } from '@next-components/common-types';
import { v4 as uuidv4 } from 'uuid';

export interface ISalesTableProps {
  actualsDetails: IBosApiVehicleMarginCalculationActuals[];
  budgetDetails: IBosApiVehicleMarginCalculationSales;
}

export const SalesTable = (props: ISalesTableProps) => {
  const [activeKey, setActiveKey] = useState<number>(0);
  const CustomToggle = ({ children, eventKey, handleClick, as }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      handleClick();
    });

    return (
      <Accordion eventKey={eventKey} as={as} className="card-header" onClick={decoratedOnClick}>
        {children}
      </Accordion>
    );
  };

  // @ts-expect-error
  const ifsUrlForCustomerInvoive = window._env_?.REACT_APP_IFS_MARGINS_CUSTOMER_INVOICE_URL;
  const urlForCustomerInvoice = `${ifsUrlForCustomerInvoive}?INVOICE_NO=`;

  return (
    <div className="row-container">
      <table className="table mainTbl">
        <Accordion>
          <Card key={0}>
            <CustomToggle
              as={Card.Header}
              eventKey="0"
              handleClick={() => {
                if (activeKey === 0) {
                  setActiveKey(null);
                } else {
                  setActiveKey(0);
                }
              }}>
              <thead>
                <tr className="cursor-pointer">
                  <th>
                    Salg
                    <span className="plusMinusMenu plus-minus-btn">
                      {activeKey === 0 ? <span className="plusButton">+</span> : <span className="minusButton">-</span>}
                    </span>
                  </th>
                </tr>
              </thead>
            </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <thead>
                  <tr>
                    <th></th>
                    <th className="center-content">Kalkyle</th>
                    <th className="right-content">Virkelig Resultat</th>
                    <th className="text-center">Ref</th>
                    <th className="text-center">Dato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="eclipsText eclipsText-big" title="Grunnpris bil">
                      Grunnpris bil
                    </td>
                    <td className="text-center eclipsText eclipsText-big">
                      {formatNumber(Math.round(props.budgetDetails?.sale?.carPrice))}
                    </td>
                    <td className="right-content" />
                    <td className="right-content" colSpan={2} />
                  </tr>
                  <tr>
                    <td>Opsjoner</td>
                    <td className="text-center eclipsText eclipsText-big">
                      {formatNumber(Math.round(props.budgetDetails?.sale?.optionFactoryPrice))}
                    </td>
                    <td className="right-content" />
                    <td className="right-content" colSpan={2} />
                  </tr>
                  <tr>
                    <td>Rabatt</td>
                    <td className="text-center eclipsText eclipsText-big">
                      {formatNumber(Math.round(props.budgetDetails?.sale?.discount))}
                    </td>
                    <td className="right-content" />
                    <td className="right-content" colSpan={2} />
                  </tr>
                  {props.budgetDetails?.sale?.optionLocals?.map((x) => (
                    <tr
                      key={props.budgetDetails?.sale?.optionLocals?.findIndex(
                        (el) => el.optionLocalText === x.optionLocalText,
                      )}>
                      <td>
                        <div className="eclipsText eclipsText-big" title={x.optionLocalText}>
                          {x.optionLocalText}
                        </div>
                      </td>
                      <td className="text-center">{formatNumber(Math.round(x.optionLocalPrice))}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Frakt/Levering</td>
                    <td className="text-center eclipsText eclipsText-big">
                      {formatNumber(Math.round(props.budgetDetails?.sale?.deliveryCharges))}
                    </td>
                    <td className="right-content" />
                    <td className="right-content" colSpan={2} />
                  </tr>
                  {uniqueByAccountStart(props.actualsDetails, '3', true)?.map((currentValue) => (
                    <tr key={uuidv4()}>
                      <td className="eclipsText eclipsText-big" title="Kundefaktura">
                        Kundefaktura
                      </td>
                      <td className="text-center eclipsText eclipsText-big"></td>
                      <td className="text-right eclipsText eclipsText-big">
                        {formatNumber(Math.abs(Math.round(currentValue.amount)))}
                      </td>
                      <td className="text-center boldFont eclipsText eclipsText-big" colSpan={1}>
                        <span
                          className={classNames(
                            {
                              link: true,
                              'primary-link': true,
                            },
                            'cursor-pointer',
                          )}
                          title={currentValue.referenceNumber}
                          key={currentValue.referenceNumber}
                          onClick={(e) => {
                            copyWithIFSUrl(currentValue.referenceNumber, urlForCustomerInvoice, e);
                          }}>
                          {currentValue.referenceNumber}
                        </span>
                      </td>
                      <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
                    </tr>
                  ))}
                  {uniqueByAccountStart(props.actualsDetails, '3', false)?.map((currentValue) => (
                    <tr key={uuidv4()}>
                      <td className="eclipsText eclipsText-big" title="Kundepostering">
                        Kundepostering
                      </td>
                      <td className="text-center eclipsText eclipsText-big" />
                      <td className="text-right eclipsText eclipsText-big">
                        {formatNumber(Math.round(currentValue.amount))}
                      </td>
                      <td className="text-center boldFont eclipsText eclipsText-big" colSpan={1}>
                        {currentValue.referenceNumber}
                      </td>
                      <td className="right-content ">{getVoucherDate(currentValue.voucherDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </table>
    </div>
  );
};
export default SalesTable;
