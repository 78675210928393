import { ApolloClient } from '@apollo/client';
import { ICartPrices } from '@next-components/cart';
import { CustomerType, INextArticle, IOrder, IVehicleConfiguration, isStockSaleOrderToBeProcessed } from 'next-common';
import React, { useEffect, useState } from 'react';
import { IGetServiceContract } from 'sales-common';
import { ICentralisedProperties, IVehiclePageProps } from '../../../../common';
import { IFinancePageProps, IVehicleDetailsPageProps } from '../../sales-finance-page';
import { ISigningPageProps } from '../../sales-signing-page';
import { IVehicleAccessoriesPageProps } from '../../sales-vehicle-accessories-page';
import { IVehiclePickerPageProps } from '../../sales-vehicle-picker-page';
import { CustomerInfo, ICustomerPageProps } from './CustomerInfo';
import { SalesAndInfoSection } from './SalesAndInfoSection';
import { getStockSaleParams } from '../../../../utils';
import { IStepMenuData } from '../../sales-wizard/sales-wizard-menu';

export enum PageSteps {
  START = 0,
  DETAILS = 10,
  PACKAGES = 1,
  FINANACE = 2,
  IMPORTEROPTIONS = 3,
  SIGN = 4,
}

export interface IHeaderProps extends ICentralisedProperties {
  picker: IVehiclePickerPageProps;
  details: IVehicleDetailsPageProps;
  packages: IVehiclePageProps;
  finance: IFinancePageProps;
  signing: ISigningPageProps;
  customer: ICustomerPageProps;
  accessories: IVehicleAccessoriesPageProps;
  activeStep: number;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  serviceContract?: IGetServiceContract;
  serialNo?: string;
  showCustomerPopup?: boolean;
  articles: INextArticle[];
  vatPrices?: ICartPrices;
  client?: ApolloClient<object>;
  allStepData?: IStepMenuData[];
  setShowCustomerPopup?(value: boolean): void;
}

interface INewDataParams {
  activeStep?: number;
  showNoData?: boolean;
  displayPrices?: boolean;
  serialNo?: string;
  class?: string;
  allStepData?: IStepMenuData[],
  onCustomerType?(customerType: CustomerType): void;
}
interface IActiveProps extends INewDataParams, ICentralisedProperties {
  orderId?: string;
  pimId?: string;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  showCustomerPopup?: boolean;
  setShowCustomerPopup?(value: boolean): void;
  serviceContract?: IGetServiceContract;
  articles?: INextArticle[];
  vatPrices?: ICartPrices;
  client?: ApolloClient<object>;
}

export const NewHeaderWrapper = (props: IHeaderProps) => {
  const [activeProps, setActiveProps] = useState(null);

  const getNewData = (params: INewDataParams): IActiveProps => ({
    showNoData: params?.showNoData ? params.showNoData : false,
    displayPrices: params?.displayPrices ? params.displayPrices : false,
    activeStep: params?.activeStep ? params.activeStep : props?.activeStep,
    order: props?.order,
    orderId: props?.order?.id,
    vehicle: props?.vehicle,
    pimId: props?.order?.orderReference?.pimId,
    showCustomerPopup: props?.showCustomerPopup,
    setShowCustomerPopup: props?.setShowCustomerPopup,
    class: params.class ? params.class : null,
    serialNo: params.serialNo ? params.serialNo : props?.serialNo,
    onCustomerType: params.onCustomerType ? params.onCustomerType : null,
    serviceContract: props?.serviceContract,
    articles: props.articles,
    vatPrices: props.vatPrices,
    client: props.client,
    configuration: props?.configuration,
    allStepData: props.allStepData
  });

  const getActiveProps = (key) => {
    const params = getStockSaleParams(props?.order);
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(
      props?.configuration,
      params?.source,
      params?.serialNo,
      params?.make,
    );
    let newData;
    switch (key) {
      case PageSteps.START:
        newData = getNewData({
          showNoData: !(props?.vehicle || isStockSaleOrder),
          displayPrices: true,
          activeStep: (props?.vehicle || isStockSaleOrder) ? 1 : props.activeStep,
          class: 'hidden-action',
        });
        setActiveProps(newData);
        break;
      case PageSteps.DETAILS:
      case PageSteps.PACKAGES:
        newData = getNewData({
          displayPrices: true,
          onCustomerType: props?.details?.onCustomerType,
        });
        setActiveProps(newData);
        break;
      case PageSteps.FINANACE:
        newData = getNewData({
          onCustomerType: props?.finance?.onCustomerType,
        });
        setActiveProps(newData);
        break;
      case PageSteps.IMPORTEROPTIONS:
        newData = getNewData({});
        setActiveProps(newData);
        break;
      case PageSteps.SIGN:
        newData = getNewData({
          onCustomerType: props?.signing?.onCustomerType,
        });
        setActiveProps(newData);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getActiveProps(props.activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <CustomerInfo {...activeProps} />
      <SalesAndInfoSection {...activeProps} />
    </>
  );
};
