import { InputField } from '@next-components/input-field';
import { InputType } from '@next-components/common-types';
import { CardInputDescription, CardInputPrice, CardInstruction, CardButtonSection } from './CustomAccessory.styled';
import { Button } from '@next-components/button';
import { StyledCard } from '../../sales/sales-vehicle-accessories-page/VehicleAccessoriesPage.styled';
import { BosDropdown, IBosDropDownOptions } from '@next-components/bos-dropdown';
import { IconType } from '@next-components/bos-icon';
import { CheckBox } from '@next-components/bos-checkbox';
import { AccessoryCategory } from 'next-common';

interface ICustomAccessoryProps {
  editEnabled: boolean;
  customName: string;
  accessoryCategoryOptions: IBosDropDownOptions[];
  id?: string;
  dealerPriceExcludingVat?: number;
  priceExcludingVat?: number;
  price?: number;
  accessoryCategory?: IBosDropDownOptions;
  isIncludeMVA?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  isElectric?: boolean;
  setEditEnabled(editEnabled: boolean): void;
  setInputOpen(inputOpen: boolean): void;
  setIsIncludeMVA(isIncludeMVA: boolean): void;
  handleSave(isEdit?: boolean): void;
  setPriceExcludingVat(priceExcludingVat: number): void;
  setPrice(price: number): void;
  setDealerPriceExcludingVat(dealerPriceExcludingVat: number): void;
  setAccessoryCategory(accessoryCategory: IBosDropDownOptions): void;
  setCustomName(customName: string): void;
  onRemove(id: string): void;
  resetStates(): void;
}

export const CustomAccessoryInputForm = (props: ICustomAccessoryProps) => {
  const isCustomDefinedCategory =
    !props?.accessoryCategory?.value || props?.accessoryCategory?.value === AccessoryCategory.CUSTOM_DEFINED;

  const handleEdit = (): void => {
    props?.handleSave(true);
    props?.setEditEnabled(false);
  };
  const cancelEdit = (): void => props?.setEditEnabled(false);

  const handleSave = (): void => props?.handleSave();

  const resetForm = (): void => {
    props?.resetStates();
    props?.setInputOpen(false);
  };

  return (
    <StyledCard isCustom={true} className="customAccessory">
      <CardInstruction> Legg til tilbehør </CardInstruction>
      <CardInputDescription>
        <InputField
          id="inputDescription"
          className="inputDescriptionText"
          label="Beskrivelse av produktet"
          type={InputType.TEXT_AREA}
          defaultValue={props?.customName}
          onChange={(input: string) => props?.setCustomName(input)}
        />
      </CardInputDescription>
      <CardInputDescription>
        <div className="multiSelectOption">
          <label htmlFor="accessoryCategory">Type tilbehør</label>
          <BosDropdown
            id="accessoryCategory"
            value={props?.accessoryCategory}
            onChange={(value) => {
              props?.setAccessoryCategory(value);
            }}
            options={props?.accessoryCategoryOptions}
            placeholder=""
            nextDropdown
            iconProps={{ iconDropdownUp: IconType.ChevronUp, iconDropdownDown: IconType.ChevronDown }}
          />
        </div>
      </CardInputDescription>
      {isCustomDefinedCategory ? (
        <CardInputPrice>
          <div className="inputFieldContainer">
            <InputField
              id="inputPrice"
              type={InputType.FLOAT}
              label="Pris (inkl. mva)"
              defaultValue={props?.price}
              endAdornment="kr"
              onChange={(input: string) => {
                const asFloat = parseFloat(input ?? '0');
                props?.setPrice(asFloat);
              }}
            />
          </div>
        </CardInputPrice>
      ) : (
        <>
          <CardInputPrice>
            <div className="inputFieldContainer">
              <InputField
                id="inputDealerPriceExcludingVat"
                type={InputType.FLOAT}
                label="Kostpris (eks. mva)"
                defaultValue={props?.dealerPriceExcludingVat}
                endAdornment="kr"
                onChange={(input: string) => {
                  const asFloat = parseFloat(input ?? '0');
                  props?.setDealerPriceExcludingVat(asFloat);
                }}
              />
            </div>
          </CardInputPrice>
          <CardInputPrice>
            <div className="inputFieldContainer">
              <InputField
                id="inputPriceExcludingVat"
                type={InputType.FLOAT}
                label="Utsalgspris (eks. mva)"
                defaultValue={props?.priceExcludingVat}
                endAdornment="kr"
                onChange={(input: string) => {
                  const asFloat = parseFloat(input ?? '0');
                  props?.setPriceExcludingVat(asFloat);
                }}
              />
            </div>
          </CardInputPrice>
        </>
      )}
      {props.isElectric && (
        <div className="includeMvaContainer">
          <CheckBox
            label={'Fritatt for mva'}
            id={'includeMva'}
            className="includeMvaCheckbox"
            onChange={(value: React.ChangeEvent<HTMLInputElement>) => props?.setIsIncludeMVA(value?.target?.checked)}
            checked={props?.isIncludeMVA}
          />
        </div>
      )}
      <CardButtonSection>
        <Button
          onClick={props?.editEnabled ? handleEdit : handleSave}
          className='cardButton'
          variant="cta"
          colorVariant="secondary"
          data-testid="Lagre"
          disabled={
            props.disabled ||
            !props?.customName ||
            (isCustomDefinedCategory ? !props?.price : !props?.dealerPriceExcludingVat || !props?.priceExcludingVat)
          }>
          <span>{'Lagre'}</span>
        </Button>
        <Button onClick={props?.editEnabled ? cancelEdit : resetForm} variant="secondary" colorVariant="secondary"  className='cardButton'
        >
          <span>{'Avbryt'}</span>
        </Button>
      </CardButtonSection>
    </StyledCard>
  );
};
