import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { cloneDeep, isEqual, get } from 'lodash';
import { Cart, ICartPrices, ICartProps, ICartSummaryData, IOneOffTaxAndWreckage } from '@next-components/cart';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import {
  AccessoryType,
  EnumMapper,
  FinancingType,
  INextArticle,
  IOrder,
  IVehicleAccessory,
  IVehicleConfiguration,
  MBApiCalculationFlag,
  mapAccessoryVatCode,
  isStockSaleOrderToBeProcessed,
  UsageCodes,
  IConfiguration,
  IAccessoryList,
  IRammeavtale,
} from 'next-common';
import {
  INotificationPageProps,
  IVehiclePageProps,
  IVehicleStock,
  Routes,
  toggleVehicleAccessoriesOnOrderMutationParsed,
  useVehicleTyreAccessories,
  useVatStateCalculationHandler,
  useConfigurations,
} from '../../../common';
import {
  VehicleConfig,
  prepareVehicleConfig,
  prepareArticles,
  checkAndUpdateCart,
  calculateVatAndCreateInput,
  isOrderEditable,
  getSelectedEquipmentInCart,
  filterSelectedEquipmentInCart,
  updateOrderVariablesForUsageCodeAndTaxes,
  isValidOverriddenCharges,
  getAccessoryCategoryKey,
  updateVehicleVatCodeAndDiscount,
  getOtherFeeTotal,
  checkIfBrukskodeEnabled,
} from '../../../utils';
import {
  AccessoryTool,
  IAccessoryChange,
  AccessoryChangeAction,
  ICustomAccessoryProps,
  getDefaultOpenedCategoryForAccessories,
} from './AccessoryTool';
import { VehicleAccessoriesPageStyled } from './VehicleAccessoriesPage.styled';
import { TireSets } from './tire-sets';
import { AccessorySet } from './accessory-sets';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { CustomAccessorySet } from './custom-accessory-sets';
import {
  ErrorNotification,
  NextErrorNotification,
  OneOffTaxChangeWarning,
  ValidationErrorCode,
} from '../../notifications';
import { MarginCart } from '../../common-components/MarginCart';
import { IStepMenuData } from '../sales-wizard/sales-wizard-menu';
import { CustomMessageBar } from '../../common-components/custom-messagebar';
import { CustomAlertPopup } from '../../common-components/custom-DeletePopup';
import { IAlertPopup } from '../sales-wizard';
import { IconType } from '@next-components/bos-icon';
import { ArticleType, VatCode } from 'sales-common';
import { ToastArea } from '../sales-common/ToastArea';
import { IOverriddenTaxAndUsageCode } from '../sales-vehicle-picker-page';
import { updateOrderMutationWithOrderInputMutationParsed } from '../../../graphql/backend/mutations/order';

export interface IVehicleAccessoriesPageProps extends IVehiclePageProps, INotificationPageProps {
  nonExactMatchCount?: number;
  exactMatchCount?: number;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  isCalled?: boolean;
  cartData?: ICartProps;
  articles?: INextArticle[];
  availableVehicleList?: IVehicleStock[];
  selectedSerialNo?: number;
  vatPrices?: ICartPrices;
  stepMenuData?: IStepMenuData;
  isElectricCar?: boolean;
  isShowDeleteModel?: boolean;
  isShowDeleteButton?: boolean;
  isLoading?: boolean;
  deleteOrderData?: IAlertPopup;
  rootUrl?: string;
  isVehicleValidForReserveOrSign?: boolean;
  currentVehicleConfig?: IVehicleConfiguration;

  setIsCalled?(called: boolean): void;
  setIsLoading?(value: boolean): void;
  setIsShowDeleteModel?: (isShowDeleteModel: boolean) => void;
}

export const VehicleAccessoriesPage = (props: IVehicleAccessoriesPageProps) => {
  const [loading, setLoading] = useState(false);
  const [accessoryTool, setAccessoryTool] = useState<AccessoryTool>(null);
  const [changes, setChanges] = useState<IAccessoryChange[]>([]);
  const order: IOrder = props?.order;
  const vehicle: IVehicleConfiguration = props?.vehicle;
  const [cart, setCart] = useState<ICartProps>(props?.cartData);
  const [articles, setArticles] = useState<INextArticle[]>(props?.articles);
  const [toggleAccessoriesOnOrder] = useMutation(toggleVehicleAccessoriesOnOrderMutationParsed);
  const [updateOrderMutationWithOrderInput] = useMutation(updateOrderMutationWithOrderInputMutationParsed);
  const navigate = useNavigate();
  const location = useLocation();

  const [defaultOpenAccessories, setDefaultOpenAccessories] = useState<string[]>([]);
  const [isCustomAccessoryOpen, setIsCustomAccessoryOpen] = useState<boolean>(false);
  const configurations: IConfiguration = useConfigurations();
  const [orderEditable, setOrderEditable] = useState<boolean>(isOrderEditable(order, configurations));
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);
  const carType = order?.pimSnapshot?.vehicleType ?? props?.vehicle?.model?.vehicleType ?? '';
  const [overriddenTaxAndUsageCodeDetails, setOverriddenTaxAndUsageCodeDetails] = useState<IOverriddenTaxAndUsageCode>({
    chargesAreOverridden: false,
    doneReset: false,
    overriddenVehicleRoadTax: null,
    usageCode: null,
  });
  const [isBrukskodeEnabled, setIsBrukskodeEnabled] = useState<boolean>(checkIfBrukskodeEnabled(props?.configuration));
  useEffect(() => {
    setOverriddenTaxAndUsageCodeDetails({
      chargesAreOverridden:
        props?.order?.chargesAreOverridden ?? overriddenTaxAndUsageCodeDetails?.chargesAreOverridden,
      doneReset: overriddenTaxAndUsageCodeDetails?.doneReset,
      overriddenVehicleRoadTax: props?.order?.vatCalculation?.overriddenVehicleRoadTax,
      usageCode: props?.order?.pimSnapshot?.vehicleUsageCode,
    });
  }, [props?.order]);

  const [articlesUpdated, setArticlesUpdated] = useState<boolean>(false);

  const { finalPrice } = useVatStateCalculationHandler({
    articles,
    carType,
  });

  const isRammeavtaleEnabled = configurations?.features?.rammeavtale ?? false;

  const [rammeavtale, setRammeavtale] = useState<IRammeavtale>(props?.order?.rammeavtale);

  useEffect(() => {
    setIsBrukskodeEnabled(checkIfBrukskodeEnabled(props?.configuration));
  }, [props?.configuration]);

  useEffect(() => {
    const isArticleUpdated = {
      modelArticleUpdated: false,
      oneOffTaxArticleUpdated: false,
    };
    if (
      articles?.length > 0 &&
      (props?.order?.chargesAreOverridden || props?.order?.pimSnapshot?.vehicleUsageCode) &&
      !articlesUpdated
    ) {
      const finalArticles = articles?.map((article) => {
        if (article?.key === VehicleConfig.MODEL) {
          if (
            article?.vatCode ===
            (overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL
              ? VatCode.DEDUCTABLE
              : VatCode.NON_DEDUCTABLE)
          ) {
            isArticleUpdated.modelArticleUpdated = true;
            return article;
          }
          return {
            ...article,
            vatCode:
              overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL
                ? VatCode.DEDUCTABLE
                : VatCode.NON_DEDUCTABLE,
          };
        } else if (article?.key === VehicleConfig.TAREWEIGHT) {
          const overriddenOneOffTax =
            props?.order?.vatCalculation?.overriddenVehicleRoadTax &&
            props?.order?.vatCalculation?.overriddenVehicleRoadTax >= 0
              ? props?.order?.vatCalculation?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot)
              : null;
          if (
            article?.priceExclVat ===
            (overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax)
          ) {
            isArticleUpdated.oneOffTaxArticleUpdated = true;
            return article;
          }
          return {
            ...article,
            priceExclVat: overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax,
          };
        } else {
          return article;
        }
      });
      if (isArticleUpdated.modelArticleUpdated && isArticleUpdated.oneOffTaxArticleUpdated) {
        setArticlesUpdated(true);
      }
      setArticles(finalArticles);
    }
  }, [props?.order, articles]);

  useEffect(() => {
    // tslint:disable-next-line:no-console
    console.log('\u001b[33m !overriddenTaxAndUsageCodeDetails', overriddenTaxAndUsageCodeDetails);
    if (isValidOverriddenCharges(overriddenTaxAndUsageCodeDetails) || overriddenTaxAndUsageCodeDetails?.doneReset) {
      if (overriddenTaxAndUsageCodeDetails?.doneReset) {
        if (articles?.length > 0) {
          const finalArticles = articles?.map((article) => {
            if (article?.key === VehicleConfig.TAREWEIGHT) {
              return {
                ...article,
                priceExclVat: props?.order?.pimSnapshot?.oneOffTaxWeight ?? finalPrice?.oneOffTax,
              };
            } else {
              return article;
            }
          });
          setArticles(finalArticles);
        }
      } else if (isValidOverriddenCharges(overriddenTaxAndUsageCodeDetails)) {
        if (articles?.length > 0) {
          const finalArticles = articles?.map((article) => {
            if (article?.key === VehicleConfig.MODEL) {
              return {
                ...article,
                vatCode:
                  overriddenTaxAndUsageCodeDetails?.usageCode === UsageCodes.EL
                    ? VatCode.DEDUCTABLE
                    : VatCode.NON_DEDUCTABLE,
              };
            } else if (article?.key === VehicleConfig.TAREWEIGHT) {
              return {
                ...article,
                priceExclVat:
                  overriddenTaxAndUsageCodeDetails?.overriddenVehicleRoadTax -
                  getOtherFeeTotal(props?.order?.pimSnapshot),
              };
            } else {
              return article;
            }
          });
          setArticles(finalArticles);
        }
      }
    }
  }, [overriddenTaxAndUsageCodeDetails]);

  const { tires, accessories, isTyreAccLoading } = useVehicleTyreAccessories({
    orderId: order?.id,
    source: order?.lead?.source,
    configuration: props?.configuration,
    serialNo: order?.vehicles?.[0]?.serialNo,
    make: order?.lead?.make,
  });

  /*
   * Any changes to order or vehicle data would trigger the following useEffect,
   * this usually happens on mount of component or any order mutation is called
   */
  useEffect(() => {
    if (!order) {
      return;
    }
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(
      props.configuration,
      order?.lead?.source,
      order?.vehicles?.[0]?.serialNo || location?.state?.serialNo,
      order?.lead?.make,
    );

    if (!isStockSaleOrder && !vehicle) return;
    const accessoryInstance = new AccessoryTool(order, vehicle, tires, accessories, isStockSaleOrder);
    setAccessoryTool(accessoryInstance);
    resetAccessories();
    setDefaultOpenAccessories(getDefaultOpenedCategoryForAccessories(accessoryInstance?.mappedAccessories));
    setIsCustomAccessoryOpen(Boolean(accessoryInstance?.mappedCustomAccessories?.length));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, vehicle, tires, accessories]);

  useEffect(() => {
    if (props?.isCalled && !changes?.length) {
      nextStep();
    }
    if (props?.isCalled && changes?.length) {
      saveAccessories();
    }
  }, [props?.isCalled]);

  useEffect(() => {
    props.setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (!isEqual(cart, props?.cartData)) {
      setCart(props.cartData);
    }
    if (!isEqual(articles, props?.articles)) {
      setArticles(props.articles);
    }
  }, [props?.cartData, props?.articles]);

  useEffect(() => {
    setOrderEditable(isOrderEditable(order, props?.configuration));
  }, [order, props?.configuration]);

  useEffect(() => {
    if (finalPrice?.error) {
      setNotifications([
        <ErrorNotification
          key="vatError"
          errorCode={ValidationErrorCode.VAT_VALIDATION}
          severityLevel={MessagebarSeverityLevel.ERROR}
          onClose={() => setNotifications([])}
        />,
      ]);
    }
  }, [finalPrice?.error]);

  const nextStep = () => {
    const route = Routes.getFinancePage(props.orderId);
    props.setIsLoading(loading);
    props.setIsCalled(false);
    const redirectUrl = get(props?.stepMenuData, 'route') ? props?.stepMenuData?.route : route;
    navigate(redirectUrl);
  };

  const resetAccessories = (isResetButtonCliked = false): void => {
    if (isResetButtonCliked) {
      setCart(props?.cartData);
      setArticles(props?.articles);
    }
    setChanges([]);
  };

  const onToggleAccessoriesOnOrder = async (): Promise<IOrder> => {
    if (!order?.id || !changes?.length) {
      return null;
    }
    setLoading(true);
    const vatCalculation = calculateVatAndCreateInput(articles, carType);
    if (!vatCalculation) {
      setLoading(false);
      props.raiseNotification(
        <ErrorNotification
          key="vatError"
          errorCode={ValidationErrorCode.VAT_VALIDATION}
          severityLevel={MessagebarSeverityLevel.ERROR}
          onClose={() => props?.clearNotifications('vatError')}
        />,
      );
      resetAccessories(true);
      return;
    }
    const removeAccesories = changes?.filter((change) => change?.action === AccessoryChangeAction.REMOVE);
    const removedAccessories = removeAccesories?.length ? accessoryTool?.getRemoveInput(removeAccesories) : [];

    const addAccessories = changes?.filter((change) => change?.action === AccessoryChangeAction.ADD);
    const addedAccessories: IVehicleAccessory[] = EnumMapper.mapEnumsToStringRecursively(
      addAccessories?.length ? accessoryTool.getAddInput(addAccessories) : [],
    );

    try {
      let variables = {
        orderId: order?.id,
        addAccessories: addedAccessories,
        removeAccessories: removedAccessories,
        nextArticles: articles,
      };
      //Articles
      variables = updateOrderVariablesForUsageCodeAndTaxes(variables, overriddenTaxAndUsageCodeDetails) as any;
      const { data } = await toggleAccessoriesOnOrder({ variables });
      if (data?.toggleVehicleAccessoriesOnOrder?.__typename === 'NextError') {
        props.raiseNotification(
          <NextErrorNotification
            key={'toggleAccessoryFail'}
            orderId={order?.id}
            errorCode={data?.toggleVehicleAccessoriesOnOrder?.errorCode}
            onClose={() => props?.clearNotifications('toggleAccessoryFail')}
          />,
        );
      }
      setLoading(false);
      if (props?.isCalled) {
        nextStep();
      }
      return data?.toggleVehicleAccessoriesOnOrder;
    } catch (error) {
      console.error('Error toggling vehicle accessories to order', error);
      setLoading(false);
      return null;
    }
  };

  const saveAccessories = () => {
    onToggleAccessoriesOnOrder()
      .catch((error) => {
        console.error('An error occurred whilst updating accessories', error);
      })
      .finally(() => {
        resetAccessories();
      });
  };

  const updateTyreInCart = (tyreId: string): void => {
    const cartCopy: ICartProps = cloneDeep(cart);
    if (accessoryTool?.tireSetData?.length) {
      accessoryTool?.tireSetData?.forEach((tire) => {
        if (tire?.id === tyreId) {
          const vatCode = mapAccessoryVatCode(tire);
          const changeTyre = prepareVehicleConfig(
            `${VehicleConfig.TYRE}`,
            tire?.id,
            tire?.name,
            tire?.priceExcludingVat,
            vatCode,
          );
          setArticles([
            ...prepareArticles(
              `${VehicleConfig.TYRE}`,
              tire?.priceExcludingVat,
              vatCode,
              ArticleType.WINTERTIRES,
              tire?.retailPriceExcludingVat ?? 0,
              false,
              articles,
              // TODO
            ),
          ]);
          checkAndUpdateCart(`${VehicleConfig.TYRE}`, cartCopy, setCart, changeTyre);
        }
      });
    }
  };

  const addNewOrRemoveInverseChange = (newChanges: IAccessoryChange[]) => {
    const prevChanges = [...changes];
    newChanges?.forEach(({ action, accessoryId, manualData }) => {
      const exisitingAction = prevChanges?.find((change) => change?.accessoryId === accessoryId);
      const inverseAction = action * -1;
      if (!exisitingAction) {
        prevChanges?.push({ accessoryId, action, manualData });
      } else if (exisitingAction?.action === inverseAction) {
        prevChanges?.splice(prevChanges?.indexOf(exisitingAction), 1);
      }
    });
    setChanges(prevChanges);
  };

  const raiseWarningForUnselectTire = () => {
    props.raiseNotification(
      <ErrorNotification
        key="removeLeasing"
        errorCode={ValidationErrorCode.UNABLE_TO_UNSELECT_TIRES}
        severityLevel={MessagebarSeverityLevel.WARNING}
        onClose={() => props?.clearNotifications('removeLeasing')}
      />,
    );
  };

  const switchTire = (from?: string, to?: string): void => {
    const switchChanges: IAccessoryChange[] = [];
    // Selected tire removed from PIM so take it from order
    if (!from) {
      from = order?.accessories?.find(
        (accessory) => accessory?.accessoryType?.toString() === AccessoryType[AccessoryType.TYRE],
      )?.customId;
    }
    if (from) {
      const fromChange: IAccessoryChange = { accessoryId: from, action: AccessoryChangeAction.REMOVE };
      switchChanges?.push(fromChange);
    }
    if (to) {
      updateTyreInCart(to);
      const toChange: IAccessoryChange = { accessoryId: to, action: AccessoryChangeAction.ADD };
      switchChanges?.push(toChange);
    }
    addNewOrRemoveInverseChange(switchChanges);
  };

  const removeAccessory = (
    id: string,
    accessory: ICustomAccessoryProps,
    accessoryType = AccessoryType.ACCESSORY,
  ): void => {
    const cartCopy: ICartProps = cloneDeep(cart);
    const accessoryKey =
      accessoryType === AccessoryType.TYRE
        ? `${VehicleConfig.TYRE}`
        : getAccessoryCategoryKey(accessory?.accessoryCategory)
        ? `${getAccessoryCategoryKey(accessory?.accessoryCategory)}_${id || ''}`
        : `${VehicleConfig.ACCESSORY}_${id || ''}`;
    const recommendedTireConfig: ICartSummaryData = prepareVehicleConfig(accessoryKey, id, '', 0, '');

    setArticles([
      ...prepareArticles(
        accessoryKey,
        accessory?.priceExcludingVat,
        accessory?.vatCode,
        ArticleType.ACCESSORY,
        accessory?.retailPriceExcludingVat ?? 0,
        false,
        articles,
        true,
      ),
    ]);

    checkAndUpdateCart(accessoryKey, cartCopy, setCart, recommendedTireConfig, true);
    const change: IAccessoryChange = { accessoryId: id, action: AccessoryChangeAction.REMOVE };
    addNewOrRemoveInverseChange([change]);
  };

  const addAccessory = (accessoryId: string, accessory?: ICustomAccessoryProps) => {
    const cartCopy: ICartProps = cloneDeep(cart);
    const accessoryKey = getAccessoryCategoryKey(accessory?.accessoryCategory)
      ? `${getAccessoryCategoryKey(accessory?.accessoryCategory)}_${accessoryId || ''}`
      : `${VehicleConfig.ACCESSORY}_${accessoryId || ''}`;
    const vatCode = mapAccessoryVatCode(accessory, accessory?.isManualInput);

    const newAccessory: ICartSummaryData = prepareVehicleConfig(
      accessoryKey,
      accessory?.id,
      accessory?.name,
      accessory?.priceExcludingVat,
      vatCode,
      false,
      accessory?.retailPriceExcludingVat,
    );
    setArticles([
      ...prepareArticles(
        accessoryKey,
        accessory?.priceExcludingVat,
        vatCode,
        ArticleType.ACCESSORY,
        accessory?.retailPriceExcludingVat ?? 0,
        false,
        articles,
      ),
    ]);
    checkAndUpdateCart(accessoryKey, cartCopy, setCart, newAccessory);

    const manualData = accessory?.isManualInput
      ? {
          ...accessory,
          priceExcludingVat: accessory?.priceExcludingVat,
          priceIncludingVat: accessory?.price,
          vatCode,
        }
      : null;

    const change: IAccessoryChange = {
      accessoryId,
      action: AccessoryChangeAction.ADD,
      manualData,
    };

    addNewOrRemoveInverseChange([change]);
  };

  const updateArticlesWithDiscount = (accessory: IAccessoryList, customerAdvantage?: boolean, discountArticles?: INextArticle[]) => {
    if (customerAdvantage) {
      setArticles(discountArticles);
    } else {
      if (accessory?.articleType === ArticleType.DELIVERY) {
        setArticles([
          ...prepareArticles(
            accessory?.key,
            accessory?.price,
            accessory?.vatCode,
            accessory.articleType,
            accessory.discountedPrice,
            false,
            articles,
            false,
            '',
            0,
            accessory.discountAmountExclVat,
          ),
        ]);
      } else {
        setArticles([
          ...prepareArticles(
            accessory?.key,
            accessory?.price,
            accessory?.vatCode,
            accessory.articleType,
            accessory.discountedPrice,
            false,
            articles,
            false,
            '',
            accessory.discountPercentage,
          ),
        ]);
      }
    }
    
  };

  const saveOverriddenTax = async (overriddenTax) => {
    if (props?.order?.vatCalculation?.overriddenVehicleRoadTax !== overriddenTax) {
      setLoading(true);
      await onToggleAccessoriesOnOrder();
      try {
        let updatedArticles = cloneDeep(articles);
        const finalArticles = updatedArticles?.map((article) => {
          if (article?.key === VehicleConfig.TAREWEIGHT) {
            const overriddenOneOffTax =
              overriddenTax && overriddenTax >= 0
                ? overriddenTax - getOtherFeeTotal(order?.pimSnapshot)
                : props?.order?.vatCalculation?.overriddenVehicleRoadTax >= 0
                ? props?.order?.vatCalculation?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot)
                : null;
            return {
              ...article,
              priceExclVat: overriddenOneOffTax ?? props?.order?.pimSnapshot?.vehicleRoadTax,
            };
          } else {
            return article;
          }
        });
        const nextArticles = {
          articles: finalArticles,
          manufactureComponentId: props?.order?.pimSnapshot?.modelFactoryCodeId,
        };
        const variables = {
          orderInput: {
            chargesAreOverridden: overriddenTax ? true : false,
            vatCalculation: {
              overriddenVehicleRoadTax: overriddenTax,
            },
          },
          updateOrderId: props?.order?.id,
          nextArticles: nextArticles,
        };
        const { data } = await updateOrderMutationWithOrderInput({
          variables,
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const editAccessory = (
    accessoryId: string,
    oldAccessory?: ICustomAccessoryProps,
    accessory?: ICustomAccessoryProps,
    accessoryType = AccessoryType.ACCESSORY,
  ) => {
    const cartCopy: ICartProps = cloneDeep(cart);
    const accessoryKey =
      accessoryType === AccessoryType.TYRE
        ? `${VehicleConfig.TYRE}`
        : getAccessoryCategoryKey(oldAccessory?.accessoryCategory)
        ? `${getAccessoryCategoryKey(oldAccessory?.accessoryCategory)}_${accessoryId || ''}`
        : `${VehicleConfig.ACCESSORY}_${accessoryId || ''}`;
    const recommendedTireConfig: ICartSummaryData = prepareVehicleConfig(accessoryKey, accessoryId, '', 0, '');

    const updatedArticlesData = [
      ...prepareArticles(
        accessoryKey,
        oldAccessory?.priceExcludingVat,
        oldAccessory?.vatCode,
        ArticleType.ACCESSORY,
        oldAccessory?.retailPriceExcludingVat,
        false,
        articles,
        true,
      ),
    ];
    const change: IAccessoryChange = { accessoryId: accessoryId, action: AccessoryChangeAction.REMOVE };

    const updatedCartdata = checkAndUpdateCart(accessoryKey, cartCopy, setCart, recommendedTireConfig, true, true);

    const vatCode = mapAccessoryVatCode(accessory, accessory?.isManualInput);
    const newAccessoryKey = getAccessoryCategoryKey(accessory?.accessoryCategory)
      ? `${getAccessoryCategoryKey(accessory?.accessoryCategory)}_${accessory?.id || ''}`
      : `${VehicleConfig.ACCESSORY}_${accessory?.id || ''}`;

    const newAccessory: ICartSummaryData = prepareVehicleConfig(
      newAccessoryKey,
      accessory?.id,
      accessory?.name,
      accessory?.priceExcludingVat,
      vatCode,
      false,
      accessory?.retailPriceExcludingVat,
    );
    setArticles([
      ...prepareArticles(
        newAccessoryKey,
        accessory?.priceExcludingVat,
        vatCode,
        ArticleType.ACCESSORY,
        accessory?.retailPriceExcludingVat,
        false,
        updatedArticlesData,
      ),
    ]);
    checkAndUpdateCart(accessoryKey, updatedCartdata, setCart, newAccessory);

    const manualData = accessory?.isManualInput
      ? {
          ...accessory,
          priceExcludingVat: accessory?.priceExcludingVat,
          priceIncludingVat: accessory?.price,
          vatCode,
        }
      : null;

    const updateChange: IAccessoryChange = {
      accessoryId: accessory?.id,
      action: AccessoryChangeAction.ADD,
      manualData,
    };

    addNewOrRemoveInverseChange([change, updateChange]);
  };

  if (!order) {
    return <LoadingSpinner isModal={true} />;
  }

  const isLeasingOffer =
    get(order, 'chosenOffer')?.financingType === FinancingType.LEASING ||
    get(order, 'offers')?.some((offer) => offer?.financingType === FinancingType.LEASING);

  const handleClose = () => {
    props.setIsShowDeleteModel(false);
  };

  const isChargesEditable = () => {
    if (props?.order?.pimSnapshot?.vehicleUsageCode && isBrukskodeEnabled) {
      return !['EL', 'PB'].includes(props?.order?.pimSnapshot?.vehicleUsageCode);
    }
    return false;
  };

  return (
    <VehicleAccessoriesPageStyled className="mainContainer">
      <div className="leftContainer vehicleAccessoriesPage">
        <div className="msgContainer">
          {props.vehicleChargeStatus?.isTaxChange ? (
            <div>
              <OneOffTaxChangeWarning vehicleChargeStatus={props.vehicleChargeStatus} order={props.order} />
            </div>
          ) : (
            ''
          )}
          <CustomMessageBar orderEditable={orderEditable} />
        </div>
        <TireSets
          loading={loading}
          changes={changes}
          tireSetData={accessoryTool?.tireSetData}
          handleSwitchTire={switchTire}
          handleRemoveTire={removeAccessory}
          isLeasingOffer={isLeasingOffer}
          raiseWarningForUnselectTire={raiseWarningForUnselectTire}
        />
        {accessoryTool?.mappedAccessories && (
          <AccessorySet
            loading={loading}
            orderId={order?.id}
            accessories={accessoryTool?.mappedAccessories}
            defaultOpenAccessories={defaultOpenAccessories}
            changes={changes}
            onAddAccessory={addAccessory}
            onRemoveAccessory={removeAccessory}
          />
        )}
        <CustomAccessorySet
          loading={loading}
          order={order}
          accessories={accessoryTool?.mappedCustomAccessories}
          changes={changes}
          onAddAccessory={addAccessory}
          onRemoveAccessory={removeAccessory}
          isCustomAccessoryOpen={isCustomAccessoryOpen}
          setIsCustomAccessoryOpen={setIsCustomAccessoryOpen}
          orderId={props?.orderId}
          calculationId={props?.calculationId}
          activeStep={props?.activeStep}
          isUnavailablePimData={props?.isUnavailablePimData}
          menuLoading={props?.menuLoading}
          onClick={props?.onClick}
          onClickSigning={props?.onClickSigning}
          rootUrl={props?.rootUrl}
          isVehicleValidForReserveOrSign={props?.isVehicleValidForReserveOrSign}
          vehicleAvailabilityCode={props.vehicleAvailabilityCode}
          isVarebilEnabled={props.isVarebilEnabled}
          onEditAccessory={editAccessory}
          invalidPcodesValidation={props?.invalidPcodesValidation}
        />
      </div>
      <Cart
        rammeavtale={isRammeavtaleEnabled}
        exactMatchCount={props.exactMatchCount}
        nonExactMatchCount={props.nonExactMatchCount}
        make={cart?.make}
        modelSeries={cart?.modelSeries}
        prices={finalPrice}
        image={cart?.image}
        data={updateVehicleVatCodeAndDiscount(
          filterSelectedEquipmentInCart(cart?.data, getSelectedEquipmentInCart(cart?.data)),
          overriddenTaxAndUsageCodeDetails?.usageCode,
          order,
          rammeavtale
        )}
        isVehicleSelected={props?.isVehicleSelected}
        serialNo={props?.selectedSerialNo}
        selectedLocation={props?.vehicleLocation}
        selectedStatus={props?.vehicleStatus}
        isFactoryOrder={props?.isFactoryOrder}
        expectedProductionDate={props?.expectedProductionDate}
        vehicleCount={props?.availableVehicleList?.length}
        isElectricCar={props?.isElectricCar}
        showMBApiWarning={order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED}
        equipmentDetails={getSelectedEquipmentInCart(cart?.data)}
        footerChildren={
          <MarginCart
            rammeavtale={rammeavtale}
            order={props?.order}
            vehicleConfiguration={props?.vehicle}
            articles={articles}
            vatPrices={props?.vatPrices}
            carType={carType}
            isUnavailablePimData={props?.isUnavailablePimData}
            saveAccessories={onToggleAccessoriesOnOrder}
            accessoryChanges={cart?.data}
            setRammeavtale={setRammeavtale}
            onUpdateArticles={updateArticlesWithDiscount}
          />
        }
        showAbortFactoryOrderAction={props.isShowDeleteButton}
        abortFactoryBorder={{
          buttonIcon: IconType?.NewNextTrash,
          buttonClass: 'red',
          buttonLabel: props.order?.vehicles?.[0]?.serialNo ? 'Fjern bil' : 'Avbryt fabrikkbestilling',
          buttonAction: () => {
            props.setIsShowDeleteModel(!props.isShowDeleteModel);
          },
        }}
        oneOffTaxAndWreckageDetails={
          finalPrice?.oneOffTaxAndWreckage >= 0
            ? {
                isEditable: isChargesEditable(),
                taxAndWreckageValue:
                  props?.order?.vatCalculation?.overriddenVehicleRoadTax ?? finalPrice?.oneOffTaxAndWreckage,
                resetTaxAndWreckageValue: props?.order?.vatCalculation?.oneOffTaxAndWreckage,
                inputValue: (param, doneReset) => {
                  setOverriddenTaxAndUsageCodeDetails({
                    ...overriddenTaxAndUsageCodeDetails,
                    chargesAreOverridden: !doneReset,
                    doneReset: doneReset,
                    overriddenVehicleRoadTax: !doneReset ? param : null,
                  });
                  saveOverriddenTax(!doneReset ? param : null);
                },
              }
            : ({} as IOneOffTaxAndWreckage)
        }
      />
      {(props?.isLoading || isTyreAccLoading) && <LoadingSpinner isModal={true} />}
      <ToastArea notifications={notifications}></ToastArea>
      {props.isShowDeleteModel && (
        <CustomAlertPopup
          isOpen={props.isShowDeleteModel}
          onCloseClick={handleClose}
          deleteOrderData={props.deleteOrderData}
          className="deleteAlert"></CustomAlertPopup>
      )}
    </VehicleAccessoriesPageStyled>
  );
};
