import { bosColor, padding, responsive, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const VehicleStockListDialogStyled = styled.div<any>`
  ${padding['16'].t};
  ${padding['0'].b};

  display: grid;
  grid-template-columns: 17.75rem 1fr;
  gap: 1.5rem;
  align-items: flex-start;
  ${responsive.lgMax} {
    grid-template-columns: 17.75% 1fr;
  }
  .left-container {
    display: grid;
    gap: 1.5rem;
    h3 {
      font-size: 1.5rem;
      line-height: 165%;
    }
  }

  .filter-accordion-container {
    border-top: 1px solid ${bosColor.neutralGrey};
    .content {
      ${padding['16'].x};
      &[open] {
        padding: 0 1rem 1.5rem;
        max-height: 20rem;
        overflow: auto;
      }

      > div {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: flex-start;
        gap: 0.5rem;
        [type='checkbox']:checked + label,
        [type='checkbox']:not(:checked) + label {
          padding-left: 0;
          line-height: 175%;
          word-wrap: break-word;
          word-break: break-all;
          text-decoration: none;
          ${responsive.lgMax} {
            font-size: 0.875rem;
          }
        }
      }
      .showOptions {
        &::after{
          display: none;
        }

        span::after {
          display: flex;
          content: "";
          position: relative;
          top: 0;
          height: 3px;
          width: 100%;
          left: 0;
          border-radius: 3px;
          background-color: ${bosColor.turquoise};
        }
      }
    }

    button.btnAccordion:not(.search-button) {
      padding: 1rem;
      position: relative;
      .distanceAndIconContainer {
        position: absolute;
        right: 1rem;
      }
    }
  }

  .right-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    position: sticky;
    top: 12rem;

    .vehicleListValidation {
      .message-box-info.messageBarWarning {
        width: 100%;
      }
    }

    .tabsList > div {
      display: grid;
      grid-template-columns: 100%;
      .tableContainer {
        .item.grid-row {
          .grid-item > div > div {
            .tagGroup {
              background: none;
              border: none;
              p {
                color: ${bosColor.black};
                font-size: 0.875rem;
                font-weight: ${typography.weight.semibold};
                line-height: 170%;
              }
            }
            span span.tooltip {
              color: ${bosColor.white};
              background: ${bosColor.black};
              z-index: 99;
              bottom: calc(100% + 0.625rem);
              border-radius: 0.5rem;
              padding: 0rem;
              max-width: 25rem;

              &::after {
                top: inherit;
                transform: rotate(227deg);
                bottom: -0.25rem;
              }

              span {
                color: ${bosColor.white};
                background: ${bosColor.black};
              }

              .tooltip-container {
                display: flex;
                gap: 0.5rem;
                align-items: flex-start;
                padding: 0.5rem 0.75rem;

                p.tooltipLabel {
                  color: ${bosColor.white};
                  font-weight: ${typography.weight.semibold};
                }

                .new.rounded {
                  p {
                    color: ${bosColor.black};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .right-container + .changeSelectedCarModal {
    .custom-modal {
      border-radius: 0.5rem;
      width: 36.5rem;

      .inner-container > .content-container {
        .alertModalBodyContainer {
          div {
            h3 {
              font-size: 1.25rem;
              line-height: 180%;
              font-weight: ${typography.weight.extrabold} !important;
            }
            p {
              white-space: pre-line;
            }
          }
        }
      }
    }
  }
`;

export const TagStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  > .new.close:not(.dark) {
    border-color: transparent;
  }
`;

export const BrukskodeSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: auto;

  .brukskodeTag {
    display: flex;
    padding: 0.125rem 0.75rem;
    align-items: flex-start;
    gap: 0.625rem;
    border: 1px solid ${bosColor.neutralPlus};
    border-radius: 50rem;
    height: fit-content;
    background-color: ${bosColor.neutralGrey};

    .brukskodeValue {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: ${typography.weight.semibold};
      line-height: 140%;
      padding: 0;
      margin: auto;
      color: ${bosColor.black};

      span.boldValue {
        font-size: 0.875rem;
        font-weight: ${typography.weight.extrabold};
      }
    }

    &:hover {
      border-radius: 62.4375rem;
      border: 1px solid ${bosColor.concrete};
      background: ${bosColor.neutralGrey};
    }
  }

  > .new.close:not(.dark) {
    border-color: transparent;
  }
`;