import { IconButton } from '@next-components/icon-button';
import { bosColor } from '@next-components/common-styles';
import { IconType } from '@next-components/bos-icon';
import { AddCustomAccessorySection } from './CustomAccessoryAdd.styled';

interface ICustomAccessoryProps {
  onAdd?(input: boolean): void;
  description: string;
  className?: string;
}

export const CustomAccessoryAdd = (props: ICustomAccessoryProps) => {
  return (
    <AddCustomAccessorySection className={props?.className}>
      <div onClick={() => props.onAdd(true)} className="iconButtonWrapper" data-testid="add-custom-accessory">
        <IconButton
          ariaLabel={'add-custom-accessory'}
          className={'addItem'}
          fill={bosColor.turquoise}
          icon={IconType.NewNextPlus}
        />
        <span>{props?.description}</span>
      </div>
    </AddCustomAccessorySection>
  );
};
