import { Icon, IconType } from '@next-components/bos-icon';
import { Button } from '@next-components/cta';
import { CustomModal } from '@next-components/custom-modal';
import { CustomModalWrapper } from '../../layout/CustomModalWrapper.styled';
import { ModalActions } from '../../sales/sales-common/modal.styled';
import { useEffect, useState } from 'react';

export interface IInvalidDatePopup {
  isOpen: boolean;
  isDateValidationPopup: string;
  handleClose: () => void;
}

export const InvalidDatePopup = (props: IInvalidDatePopup) => {
  const [data, setData] = useState(null);

  const handleModalClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.isDateValidationPopup) {
      setData(JSON.parse(props.isDateValidationPopup));
    }
  }, [props.isDateValidationPopup]);

  return (
    <CustomModalWrapper className="backdrop smModal pCodeModel" active={props.isOpen}>
      <CustomModal onCloseClick={handleModalClose} active={props.isOpen} isModelCloseAllowedOutside={false}>
        <header>
          <Icon icon={IconType.Warning} color="#edbb5a" />
          <h3>
            {data?.selected
              ? 'Det gjeldende alternativet bør ikke velges fordi gyldighetsdatoen har passert.'
              : 'De gjeldende alternativene tillater ikke valg fordi gyldighetsdatoen har passert.'}
          </h3>
        </header>

        <ModalActions items={2} className="commentBoxModal">
          <div className="gridListItem">
            <Button onClick={handleModalClose}>
              <span>OK</span>
            </Button>
          </div>
        </ModalActions>
      </CustomModal>
    </CustomModalWrapper>
  );
};
