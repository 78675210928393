import { PivotField } from '@next-components/pivot-field';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IDropdownOption, InputType, formatNumberInput } from '@next-components/common-types';
import { useState } from 'react';
import {
  DropDownValues,
  IDiscountFieldProps,
  articlesForDiscount,
  dropDownOptions,
  getValidity,
  validateDiscount,
} from '../MarginCartUtils';
import { defaultTo, get } from 'lodash';
import { OnChangeValue } from 'react-select';
import { ICartPrices } from '@next-components/cart';
import { ICalculatePricesBasedOnArticleListResult } from 'sales-common-calculations';
import { IAccessoryList, IAuxiliaryCalculationsInput, INextArticle, mapVatPricesForNext } from 'next-common';
import { calculateVatForElCars } from '../../../../utils';

interface ICalculateDiscountProps extends IDiscountFieldProps {
  articles: INextArticle[];
  vatPrices: ICartPrices;
  setVatPrices(vatPrices: ICartPrices): void;
  carType: string;
  onUpdateArticles?(value: IAccessoryList, customerAdvantage?: boolean, discountArticles?: INextArticle[]): void;
}

export const CalculateDiscount = (props: ICalculateDiscountProps) => {
  const [inputType, setInputType] = useState<InputType>(InputType.INTEGER);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const getDiscountValue = (marginObject: string = 'activeMargin') => {
    if (inputType === InputType.INTEGER) {
      return get(props, `${marginObject}.campaignDiscount`, 0);
    }
    return get(props, `${marginObject}.campaignDiscountPercentage`, 0);
  };

  const handleOnChangeDropDown = (dropDownValue: OnChangeValue<IDropdownOption<any>, false>): void => {
    switch (dropDownValue.label) {
      case DropDownValues.KR:
        setInputType(InputType.INTEGER);
        break;
      case DropDownValues.PERCENTAGE:
        setInputType(InputType.FLOAT);
        break;
    }
  };

  const onDiscountChange = (
    input: string | number,
    dropDownValue?: OnChangeValue<IDropdownOption<any>, false>,
    formatted?: string,
    event?: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    if (event.type === 'blur') {
      return null;
    }

    const isChanged = getDiscountValue('latestMargin') !== Number(input);
    
    setHasChanged(isChanged);
    
    const valid = validateDiscount(Number(input), dropDownValue?.label as DropDownValues, 30);

    props.setIsValid(valid);

    let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;
    let auxillaryInput: IAuxiliaryCalculationsInput = null;
    let discount: number = 0;

    let discountArticles = [];

    if (inputType === InputType.INTEGER) {
      auxillaryInput = {
        discount: Number(input),
        importerDiscount: props.activeMargin?.campaignImporter,
        importerPercentage: props.activeMargin?.campaignImporterPercentage,
      };

      discountArticles = articlesForDiscount(props.articles, auxillaryInput, props.order);

      console.log('Margin articles', discountArticles);

      vatCalculation = calculateVatForElCars(discountArticles, props.carType);

      discount = vatCalculation?.discount;
    } else {
      auxillaryInput = {
        discountPercentage: Number(input),
        importerDiscount: props.activeMargin?.campaignImporter,
        importerPercentage: props.activeMargin?.campaignImporterPercentage,
      };

      discountArticles = articlesForDiscount(props.articles, auxillaryInput, props.order);

      console.log('Margin articles', discountArticles);

      vatCalculation = calculateVatForElCars(discountArticles, props.carType);

      discount = vatCalculation?.discount;
    }

    console.log(vatCalculation);

    props?.setActiveMargin({
      ...get(props, 'activeMargin', []),
      campaignDiscount: Math.abs(discount),
      campaignDiscountPercentage: vatCalculation?.customerAdvantagePercentage,
      marginValue: vatCalculation?.marginAmount,
      marginPercentage: vatCalculation?.marginPercentage,
    });
    
    if (!valid) return;

    props.setVatPrices(mapVatPricesForNext(vatCalculation) as any);
    if (typeof props.onUpdateArticles === 'function') { 
      props.onUpdateArticles(null, true, discountArticles);
    }
  };

  return (
    <div className="discountContainer">
      <label htmlFor="add-discount" className="pivotFieldLabel">
        Legg til rabatt
      </label>
      <PivotField
        dropDownOptions={dropDownOptions}
        onChangeDropDown={handleOnChangeDropDown}
        onChange={(input, drop, formatted, event) => onDiscountChange(input, drop, formatted, event)}
        value={`${getDiscountValue()}`}
        disabled={false}
        type={inputType}
        decimals={2}
        {...getValidity(
          hasChanged,
          props?.vatPrices?.discount > 0 ? props.isValid : null,
          props.vehicleConfiguration,
          true,
        )}
      />
      {defaultTo(props.pimSnapshot?.customerAdvantageExcludingVat, 0) !== 0 && (
        <Messagebar
          className="small"
          messageBoxInfo
          severityLevel={MessagebarSeverityLevel.INFORMATION}
          title={`Markedsført rabatt: ${
            formatNumberInput(
              Math.abs(get(props, 'pimSnapshot.customerAdvantageExcludingVat', 0))?.toString(),
              inputType,
              2,
            )?.formatted || 0
          } kr`}
        />
      )}
    </div>
  );
};
