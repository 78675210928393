import styled from 'styled-components';
import { fontWeightExtraBold, bosColor } from '@next-components/common-styles';

export const AddCustomAccessorySection = styled.section`
  color: ${bosColor.black};
  font-size: 1.125rem;
  font-style: normal;
  font-weight: ${fontWeightExtraBold};
  line-height: 175%;

  .iconButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .addItem > svg {
      padding: 4px;
    }
  }
  &.custom-accessory-add-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${bosColor.neutralGrey};
    border-radius: 0.5rem;
    min-height: 24.5rem;
  }
`;
