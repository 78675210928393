import React, { useEffect, useState } from 'react';
import { OverlayContainer } from '@next-components/overlay-container';
import { bosColor } from '@next-components/common-styles';
import { useDisableScroll } from '../../../../common';
import { CustomModal } from '@next-components/custom-modal';
import {
  BrukskodeActions,
  BrukskodeModalContent,
  BrukskodeModalHeader,
  BrukskodeModalWrapper,
  BurkskodeOptionContainer,
  Divider,
  NewUserBrukskode,
  SelectedBrukskode,
} from './BrukskodeAlertModal.styled';
import { BosDropdown, IBosDropDownOptions } from '@next-components/bos-dropdown';
import { defaultTo } from 'lodash';
import { Button } from '@next-components/button';
import { IOrder, IVehicleConfiguration, UsageCodes } from 'next-common';
import { IOverriddenTaxAndUsageCode } from '../VehiclePickerPage';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IconType } from '@next-components/bos-icon';
import { isStockOrFactoryOrder } from '../../../../utils';

interface IBrukskodeModal {
  isOpen: boolean;
  className?: string;
  allowScrolling?: boolean;
  onClose: () => void;
  onSave:  (usageCode: string) => void;
  isLoading: boolean;
  order: IOrder;
  vehicleType: string;
  overriddenTaxAndUsageCodeDetails: IOverriddenTaxAndUsageCode;
  setOverriddenTaxAndUsageCodeDetails: React.Dispatch<React.SetStateAction<IOverriddenTaxAndUsageCode>>;
  setIsChange: (value: React.SetStateAction<boolean>) => void
  setIsChangeVehicle: (value: boolean) => void
}

export interface IUsageCodeOptions { key: string, label: string, isSelected: boolean, isDefaultValue?: boolean, value: string }

const getIsSelectedValue = (overriddenTaxAndUsageCodeDetails: IOverriddenTaxAndUsageCode, usageCode: string): boolean => {
  if ((overriddenTaxAndUsageCodeDetails?.usageCode === usageCode) && !overriddenTaxAndUsageCodeDetails?.doneReset) {
    return true
  }
  return false;
}

const prepareOptionList = (overriddenTaxAndUsageCodeDetails: IOverriddenTaxAndUsageCode) => {
  const usageCodesOptionList: IUsageCodeOptions[] = [];
  Object.keys(UsageCodes)?.forEach(code => {
    switch (code) {
      case UsageCodes.EL:
        usageCodesOptionList?.push({ key: 'EL', label: 'EL', isSelected: getIsSelectedValue(overriddenTaxAndUsageCodeDetails, 'EL') , value: 'Elektrisk personbil' });
        break;
      case UsageCodes.ELV:
        usageCodesOptionList?.push({ key: 'ELV', label: 'ELV', isSelected: getIsSelectedValue(overriddenTaxAndUsageCodeDetails, 'ELV'), value: 'Elektrisk varebil' },);
        break;
      case UsageCodes.PB:
        usageCodesOptionList?.push({ key: 'PB', label: 'PB', isSelected: getIsSelectedValue(overriddenTaxAndUsageCodeDetails, 'PB'), value: 'Personbil' });
        break;
      case UsageCodes.V2:
        usageCodesOptionList?.push({ key: 'V2', label: 'V2', isSelected: getIsSelectedValue(overriddenTaxAndUsageCodeDetails, 'V2'), value: 'Varebil klasse 2' });
        break;
      default:
        break;
    }
  })
  return usageCodesOptionList;
}

export const BrukskodeAlertModal = (props: IBrukskodeModal) => {
  const { isOpen, className, allowScrolling, onClose, onSave, order, vehicleType, isLoading,
     overriddenTaxAndUsageCodeDetails, setOverriddenTaxAndUsageCodeDetails } = props;

  const [optionsList, setOptionsList] = useState([]);
  useDisableScroll(allowScrolling ? false : isOpen);

  useEffect(() => {
    const optionList = prepareOptionList(overriddenTaxAndUsageCodeDetails);
    setOptionsList(optionList);
  }, [order, overriddenTaxAndUsageCodeDetails]);
  
  const customOption = (param: IBosDropDownOptions) => (
    <BurkskodeOptionContainer isSelected={param?.isSelected} className="BurkskodeOptionContainer">
      <span className={`optionLabel ${param?.isSelected ? 'selected' : ''}`}>{defaultTo(param?.label, '')}</span>
      <span className="distanceLabel">{defaultTo(param?.value, '')}</span>
    </BurkskodeOptionContainer>
  );

  const handleOptionSelection = (param) => {
    const tt = optionsList?.map((item) => ({
      ...item,
      isSelected: item?.key === param?.key ? true : false,
    }));
    setOptionsList(tt);
  };

  const handleSave = () => {
    const selectedOption = optionsList?.find((i) => i.isSelected);
    if (selectedOption) {
      if (!isStockOrFactoryOrder(order)) {
        props?.setIsChange(true);
        props?.setIsChangeVehicle(true);
      }

      setOverriddenTaxAndUsageCodeDetails({ ...overriddenTaxAndUsageCodeDetails, usageCode: selectedOption?.key, doneReset: false });
    }
    onSave(selectedOption?.key ?? null);
  }

  return (
    <BrukskodeModalWrapper>
      {isOpen && (
        <OverlayContainer className={className ?? ''} overlayBgColor={`${bosColor.ultramarine}cc`} zIndex={10001}>
          <CustomModal active={isOpen} onCloseClick={onClose}>
            <BrukskodeModalHeader>
              <div className="headerText">Endre brukskode</div>
            </BrukskodeModalHeader>

            <BrukskodeModalContent>
              <SelectedBrukskode>
                <p className="title">Kjøretøyets/salgspakkens nåværende brukskode</p>
                <p className="code">{vehicleType}</p>
              </SelectedBrukskode>
              <Divider />

              <NewUserBrukskode>
                <BosDropdown
                  options={optionsList?.map((item) => ({ ...item, label: customOption(item) }))}
                  placeholder={''}
                  label="Ny brukskode"
                  value={optionsList?.find((i) => i.isSelected)}
                  nextDropdown
                  onChange={(param) => handleOptionSelection(param)}
                  className="brukskodeOptions"
                />
              </NewUserBrukskode>
              {optionsList?.find((i) => i.isSelected) &&
                optionsList?.find((i) => i.isSelected)?.key !== vehicleType && (
                  <Messagebar
                    messageBoxInfo
                    severityLevel={MessagebarSeverityLevel.INFORMATION}
                    title={
                      <>
                        Vær oppmerksom på at noen opsjoner kan være ugyldige for denne brukskoden. Kontroller at din
                        konfigurasjon er gyldig med brukskoden{' '}
                        <strong className="selectedCode">{optionsList?.find((i) => i.isSelected)?.key}</strong>.
                      </>
                    }
                    alertIcon={IconType.CircleInfo}
                    className="brukskodeChangeAlert"
                  />
                )}
              <BrukskodeActions>
                <Button
                  onClick={handleSave}
                  isLoading={isLoading}
                  className="brukskodeAction">
                  Lagre brukskode
                </Button>
                <Button onClick={onClose} className="brukskodeAction" variant={'secondary'}>
                  Avbryt
                </Button>
              </BrukskodeActions>
            </BrukskodeModalContent>
          </CustomModal>
        </OverlayContainer>
      )}
    </BrukskodeModalWrapper>
  );
};
