import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { SourceObject, Style } from '@react-pdf/types';
import {
  AccessoryType,
  getFuelEnumValueByName,
  getFuelTranslatedValue,
  getMakeNameByCode,
  IOrder,
  IVehicle,
  IVehicleConfiguration,
  MAKE,
  calculateTotalTaxOnCustomAccesories,
  getAccessoryCalculatedPriceByCustomerType,
  IVehicleAccessory,
  IVatCalculation,
  getConditionalValue,
  INextArticle,
  CustomerType,
} from 'next-common';
import { formatNumber } from '@next-components/common-types';
import { ImageOrPlaceholder } from './ImageOrPlaceholder';
import { bosBrand, kiaColor, mercedesColor, peugeotColor, srtColor, white } from '@next-components/common-styles';
import { IAccessoryConfig } from '../SalesOfferPdf';
import { ICartPrices, ICartProps, ICartSummaryData } from '@next-components/cart';
import { VehicleConfig } from '../../../../../utils';
import {
  CarInfoDetailsStyle,
  CarInfoImageStyle,
  carInfoPriceHeight,
  carInfoPriceMargin,
  CarInfoPriceStyle,
  CarInfoSpecsStyle,
  CarInfoStyle,
  PriceHeaderStyled,
  topPriceStyles,
  vatPriceStyle,
} from './common-styles';
import { get, defaultTo, compact } from 'lodash';
import { IServiceContract } from '../OfferDocument';
import { VatCode } from 'sales-common';
import { calculatePricesBasedOnArticleList, mapVehicleApplicationCodeStringToEnum } from 'sales-common-calculations';
import { CarInfoMakeStyle } from './CarInfo.style';
import { sortCartItems, sortingOrder } from '../salesOfferPdf.utils';

export interface ICarInfoProps {
  config: IVehicleConfiguration;
  vehicle: IVehicle;
  vehicleImage: SourceObject;
  totalPrice: number;
  discount: number;
  accessories: IAccessoryConfig[];
  isSerialNo: boolean;
  order: IOrder;
  cartData?: ICartProps;
  isOfferExpire: boolean;
  vatPrices: ICartPrices;
  serviceContract?: IServiceContract;
  totalMVA?: number;
  isElectric: boolean;
  articles?: INextArticle[];
  carType?: string;
  isStockSaleOrder?: boolean;
  dealerRole?: string;
}
interface ICarInfoPriceBlockProps {
  color: boolean;
  label: string;
  value: string;
  margin?: string;
  height?: string;
  isText?: boolean;
  make?: string;
  dealerRole?: string;
}
interface IShowCarInfoProps {
  cartData: ICartProps;
  carInfoPriceHeight: string;
  topPriceStyles: Style;
  vatCalculation: IVatCalculation;
  vatPrices: ICartPrices;
  serviceContract?: IServiceContract;
  isElectric: boolean;
  totalMVA?: number;
  articles?: INextArticle[];
  carType?: string;
  make?: string;
  dealerRole?: string;
  config?: IVehicleConfiguration;
  order?: IOrder;
  isStockSaleOrder?: boolean;
}

const getMake = (makeCode: string = null) => {
  if (!makeCode) return 'Ukjent merke';
  const makeEnumValue = Object.keys(MAKE).find((makeKey) => MAKE[makeKey] === makeCode);
  return getMakeNameByCode(MAKE[makeEnumValue], true);
};

const getFuel = (fuelCode: string = null) => {
  if (!fuelCode) return null;
  const fuelEnumValue = getFuelEnumValueByName(fuelCode?.toLowerCase());
  return getFuelTranslatedValue(fuelEnumValue, true);
};
interface ITotalPriceSection {
  totalPrice: number;
  make?: string;
  totalMVA: number;
  dealerRole?: string;
}

const LineBreak = () => <View style={{ borderTop: '0.5px solid #30306F', marginBottom: '4px' }} />;

const isDiscountApplicableOnPrices = (cartPrices: ICartPrices) => {
  return cartPrices?.discount !== 0;
};

const getTotalPrice = (isDiscountApplicableOnPrices: boolean, cartPrices: ICartPrices) => {
  return isDiscountApplicableOnPrices
    ? cartPrices?.calculatedSalesTotalBeforeDiscount
    : cartPrices?.salesTotalIncludingVatAndTax;
};

const getBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      case MAKE.Mercedes:
        return mercedesColor.black;
      case MAKE.Peugeot:
        return peugeotColor.black;
      case MAKE.Kia:
        return kiaColor.black;
      default:
        return bosBrand;
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      default:
        return bosBrand;
    }
  }
};

const TotalPriceSection = ({ make, totalPrice, totalMVA, dealerRole }: ITotalPriceSection) => {
  const bgColor = getBgColor(dealerRole, make)
  return (
    <View style={{ ...CarInfoPriceStyle, backgroundColor: bgColor }}>
      <Text style={CarInfoMakeStyle[dealerRole][make]['totalPriceLabel']}>{'Sum å betale'}</Text>
      <View style={{ paddingRight: '8px', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
        <Text style={CarInfoMakeStyle[dealerRole][make]['totalPriceValue']}>{`${formatNumber(totalPrice ?? 0)} kr`}</Text>
        <Text style={CarInfoMakeStyle[dealerRole][make]['totalMVA']}>
          {`herav ${formatNumber(totalMVA ?? 0)} kr mva.`}
        </Text>
      </View>
    </View>
  );
};

const CarInfoPriceBlock = (props: ICarInfoPriceBlockProps) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: props?.height || '30px',
        paddingLeft: '8px',
        paddingRight: '8px',
      }}>
      <Text style={CarInfoMakeStyle[props?.dealerRole][props?.make]['infoPriceBlock']}>{props?.label}</Text>
      <Text style={CarInfoMakeStyle[props?.dealerRole][props?.make]['infoPriceBlock']}>
        {props?.value}
        {!props?.isText ? 'kr' : ''}
      </Text>
    </View>
  );
};

const ShowCarInfo = (props: IShowCarInfoProps) => {
  const { cartData, carInfoPriceHeight, vatCalculation, vatPrices, serviceContract, articles, carType, config, order } =
    props;
  const vehicleApplicationCode = mapVehicleApplicationCodeStringToEnum(carType || 'EL');
  const result = calculatePricesBasedOnArticleList({ articles, vehicleApplicationCode });
  const isBusiness = order?.customer?.customerType?.toString() === CustomerType[CustomerType.BUSINESS];
  const getTotalPriceText = (isDiscountApplicable: boolean, totalPriceText: string) => {
    return isDiscountApplicable ? 'Veiledende pris inkl. mva og avgifter' : totalPriceText;
  };
  const effect = !props?.isStockSaleOrder
    ? defaultTo(config?.electricProps?.extraEffect, order?.pimSnapshot?.effect ?? '')
    : getValidOfferValue(order?.pimSnapshot?.effect ?? "");
  const allCartDataMappingPrivate = useMemo(() => {
    const filteredCartdata =
      cartData?.data?.filter((item) => !(item?.key === VehicleConfig.DISCOUNT && item?.price === 0)) ?? [];
      const updatedCartData = filteredCartdata?.map((item)=>{
        if(item?.key === VehicleConfig.MODEL){
          return {
            ...item,
            name:`${item?.name || ""} ${effect || ""}`
          }
        }
        return item;
      })
    const sortedCartData = sortCartItems(updatedCartData, sortingOrder);
    const isDiscountApplicable: boolean = isDiscountApplicableOnPrices(vatPrices);
    const vatCalculationPriceFields = [
      {
        name: 'Sum eks. mva',
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `${formatNumber(vatCalculation?.salesTotalExcludingVatAndTax ?? 0)} `,
        isVat: true,
        isVatStart: true,
        isServiceContract: false,
      },
      {
        name: '*MVA',
        height: carInfoPriceHeight,
        color: false,
        style: vatPriceStyle,
        isCombined: true,
        value: `${formatNumber(
          defaultTo(result?.vatBeforeDiscount + result?.vatOnArticlesWithNonDeductibleVat, 0),
        )} `,
        isVat: true,
        isServiceContract: false,
      },
      {
        name: 'Beregnet engangsavgift/vrakpant',
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `${formatNumber((vatCalculation?.overriddenVehicleRoadTax && vatCalculation?.overriddenVehicleRoadTax >= 0)? vatCalculation?.overriddenVehicleRoadTax : vatCalculation?.oneOffTaxAndWreckage ?? 0)} `,
        isVat: true,
        isServiceContract: false,
      },
    ];
    const totalPriceText = 'Totalpris inkl. mva og avgifter';
    const totalPriceWithServiceContract =
      defaultTo(vatPrices?.salesTotalIncludingVatAndTax, 0) + defaultTo(serviceContract?.price, 0);
    const totalPriceChunk = [
      {
        name: getTotalPriceText(isDiscountApplicable, totalPriceText),
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `${formatNumber(getTotalPrice(isDiscountApplicable, vatPrices)) ?? 0} `,
        isVat: true,
        isVatStart: true,
        isVatEnd: !isDiscountApplicable,
        isServiceContract: false,
      },
      ...(isDiscountApplicable
        ? [
            {
              name: 'Rabatt',
              height: carInfoPriceHeight,
              color: false,
              style: topPriceStyles,
              isCombined: true,
              value: `- ${formatNumber(vatPrices?.discount ?? 0)} `,
              isVat: true,
              isServiceContract: false,
            },
            // {
            //   name: 'Rabatt på bil',
            //   height: carInfoPriceHeight,
            //   color: false,
            //   style: topPriceStyles,
            //   isCombined: true,
            //   value: `- ${formatNumber(vatPrices?.discount ?? 0)} `,
            //   isVat: true,
            //   isServiceContract: false,
            // },
            // {
            //   name: 'Rabatt på leveringsomkostninger iht rammeavtale 000-000-00',
            //   height: carInfoPriceHeight,
            //   color: false,
            //   style: topPriceStyles,
            //   isCombined: true,
            //   value: `- ${formatNumber(vatPrices?.calculatedDiscountOnDeliveryCost ?? 0)} `,
            //   isVat: true,
            //   isServiceContract: false,
            // },
            // {
            //   name: 'Rabatt på utstyr iht rammeavtale 000-000-00',
            //   height: carInfoPriceHeight,
            //   color: false,
            //   style: topPriceStyles,
            //   isCombined: true,
            //   value: `- ${formatNumber(vatPrices?.calculatedDiscountOnAccessories ?? 0)} `,
            //   isVat: true,
            //   isServiceContract: false,
            // },
            {
              name: totalPriceText,
              height: carInfoPriceHeight,
              color: false,
              style: topPriceStyles,
              isCombined: true,
              value: `${formatNumber(vatPrices?.salesTotalIncludingVatAndTax ?? 0)} `,
              isVat: true,
              isVatEnd: true,
              isServiceContract: false,
            },
          ]
        : []),
    ];
    const serviceContractChunk = serviceContract
      ? [
          {
            name: serviceContract?.name || '',
            height: carInfoPriceHeight,
            color: false,
            style: topPriceStyles,
            isCombined: true,
            value: `${formatNumber(serviceContract?.price ?? 0)} `,
            isVat: true,
            isVatEnd: true,
            isServiceContract: true,
          },
          {
            name: 'Sum å betale',
            height: carInfoPriceHeight,
            color: false,
            style: topPriceStyles,
            isCombined: true,
            value: `${formatNumber(totalPriceWithServiceContract ?? 0)} `,
            isVat: true,
            isVatEnd: true,
            isServiceContract: true,
          },
        ]
      : [];
      const isPriceContinue =
        (sortedCartData?.length || 0) +
          (vatCalculationPriceFields?.length || 0) +
          (totalPriceChunk?.length || 0) +
          (serviceContractChunk?.length || 0) >
        15;
    const finalPriceMap = [];
    [...sortedCartData, ...vatCalculationPriceFields, ...totalPriceChunk, ...serviceContractChunk]?.forEach(
      (item, index) => {
        if (isPriceContinue && index === 14) {
          finalPriceMap.push({
            isPriceContinue: true,
            value: index,
          });
          finalPriceMap.push(item);
        } else {
          finalPriceMap.push(item);
        }
      },
    );

    return finalPriceMap;
  }, [cartData, carInfoPriceHeight, vatCalculation]);

  const allCartDataMappingBusiness = useMemo(() => {
    const filteredCartdata =
      cartData?.data?.filter((item) => !(item?.key === VehicleConfig.DISCOUNT && item?.price === 0)) ?? [];
      const updatedCartData = filteredCartdata?.map((item)=>{
        if(item?.key === VehicleConfig.MODEL){
          return {
            ...item,
            name:`${item?.name || ""} ${effect || ""}`
          }
        }
        return item;
      })
    const sortedCartData = sortCartItems(updatedCartData, sortingOrder);
    const isDiscountApplicable: boolean = isDiscountApplicableOnPrices(vatPrices);
    const totalPriceText = 'Totalpris inkl. mva og avgifter';
    const discountFields = [
      {
        name: 'Rabatt eks. mva',
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `- ${formatNumber(vatPrices?.calculatedDiscountOnVehicleExclVat ?? 0)} `,
        isVat: true,
        isServiceContract: false,
      },
      // {
      //   name: 'Rabatt tilbehør eks. mva',
      //   height: carInfoPriceHeight,
      //   color: false,
      //   style: topPriceStyles,
      //   isCombined: true,
      //   value: `- ${formatNumber(vatPrices?.calculatedDiscountOnAccessoriesExclVat ?? 0)} `,
      //   isVat: true,
      //   isServiceContract: false,
      // },
      // {
      //   name: 'Rabatt leveringsomkostninger eks. mva',
      //   height: carInfoPriceHeight,
      //   color: false,
      //   style: topPriceStyles,
      //   isCombined: true,
      //   value: `- ${formatNumber(vatPrices?.calculatedDiscountOnDeliveryCostExclVat ?? 0)} `,
      //   isVat: true,
      //   isServiceContract: false,
      // },
    ];
    const vatCalculationPriceFields = [
      {
        name: 'Sum eks. mva',
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `${formatNumber(vatCalculation?.salesTotalExcludingVatAndTax ?? 0)} `,
        isVat: true,
        isVatStart: true,
        isServiceContract: false,
      },
      ...(isDiscountApplicable ? discountFields : []),
      {
        name: 'Beregnet engangsavgift/vrakpant',
        height: carInfoPriceHeight,
        color: false,
        style: topPriceStyles,
        isCombined: true,
        value: `${formatNumber(vatCalculation?.oneOffTaxAndWreckage)} `,
        isVat: true,
        isServiceContract: false,
      },
    ];

    const totalPriceWithServiceContract =
      defaultTo(vatPrices?.salesTotalIncludingVatAndTax, 0) + defaultTo(serviceContract?.price, 0);
      const totalPriceChunk = [
        {
          name: 'Totalpris eks. mva',
          height: carInfoPriceHeight,
          color: false,
          style: topPriceStyles,
          isCombined: true,
          value: `${formatNumber(vatCalculation?.totalPriceExclVatInclTaxDiscount ?? 0)} `,
          isVat: true,
          isVatStart: true,
          isServiceContract: false,
        },
        {
          name: 'mva',
          height: carInfoPriceHeight,
          color: false,
          style: vatPriceStyle,
          isCombined: true,
          value: `${formatNumber(
            defaultTo(vatCalculation?.calculatedVatOnDeductableItems, 0),
          )} `,
          isVat: true,
          isServiceContract: false,
        },
        {
          name: totalPriceText,
          height: carInfoPriceHeight,
          color: false,
          style: topPriceStyles,
          isCombined: true,
          value: `${formatNumber(vatCalculation?.salesTotalIncludingVatAndTax)} `,
          isVat: true,
          isVatEnd: true,
          isServiceContract: false,
        },
      ];
    const serviceContractChunk = serviceContract
      ? [
          {
            name: serviceContract?.name || '',
            height: carInfoPriceHeight,
            color: false,
            style: topPriceStyles,
            isCombined: true,
            value: `${formatNumber(serviceContract?.price ?? 0)} `,
            isVat: true,
            isVatEnd: true,
            isServiceContract: true,
          },
          {
            name: 'Sum å betale',
            height: carInfoPriceHeight,
            color: false,
            style: topPriceStyles,
            isCombined: true,
            value: `${formatNumber(totalPriceWithServiceContract ?? 0)} `,
            isVat: true,
            isVatEnd: true,
            isServiceContract: true,
          },
        ]
      : [];
      const isPriceContinue =
        (sortedCartData?.length || 0) +
          (vatCalculationPriceFields?.length || 0) +
          (totalPriceChunk?.length || 0) +
          (serviceContractChunk?.length || 0) >
        15;
    const finalPriceMap = [];
    [...sortedCartData, ...vatCalculationPriceFields, ...totalPriceChunk, ...serviceContractChunk]?.forEach(
      (item, index) => {
        if (isPriceContinue && index === 14) {
          finalPriceMap.push({
            isPriceContinue: true,
            value: index,
          });
          finalPriceMap.push(item);
        } else {
          finalPriceMap.push(item);
        }
      },
    );

    return finalPriceMap;
  }, [cartData, carInfoPriceHeight, vatCalculation]);

  const allCartDataMapping = isBusiness ? allCartDataMappingBusiness : allCartDataMappingPrivate;

  return (
    <>
      {allCartDataMapping?.map((item, index) => {
        /* Price continue */
        if (item?.isPriceContinue) {
          return (
            <View break={true}>
              <Text key={`priser-continue-${item?.value}`} style={PriceHeaderStyled}>
                Priser fortsetter
              </Text>
            </View>
          );
          /* Vat */
        } else if (item?.isVat) {
          if (item?.isVatStart) {
            return (
              <>
                <LineBreak />
                <CarInfoPriceBlock
                  key={`${item?.name}-${item?.value}`}
                  height={item?.height}
                  label={item?.name}
                  color={item?.color}
                  value={item?.value}
                  make={props?.make}
                  dealerRole={props?.dealerRole}
                />
                {item?.isVatEnd ? <LineBreak /> : null}
              </>
            );
          }
          return (
            <View break={item?.isServiceContract}>
              <CarInfoPriceBlock
                key={`${item?.key}-${item?.value}`}
                height={item?.height}
                label={item?.name}
                color={item?.color}
                value={item?.value}
                make={props?.make}
                dealerRole={props?.dealerRole}
              />
              {item?.isVatEnd ? <LineBreak /> : null}
            </View>
          );
          /* Equipments */
        } else {
          return (
            <CarInfoPriceBlock
              key={item?.key}
              color={true}
              label={item?.vatCode === VatCode.DEDUCTABLE ? (item?.name ?? '') + '*' : item?.name}
              value={item?.isRecommended ? 'Inkludert' : getItemPrice(item, index)}
              isText={item?.isRecommended}
              height={carInfoPriceHeight}
              make={props?.make}
              dealerRole={props?.dealerRole}
            />
          );
        }
      })}
    </>
  );
};

const CarInfoDetails = (props: ICarInfoProps) => {
  const packageName = getConditionalValue(props.vehicle?.model?.type, [get(props, 'vehicle.model.type')], []);
  const packageNames = defaultTo(
    props?.config?.salesPackages?.map(({ name }) => name),
    packageName,
  );
  const effect = getEffektValue(props);
  const powerTrain = defaultTo(props.order?.pimSnapshot?.powerTrain, null);
  const isMakeSmart =
    props?.order?.lead?.make === MAKE?.Smart && props?.config?.model?.name?.includes(packageNames?.toString());
  const specs = isMakeSmart
    ? compact([`${effect ? ', ': ''} ${effect}`, powerTrain])?.join(', ')
    : compact([...packageNames, defaultTo(effect, ''), powerTrain])?.join(', ');

  return (
    <>
      <View style={CarInfoDetailsStyle}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '3px',
            marginBottom: '10px',
            height: '36%',
          }}>
          <View style={{ width: '70%', paddingRight: 10 }}>
          <Text style={CarInfoMakeStyle[props?.dealerRole][props?.order?.lead?.make]['make']}>
              {getMake(defaultTo(props.order?.lead?.make, ''))}
            </Text>
            <Text style={CarInfoMakeStyle[props?.dealerRole][props?.order?.lead?.make]['model']}>
              {defaultTo(props?.config?.model?.name, props.vehicle?.model?.name)} {specs}
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
          <ImageOrPlaceholder source={props?.vehicleImage} style={CarInfoImageStyle} />
          <View style={{ justifyContent: 'space-between', width: '70%' }}>
            <View>
              <CarInfoSpecs {...props} />
            </View>
          </View>
        </View>
      </View>
      <Text style={CarInfoMakeStyle[props?.dealerRole][props?.order?.lead?.make]['commonText']}>
        *Illustrasjonsbildet kan avvike fra tilbudet
      </Text>
    </>
  );
};

const findVarnish = (order: IOrder) => {
  return defaultTo(order?.options?.[0]?.colorName, '');
};

const findInteriorCodeName = (order: IOrder) => {
  return order?.options?.[0]?.interiorName || '';
};

const getItemPrice = (item: ICartSummaryData, index: number): string => {
  const pricePrefix = item?.price > 0 && index > 0 ? '+ ' : '';
  const itemPrice = `${formatNumber(item?.price ?? 0)} `;
  return `${pricePrefix} ${itemPrice}`;
};

const getValidOfferValue = (value: string = '', prefix: string = '') => {
  const offerValue = value?.substring(0, value?.indexOf(' '));
  return offerValue === '0' || offerValue === '' ? '' : `${prefix}${value}`;
};

const getEffektValue = (props: ICarInfoProps): string => {
  if (!props.isStockSaleOrder) {
    return defaultTo(props?.config?.electricProps?.extraEffect, props.order?.pimSnapshot?.effect ?? '');
  }
  return getValidOfferValue(props.order?.pimSnapshot?.effect);
};

const getRangeValue = (props: ICarInfoProps): string => {
  const rangeFromPimSnapshot = `${defaultTo(props.order?.pimSnapshot?.electricRange, '')}`;
  const range = `${defaultTo(props?.config?.electricProps?.extraElectricRange, rangeFromPimSnapshot)}`;
  const prefix = 'Inntil ';
  if (!props.isStockSaleOrder) {
    return `${range ? `${prefix}${range}`: ''}`;
  }
  return getValidOfferValue(rangeFromPimSnapshot, prefix);
};

const CarInfoSpecs = (props: ICarInfoProps) => {
  const varnish = findVarnish(props?.order);
  const interiorCodeName = findInteriorCodeName(props?.order);

  return (
    <View style={{ minHeight: '60px' }}>
      <View style={CarInfoSpecsStyle}>
        <CarInfoSpecBlock
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
          label="Drivstoff"
          value={defaultTo(
            props?.config?.model?.fuelType,
            defaultTo(props.order?.pimSnapshot?.fuelType, ''),
          )}
        />
        <CarInfoSpecBlock
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
          label="Effekt" 
          value={getEffektValue(props)} 
        />
        {/* This field is removed https://dev.azure.com/bertelosteen/Sales%20-%20master%20project/_workitems/edit/102042 */}
        {(props?.isElectric &&  false) &&
          <CarInfoSpecBlock
            make={props?.order?.lead?.make}
            dealerRole={props?.dealerRole}
            label="Rekkevidde (WLTP)" 
            value={getRangeValue(props)} 
          />
        }

        <CarInfoSpecBlock
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
          label="Drivlinje"
          value={defaultTo(props?.config?.model?.powerTrain, props.order?.pimSnapshot?.powerTrain)}
        />
        <CarInfoSpecBlock
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
          label="Farge" 
          value={varnish} 
        />
        <CarInfoSpecBlock
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
          label="Interiør" 
          value={interiorCodeName} 
        />
      </View>
    </View>
  );
};

const CarInfoSpecBlock = (props) => (
  <View style={{ width: '33%', paddingRight: 5, marginBottom: '24px' }}>
    <Text style={CarInfoMakeStyle[props?.dealerRole][props?.make]['InfoSpecLabel']}>{props?.label}</Text>
    <Text style={CarInfoMakeStyle[props?.dealerRole][props?.make]['InfoSpecValue']}>{props?.value}</Text>
  </View>
);

const ShowCarInfoWithoutVat = (props: ICarInfoProps) => {
  const totalAdvantage = (Math.abs(props?.config?.pricing?.customerAdvantage) || 0) + (props?.discount || 0);
  const includedText = 'Inkludert';
  let totalAccessoriesPrice = 0;

  props?.accessories?.length &&
    props.accessories.forEach((accessory) => {
      totalAccessoriesPrice += getAccessoryCalculatedPriceByCustomerType(
        accessory as IVehicleAccessory,
        props?.order?.customer?.customerType,
      );
    });

  const getMakePrice = (props: ICarInfoProps): number => {
    let makePrice = props?.config?.pricing?.indicativePrice;
    if (props?.order?.options?.length > 0) {
      makePrice += props?.order?.options?.[0]?.price;
    }
    return formatNumber(makePrice);
  };

  const getVariant = (props: ICarInfoProps): ICartSummaryData => {
    return props?.cartData?.data?.find((details) => {
      return details?.key === 'variant';
    });
  };

  const getSelectables = (props: ICarInfoProps): ICartSummaryData[] => {
    const selectables: ICartSummaryData[] = props?.cartData?.data?.filter((details) => {
      return details?.key?.startsWith(VehicleConfig.SELECTABLE_EQUIPMENT);
    });
    return selectables;
  };

  const getRims = (props: ICarInfoProps): ICartSummaryData[] => {
    const rims: ICartSummaryData[] = props?.cartData?.data?.filter((details) => {
      return details?.key?.startsWith(VehicleConfig.RIM);
    });
    return rims;
  };

  const variantDetails = getVariant(props);

  return (
    <>
      <CarInfoPriceBlock
        color={true}
        label={`${getMake(props?.config?.model?.make)} ${props?.config?.model?.series}`}
        value={`${getMakePrice(props)}`}
        margin={carInfoPriceMargin}
        height={carInfoPriceHeight}
        make={props?.order?.lead?.make}
        dealerRole={props?.dealerRole}
      />
      <CarInfoPriceBlock
        color={true}
        label={variantDetails?.name || ''}
        value={variantDetails?.isRecommended ? includedText : formatNumber(variantDetails?.price) || 0}
        isText={variantDetails?.isRecommended}
        height={carInfoPriceHeight}
        make={props?.order?.lead?.make}
        dealerRole={props?.dealerRole}
      />
      {getSelectables(props)?.map((selectable) => {
        return (
          <CarInfoPriceBlock
            key={selectable?.key}
            color={true}
            label={selectable?.name || ''}
            value={selectable?.isRecommended ? includedText : formatNumber(selectable?.price) || 0}
            isText={selectable?.isRecommended}
            height={carInfoPriceHeight}
            make={props?.order?.lead?.make}
            dealerRole={props?.dealerRole}
          />
        );
      })}
      {getRims(props)?.map((rim) => {
        return (
          <CarInfoPriceBlock
            key={rim?.key}
            color={true}
            label={`${rim?.name || ''}`}
            value={rim?.isRecommended ? includedText : formatNumber(rim?.price) || 0}
            isText={rim?.isRecommended}
            height={carInfoPriceHeight}
            make={props?.order?.lead?.make}
            dealerRole={props?.dealerRole}
          />
        );
      })}
      <CarInfoPriceBlock
        label={'Beregnet engangsavgift/vrakpant'}
        color={false}
        value={formatNumber(props?.config?.model?.pricing?.oneTimeFee)}
        make={props?.order?.lead?.make}
        dealerRole={props?.dealerRole}
      />
      <CarInfoPriceBlock
        label={'Leveringsomkostninger'}
        color={true}
        value={formatNumber(props?.config?.model?.pricing?.deliveryCharge)}
        make={props?.order?.lead?.make}
        dealerRole={props?.dealerRole}
      />
      <CarInfoPriceBlock 
        label={'Tilbehør'} 
        color={false} 
        value={formatNumber(totalAccessoriesPrice)}
        make={props?.order?.lead?.make}
        dealerRole={props?.dealerRole}
      />
      {totalAdvantage && Math.abs(totalAdvantage) >= 5000 ? (
        <CarInfoPriceBlock 
          label={'Rabatt'} 
          color={true} 
          value={`-${formatNumber(totalAdvantage || 0)}`}
          make={props?.order?.lead?.make}
          dealerRole={props?.dealerRole}
        />
      ) : null}
    </>
  );
};

const showPricesComponent = (props: ICarInfoProps) => {
  const parseAndCalculateMVA = (accesories: IVehicleAccessory[]): number => {
    let mvaTotalPrice = 0;
    if (accesories) {
      let parseAccesories = [];
      accesories?.forEach((accessory) => {
        if (AccessoryType[accessory?.accessoryType] === AccessoryType[AccessoryType.CUSTOM]) {
          const accessoryWithEnumUpdate = {
            ...accessory,
            accessoryType: AccessoryType.CUSTOM,
          };
          parseAccesories?.push(accessoryWithEnumUpdate);
        } else {
          parseAccesories?.push(accessory);
        }
      });
      mvaTotalPrice = calculateTotalTaxOnCustomAccesories(parseAccesories);
    }
    return mvaTotalPrice;
  };

  const mvaPrice = props?.order?.accessories ? parseAndCalculateMVA(props?.order?.accessories) : 0;
  const bgColor = getBgColor(props?.dealerRole, props?.config?.model?.make)
  return (
    <View
      style={{
        ...CarInfoPriceStyle,
        backgroundColor: bgColor,
      }}>
      <Text style={CarInfoMakeStyle[props?.dealerRole][props?.config?.model?.make]['totalPriceLabel']}>{'TOTALT'}</Text>
      <View style={{ paddingRight: '8px', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
        <Text style={CarInfoMakeStyle[props?.dealerRole][props?.config?.model?.make]['totalPriceValue']}>
          {`${formatNumber(props?.totalPrice || 0)} kr`}
        </Text>
        <Text style={CarInfoMakeStyle[props?.dealerRole][props?.config?.model?.make]['totalMVA']}>herav {mvaPrice} kr mva</Text>
      </View>
    </View>
  );
};

const CarInfoPrice = (props: ICarInfoProps) => {
  return (
    <>
      <View style={{ marginTop: 10 }}>
        {props?.isOfferExpire ? null : (
          <TotalPriceSection
            dealerRole={props?.dealerRole}
            make={props?.config?.model?.make || props?.vehicle?.make}
            totalPrice={props?.totalPrice}
            totalMVA={props?.totalMVA}
          />
        )}
        <Text 
          style={{ 
            ...PriceHeaderStyled, 
            ...CarInfoMakeStyle[props?.dealerRole][props?.config?.model?.make || props?.vehicle?.make]['priser'] 
          }}
        >
          Priser
        </Text>
        <View>
          {props?.isOfferExpire
            ? ShowCarInfoWithoutVat(props)
            : props?.cartData?.data?.length > 0 && (
                <ShowCarInfo
                  cartData={props?.cartData}
                  carInfoPriceHeight={carInfoPriceHeight}
                  topPriceStyles={topPriceStyles}
                  vatCalculation={props?.order?.vatCalculation}
                  vatPrices={props?.vatPrices}
                  serviceContract={props?.serviceContract}
                  isElectric={props?.isElectric}
                  totalMVA={props?.totalMVA}
                  articles={props.articles}
                  carType={props.carType}
                  make={props?.order?.lead?.make}
                  dealerRole={props?.dealerRole}
                  config = {props?.config}
                  order = {props?.order}
                  isStockSaleOrder = {props?.isStockSaleOrder}
                />
              )}
        </View>
      </View>
      {props?.isOfferExpire ? showPricesComponent(props) : null}
    </>
  );
};

export const CarInfo = (props: ICarInfoProps) => (
  <View style={CarInfoStyle}>
    <CarInfoDetails {...props} />
    <CarInfoPrice {...props} />
  </View>
);
