import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { IVehicleStockEcomItem } from 'sales-common';
import { ERROR_POLICY } from '../constants';
import { searchVehicleBySerialNumberParsed } from '../graphql';
import { isValid, differenceInHours } from 'date-fns';
import { IConfiguration } from 'next-common';

export interface IVehicleDataParams {
  serialNo: string;
  pCodesUpdatedAt?: string;
  configuration?: IConfiguration;
  fetchPolicy?: WatchQueryFetchPolicy;
}

export interface IVehicleResult {
  data: IVehicleStockEcomItem;
  loading: boolean;
}

const isSkipVehicleStatusCall = (pCodesUpdatedAt: string, configuration: IConfiguration): boolean => {
  if (!pCodesUpdatedAt) return false;
  const statusApiPreventionHours = configuration?.features?.varebil?.statusApiPreventionHours ?? 0;
  try {
    const updatedDate = new Date(pCodesUpdatedAt);
    const currentDate = new Date();
    if (isValid(updatedDate)) {
      const hours = differenceInHours(currentDate, updatedDate);
      return hours < statusApiPreventionHours;
    }
    return false;
  } catch (error) {
    console.error('An error occured in differenceInHours function', error);
  }
}

export const useVehicleData = (params: IVehicleDataParams): IVehicleResult => {
  const isSkip = isSkipVehicleStatusCall(params?.pCodesUpdatedAt, params?.configuration);
  const { data, loading } = useQuery(searchVehicleBySerialNumberParsed, {
    variables: {
      serialNo: params?.serialNo,
    },
    errorPolicy: ERROR_POLICY,
    skip: !params?.serialNo || isSkip,
    fetchPolicy: params?.fetchPolicy || 'cache-first',
  });

  return { data: data?.searchVehicleBySerialNumber, loading };
};
