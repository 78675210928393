import React from 'react';
import { View } from '@react-pdf/renderer';
import { IPackageEquipmentItems, IVehicleConfiguration } from 'next-common';
import { IAccessoryConfig } from '../SalesOfferPdf';
import { EquipmentBundleList, EquipmentBundleListForStockSale } from './EquipmentBundleList';

const EquipmentListStyled = {
  padding: '10 20',
};

interface IEquipmentListProps {
  make?: string,
  dealerRole?: string,
  accessories: IAccessoryConfig[];
  config: IVehicleConfiguration;
  standredPackageEquipmentList?: IPackageEquipmentItems[] | string[];
  modelPackagesEquipmentList?: IPackageEquipmentItems[];
  isStockSaleOrder: boolean;
}

export const EquipmentList = (props: IEquipmentListProps) => {
  if (props.isStockSaleOrder) {
    return (
      <View style={EquipmentListStyled}>
        <EquipmentBundleListForStockSale 
          make={props?.make} 
          dealerRole={props?.dealerRole}
          breakPage={true} 
          equipmentBundleList={props.standredPackageEquipmentList as  IPackageEquipmentItems[]} 
        />
      </View>
    )
  }
  return(
    <View style={EquipmentListStyled} wrap={true} >
      <EquipmentBundleList
        make={props?.make}
        dealerRole={props?.dealerRole}
        name={props.config?.model?.equipment?.[0]?.name}
        equipmentBundleList={props?.standredPackageEquipmentList as IPackageEquipmentItems[]}
        breakPage={true}
      />
      <EquipmentBundleList
        make={props?.make}
        dealerRole={props?.dealerRole}
        name={props.config?.salesPackages?.[0]?.name}
        equipmentBundleList={props?.modelPackagesEquipmentList}
        breakPage={true}
      />
    </View>
)};
