import { gql } from '@apollo/client';
import { getPCodeMasterListQuery, togglePcodesOnOrderMutation } from 'next-common';

export const getPCodeMasterListQueryParsed = gql`
  ${getPCodeMasterListQuery}
`;

export const togglePcodesOnOrderMutationParsed = gql`
  ${togglePcodesOnOrderMutation}
`;
