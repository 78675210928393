import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { CustomerType, IBosApiDealerInfoData, ICustomer, IUser, MAKE } from 'next-common';
import { formatPhoneNumberInput } from '@next-components/common-types';
import { backgroundCard, kiaColor, mercedesColor, peugeotColor, srtColor } from '@next-components/common-styles';
import { daysUntil, findExpiresAt, getFormattedExpiresAt } from '../../../../../utils';
import capitalize from 'lodash/capitalize';
import { FooterMakeStyle } from './Footer.style';

export interface ISalesOfferFooterProps {
  dealer: IBosApiDealerInfoData;
  user: IUser;
  createdAt: string;
  expiresAt: string;
  prerequisiteComment?: string;
  make: string;
  infoAgent: string;
  dealerRole?: string;
  customer?: ICustomer;
}

// Calculate total no of days for expiry
const calculateDateDifference = (expiresAt: string, createdAt: string) => {
  try {
    const expiry = findExpiresAt(expiresAt, createdAt);
    return daysUntil(expiry);
  } catch (error) {
    console.error('Error formatting date difference:', error);
    return '-'; 
  }
};

const FooterStyle: Style = {
  backgroundColor: backgroundCard,
  position: 'absolute',
  bottom: 0,
  padding: '5 15',
  height: 85
};

const FooterInfo: Style = {
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-evenly',
};

const FooterBlockStyle: Style = {
  padding: '5',
  width: '30%',
};

const FooterBlock = (props) => {
  const info = props.info.filter((o) => o !== undefined);
  return (
  <View style={{...FooterBlockStyle , ...props?.style ?? {}}}>
      <Text style={FooterMakeStyle[props?.dealerRole][props?.make]['header']}>{props.header}</Text>

    {info.map((i, index) => (
      <Text style={FooterMakeStyle[props?.dealerRole][props?.make]['info']} key={`${i.header}-${index}`}>
        {i}
      </Text>
    ))}
  </View>
)};

const fetchUserMobilePhone = (user: IUser): string => {
  let mobileNumber = '';
  if (user?.mobilePhone) {
    if (user.mobilePhone.startsWith('+47')) {
      mobileNumber = formatPhoneNumberInput(user.mobilePhone.toString(), true).formatted;
    } else {
      mobileNumber = formatPhoneNumberInput(`+47${user.mobilePhone.toString()}`, true).formatted;
    }
  }
  return mobileNumber;
};

const getOptionPriceBgColor = (dealer: string, brand: string) => {
  if (dealer === 'INDEPENDENT_DEALER') {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.black;
      case MAKE.Mercedes:
        return mercedesColor.black;
      case MAKE.Peugeot:
        return peugeotColor.black;
      case MAKE.Kia:
        return kiaColor.black;
      default:
        return backgroundCard;
    }
  } else {
    switch (brand) {
      case MAKE.Smart:
        return srtColor.silver;
      default:
        return backgroundCard;
    }
  }
};

export const Footer = (props: ISalesOfferFooterProps) => (
  <View fixed style={{ ...FooterStyle, backgroundColor: getOptionPriceBgColor(props?.dealerRole, props?.make) }}>
    <View style={FooterInfo}>
      <FooterBlock
        header={`${props.dealer?.name || 'Forhandler'}`}
        info={[
          `${props.dealer?.visitingAddressStreet || ''}`,
          `${props.dealer?.visitingAddressZipCode || ''} ${capitalize(props.dealer?.visitingAddressCity) || ''} `,
        ]}
        make={props?.make}
        dealerRole={props?.dealerRole}
        style = { props?.customer?.customerType?.toString() !== CustomerType[CustomerType.BUSINESS] ? {width: '33.33%'} : {}}       
      />
      <FooterBlock
        header="Selger"
        info={[
          props.infoAgent,
          `${props.user?.firstName || ''} ${props.user?.lastName || ''}`,
          `Tlf: ${fetchUserMobilePhone(props?.user)}`,
          `E-post: ${props.user?.email || ''}`,
        ]}
        make={props?.make}
        dealerRole={props?.dealerRole}
        style = { props?.customer?.customerType?.toString() !== CustomerType[CustomerType.BUSINESS] ? {width: '33.33%'} : {}}
      />
      {props?.customer?.customerType?.toString() === CustomerType[CustomerType.BUSINESS] ? (
        <FooterBlock
          header="Forutsetninger"
          info={[
            `Gyldig til ${getFormattedExpiresAt(props.expiresAt, props.createdAt)}. `,
            'Forbehold om pris-, avgifts- og spesifikasjonsendringer,',
            'rentejusteringer, samt godkjent finansiering.',
            `${props?.prerequisiteComment}`,
          ]}
          make={props?.make}
          dealerRole={props?.dealerRole}
          style={{ width: '40%' }}
        />
      ) : (
        <FooterBlock
          header="Info om forutsetninger"
          info={[
            `Gyldighet i ${calculateDateDifference(props.expiresAt, props.createdAt)} dager`,
            'Forbehold om renteendringer',
            'Forbehold om godkjent finansiering',
            `${props?.prerequisiteComment}`,
          ]}
          make={props?.make}
          dealerRole={props?.dealerRole}
          style={{ width: '33.33%' }}
        />
      )}
    </View>
  </View>
);
