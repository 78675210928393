import { differenceInDays, format } from 'date-fns';
import { INextArticle, IOrder, IPcodes, OptionSource, getFilteredPcodes, getLocalOptionsFromAllOptions } from 'next-common';
import { ArticleType, IPcodeMaster, IVehicleStockEcomConfiguration, IVehicleStockEcomItem } from 'sales-common';
import { prepareVehicleConfig, removeDuplicateObjectFromArray, VehicleConfig } from './vehicleOrder';
import { ICartSummaryData } from '@next-components/cart';
import { prepareArticles } from './vat';
import { defaultTo, differenceBy } from 'lodash';
import { isOfferCase } from './order';

export interface ICurrentOptions {
  name: string;
  price: number;
  sku: string;
}

export interface ICurrentChanges {
  addedOptions: ICurrentOptions[];
  removedOptions: ICurrentOptions[];
}
interface ITTCodeValidation {
  isSelectionAllowed: boolean;
  isDeSelectionAllowed: boolean;
  showWarning: boolean;
}

export const isOptionSourceIsVehicleStatus = (option: IPcodes) => {
  return option?.optionSource === OptionSource.VEHICLE_STATUS;
}

export const applyTTCodeValidation = (pCode: IPcodeMaster, vehicleTTCode: number, order: IOrder, preSelectedPcodes: IPcodes[]): ITTCodeValidation => {
  if ((!pCode?.trackTraceAdd && !pCode?.trackTraceRemove) || !vehicleTTCode) return { isSelectionAllowed: true, isDeSelectionAllowed: true, showWarning: false };

  let isSelectionAllowed = false;
  let isDeSelectionAllowed = false;
  let showWarning = false;

  // Options cannot be added after vehicle has reached TT-limit
  if (!pCode?.trackTraceAdd || (pCode?.trackTraceAdd && pCode?.trackTraceAdd >= vehicleTTCode)) {
    isSelectionAllowed = true;
  }

  // Options cannot be removed after vehicle has reached TT-limit
  // validation will work only for orders not offers
  // check option source (Validation should not worked for source "vehicle status")
  const selectedPCode = preSelectedPcodes?.find(currentPcode => currentPcode?.localOptionCode === pCode?.localOptionCode);
  if(isOfferCase(order) && selectedPCode && !isOptionSourceIsVehicleStatus(selectedPCode)) {
    isDeSelectionAllowed = true;
    if(pCode?.trackTraceRemove && pCode?.trackTraceRemove < vehicleTTCode) {
      showWarning = true;
    }
  }
  else if (!pCode?.trackTraceRemove || (pCode?.trackTraceRemove && pCode?.trackTraceRemove >= vehicleTTCode)) {
    isDeSelectionAllowed = true;
  }

  // console.log("TT Code validation: ", pCode?.localOptionCode, ":", `isSelectionAllowed: ${isSelectionAllowed}, isDeSelectionAllowed: ${isDeSelectionAllowed}`)
  return {
    isSelectionAllowed, isDeSelectionAllowed, showWarning
  };
};

export const isDateValidateFailed = (pCode: IPcodeMaster) => {
  try {
    let isValidaFomDate = true;
    let isValidToDate = true;
    const currentDate = new Date(format(new Date(), 'yyyy-MM-dd'));

    //Valid From Date validation
    if (pCode?.validFromDate) {
      const validFromDate = new Date(format(new Date(pCode?.validFromDate), 'yyyy-MM-dd'));
      const days = differenceInDays(validFromDate, currentDate);
      isValidaFomDate = days <= 0;
    }

    //Valid To Date validation
    if (pCode?.validToDate) {
      const validToDate = new Date(format(new Date(pCode?.validToDate), 'yyyy-MM-dd'));
      const days = differenceInDays(validToDate, currentDate);
      isValidToDate = days >= 0;
    }

    return { isValidaFomDate, isValidToDate };
  } catch (error) {
    console.log('Error in isDateValidateFailed', error);
    return { isValidaFomDate: true, isValidToDate: true };
  }
};

export const vehicleStatusToSavedDataValidation = (order: IOrder, vehicleData: IVehicleStockEcomItem) => {
  const localOptions = getLocalOptionsFromAllOptions(vehicleData?.vehicleConfiguration?.options);
  const mismatchedData = order?.pCodes?.filter((pcode) => {
    const pCodeFound = localOptions?.filter((data) => data?.optionId === pcode?.localOptionCode);

    return pCodeFound?.length ? false : true;
  });

  console.log('Vehicle status to saved data mis-match =>', mismatchedData);

  return mismatchedData;
};

export const masterAPIToSavedDataValidation = (order: IOrder, masterData: IPcodeMaster[]) => {
  if (!order?.pCodes?.length) return [];
  const filteredPcodes = getFilteredPcodes(order?.pCodes);
  const mismatchedData = filteredPcodes?.filter((pcode) => {
    const pCodeFound = masterData?.filter((data) => data?.localOptionCode === pcode?.localOptionCode);

    return pCodeFound?.length ? false : true;
  });

  console.log('Master API to saved data mis-match =>', mismatchedData);
  return mismatchedData;
};

export const getPriceValidation = (order: IOrder, removedOptions: IPcodeMaster[], masterData: IPcodeMaster[]) => {
  if (!order?.pCodes?.length) return [];
  const priceMismatchPcodes = [];
  const filteredPcodes = getFilteredPcodes(order?.pCodes);
  const finalPcodes = differenceBy(filteredPcodes, removedOptions, 'localOptionCode');
  finalPcodes?.forEach((pcode) => {
    const masterPcode = masterData?.find((option) => option?.localOptionCode === pcode?.localOptionCode);
    const currentPrice = defaultTo(pcode?.customerPriceExclVat, 0);
    const newPrice = defaultTo(masterPcode?.customerPriceExclVat, 0);
    if (currentPrice !== newPrice) {
      priceMismatchPcodes?.push({
        name: pcode?.localOptionDescription,
        currentPrice,
        newPrice,
      });
    }
  });
  return priceMismatchPcodes;
};

export const mapSelectedOptions = (pCodes: IPcodes[]) => {
  if (!pCodes?.length) return [];
  return pCodes?.map((pcode) => {
    return {
      name: defaultTo(pcode?.localOptionDescription, ''),
      price: defaultTo(pcode?.customerPriceExclVat, 0),
      sku: defaultTo(pcode?.localOptionCode, ''),
    };
  });
};

export const setOptionChanges = (
  existingOption: ICurrentChanges,
  addedOption: ICurrentOptions[],
  removedOption: ICurrentOptions[],
): ICurrentChanges => {
  const addedPcodes = [...existingOption?.addedOptions, ...addedOption];
  const removedPcodes = [...existingOption?.removedOptions, ...removedOption];
  const addedOptions = removeDuplicateObjectFromArray(addedPcodes, 'sku');
  const removedOptions = removeDuplicateObjectFromArray(removedPcodes, 'sku');
  return { addedOptions, removedOptions };
};

export const mapPCodes = (vehicleStatus: IVehicleStockEcomConfiguration, masterData: IPcodeMaster[]): IPcodes[] => {
  if (!vehicleStatus?.options?.length) return [];
  const localOptions = getLocalOptionsFromAllOptions(vehicleStatus?.options);
  if (!localOptions?.length) return [];
  const mappedPcodes: IPcodes[] = [];
  localOptions?.forEach((option) => {
    const masterPcode = masterData?.find((pcode) => pcode?.localOptionCode === option?.optionId);
    if (masterPcode) {
      mappedPcodes.push({ ...masterPcode, isDefaultPcode: false, optionSource: OptionSource.VEHICLE_STATUS });
    } else if (option?.customerPriceExlVat > 0) {
      mappedPcodes.push({
        articleCode: vehicleStatus?.partNumber,
        articleCodeDesc: null,
        characteristicId: `${vehicleStatus?.brand}-${option?.optionId}`,
        costPriceExclVat: option?.costPrice,
        customerPriceExclVat: option?.customerPriceExlVat,
        lastUpdated: null,
        localOptionCode: option?.optionId,
        localOptionDescription: option?.optionDescription,
        localOptionCodeType: 'LOCAL_OPTION',
        trackTraceAdd: null,
        trackTraceRemove: null,
        validToDate: null,
        validFromDate: null,
        vatCode: option?.vatCode,
        isDefaultPcode: true,
        optionSource: OptionSource.VEHICLE_STATUS,
      });
    }
  });
  return mappedPcodes;
};

export const getSelectedPcodes = (
  orderPcodes: IPcodes[],
  selectedVehicleData: IVehicleStockEcomItem,
  masterData: IPcodeMaster[],
  isOffer: boolean,
) => {
  const mappedVehicleStatusOptions = mapPCodes(selectedVehicleData?.vehicleConfiguration, masterData);
  const allPcodes = removeDuplicateObjectFromArray(
    [...(orderPcodes || []), ...(mappedVehicleStatusOptions || [])],
    'localOptionCode',
  );
  const mappedPcodes: IPcodes[] = [];
  const articles: INextArticle[] = [];
  const cartData: ICartSummaryData[] = [];

  allPcodes?.forEach((option) => {
    let newPcode: IPcodes = null;
    const masterPcode = masterData?.find((pcode) => pcode?.localOptionCode === option?.localOptionCode);
    if (masterPcode) {
      newPcode = isOffer ? {...masterPcode, isDefaultPcode: false, optionSource: option?.optionSource } : option;
      mappedPcodes.push(newPcode);
    } else if (option?.customerPriceExclVat > 0 && option?.isDefaultPcode) {
      newPcode = { ...option, isDefaultPcode: true };
      mappedPcodes.push(newPcode);
    } else {
      newPcode = option;
    }
    const optionKey = `${VehicleConfig.PCODE}_${newPcode?.localOptionCode}`;
    articles.push(
      ...prepareArticles(
        optionKey,
        newPcode?.customerPriceExclVat,
        newPcode?.vatCode,
        ArticleType.EQUIPMENT,
        newPcode?.costPriceExclVat,
      ),
    );
    cartData?.push(
      prepareVehicleConfig(
        optionKey,
        newPcode?.localOptionCode,
        newPcode?.localOptionDescription,
        newPcode?.customerPriceExclVat,
        newPcode?.vatCode,
        false,
        newPcode?.costPriceExclVat,
      ),
    );
  });
  return { pCodes: mappedPcodes, mappedArticles: articles, cartData, preSelectedPcodes: mappedVehicleStatusOptions };
};
