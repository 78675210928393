import { BosDropdown } from '@next-components/bos-dropdown';
import {
  CustomerType,
  FinancingType,
  ICalculation,
  IFinancing,
  IFinancingRequest,
  IOrder,
  IServiceContract,
  IVehicleConfiguration,
} from 'next-common';
import { formatNumber, InputType } from '@next-components/common-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormRow } from '../../FormRow';
import { StyledForm } from '../FinancingForm/FinancingForm.styled';
import { InputField } from '@next-components/input-field';
import { getServiceContractCalculation, getServiceContractPrice, serviceContractDefault } from '../../../../../utils';
import { ButtonStyled } from '../../../sales-wizard/sales-header/SalesHeader.styled';
import {
  getCarTypeOptions,
  getPaymentPeriodDataListOptions,
  getServiceContractDistanceOptions,
  getServiceContractDurationOptions,
  getServiceContractOptions,
} from '../../../../../utils/order/orderUtils';
import cloneDeep from 'lodash/cloneDeep';
import { IGetServiceContract, IPaymentPeriodDataList, IUsageFactorDataList } from 'sales-common';
import { Button } from '@next-components/cta';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { primary } from '@next-components/common-styles';
import { SmallLink } from '@next-components/link';

const getChangeIndex = (formChanges: IFormChange[], fieldName) => {
  return formChanges.findIndex((change) => change.fieldName === fieldName);
};

export interface IFormChange {
  fieldName: string;
  originalValue: number;
  value: number;
}

export const fieldIds = {
  type: 'type',
  dealerAmount: 'dealerAmount',
  usageType: 'usageType',
  usageId: 'usageId',
  drivingDistance: 'drivingDistance',
  duration: 'duration',
  totalPrice: 'totalPrice',
  totalSavings: 'totalSavings',
  monthlyPrice: 'monthlyPrice',
  rebatePercentage: 'rebatePercentage',
  contractTypeId: 'contractTypeId',
  importerDiscount: 'importerDiscount',
  workshopDiscount: 'workshopDiscount',
  serviceContractPriceConfig: 'serviceContractPriceConfig',
  isLoanPaymentIntervalSelected: 'isLoanPaymentIntervalSelected',
  paymentPeriodId: 'paymentPeriodId',
};

interface IFinancingFormProps {
  offerId?: string;
  calculation: ICalculation;
  order?: IOrder;
  vehicleConfig: IVehicleConfiguration;
  customerType: CustomerType;
  unknownText?: string;
  type?: FinancingType;
  isSaving?: boolean;
  isEditable?: boolean;
  isModal: boolean;
  hideComments?: boolean;
  serviceContract?: IGetServiceContract;
  serviceContractDb?: IServiceContract;
  isUnavailablePimData?: boolean;
  setShowServiceContractComparisionModal(toShow: boolean): void;
  setShowServiceContract(showServiceContract: boolean): void;
  onUpdateServiceContract(changes, id): void;
}

export const getDistance = (serviceContractDb, request, isLeasing = false) => {
  if (isLeasing) return request?.mileage;
  return serviceContractDb?.drivingDistance ?? serviceContractDefault.drivingDistance;
};

export const getDuration = (serviceContractDb, request, isLeasing = false) => {
  if (isLeasing) return request?.durationMonths;
  return serviceContractDb?.duration ?? serviceContractDefault.duration;
};

export const getUsageType = (
  serviceContract: IGetServiceContract,
  serviceContractDb: IServiceContract,
): IUsageFactorDataList => {
  if (!serviceContract) return null;
  if (serviceContractDb) {
    return serviceContract?.usageFactorDataList?.find(
      (type) => type?.id === serviceContractDb.serviceContractPriceConfig?.usageFactorId,
    );
  } else {
    return serviceContract?.usageFactorDataList[0];
  }
};

export const getPaymetInterval = (
  serviceContract: IGetServiceContract,
  serviceContractDb: IServiceContract,
): IPaymentPeriodDataList => {
  if (!serviceContract) return null;
  if (!serviceContractDb?.paymentPeriodId) {
    return serviceContract?.paymentPeriodDataList[0];
  } else {
    return serviceContract?.paymentPeriodDataList?.filter((type) => type.id === serviceContractDb?.paymentPeriodId)[0]; //added static value
  }
};

const getServiceContract = (props) => {
  return !props.serviceContractDb ? props.calculation?.serviceContract : props.serviceContractDb;
};

export const ServiceAgreementForm = (props: IFinancingFormProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(props.isSaving);
  const [changes, setChanges] = useState<IFormChange[]>([]);
  const [financing, setFinancing] = useState<IFinancing>(props.calculation?.financing);
  const [request, setRequest] = useState<IFinancingRequest>(props.calculation?.financing?.request);
  const [financingType, setFinancingType] = useState<FinancingType>(props.type || props.calculation?.financingType);
  const serviceContract: IGetServiceContract = props.serviceContract;
  const [serviceContractDb, setServiceContractDb] = useState<IServiceContract>(getServiceContract(props));
  const isLoan = financingType === FinancingType.LOAN;
  const isLeasing = financingType === FinancingType.LEASING;
  const isCash = financingType === FinancingType.CASH;
  const [isForhandlerVisible, setIsForhandlerVisible] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  useEffect(() => {
    setServiceContractDb(props.serviceContractDb);
    setFinancing(props.calculation?.financing);
    setRequest(props.calculation?.financing?.request);
  }, [props.calculation, props.serviceContractDb]);

  const getSelectedValue = () => {
    const selectedOffer = props.order?.offers?.find((offer) => offer?.id === props.offerId);
    return selectedOffer?.serviceContract?.contractTypeId ?? 0;
  };
  const [serviceAgreementSelectedValue, setServiceAgreementSelectedValue] = useState(
    props.serviceContractDb?.contractTypeId ?? serviceContractDb?.contractTypeId ?? 0,
  );

  const [serviceAgreementType, setServiceAgreementType] = useState(
    serviceContractDb?.type ?? 'Ingen serviceavtale valgt',
  );
  const [isLoanPaymentSelected, setIsLoanPaymentSelected] = useState<boolean>(
    serviceContractDb?.isLoanPaymentIntervalSelected ?? false,
  );

  const serviceAgreementText = 'Velg serviceavtale';
  const drivingDistanceText = 'Årlig kjørelengde';
  const dealerSupportText = 'Forhandlerrabatt';
  const durationText = 'Varighet serviceavtale';
  const useTypeText = 'Brukstype';
  const paymentIntervalText = 'Betalingsintervall';

  const [drivingDistanceSelectedValue, setDrivingDistanceSelectedValue] = useState(
    getDistance(serviceContractDb, request, isLeasing),
  );

  const [dealerAmount, setDealerAmount] = useState<number>(serviceContractDb?.dealerAmount ?? 0);

  const [durationSelectedValue, setDurationSelectedValue] = useState(
    getDuration(serviceContractDb, request, isLeasing),
  );

  const usageType = getUsageType(serviceContract, serviceContractDb);
  const [selectedInterval, setSelectedInterval] = useState(getPaymetInterval(serviceContract, serviceContractDb));

  const [useTypeSelectedValue, setUseTypeSelectedValue] = useState(usageType?.name);
  const [usageTypeId, setUsageTypeId] = useState(usageType?.id);

  const [paymentIntervalId, setPaymentIntervalId] = useState(selectedInterval?.id);
  const [paymentIntervalSelectedValue, setPaymentIntervalSelectedValue] = useState(selectedInterval?.name);

  const [resetForm, setResetForm] = useState<boolean>(false);

  const serviceContractPrice = getServiceContractPrice(
    serviceContract,
    props.calculation,
    serviceAgreementSelectedValue,
    durationSelectedValue,
    drivingDistanceSelectedValue,
    usageTypeId,
  );

  const calculation = getServiceContractCalculation(serviceContractPrice, dealerAmount);
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (!resetForm) {
      getServiceContractPriceFieldValue(serviceContractPrice);
    } else {
      setResetForm(false);
    }
  }, [serviceAgreementSelectedValue, durationSelectedValue, drivingDistanceSelectedValue, dealerAmount, usageTypeId]);

  useEffect(() => {
    setIsSaving(props.isSaving);
    if (props.isSaving) {
      setChanges([]);
    }
  }, [props.isSaving]);

  useEffect(() => {
    setServiceAgreementSelectedValue(getSelectedValue());
    setIsForhandlerVisible(false);
    if (props.serviceContract && props.serviceContractDb) {
      setSelectedInterval(getPaymetInterval(props.serviceContract, props.serviceContractDb));
    }
    if (props.serviceContractDb) {
      const newFinancingIsLeasing = (props.type || props.calculation?.financingType) === FinancingType.LEASING;
      setServiceContractDb(props.serviceContractDb);
      setServiceAgreementType(props.serviceContractDb.type);
      setIsForhandlerVisible(true);
      setDrivingDistanceSelectedValue(
        getDistance(props.serviceContractDb, props.calculation?.financing?.request, newFinancingIsLeasing),
      );
      setDurationSelectedValue(
        getDuration(props.serviceContractDb, props.calculation?.financing?.request, newFinancingIsLeasing),
      );
      setIsLoanPaymentSelected(props.serviceContractDb?.isLoanPaymentIntervalSelected);
      setChanges([]);
    }
  }, [props.serviceContractDb, resetForm]);

  useEffect(() => {
    setIsSaving(false);
    setFinancing(props.calculation?.financing);
    setRequest(props.calculation?.financing?.request);
    setFinancingType(props.calculation?.financingType);
    setDrivingDistanceSelectedValue(
      getDistance(props.serviceContractDb, props.calculation?.financing?.request, isLeasing),
    );
    setDurationSelectedValue(getDuration(props.serviceContractDb, props.calculation?.financing?.request, isLeasing));
  }, [props.calculation]);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onValueChange = (value, fieldName, originalValue) => {
    if (!value && typeof value !== 'number' && fieldIds.isLoanPaymentIntervalSelected !== fieldName) return null;
    const formChanges = [...changes];
    let newChange;
    const previousChangeIndex = getChangeIndex(formChanges, fieldName);
    if (value === originalValue) {
      // 1. if value is the same than original value, remove change
      if (previousChangeIndex > -1) {
        formChanges.splice(previousChangeIndex, 1);
        setChanges(formChanges);
      }
    } else {
      if (previousChangeIndex > -1) {
        // 2. if value is different than original value, add change
        formChanges.splice(previousChangeIndex, 1);
      }
      newChange = { fieldName, originalValue, value };
      setChanges([...formChanges, newChange]);
    }
  };

  const setPriceFields = (value, fieldName, originalValue) => {
    const previousChangeIndex = getChangeIndex(changes, fieldName);
    if (previousChangeIndex > -1) {
      changes.splice(previousChangeIndex, 1);
    }
    const newChange = { fieldName, originalValue, value };
    setChanges((prevState) => [...prevState, newChange]);
  };

  const getServiceContractPriceFieldValue = (price) => {
    if (serviceAgreementType !== 'Ingen serviceavtale valgt') {
      setIsChange(true);
      setPriceFields(calculation?.totalSavings, fieldIds.totalSavings, 0);
      setPriceFields(calculation?.monthlyPrice, fieldIds.monthlyPrice, 0);
      setPriceFields(calculation?.totalPrice, fieldIds.totalPrice, 0);
      setPriceFields(calculation?.rebatePercentage, fieldIds.rebatePercentage, 0);
      setPriceFields(serviceAgreementType, fieldIds.type, serviceAgreementType);
      setPriceFields(calculation?.importerDiscount, fieldIds?.importerDiscount, calculation?.importerDiscount);
      setPriceFields(calculation?.workshopDiscount, fieldIds?.workshopDiscount, calculation?.workshopDiscount);
      setPriceFields(serviceContractPrice, fieldIds.serviceContractPriceConfig, serviceContractPrice);
      setPriceFields(price?.km, fieldIds.drivingDistance, 0);
      setPriceFields(price?.months, fieldIds.duration, 0);
      setPriceFields(isLoanPaymentSelected, fieldIds.isLoanPaymentIntervalSelected, false);
      setPriceFields(paymentIntervalId, fieldIds.paymentPeriodId, paymentIntervalId);
      setPriceFields(serviceAgreementSelectedValue, fieldIds.contractTypeId, serviceAgreementSelectedValue);
      setPriceFields(useTypeSelectedValue, fieldIds.usageType, '');
      setPriceFields(usageTypeId, fieldIds.usageId, 0);
    } else {
      setPriceFields(serviceAgreementSelectedValue, fieldIds.contractTypeId, serviceAgreementSelectedValue);
      return;
    }
    if (!serviceContractPrice) {
      setChanges([]);
    }
  };

  const onInputChange = (event, fieldName, originalValue) => {
    let valueString: string = event?.currentTarget?.value ?? event.target.value;
    valueString = valueString?.replace(/\s/g, '')?.replace(',', '.');
    const value: number = parseFloat(!valueString ? '0' : valueString);
    serviceContractPrice ? onValueChange(value, fieldName, originalValue) : setChanges([]);
    setDealerAmount(value);
  };

  const getServiceContracts = () => {
    const defaultOption = { id: 0, name: 'Ingen serviceavtale valgt' };
    const contractList = cloneDeep(serviceContract.contractTypeList);
    contractList.unshift(defaultOption);
    return getServiceContractOptions(contractList, serviceAgreementSelectedValue);
  };

  const onServiceContractChange = (option: any, originalValue: number) => {
    setServiceAgreementSelectedValue(option.value);
    setServiceAgreementType(option.label);
    onValueChange(option.value, fieldIds.contractTypeId, originalValue);
    const setVisible = option.value !== 0;
    setIsForhandlerVisible(setVisible);
  };

  const onServiceContractDurationChange = (option: any, originalValue: number) => {
    option.value = !isLeasing ? option.value * 12 : option.value;
    setDurationSelectedValue(option.value);
    onValueChange(option.value, fieldIds.duration, originalValue);
  };

  const onServiceContractDistanceChange = (option: any, originalValue: number) => {
    setDrivingDistanceSelectedValue(option.value);
    onValueChange(option.value, fieldIds.drivingDistance, originalValue);
  };

  const onCarTypeChange = (option: any, originalValue: string) => {
    onValueChange(option.value, fieldIds.usageId, originalValue);
    setUseTypeSelectedValue(option.label);
    setUsageTypeId(option.value);
    onValueChange(option.label, fieldIds.usageType, originalValue);
  };

  const getDealerDiscount = () => {
    const dealerAmount = props.serviceContractDb?.dealerAmount ?? serviceContractDb?.dealerAmount;
    return typeof dealerAmount === 'number' ? dealerAmount : 0;
  };

  const getSavingPrice = (price: number, percentage: number) => {
    if (!isChange) {
      return serviceContractDb
        ? `${formatNumber(serviceContractDb?.totalSavings)} kr (${formatNumber(serviceContractDb.rebatePercentage)}%)`
        : `${props.unknownText} kr`;
    }
    return calculation ? `${formatNumber(price)} kr (${formatNumber(percentage)}%)` : `${props.unknownText} kr`;
  };

  const onPaymentIntervalChange = (option: any, originalValue: string) => {
    if (option.label === 'Full') {
      setIsLoanPaymentSelected(true);
    }
    setPaymentIntervalSelectedValue(option.label);
    setPaymentIntervalId(option.value);
    onValueChange(option.value, fieldIds.paymentPeriodId, originalValue);
  };

  const onChangePaymentIntervalCheckbox = (e) => {
    setIsLoanPaymentSelected(e.target.checked);
    onValueChange(e.target.checked, fieldIds.isLoanPaymentIntervalSelected, isLoanPaymentSelected);
    if (e.target.checked) {
      const paymentPeriodData = serviceContract.paymentPeriodDataList.filter((payment) => payment.name === 'Full')?.[0];
      setPaymentIntervalSelectedValue(paymentPeriodData.name);
      setPaymentIntervalId(paymentPeriodData.id);
    } else {
      const paymentPeriodData = serviceContract.paymentPeriodDataList.filter((payment) => payment.month === 1)?.[0];
      setPaymentIntervalSelectedValue(paymentPeriodData.name);
      setPaymentIntervalId(paymentPeriodData.id);
    }
  };

  const resetValues = () => {
    setResetForm(true);
    setDealerAmount(serviceContractDb?.dealerAmount ?? 0);
    setServiceAgreementSelectedValue(props.serviceContractDb?.contractTypeId ?? serviceContractDb?.contractTypeId ?? 0);
    setServiceAgreementType(serviceContractDb?.type ?? 'Ingen serviceavtale valgt');
    setIsLoanPaymentSelected(serviceContractDb?.isLoanPaymentIntervalSelected ?? false);
    setDrivingDistanceSelectedValue(getDistance(serviceContractDb, request, isLeasing));
    setDurationSelectedValue(getDuration(serviceContractDb, request, isLeasing));
    setPaymentIntervalId(selectedInterval?.id);
    setPaymentIntervalSelectedValue(selectedInterval?.name);
    setUseTypeSelectedValue(usageType?.name);
    setUsageTypeId(usageType?.id);
    setChanges([]);
  };

  if (!financing) {
    return null;
  }

  return (
    <StyledForm
      name={`${isLoan ? 'loan' : 'leasing'}-update`}
      className={`${props.isSaving ? 'fullHeight' : ''}`}
      onSubmit={onSubmit}
      isEditable={props.isEditable}>
      {props.isSaving ? (
        <div className="loading">
          <LoadingSpinner size={48} color={primary} />
        </div>
      ) : (
        <>
          <div className="formTitle">Serviceavtale</div>
          {/* Check below condition when we need to enable included in price check box for offers */}
          {/* {(isLoan || isCash) && isForhandlerVisible && (
            <div className="confirmation-checkbox">
              {props.isEditable ? (
                <CheckBox
                  className={'styled-checkbox'}
                  label={isLoan ? 'Inkluder i lån' : 'Inkluder i bilpris'}
                  id={`confirmation-checkbox ${serviceContractDb?.id}`}
                  checked={isLoanPaymentSelected}
                  onChange={(e) => onChangePaymentIntervalCheckbox(e)}
                />
              ) : (
                <CheckBox
                  className={'styled-checkbox'}
                  label={isLoan ? 'Inkluder i lån' : 'Inkluder i bilpris'}
                  id={`confirmation-checkbox ${serviceContractDb?.id}`}
                  checked={isLoanPaymentSelected}
                  disabled={true}
                />
              )}
            </div>
          )} */}
          {(isLoan || isCash) && isForhandlerVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'} htmlFor={fieldIds.paymentPeriodId}>
                {paymentIntervalText}
              </label>
              {props.isEditable ? (
                !isLoanPaymentSelected ? (
                  <BosDropdown
                    className="next-dropdown"
                    nextDropdown
                    key={`${fieldIds.paymentPeriodId}_${props.calculation?.id}`}
                    onChange={(option) => onPaymentIntervalChange(option, paymentIntervalSelectedValue)}
                    options={getPaymentPeriodDataListOptions(
                      serviceContract?.paymentPeriodDataList,
                      paymentIntervalSelectedValue,
                    )}
                    placeholder={'Betalingsintervall'}
                    value={getPaymentPeriodDataListOptions(
                      serviceContract?.paymentPeriodDataList,
                      paymentIntervalSelectedValue,
                    )?.find((item) => item.label === paymentIntervalSelectedValue)}
                  />
                ) : (
                  <InputField
                    className="borderLight"
                    id={fieldIds.paymentPeriodId}
                    type={InputType.TEXT_LINE}
                    disabled={isLoanPaymentSelected}
                    defaultValue={isLoan ? 'Inkluder i lån' : 'Inkluder i bilpris'}
                  />
                )
              ) : (
                <p className={'smallFont'}>{`${paymentIntervalSelectedValue}`}</p>
              )}
            </div>
          )}
          <div className={'form-row'}>
            <label className={'smallFont'} htmlFor={fieldIds.type}>
              {serviceAgreementText}
            </label>
            {props.isEditable ? (
              <>
                <BosDropdown
                  className="next-dropdown"
                  nextDropdown
                  key={`${fieldIds.duration}_${props.calculation?.id}`}
                  onChange={(option) => onServiceContractChange(option, serviceAgreementSelectedValue)}
                  options={getServiceContracts()}
                  placeholder={serviceAgreementText}
                  value={getServiceContracts().find((item) => item.value === serviceAgreementSelectedValue)}
                />
                <div>
                  <SmallLink
                    onLinkClick={() => props.setShowServiceContractComparisionModal(true)}
                    text={'Sammenlign serviceavtaler'}
                  />
                </div>
              </>
            ) : (
              <p className={'smallFont'}>{`${serviceAgreementType}`}</p>
            )}
          </div>
          {!isLeasing && isForhandlerVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'} htmlFor={fieldIds.drivingDistance}>
                {drivingDistanceText}
              </label>
              {props.isEditable ? (
                <BosDropdown
                  className="next-dropdown"
                  nextDropdown
                  key={`${fieldIds.drivingDistance}_${props.calculation?.id}`}
                  onChange={(option) => onServiceContractDistanceChange(option, drivingDistanceSelectedValue)}
                  options={getServiceContractDistanceOptions(
                    serviceContract?.kiloMeterList,
                    drivingDistanceSelectedValue,
                  )}
                  placeholder={drivingDistanceText}
                  value={getServiceContractDistanceOptions(
                    serviceContract?.kiloMeterList,
                    drivingDistanceSelectedValue,
                  )?.find((item) => item.value === drivingDistanceSelectedValue)}
                />
              ) : (
                <p className={'smallFont'}>{`${drivingDistanceSelectedValue} km`}</p>
              )}
            </div>
          )}
          {!isLeasing && isForhandlerVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'} htmlFor={fieldIds.duration}>
                {durationText}
              </label>
              {props.isEditable ? (
                <BosDropdown
                  className="next-dropdown"
                  nextDropdown
                  id="text-style"
                  key={`${fieldIds.duration}_${props.calculation?.id}`}
                  onChange={(option) => onServiceContractDurationChange(option, durationSelectedValue)}
                  options={getServiceContractDurationOptions(serviceContract?.yearList, durationSelectedValue)}
                  placeholder={durationText}
                  value={getServiceContractDurationOptions(serviceContract?.yearList, durationSelectedValue)?.find(
                    (item) => +item.value === durationSelectedValue / 12,
                  )}
                />
              ) : (
                <p className={'smallFont'}>{`${durationSelectedValue} mnd`}</p>
              )}
            </div>
          )}
           {isForhandlerVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'} htmlFor={fieldIds.usageType}>
                {useTypeText}
              </label>
              {props.isEditable ? (
                <BosDropdown
                  className="next-dropdown"
                  nextDropdown
                  key={`${fieldIds.usageType}_${props.calculation?.id}`}
                  onChange={(option) => onCarTypeChange(option, useTypeSelectedValue)}
                  options={getCarTypeOptions(serviceContract.usageFactorDataList, useTypeSelectedValue)}
                  placeholder={useTypeText}
                />
              ) : (
                <p className={'smallFont'}>{`${useTypeSelectedValue}`}</p>
              )}
            </div>
          )}
          {isForhandlerVisible && (
            <div className={'form-row'}>
              <label className={'smallFont'} htmlFor={fieldIds.dealerAmount}>
                {dealerSupportText}
              </label>
              {props.isEditable ? (
                <InputField
                  className="borderLight"
                  id={fieldIds.dealerAmount}
                  type={InputType.FLOAT}
                  endAdornment="kr"
                  key={`${fieldIds.dealerAmount}_${props.calculation?.id}`}
                  defaultValue={resetForm ? getDealerDiscount() : dealerAmount}
                  onBlur={(e) => onInputChange(e, fieldIds.dealerAmount, getDealerDiscount())}
                  onKeyDown={(e: React.KeyboardEvent<any>) => {
                    if (e.which === 13) {
                      onInputChange(e, fieldIds.dealerAmount, getDealerDiscount());
                    }
                  }}
                  decimals={0}
                />
              ) : (
                <p className={'smallFont'}>{`${formatNumber(dealerAmount)} kr`}</p>
              )}
            </div>
          )}

          {isForhandlerVisible && (
            <div className="serviceAggrementSummaryWrapper">
              <FormRow
                className="serviceAggrementSummary"
                id="totalPrice"
                isValid
                isEditable={false}
                label="Totalpris serviceavtale: "
                value={!isChange ? serviceContractDb?.totalPrice : calculation?.totalPrice ?? props.unknownText}
                valuePostfix={' kr'}
                type={InputType.FLOAT}
              />
              <FormRow
                className="serviceAggrementSummary"
                id="totalSavings"
                isValid
                isEditable={false}
                label="Besparelse totalt: "
                value={getSavingPrice(calculation?.totalSavings, calculation?.rebatePercentage)}
                valuePostfix={''}
                type={InputType.FLOAT}
              />
              <FormRow
                className="serviceAggrementSummary"
                id="monthlyPrice"
                isValid
                isEditable={false}
                label="Månedspris: "
                value={!isChange ? serviceContractDb?.monthlyPrice : calculation?.monthlyPrice ?? props.unknownText}
                valuePostfix={' kr'}
                type={InputType.FLOAT}
              />
            </div>
          )}
          <ButtonStyled>
            {props.isEditable ? (
              <>
                <Button
                  variant={'cta'}
                  disabled={changes.length === 0 || props.isUnavailablePimData}
                  isLoading={isSaving}
                  onClick={() => props.onUpdateServiceContract(changes, serviceContractDb?.id)}>
                  Lagre
                </Button>
                <Button
                  variant={'secondary'}
                  isLoading={isSaving}
                  onClick={() => (props.serviceContractDb ? resetValues() : props.setShowServiceContract(false))}>
                  Avbryt
                </Button>
              </>
            ) : null}
          </ButtonStyled>
        </>
      )}
    </StyledForm>
  );
};

ServiceAgreementForm.defaultProps = {
  unknownText: 'ukjent',
  isSaving: false,
};
