import { gql } from '@apollo/client';
import { createExistingOrderMutationOrderFragments } from 'next-common';

export const updateOrderMutationWithOrderInputMutationParsed = gql`
 mutation UpdateOrder($orderInput: OrderInput!, $updateOrderId: String!, $nextArticles: NextArticlesInput) {
  updateOrder(OrderInput: $orderInput, id: $updateOrderId, nextArticles: $nextArticles) {
      ...order
    }
  }
 ${createExistingOrderMutationOrderFragments}
`;
