import { useQuery } from '@apollo/client';
import { IPcodeMaster } from 'sales-common';
import { getPCodeMasterListQueryParsed } from '../../graphql';
import { ERROR_POLICY } from '../constants';

export interface IPCodesParams {
  articleCode: string;
  pCodeMasterData: IPcodeMaster[];
}

export interface IPcodesResult {
  pCodes: IPcodeMaster[];
  loading: boolean;
}

export const usePCodes = (params: IPCodesParams): IPcodesResult => {
  const isPcodeAvailable = () => params?.pCodeMasterData?.[0]?.articleCode === params.articleCode;
  const query = getPCodeMasterListQueryParsed;
  const { data, loading } = useQuery(query, {
    variables: { articleCode: params.articleCode },
    skip: !params.articleCode || isPcodeAvailable(),
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
  });
  return { pCodes: isPcodeAvailable ? params?.pCodeMasterData : data?.getPCodeMasterList, loading };
};
