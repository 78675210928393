import { ILead, IOrder, OrderCreationType, OrderStatus } from 'next-common';
import { IMyOrdersItem } from '../../components/dashboard/my-overview-page/MyOrders';
import { statusChecked } from './apollo';

export const orderPlaceholder = ':orderId';
export const pimIdPlaceholder = ':pimId';
export const makePlaceholder = ':make';
export const modelSeriesPlaceholder = ':modelSeries';
export const PATHS = {
  home: '/',
  salesWizard: '/salg',
  salesWizardRoot: '/salg*',
  view: `/view/${orderPlaceholder}`,
  margins: '/marginer',
  details: '/marginer/detaljer',
};

export const packageAppendingPath = '/pakker';

export const getRoutes = (wizardRoot = PATHS.salesWizard) => ({
  vehiclePicker: `${wizardRoot}/bil/${orderPlaceholder}`,
  vehicleLeadPicker: `${wizardRoot}/bil/${orderPlaceholder}?merke=${makePlaceholder}&modellserie=${modelSeriesPlaceholder}`,
  vehiclePackages: `${wizardRoot}${packageAppendingPath}/${pimIdPlaceholder}`,
  vehicleAccessories: `${wizardRoot}/tilbehor/${orderPlaceholder}`,
  importerOptions: `${wizardRoot}/importerOpsjoner/${orderPlaceholder}`,
  customer: `${wizardRoot}/kunde/${orderPlaceholder}`,
  finance: `${wizardRoot}/finansogtjenester/${orderPlaceholder}`,
  signing: `${wizardRoot}/signering/${orderPlaceholder}`,
  forbidden: `${wizardRoot}/ingen-tilgang`,
  dynamicListMer: `${wizardRoot}/dynamic-list-mer`,
});

export const newRoutes = {
  vehiclePicker: `/bil/${orderPlaceholder}`,
  vehicleLeadPicker: `/bil/${orderPlaceholder}?merke=${makePlaceholder}&modellserie=${modelSeriesPlaceholder}`,
  vehiclePackages: `${packageAppendingPath}/${pimIdPlaceholder}`,
  vehicleAccessories: `/tilbehor/${orderPlaceholder}`,
  importerOptions: `/importerOpsjoner/${orderPlaceholder}`,
  customer: `/kunde/${orderPlaceholder}`,
  finance: `/finansogtjenester/${orderPlaceholder}`,
  signing: `/signering/${orderPlaceholder}`,
  forbidden: '/ingen-tilgang',
  dynamicListMer: '/dynamic-list-mer',
};

const isOffer = (status: OrderStatus) =>
  ['CREATED', 'DRAFT_CREATED', 'PAUSED', 'FAILURE', 'SIGNED', 'EXPIRED'].includes(status?.toString());

const isEditable = (status: OrderStatus) => {
  return ['CONFIRMED','SIGNED'].includes(status?.toString());
};

export const isStockOrder = (orderType) => {
  return orderType === OrderCreationType.STOCK
}

export const isDemoOrder = (orderType) => {
  return orderType === OrderCreationType.DEMO
}

// Manage sigining buttons
export const manageSignPageButtonOperation = (orderType) => {
  switch (orderType) {
    case OrderCreationType.STOCK:
      return { 
        disableDigitalSignBtn: true, 
        disableManualSignBtn: true, 
        disableStockOrderBtn: false, 
        showStockOrderBtn: true,
        disableChangeFinanceBtn: true,
        disableChangeServiceContractBtn: true 
      };
    case OrderCreationType.DEMO:
      return { 
        disableDigitalSignBtn: true, 
        disableManualSignBtn: false, 
        disableStockOrderBtn: true, 
        showStockOrderBtn: false,
        disableChangeFinanceBtn: true,
        disableChangeServiceContractBtn: true  
      };
    default:
      return { 
        disableDigitalSignBtn: false, 
        disableManualSignBtn: false, 
        disableStockOrderBtn: true, 
        showStockOrderBtn: false,
        disableChangeFinanceBtn: false,
        disableChangeServiceContractBtn: false  
      };
  }
}

export class Routes {
  static getHomePage = () => PATHS.home;
  static getSalesWizardPage = () => PATHS.salesWizard;
  static getMarginsPage = () => PATHS.margins;
  static getDetailsPage = () => PATHS.details;
  static getVehiclePickerPage = (orderId = '', root = PATHS.salesWizard, lead: ILead = null) => {
    if (lead) {
      return getRoutes(root)
        .vehicleLeadPicker.replace(orderPlaceholder, orderId)
        .replace(makePlaceholder, lead.make)
        .replace(modelSeriesPlaceholder, lead.modelSeries?.toLowerCase() ?? '');
    }
    return getRoutes(root).vehiclePicker.replace(orderPlaceholder, orderId);
  };
  static getNewVehiclePickerPage = (lead: ILead = null) => {
    if (lead) {
      return newRoutes.vehicleLeadPicker;
    }
    return newRoutes.vehiclePicker;
  };
  static getVehiclePackagePage = (pimId = '', root = PATHS.salesWizard) =>
    getRoutes(root).vehiclePackages.replace(pimIdPlaceholder, pimId);
  static getImporterOptionsPage = (orderId = '', root = PATHS.salesWizard) => //New page for importers(varebil options)
    getRoutes(root).importerOptions.replace(orderPlaceholder, orderId);
  static getVehicleAccessoriesPage = (orderId = '', root = PATHS.salesWizard) =>
    getRoutes(root).vehicleAccessories.replace(orderPlaceholder, orderId);
  static getCustomerPage = (orderId = '', root = PATHS.salesWizard) =>
    getRoutes(root).customer.replace(orderPlaceholder, orderId);
  static getFinancePage = (orderId = '', root = PATHS.salesWizard) =>
    getRoutes(root).finance.replace(orderPlaceholder, orderId);
  static getSigningPage = (orderId = '', root = PATHS.salesWizard) =>
    getRoutes(root).signing.replace(orderPlaceholder, orderId);
  static getViewPage = (orderId = '') => PATHS.view.replace(orderPlaceholder, orderId);
  static getForbiddenPage = () => getRoutes('').forbidden;
  static getDynamicListMer = () => getRoutes('').dynamicListMer;
}

export const findRouteForOrder = (order: IOrder | IMyOrdersItem) => {
  statusChecked({
    isNoVehicle: false,
    isNoPimData: false,
    isNoFactoryCode: false,
  });
  if (isEditable(order?.status)) return Routes.getVehicleAccessoriesPage(order?.id);
  if (!isOffer(order?.status) && !isEditable(order?.status)) throw new Error('Cannot continue on completed order');
  if (!order?.vehicles || order?.vehicles?.length < 1) return Routes.getVehiclePickerPage(order?.id);
  if (isStockOrder(order?.lead?.orderType)) {
    if(order?.chosenOffer) {
      return Routes.getSigningPage(order?.id);
    }
    return Routes.getVehiclePickerPage(order?.id);
  }
  if (!order?.chosenOffer) return Routes.getFinancePage(order?.id);
  return Routes.getSigningPage(order?.id);
};
