import { useEffect, useState } from 'react';
import { SalesOrderViewStyled, SalesOrderWrapperStyled } from './SalesOrderView.styled';
import { OrderPane } from './OrderPane';
import { SalesOrderBanner } from './Banner';
import { CommentPane } from './CommentPane';
import { ERROR_POLICY, IVehicleStockResult, useEmployee, useOrder, useVehicleDataByPimCode, useVehicleStock } from '../../../common';
import { IApiEmployee, IConfiguration, IOrder, IVehicleConfiguration} from 'next-common';
import { checkVehicleSoldOrReserved, getAppendingNumericIdFromUrl, getEmployeeDealerIds, getGroupName, getIndependentDealer, importerLocationIds, isSelectedVehileIsUnavailable, isValidStockSaleOrder, isVehicleValidForReserveOrSignin } from '../../../utils';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { ITypedComment } from '../sales-common/Comments';
import { EventPane } from './EventPane';
import { Tabs } from '@next-components/tabs';
import { OrderViewTabs } from '../sales-common/enums';
import { MarginPane } from './MarginPane';
import { useLazyQuery } from '@apollo/client';
import { findVehicleByPimCode } from '../../../graphql';
import { IVehicleUpdate } from '../sales-wizard';
import { MongoEvents } from 'sales-common';
import { isNil } from 'lodash';
import { PreparationPane } from './PreparationPane';
import { useVehicleOrderDraft } from '../../../common/hooks/useVehicleOrderDraft';

export interface ISalesOrderViewProps {
  dealerRole?: string;
  isTestUser?: boolean;
  configuration: IConfiguration;
  manageSocket?(group?: string, vehicleUpdate?: (update: IVehicleUpdate) => void): void;
}

const getViewPageProps = () => ({
  orderId: getOrderId(),
  order: null,
  vehicle: null,
  pimId: null,
});

const getOrderId = () => getAppendingNumericIdFromUrl('');

const sortByTimestampDesc = () => {
  return (a: ITypedComment, b: ITypedComment) => (a.timestamp < b.timestamp ? 1 : -1);
};

export const findComments = (order: IOrder) => {
  const salesComments: ITypedComment[] = Array.isArray(order?.comments) ? order.comments?.map((c) => ({ ...c, type: 'sale' })) : [];
  let financeComments: ITypedComment[] = [];
  order.offers?.forEach((calculation) => {
    financeComments = [...financeComments, ...calculation?.comments?.map((c) => ({ ...c, type: 'finance' })) ?? []];
  });
  return [...salesComments, ...financeComments].sort(sortByTimestampDesc());
};

export const SalesOrderView = (props: ISalesOrderViewProps) => {
  const params = new URLSearchParams(window.location.search);
  const tab = params.get('tab') ? OrderViewTabs[params.get('tab').toUpperCase()] : OrderViewTabs.OFFER;
  const order: IOrder = useOrder(getViewPageProps());
  const { vehicle,useVehicleLoading } = useVehicleDataByPimCode({
    orderId: getOrderId(),
    source: order?.lead?.source,
    configuration: props?.configuration,
    serialNo: order?.vehicles?.[0]?.serialNo,
    make: order?.lead?.make,
    pimCode:order?.orderReference?.pimCode
  });

  const {orderDraft,draftLoading} = useVehicleOrderDraft(getOrderId())

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnavailablePimData, setIsUnavailablePimData] = useState<boolean>(false);
  const [group, setGroup] = useState<string>(null);
  const [validLocationIds, setValidLocationIds] = useState<string[]>([]);
  const isStockSaleOrder = isValidStockSaleOrder(order, props.configuration);
  const [isVehicleValidForReserveOrSign, setIsVehicleValidForReserveOrSign] = useState<boolean>(true);  
  const [vehicleAvailabilityCode, setVehicleAvailabilityCode] = useState<string>(null);
  const [isSelectedVehicleUnavailable, setIsSelectedVehicleUnavailable] = useState<boolean>(false);
  const [vehicleList, setVehicleList] = useState<IVehicleConfiguration>();
  const [dealerRoleType, setDealerRoleType] = useState<string>('DEFAULT');

  const employee: IApiEmployee = useEmployee();
  useEffect(() => {
    if (!employee) return;
    const employeeDealerIds: string[] = getEmployeeDealerIds(employee);

    setValidLocationIds([...employeeDealerIds, ...importerLocationIds]);
  }, [employee]);

  useEffect(()=>{
    if(!isNil(vehicle)){
      setVehicleList(vehicle);
    }
  },[vehicle])

  const { data: selectedVehicleStock, loading: stockLoading }: IVehicleStockResult = useVehicleStock({
    group: getGroupName(order?.lead?.make, order?.lead?.modelSeries),
    serialNo: order?.vehicles?.[0]?.serialNo,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isStockSaleOrder) {
      const groupName: string = getGroupName(order?.lead?.make, order?.lead?.modelSeries);
      setGroup(groupName);
    }
  }, [order, props?.configuration, isStockSaleOrder]);

  useEffect(() => {
    setVehicleAvailabilityCode(selectedVehicleStock?.[0]?.availabilityCode);
    if(isStockSaleOrder && selectedVehicleStock) {
      const vehicleUnavailable = isSelectedVehileIsUnavailable(order, null, selectedVehicleStock?.[0]);
      setIsSelectedVehicleUnavailable(vehicleUnavailable)
      const isValidVehicle = isVehicleValidForReserveOrSignin(selectedVehicleStock?.[0], validLocationIds, order);
      setIsVehicleValidForReserveOrSign(isValidVehicle);
    }
  }, [order, selectedVehicleStock, validLocationIds]);

  useEffect(() => {
    if (group) props.manageSocket(group, receiveVehicleUpdate);
    return () => {
      props.manageSocket();
    };
  }, [group]);

  //Receive vehicle stock update on view page for Showing validation message and hide reserve button
  const receiveVehicleUpdate = (updatedVehicle: IVehicleUpdate) => {
    const event = updatedVehicle?.operation;
    const updatedData = updatedVehicle?.data;
    const selectedSerialNo = order?.vehicles?.[0]?.serialNo;
    if (updatedData && selectedSerialNo === updatedData?.serialNumber.toString()) {
      if(event === MongoEvents.DELETE) {
        const currentVehicleStatus = checkVehicleSoldOrReserved(updatedData, order?.id);
        const vehicleUnavailable = isSelectedVehileIsUnavailable(order, currentVehicleStatus);
        setIsSelectedVehicleUnavailable(vehicleUnavailable)
      }
      if(event === MongoEvents.UPDATE) {
        const isValidVehicle = isVehicleValidForReserveOrSignin(updatedData, validLocationIds);
        setIsVehicleValidForReserveOrSign(isValidVehicle);
        setVehicleAvailabilityCode(updatedData?.availabilityCode);
      }
    }
  }

  useEffect(() => {
    if (order?.user?.email) {
      getIndependentDealer(order?.user?.email)
        .then((data) => {
          if(data) {
            console.log("===== Dealer Data ===== ", data)
            setDealerRoleType('INDEPENDENT_DEALER')
          }
        })
        // tslint:disable-next-line:no-console
        .catch((err) => console.error(err));
    }
  }, [order])

  if (!order || isLoading || stockLoading || useVehicleLoading || draftLoading) {
    return <LoadingSpinner isModal={true} />;
  }

  return (
    <SalesOrderWrapperStyled>
      <SalesOrderBanner order={order} />
      <SalesOrderViewStyled>
        <Tabs defaultTabId={tab} filled>
          <Tabs.TabList>
            <Tabs.Tab tabId={OrderViewTabs.OFFER}>Salg</Tabs.Tab>
            <Tabs.Tab tabId={OrderViewTabs.EVENTS}>Hendelser</Tabs.Tab>
            <Tabs.Tab tabId={OrderViewTabs.COMMENTS}>Kommentarer</Tabs.Tab>
            <Tabs.Tab tabId={OrderViewTabs.Preparation}>Klargjøring</Tabs.Tab>
            {/* To be hidden until further clarity */}
            {/* {!order?.isAgent && <Tabs.Tab tabId={OrderViewTabs.MARGIN}>Marginer</Tabs.Tab>} */}
          </Tabs.TabList>
          <Tabs.TabPanel tabId={OrderViewTabs.OFFER}>
            <OrderPane
              order={order}
              vehicleConfig={vehicle}
              vehicleList={vehicleList}
              configuration={props.configuration}
              isVehicleValidForReserveOrSign={isVehicleValidForReserveOrSign}
              vehicleAvailabilityCode={vehicleAvailabilityCode}
              isSelectedVehicleUnavailable={isSelectedVehicleUnavailable}
              dealerRole={dealerRoleType}
              orderDraft = {orderDraft}
            />
          </Tabs.TabPanel>
          <Tabs.TabPanel tabId={OrderViewTabs.EVENTS}>
            <EventPane orderId={order.id} />
          </Tabs.TabPanel>
          <Tabs.TabPanel tabId={OrderViewTabs.COMMENTS}>
            <CommentPane comments={findComments(order)} orderId={order.id} />
          </Tabs.TabPanel>
          {!order?.isAgent && (
            <Tabs.TabPanel tabId={OrderViewTabs.MARGIN}>
              <MarginPane order={order} vehicleConfig={vehicle} />
            </Tabs.TabPanel>
          )}
          <Tabs.TabPanel tabId={OrderViewTabs.Preparation}>
            <PreparationPane orderId={order.id} configuration={props?.configuration}/>
          </Tabs.TabPanel>
        </Tabs>
      </SalesOrderViewStyled>
    </SalesOrderWrapperStyled>
  );
};

export default SalesOrderView;
