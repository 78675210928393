import { CheckBox } from '@next-components/bos-checkbox';
import { ToggleSwitch } from '@next-components/toggle-switch';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Accordion } from '@next-components/accordion';
import {
  InteriorFormatterComponent,
  LagerKodeFormatterComponent,
  StedFormatterComponent,
  TTFormatterComponentStock,
  TilleggFormatterComponent,
  fargeFormatterComponent,
} from '../VehicleListDialog/VehicleListFormatter';
import { IOrder, MAKE, NextErrorCode, getIFSModelSeriesCodeFromPimCode, invokeProvidedBrandSpecificCode } from 'next-common';
import { DynamicListStock, IDynamicListStockTableData, IRowPosition } from '../../../common-components/DynamicListStock';
import { StatusTagSeverityLevel, Tag } from '@next-components/tag';
import { TagStyled } from './VehicleStockListDialog.styled';
import { Search } from '@next-components/search';
import { Button } from '@next-components/button';
import {
  ISellAllCarsFilter,
  IVehicleFilter,
  IVehicleFilterDetail,
  IVehicleListOrderProps,
  IVehicleStockListDialogProps,
  createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed,
  getTTCodeStatusName,
  IVehicleFilterConfig,
  IVehicleStockForSellAllCars,
  IVehicleStockSellAllCars,
  Routes,
  removeVehiclesFromOrderMutationParsed,
  addChosenOfferMutationParsed,
  ERROR_POLICY,
  isStockOrder,
  isDemoOrder,
  ITilleggSelectable
} from '../../../../common';
import { isEmpty, find, defaultTo, intersection } from 'lodash';
import { IVehicleStock } from 'sales-common';
import { OperationVariables, useLazyQuery, useMutation } from '@apollo/client';
import { NextErrorNotification, ValidationErrorCode, getErrorMessageForError } from '../../../notifications';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { ToastArea } from '../../sales-common/ToastArea';
import lodashFilter from 'lodash/filter';
import { LocationIds, getAppendingNumericIdFromUrl, mapIsDisableVehicles, prepareVehicleInputForStock, removeVehicleStockFromCache, updateOrderVariablesForUsageCodeAndTaxes } from '../../../../utils';
import { CustomAlertPopup } from '../../../common-components/custom-DeletePopup';
import saleAllCarsWorker from '../../../../utils/sales/salsAllCarsWorker';
import { useNavigate } from 'react-router';
import { IAlertPopup } from '../../sales-wizard';
import { OtherDealerVehicleSelectionPopup } from '../../../common-components/OtherDealerVehicleSelection';
import { isVehicleOnOtherDealerLocation } from '../../../../utils';
import { IconType } from '@next-components/bos-icon';
import { getPCodeMasterListQueryParsed } from '../../../../graphql';

/**
 * The function `getFilterKeys` returns an array of keys from an object filter if it is not empty.
 * @param filter - The `filter` parameter is an object that contain key-value pairs used for
 * filtering data.
 * @returns An array of keys from the `filter` object is being returned.
 */
const getFilterKeys = (filter: IVehicleFilterConfig) => {
  let data = [];
  if (filter && Object.keys(filter)?.length > 0) {
    data?.push(...Object.keys(filter));
  }
  return data;
};
/**
 * The function `addIsDisable` takes in vehicle stock data, valid location IDs, and a reserve status,
 * and returns a sorted array of vehicles with an additional property `isDisable` based on the input
 * parameters.
 * @param {IVehicleStock[]} vehicleStockData - IVehicleStock[] - an array of objects containing
 * information about vehicle stock
 * @param {string[]} validLocationIds - validLocationIds is an array of string values representing the
 * valid location IDs where vehicles can be reserved.
 * @param {boolean} reserveStatus - The `reserveStatus` parameter is a boolean value that indicates
 * whether a vehicle is reserved or not. If `reserveStatus` is `true`, it means the vehicle is
 * reserved, and if it is `false`, it means the vehicle is not reserved.
 * @returns The function `addIsDisable` is returning the result of calling the `mapIsDisableVehicles`
 * function with the provided arguments `vehicleStockData`, `validLocationIds`, and `reserveStatus`.
 * The result is then sorted based on the `isDisable` property of the elements in the array.
 */
const addIsDisable = (vehicleStockData: IVehicleStock[], validLocationIds: string[], reserveStatus: boolean) => {
  return mapIsDisableVehicles(vehicleStockData, validLocationIds, reserveStatus)?.sort((x, y) => {
    if (x.isDisable === y.isDisable) return 0;
    else return !x.isDisable ? -1 : 1;
  });
};
interface IVehicleStockwithPartNo extends IVehicleStock {
  partNo?: string;
}

export const VehicleStockListDialog = (props: IVehicleStockListDialogProps) => {
  const [isExactMatch, setIsExactMatch] = useState<boolean>(false);
  const [exactMatch, setExactMatch] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState<ISellAllCarsFilter[]>([]);
  const [isShowAllDisplay, setIsShowAllDisplay] = useState(props.filters?.equipments?.length > 5);
  const [filter, setFilter] = useState<IVehicleFilterConfig>();
  const [filterKeys, setFilterKeys] = useState<string[]>(getFilterKeys(filter));
  const [dynamicListData, setDynamicListData] = useState(
    addIsDisable(props?.vehicleStockData, props?.validLocationIds, props.vehicleReservedStatus) ?? [],
  );
  const [ttCodes, setTtCodes] = useState<string[]>();
  const [updatedFilter, setUpdatedFilter] = useState<IVehicleFilter>();
  const [selectedSerialNo, setSelectedSerialNo] = useState<number>(props.selectedSerialNo ?? 0);
  const [isCalledOnSelect, setIsCalledOnSelect] = useState<boolean>(false);
  const [updateOrderWithVehicleEquipmentMutation] = useMutation(
    createExistingOrderWithVehicleAndVehicleEquipmentMutationParsed,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [order, setOrder] = useState<IOrder>(props.order);
  const [showOtherDealerVehiclePopup, setShowOtherDealerVehiclePopup] = useState(false);
  const listStockDataWebWorker = useRef(null);
  const modelType = props?.order?.lead?.make ?? MAKE.Mercedes;
  const navigate = useNavigate();
  const [removeVehiclesFromOrder] = useMutation(removeVehiclesFromOrderMutationParsed);
  const [newSelectedCar, setNewSelectedCar] = useState<IDynamicListStockTableData>(null);
  const [resetSerialNumberSearch, setResetSerialNumberSearch] = useState<boolean>(false);
  const [searchSerialNumberString, setSearchSerialNumberString] = useState<string>('');
  const [resetExtraEquipmentSearch, setResetExtraEquipmentSearch] = useState<boolean>(false);
  const [searchExtraEquipmentString, setSearchExtraEquipmentString] = useState<string>('');
  const [showOtherDealerVehicleMsg, setShowOtherDealerVehicleMsg] = useState<boolean>(false);
  const isOrderStockType = isStockOrder(props?.order?.lead?.orderType);
  const isOrderDemoType = isDemoOrder(props?.order?.lead?.orderType);

  useEffect(() => {
    if(filter?.['locationId']?.length) {
      let selectedLocationIds = intersection(filter?.['locationId'], props.validLocationIds);
      if(selectedLocationIds?.length) setShowOtherDealerVehicleMsg(false)
      else setShowOtherDealerVehicleMsg(true)
    } else {
      setShowOtherDealerVehicleMsg(false)
    }
  }, [props.validLocationIds, filter])

  const raiseNotification = (errorCode?: NextErrorCode, serialNo?: string) => {
    switch (errorCode) {
      case NextErrorCode.VEHICLE_STOCK_STATUS_ERROR:
        return setNotifications([
          <NextErrorNotification
            key="InvalidvehicleStockStatusError"
            severityLevel={MessagebarSeverityLevel.ERROR}
            errorCode={NextErrorCode.VEHICLE_STOCK_STATUS_ERROR}
            hideLink
            onClose={async () => {
              await removeVehicleStockFromCache({
                vehicleStockDetails: { brand: modelType, modelSeries: getIFSModelSeriesCodeFromPimCode(props?.modelSeries)?.toUpperCase(), serialNumber: Number(serialNo) },
              });
              setNotifications([]);
            }}
            onCloseLinkText="Ok"
          />,
        ]);
      default:
        return setNotifications([
          <NextErrorNotification
            key="vehicleAddError"
            severityLevel={MessagebarSeverityLevel.ERROR}
            errorCode={NextErrorCode.VEHICLE_ADD_ERROR}
            onClose={() => setNotifications([])}
          />,
        ]);
    }
  };

  useEffect(() => {
    listStockDataWebWorker.current = saleAllCarsWorker();

    listStockDataWebWorker.current.onmessage = (event) => {
      setDynamicListData(addIsDisable(event?.data, props?.validLocationIds, props.vehicleReservedStatus));
      props?.setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useEffect(() => {
    if (!isEmpty(filter)) {
      listStockDataWebWorker.current?.postMessage({
        vehicleStock: props?.vehicleStockData,
        filters: filter,
        make: modelType,
        selectedSerialNo: selectedSerialNo,
      });
    } else {
      setDynamicListData(addIsDisable(props?.vehicleStockData, props?.validLocationIds, props.vehicleReservedStatus));
    }
  }, [props?.vehicleStockData]);

  useEffect(() => {
    setTtCodes(() => {
      const newTtCodes: string[] = [];
      props.filters?.ttCodes?.forEach((code) => {
        const name = getTTCodeStatusName(modelType, +code?.id);
        if (!newTtCodes?.includes(name)) {
          newTtCodes?.push(name);
        }
      });
      return newTtCodes;
    });
  }, [props.filters?.ttCodes]);

  useEffect(() => {
    setIsShowAllDisplay(props.filters?.equipments?.length > 5);
    setFilteredEquipments(props.filters?.equipments?.slice(0, 5));
  }, [props.filters?.equipments]);

  useEffect(() => {
    setIsShowAllDisplay(props?.filters?.equipments?.length > 5);
    setFilteredEquipments(props?.filters?.equipments?.slice(0, 5));
  }, [props?.filters?.equipments]);

  useEffect(() => {
    if (!isEmpty(props?.selectedModelName) && !selectedSerialNo) {
      if(invokeProvidedBrandSpecificCode(MAKE.Kia, { order: props?.order })) {
        props.dataFromFactoryEquipLevels?.modelCodes?.forEach((modelCode) => {
          const modelName = props?.filters?.models?.find(model => model?.id === modelCode)?.labelName;
          handleFilterCheck('modelId', modelCode, modelName);
        });
      } else {
        if (!isEmpty(props?.selectedModelCode) && props?.selectedModelName) {
          handleFilterCheck('modelId', props?.selectedModelCode, props?.selectedModelName);
        }
      }
      if (!isEmpty(props.selectedVariantSku)) {
        if (props.selectedVariantSku !== props?.selectedModelCode) {
          if(invokeProvidedBrandSpecificCode(MAKE.Kia, { order: props?.order })) {
            props.dataFromFactoryEquipLevels?.skus?.forEach((sku) => {
              const equipName = props?.filters?.equipments?.find(equipment => equipment?.id === sku)?.labelName;
              handleFilterCheck('exteriorSelectableFormatter', sku, equipName);
            });
          } else {
            if (props.selectedVariantSku !== "" && props.selectedVariantName !== "") {
              handleFilterCheck(
                'exteriorSelectableFormatter',
                props.selectedVariantSku?.split(' ')?.pop(),
                props.selectedVariantName,
              );
            }
          }
        }
      }
      if (!isEmpty(props?.selectedColorId) && props?.selectedColorName) {
        handleFilterCheck('colorId', props?.selectedColorId, props?.selectedColorName);
      }
      if (!isEmpty(props?.selectedInteriorId) && props?.selectedInteriorName) {
        handleFilterCheck('interiorCode', props?.selectedInteriorId, props?.selectedInteriorName);
      }
      if (!isEmpty(props?.selectedRims)) {
        handleFilterCheck('exteriorSelectableFormatter', props?.selectedRims?.sku, props?.selectedRims?.name, props?.selectedRims?.priceExcludingVat);
      }
      if (!isEmpty(props?.selectedListverk)) {
        handleFilterCheck('exteriorSelectableFormatter', props?.selectedListverk?.sku, props?.selectedListverk?.name, props?.selectedListverk?.priceExcludingVat);
      }
      if (!isEmpty(props?.selectedEquipmentIds)) {
        props?.selectedEquipmentIds?.forEach((equipment) => {
          handleFilterCheck('exteriorSelectableFormatter', equipment?.sku, equipment?.name, equipment?.priceExcludingVat);
        });
      }
    }
    if (props.isSpecificSellerEnabled && !isOrderStockType) {
      switch (props.vehicleLocation) {
        case LocationIds.OWN:
          props.dealerIds?.forEach((dealerId) => {
            const stedObj = props.filters?.locations?.find((loc) => loc.id === dealerId);
            if (!isEmpty(stedObj)) {
              handleFilterCheck('locationId', dealerId, stedObj?.name);
            }
          });
          break;
        case LocationIds.PORT:
          props.importerLocationIds?.forEach((importerId) => {
            const stedObj = props.filters?.locations?.find((loc) => loc.id === importerId);
            if (!isEmpty(stedObj)) {
              handleFilterCheck('locationId', importerId, stedObj?.name);
            }
          });
          break;
        case LocationIds.OTHER:
          const mergedLocationIds = [...props.dealerIds, ...props.importerLocationIds];
          const uniqueLocations = props.filters?.locations?.filter(
            (location) => !mergedLocationIds.includes(location.id),
          );
          uniqueLocations?.forEach((location) => handleFilterCheck('locationId', location?.id, location?.name));
          break;
        default:
          break;
      }
    }
  }, [
    props?.selectedModelName,
    props?.selectedModelCode,
    props?.selectedInteriorId,
    props?.selectedColorId,
    props?.selectedRims,
    props?.selectedListverk,
    props?.selectedEquipmentIds,
    props.selectedVariantSku,
    props.selectedVariantName,
    props.selectedColorName,
    props.selectedInteriorName
  ]);

  useEffect(() => {
    const filterKeys = filter ? Object.keys(filter) : [];
    if (filterKeys.length > 0) {
      if (
        filter?.modelId?.length === 1 &&
        filter?.colorId?.length === 1 &&
        filter?.interiorCode?.length === 1 &&
        !filter?.trackTraceCode &&
        !filter?.locationId
      ) {
        filterExactandNonExactMatch(props?.vehicleStockData, filter);
      } else {
        setIsExactMatch(false);
        listStockDataWebWorker.current?.postMessage({
          vehicleStock: props?.vehicleStockData,
          filters: filter,
          make: modelType,
          selectedSerialNo: selectedSerialNo,
        });
      }
    } else {
      setDynamicListData(addIsDisable(props?.vehicleStockData, props?.validLocationIds, props.vehicleReservedStatus));
    }

    setFilterKeys(filterKeys);
    props?.setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (selectedSerialNo && dynamicListData?.length) {
      const selectedRows = lodashFilter(dynamicListData, { serialNumber: selectedSerialNo });
      setSelectedRow(selectedRows);
      if (!selectedRows?.length) setSelectedSerialNo(props?.selectedSerialNo ?? 0);
    }
  }, [isCalledOnSelect]);

  const fargeFormatter = (colorId: string, vehicleListData: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
    return fargeFormatterComponent(colorId, vehicleListData, filter, rowPosition);
  };

  const TTFormatter = (traceCode: number, vehicleListData: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
    return TTFormatterComponentStock(traceCode, modelType, filter, vehicleListData, rowPosition);
  };

  const StedFormatter = (locationId: string, vehicleListData: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
    return StedFormatterComponent(locationId, vehicleListData, filter, rowPosition);
  };

  const interiorCodeFormatter = (interiorCode: string, vehicleListData: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
    return InteriorFormatterComponent(interiorCode, vehicleListData, filter, rowPosition);
  };

  const LagerKodeFormatter = (lagerKode: string, vehicleListData: IDynamicListStockTableData, rowPosition?: IRowPosition) => {
    return LagerKodeFormatterComponent(lagerKode, vehicleListData, rowPosition);
  };

  const ekstrautstyrFormatter = (
    exteriorSelectableFormatter: ITilleggSelectable[],
    vehicleListData: IDynamicListStockTableData,
    rowPosition?: IRowPosition,
  ) => {
    return TilleggFormatterComponent(exteriorSelectableFormatter, vehicleListData, filter, rowPosition);
  };

  const mainConfig = {
    cols: [
      { header: 'Motorvariant', key: 'modelDesc', colWidth: '12.5rem', noSort:true },
      { header: 'Farge', key: 'farge', colWidth: '12.5rem', formatter: fargeFormatter, noSort: true },

      { header: 'Interiør', key: 'interiorCode', colWidth: '10.5rem', noSort: true, formatter: interiorCodeFormatter },
      {
        header: 'Ekstrautstyr',
        key: 'exteriorSelectableFormatter',
        colWidth: '10.5rem',
        formatter: ekstrautstyrFormatter,
        noSort: true,
      },
      { header: 'Serienummer', key: 'serialNumber', colWidth: '10.5rem', noSort: true },
      { header: 'Prod.dato', key: 'prod', colWidth: '10.5rem', noSort: true },
      { header: 'TT', key: 'trackTraceCode', colWidth: '5.625rem', formatter: TTFormatter, noSort: false  },
      { header: 'Sted', key: 'locationId', colWidth: '5.625rem', formatter: StedFormatter, noSort: true },
      { header: 'Lagerkode', key: 'status', colWidth: '8.25rem', noSort: true, formatter: LagerKodeFormatter },
    ],
    withCheckbox: true,
    noHeader: false,
    selectable: true,
    maxSelected: 1,
    withAccordion: true,
  };

  const handleSwitchToggle = (): void => {
    setIsExactMatch(!isExactMatch);
  };

  const handleSearchChange = (event) => {
    const searchString = event?.target?.value;
    if (!searchString) {
      setFilteredEquipments(props?.filters?.equipments?.slice(0, 5));
      setIsShowAllDisplay(props?.filters?.equipments?.length > 5);
    } else {
      const equipments = props?.filters?.equipments?.filter((equipment) =>
        equipment?.name?.toLowerCase()?.includes(searchString?.toLowerCase()),
      );
      setFilteredEquipments(equipments?.slice(0, 5));
      setIsShowAllDisplay(equipments?.length > 5);
    }
  };

  const handleSerialNumberSearch = (event) => {
    const searchString: string = event?.target?.value;
    let timeOutHandler = null;
    if (!isNaN(Number(searchString)) && searchString !== '') {
      if (filter?.serialNumber?.[0] !== searchString || filter?.serialNumber?.length === 0) {
         setTimeout(() => {
          timeOutHandler = setFilter((prevFilter) => {
            return {
              ...prevFilter,
              serialNumber: [searchString],
            };
          });
        }, 800);
      }
    } else {
      if (searchString === '' && filter?.serialNumber.length > 0) {
        setFilter((prevFilter) => {
          const newFilter = {...prevFilter};
          delete newFilter['serialNumber'];
          return {
            ...newFilter,
          };
        });
      }
     clearTimeout(timeOutHandler);
    }
  };

  const showAllEquipments = () => {
    setIsShowAllDisplay(!isShowAllDisplay);
    isShowAllDisplay
      ? setFilteredEquipments(props?.filters?.equipments)
      : setFilteredEquipments(props.filters?.equipments?.slice(0, 5));
  };

  /**
   * The function `handleFilterCheck` is used to update a filter object based on the type and value
   * provided.
   * @param {string} type - Type is a string that represents the category or type of filter being
   * applied, such as "color", "motorvariant", "trackTraceCode", etc.
   * @param value - The `value` parameter in the `handleFilterCheck` function represents the value that
   * you want to add to or remove from the filter based on the specified `type`. It is used to update
   * the filter object based on the type of filter being applied.
   */
  const handleFilterCheck = useCallback(
    (type: string, value: string, name?: string, price?: number) => {
      if (props?.order?.lead?.make === MAKE.Mercedes && price <= 0) return;
      props?.setIsLoading(true);
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        if (newFilter?.[type]) {
          if (newFilter[type]?.includes(value)) {
            if (newFilter[type]?.length > 1) {
              newFilter[type] = newFilter[type]?.filter((element: string) => element !== value);
            } else {
              delete newFilter[type];
            }
          } else {
            newFilter[type]?.push(value);
          }
        } else {
          newFilter[type] = [value];
        }

        return {
          ...newFilter,
        };
      });

      const findMatchingObject = (newFilter) => {
        return find(newFilter, function (filterDataObject) {
          if (filterDataObject?.value === value) {
            return true;
          }
          return false;
        });
      };
      setUpdatedFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        if (!isEmpty(newFilter[type])) {
          if (findMatchingObject(newFilter[type])) {
            if (newFilter[type]?.length > 1) {
              newFilter[type] = newFilter[type]?.filter((element: IVehicleFilterDetail) => element?.value !== value);
            } else {
              delete newFilter[type];
            }
          } else {
            newFilter[type]?.push({ value, name });
          }
        } else {
          newFilter[type] = [{ value, name }];
        }
        return {
          ...newFilter,
        };
      });
    },
    [filter],
  );

  const removeAndAddNewCar = async (order: IOrder) => {
    props.setIsLoading(true);
    let variables: OperationVariables = {
      orderId: order?.id,
      options: null,
      vehicles: null,
      vatCalculation: null,
    };
    const { data } = await removeVehiclesFromOrder({ variables });
    if (data?.removeVehiclesFromOrder.__typename === 'NextError') {
      props.raiseNotification(
        <NextErrorNotification
          key="removeVehicleFail"
          orderId={order?.id}
          errorCode={data?.removeVehiclesFromOrder?.errorCode}
          onClose={() => props.clearNotifications('removeVehicleFail')}
        />,
      );
    } else {
      if (!!newSelectedCar) {

        setSelectedSerialNo(newSelectedCar?.serialNumber);
        setNewSelectedCar(null);
        //Create vehicle
        if (newSelectedCar?.serialNumber && dynamicListData?.length) {
          const selectedRows = lodashFilter(dynamicListData, { serialNumber: newSelectedCar?.serialNumber });
          setSelectedRow(selectedRows);
          if (!selectedRows?.length) {
            setSelectedSerialNo(props?.selectedSerialNo ?? 0);
            onSelectStockVehicle(props?.selectedSerialNo ?? 0);
          } else {
            onSelectStockVehicle(newSelectedCar?.serialNumber);
          }
        }
      }
    }
  };

  const onButtonClickDetailPage = (selectedRowData) => {
    if (props?.selectedSerialNo) {
      setNewSelectedCar(selectedRowData)
      setShowDeletePopup(true);
    } else {
      // setLoading(true);
      setSelectedSerialNo(selectedRowData?.serialNumber);
      setIsCalledOnSelect(true);
      if (selectedRowData?.serialNumber) {
        const selectedVehicleStock = getSelectedVehicleStock(selectedRowData?.serialNumber);
        if(isVehicleOnOtherDealerLocation(props?.validLocationIds, selectedVehicleStock?.locationId)) {
          setShowOtherDealerVehiclePopup(true);
        } else onSelectStockVehicle(selectedRowData?.serialNumber);
      } 
    }
  };

  const getOrderId = (appendingPath = '') => getAppendingNumericIdFromUrl(appendingPath);

  const [addChosenOffer] = useMutation(addChosenOfferMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });

  const isPcodePageAvailable = async (data: IOrder) => {
    let isPCodesAvailable = false;
    if(props.isVarebilEnabled) {
      if(data?.pCodes?.length) {
        isPCodesAvailable = true;
      } else {
        if(data?.pimSnapshot?.salesArticle === props.pCodeMasterData?.[0]?.articleCode) isPCodesAvailable = true;
        else {
          const UpdatedPCodeList = await getPCodeList({ variables: { articleCode: data?.pimSnapshot?.salesArticle } });
          isPCodesAvailable = Boolean(UpdatedPCodeList?.data?.getPCodeMasterList?.length);
        }
      }
    }
    return isPCodesAvailable;
  }

  /**
   * redirect to accessories page
   * @returns void;
   */
  const redirect = async ({ data }: { data?: IOrder }) => {
    const serialNo = data?.vehicles?.[0]?.serialNo
    let route;
    if (isOrderStockType) {
      route = Routes.getSigningPage(props.order?.id);
    } else if (props.isVarebilEnabled) {
      setLoading(true);
      const isPCodesAvailable = await isPcodePageAvailable(data);
      if(isPCodesAvailable) {
        route = Routes.getImporterOptionsPage(props.order?.id);
      } else {
        route = Routes.getVehicleAccessoriesPage(props.order?.id);
      }
    } else {
      route = Routes.getVehicleAccessoriesPage(props.order?.id);
    }
    navigate(route, { state: { serialNo } });
  };

  const [getPCodeList] = useLazyQuery(getPCodeMasterListQueryParsed, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: ERROR_POLICY,
    onCompleted: (data) => {
      // setLoading(false);
      return;
    },
    onError: (error) => {
      // setLoading(false);
      console.log(error);
    },
  });
  
  const getSelectedVehicleStock = (selectedSerialNo: number): IVehicleStockwithPartNo => {
    const selectedVehicleRow = dynamicListData?.filter((vehicle) => vehicle?.serialNumber === selectedSerialNo);
    return defaultTo(selectedVehicleRow?.[0], null);
  }

  const onSelectStockVehicle = async (serialNo: number = 0) => {
    try {
      if (selectedSerialNo || serialNo) {
        setLoading(true);
        const selectedVehicleStockRow = getSelectedVehicleStock(serialNo || selectedSerialNo);
        const vehicles = prepareVehicleInputForStock(selectedVehicleStockRow);
        let orderVariables = props.getUpdateOrderVariables(null);
        let variables: IVehicleListOrderProps = {
          id: orderVariables?.id,
          input: {
            ...orderVariables?.input,
            //For now we have set to false but need to check with vehicleStatus Object
            isAgent: props?.order?.lead?.make === MAKE.Smart,
            orderReference: {
              pimCode: selectedVehicleStockRow?.modelId,
              pimId: null,
            },
          },
          ...(selectedVehicleStockRow?.modelDesc
            ? { lead: { modelSeries: selectedVehicleStockRow?.modelDesc, make: selectedVehicleStockRow?.brand } }
            : {}),
        };
        variables.input.lead = {
          ...variables?.input?.lead,
          subFamilySeries: props.modelSeries || '',
        };           
        variables = updateOrderVariablesForUsageCodeAndTaxes(variables, props?.overriddenTaxAndUsageCodeDetails) as any;

        const { data } = await updateOrderWithVehicleEquipmentMutation({
          variables: {
            ...variables,
            orderId: getOrderId(),
            vehicles,
            options: [],
            priceConjunctionList: [],
            nextArticles: null,
            equipments: [],
          },
        });
        const calculationId = data?.createExistingOrderWithVehicleAndVehicleEquipment?.offers?.[0]?.id;
        if (calculationId && (isOrderStockType || isOrderDemoType)) {
          const { data } = await addChosenOffer({
            variables: {
              orderId: props?.order?.id,
              calculationId: calculationId,
              nextArticles: null,
            },
          });

          if (data?.addChosenOffer.__typename === 'NextError') {
            props.raiseNotification(
              <NextErrorNotification
                key="chooseOfferFail"
                orderId={props?.order?.id}
                errorCode={data?.addChosenOffer?.errorCode}
                onClose={() => props?.clearNotifications('chooseOfferFail')}
              />,
            );
          }
        }
        const order = data?.createExistingOrderWithVehicleAndVehicleEquipment;

        if (!data?.createExistingOrderWithVehicleAndVehicleEquipment?.id) {
          return raiseNotification(
            data?.createExistingOrderWithVehicleAndVehicleEquipment?.errorCode,
            vehicles?.[0]?.serialNo,
          );
        } else {
          setIsCalledOnSelect(false);
          setFilter(null);
          setUpdatedFilter(null);
          props.setSelectedModelName(null);
          redirect({ data: data?.createExistingOrderWithVehicleAndVehicleEquipment });
        }
      }
    } catch (error) {
      raiseNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (vehicle) => {
    setSelectedSerialNo(selectedSerialNo === vehicle?.serialNumber ? 0 : vehicle?.serialNumber);
  };

  const removeVehicle = () => {
    props.setIsShowDeleteModel(true);
  };

  /**
   * The function `filterExactandNonExactMatch` filters a list of vehicle stock based on exact matches
   * of color, interior code, and exterior equipment.
   * @param {IVehicleStockSellAllCars[]} data - The `data` parameter in the
   * `filterExactandNonExactMatch` function is an array of objects of type `IVehicleStockSellAllCars`.
   * Each object in the array represents a vehicle stock with various properties such as `colorId`,
   * `interiorCode`, and `exteriorSelectableFormatter
   * @param {IVehicleFilterConfig} filters - The `filters` parameter in the
   * `filterExactandNonExactMatch` function is an object that contains filter configurations for
   * vehicles. It includes properties such as `colorId`, `interiorCode`, and
   * `exteriorSelectableFormatter` which are used to filter the `data` array of vehicle stock
   */
  const filterExactandNonExactMatch = (data: IVehicleStockSellAllCars[], filters: IVehicleFilterConfig) => {
    let exactMatch = [];
    if (filters?.exteriorSelectableFormatter?.length > 0) {
      data?.forEach((row) => {
        if (row?.colorId === filters?.colorId?.[0] && row?.interiorCode === filters?.interiorCode?.[0] && row?.modelId === filters?.modelId?.[0]) {
          let matchingEquipment = row?.exteriorSelectableFormatter?.filter((equipment) =>
            filters?.exteriorSelectableFormatter?.includes(equipment?.sku),
          );

          if (row?.exteriorSelectableFormatter?.length === matchingEquipment?.length) {
            exactMatch.push(row);
          }
        }
      });
    }

    if (exactMatch.length > 0) {
      setExactMatch(addIsDisable(exactMatch, props?.validLocationIds, false));
      setIsExactMatch(!!exactMatch.length);
      listStockDataWebWorker.current?.postMessage({
        vehicleStock: exactMatch,
        filters: filter,
        make: modelType,
        selectedSerialNo: selectedSerialNo,
      });
    } else {
      setExactMatch([]);
      setIsExactMatch(false);
      listStockDataWebWorker.current?.postMessage({
        vehicleStock: props?.vehicleStockData,
        filters: filter,
        make: modelType,
        selectedSerialNo: selectedSerialNo,
      });
    }
  };


  /**
   * The function setFilterTagColor determines the severity level of a status tag based on whether a
   * specific ID is included in a filter.
   * @param {string} id - The `id` parameter is a string that represents the identifier of a specific
   * item or element.
   * @param {string} filterKey - The `filterKey` parameter is a string that represents the key used to
   * access a specific filter in an object or data structure. It is used to retrieve a specific filter
   * from the `filter` object in the `setFilterTagColor` function.
   * @returns The function `setFilterTagColor` returns either `StatusTagSeverityLevel.INFORMATION` or
   * `StatusTagSeverityLevel.WARNING` based on whether the `id` is included in the filter specified by
   * `filterKey`.
   */
  const setFilterTagColor = (id: string, filterKey: string): StatusTagSeverityLevel => {
    return filter?.[filterKey]?.includes(id) ? StatusTagSeverityLevel.INFORMATION : StatusTagSeverityLevel.WARNING;
  }


  const handleClose = () => {
    props.setIsShowDeleteModel(false);
  };

  const replaceSelectedCarData: IAlertPopup = {
    title: `Endre valgt bil?`,
    description: `${order?.vehicles?.[0]?.serialNo} er allerede valgt bil.
      Vil du endre valget til ${newSelectedCar?.serialNumber}?`,
    actionsList: [
      {
        label: 'Ja, endre valgt bil',
        actionMethod: () => {
          if(isVehicleOnOtherDealerLocation(props?.validLocationIds, newSelectedCar?.locationId)) {
            setShowOtherDealerVehiclePopup(true);
          }
          else {
            removeAndAddNewCar(order);
          }
          setShowDeletePopup(false);
        },
      },
      {
        label: 'Avbryt',
        actionMethod: () => setShowDeletePopup(false),
        actionVariant: 'secondary',
      },
    ],
  };


  const onResetSerialNumberSearch = async () => {
    setSearchSerialNumberString('');
    await setResetSerialNumberSearch(true);
    setResetSerialNumberSearch(false);

  };

  const onResetSearchExtraEquipment = async () => {
    setSearchExtraEquipmentString('');
    await setResetExtraEquipmentSearch(true);
    setResetExtraEquipmentSearch(false);
  };
  

  return (
    <>
      <div className="left-container">
        <h3>Filtrer treff ({isExactMatch ? exactMatch?.length : dynamicListData?.length})</h3>
        <ToggleSwitch
          label={'Vis nesten like biler'}
          checked={!isExactMatch}
          disabled={
            filter?.modelId?.length > 1 ||
            filter?.colorId?.length > 1 ||
            filter?.interiorCode?.length > 1 ||
            !!filter?.locationId ||
            !!filter?.trackTraceCode?.length
          }
          toggleHandler={handleSwitchToggle}
        />
        <div className="filter-accordion-container">
        <Accordion primaryLabel="Søk på serienummer" isOpen={true} className='serialNumberSearchFilter'>
          <Search  onChange={(event) => handleSerialNumberSearch(event)} onSearch={setSearchSerialNumberString} onBlur={null} nextComponent  defaultValue={searchSerialNumberString} resetSearch={resetSerialNumberSearch}/>
        </Accordion>
          <Accordion primaryLabel="Motorvariant" isOpen={true}>
            {props.filters?.models?.map((model) => {
              const VehicleStock = props?.vehicleStockData || [];
              const vehicle = VehicleStock?.filter(
                (vehicle: IVehicleStockForSellAllCars) => vehicle?.modelId === model?.id,
              );
              return (
                <CheckBox
                  checked={filter?.['modelId']?.includes(model?.id) ?? false}
                  label={`${model?.labelName} (${vehicle?.length})`}
                  onChange={() => handleFilterCheck('modelId', model?.id, model?.labelName)}
                  id={model?.id}
                  key={model?.id}
                />
              );
            }) ?? 'Ingen motorvariant tilgjengelig'}
          </Accordion>
          <Accordion primaryLabel="Ekstrautstyr" isOpen={true}>
            <Search onChange={handleSearchChange} onSearch={setSearchExtraEquipmentString} onBlur={null} nextComponent  defaultValue={searchExtraEquipmentString} resetSearch={resetExtraEquipmentSearch}/>
            {filteredEquipments?.map((equipement) => (
                <CheckBox
                  checked={filter?.['exteriorSelectableFormatter']?.includes(equipement?.id) ?? false}
                  // @ts-expect-error
                  label={
                    <TagStyled>
                      {equipement?.name?.slice(0, equipement?.name.lastIndexOf(' ') + 1)}
                      <Tag
                        className="new rounded"
                        value={equipement?.name
                          ?.substring(equipement?.name.lastIndexOf(' ') + 1)
                          ?.replace('(', '')
                          ?.replace(')', '')}
                        key={equipement?.id}
                        statusTagSeverity={setFilterTagColor(equipement?.id, 'exteriorSelectableFormatter')}
                      />
                    </TagStyled>
                  }
                  onChange={() => handleFilterCheck('exteriorSelectableFormatter', equipement?.id, equipement?.labelName)}
                  id={equipement?.id}
                  key={equipement?.id}
                />
              ))}

            {filteredEquipments?.length >= 5 && (
              <Button className="showOptions" onClick={showAllEquipments} variant="action">
                <span>{`Vis ${isShowAllDisplay ? 'alle' : 'less'}`}</span>
              </Button>
            )}
          </Accordion>
          <Accordion primaryLabel="Farge" isOpen={true}>
            {props.filters?.colors?.map((color) => (
              <CheckBox
                checked={filter?.['colorId']?.includes(color?.id) ?? false}
                // @ts-expect-error
                label={
                  <TagStyled>
                    {color?.name.slice(0, color.name.length - 6)}
                    <Tag
                      className="new rounded"
                      value={color?.name
                        ?.slice(color?.name?.length - 5, color?.name?.length)
                        ?.replace('(', '')
                        ?.replace(')', '')}
                      key={color?.id}
                      statusTagSeverity={setFilterTagColor(color?.id, 'colorId')}
                    />
                  </TagStyled>
                }
                onChange={() => handleFilterCheck('colorId', color?.id, color?.labelName)}
                id={color?.id}
                key={color?.id}
              />
            )) ?? 'Ingen farge tilgjengelig'}
          </Accordion>
          <Accordion primaryLabel="Interiør" isOpen={true}>
            {props.filters?.interiors?.map((interior) => (
              <CheckBox
                checked={filter?.['interiorCode']?.includes(interior?.id) ?? false}
                // @ts-expect-error
                label={
                  <TagStyled>
                    {interior?.labelName}
                    <Tag
                      className="new rounded"
                      value={interior?.id}
                      key={interior?.id}
                      statusTagSeverity={setFilterTagColor(interior?.id, 'interiorCode')}
                    />
                  </TagStyled>
                }
                onChange={() => handleFilterCheck('interiorCode', interior?.id, interior?.labelName)}
                id={interior?.id}
                key={interior?.id}
              />
            )) ?? 'Ingen interiør tilgjengelig'}
          </Accordion>
          <Accordion primaryLabel="TT" isOpen={true}>
            {props.filters?.ttCodes?.length > 0
              ? ttCodes?.map((ttCodeName) => (
                  <CheckBox
                    key={ttCodeName}
                    checked={filter?.trackTraceCode?.includes(ttCodeName)}
                    label={ttCodeName}
                    id={ttCodeName}
                    onChange={() => handleFilterCheck('trackTraceCode', ttCodeName)}
                  />
                ))
              : 'Ingen TT tilgjengelig'}
          </Accordion>
          {isOrderStockType ? null :
          <Accordion primaryLabel="Sted" isOpen={true}>
            {props.filters?.locations?.map((location) => (
              <CheckBox
                checked={filter?.['locationId']?.includes(location?.id) ?? false}
                // @ts-expect-error
                label={
                  <TagStyled>
                    {location?.name.slice(0, location.name.length - 6)}
                    <Tag
                      className="new rounded"
                      value={location?.name
                        ?.slice(location?.name?.length - 5, location?.name?.length)
                        ?.replace('(', '')
                        ?.replace(')', '')}
                      key={location?.id}
                      statusTagSeverity={setFilterTagColor(location?.id, 'locationId')}
                    />
                  </TagStyled>
                }
                onChange={() => handleFilterCheck('locationId', location?.id, location?.name)}
                id={location?.id}
                key={location?.id}
              />
            )) ?? 'Ingen sted tilgjengelig'}
          </Accordion>
          }
        </div>
      </div>
      <ToastArea notifications={notifications}></ToastArea>

      <div className="right-container">
        <TagStyled>
          {((!isEmpty(updatedFilter) && filterKeys?.length > 0) || !isExactMatch) && (
            <Tag
              value="Fjern alle filtre"
              className={'new dark close'} 
              onClose={() => {
                onResetSerialNumberSearch();
                onResetSearchExtraEquipment();
                props?.setIsLoading(true);
                // Added timeout to show loader on clear all filters
                setTimeout(() => {
                  setFilter(null);
                  setUpdatedFilter(null);
                  setExactMatch([]);
                  setIsExactMatch(false);
                  props?.setIsLoading(false);
                }, 1400);
              }}
            />
          )}
          {!isExactMatch && (
            <Tag
              value="Nesten like biler (tillegg markert i gult)"
              className={'new light close'}
              disabled={
                filter?.modelId?.length > 1 ||
                filter?.colorId?.length > 1 ||
                filter?.interiorCode?.length > 1 ||
                filter?.locationId?.length > 0 ||
                filter?.trackTraceCode?.length > 0
              }
              onClose={() => {
                if (
                  !(
                    filter?.modelId?.length > 1 ||
                    filter?.colorId?.length > 1 ||
                    filter?.interiorCode?.length > 1 ||
                    filter?.locationId?.length > 0 ||
                    filter?.trackTraceCode?.length > 0
                  )
                ) {
                  setIsExactMatch(!isExactMatch);
                }
              }}
            />
          )}
          {filterKeys?.map((filterKey) => {
            return updatedFilter?.[filterKey]?.map((filter: IVehicleFilterDetail) => (
              <Tag
                key={filter?.value}
                value={filterKey === 'trackTraceCode' ? filter?.value : filter?.name}
                className={'new light close'}
                onClose={() => handleFilterCheck(filterKey, filter?.value)}
              />
            ));
          })}
        </TagStyled>
        {
          showOtherDealerVehicleMsg ?
          <div className='vehicleListValidation'>
            <Messagebar
              messageBoxInfo
              alertIcon={IconType.Warning}
              severityLevel={MessagebarSeverityLevel.WARNING}
              title={getErrorMessageForError(ValidationErrorCode.OTHER_DEALER_VEHICLE_LIST_VALIDATION)}
            /> 
          </div> : ''
        }
        <DynamicListStock
          isVehicleReserved={props.isVehicleReserved}
          onButtonClickDetailPage={onButtonClickDetailPage}
          isLoading={loading || props?.isLoading}
          isNewDynamicList={true}
          height="calc(100vh - 32.5rem)"
          data={isExactMatch ? exactMatch : dynamicListData}
          config={mainConfig}
          setActiveTabId={() => setIsExactMatch(!isExactMatch)}
          fetchingExactMatchLength={() => null}
          fetchingNonExactMatchLength={() => null}
          filter={filter}
          onElementClick={handleSelect}
          selectedRows={selectedRow}
          selectedRowsKey="serialNumber"
          selectedSerialNumber={props?.selectedSerialNo ?? 0}
          removeVehicle={removeVehicle}
          order={props?.order}
        />
      </div>
      {showDeletePopup && (
        <CustomAlertPopup
          className="changeSelectedCarModal"
          hideIcon={true}
          isOpen={showDeletePopup}
          onCloseClick={() => {
            setShowDeletePopup(false);
          }}
          deleteOrderData={replaceSelectedCarData}></CustomAlertPopup>
      )}
      {props.isShowDeleteModel && (
        <CustomAlertPopup
          isOpen={props.isShowDeleteModel}
          onCloseClick={handleClose}
          className='deleteAlert'  
          deleteOrderData={props.deleteOrderData}></CustomAlertPopup>
      )}
      {showOtherDealerVehiclePopup && (
        <OtherDealerVehicleSelectionPopup 
          isOpen={showOtherDealerVehiclePopup}
          availabilityCode={newSelectedCar?.availabilityCode || getSelectedVehicleStock(selectedSerialNo)?.availabilityCode}
          onCancel={() => {
            setShowDeletePopup(false);
            setShowOtherDealerVehiclePopup(false)}
          }
          onApprove = {() => {
            setShowDeletePopup(false);
            setShowOtherDealerVehiclePopup(false);
            if(props?.selectedSerialNo) {
              removeAndAddNewCar(order);
            } else {
              onSelectStockVehicle();
            }
          }}
        />
      )}
    </>
  );
};
