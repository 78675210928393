import { findIndex, isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getConfig, sortItems } from './DynamicListStock.utils';
import DynamicListDisplayRecord from './DynamicListStockDisplayRecord';
import { IDynamicListConfig, IDynamicListStockTableData, IDynamicListProps } from './IDynamicListStock';

export enum DISPLAY_DATA {
  EXACT_MATCH,
  NON_EXACT_MATCH,
}

export const DynamicListStock = (props: IDynamicListProps) => {
  const [config, setConfig] = useState<IDynamicListConfig>(getConfig(props));
  const [sortKey, setSortKey] = useState<string[]>(props.sort?.map((column) => column?.key));
  const [sortReverse, setSortReverse] = useState<boolean[]>(props.sort?.map((column) => column?.reverse));
  const [sortedExactMatch, setSortedExactMatch] = useState([]);
  const [sortedNonExactMatch, setSortedNonExactMatch] = useState([]);
  const [selectedRows, setSelectedRows] = useState<IDynamicListStockTableData[]>(props.selectedRows);


  // Setting the current value of "sort" to reflect recently changed value
  useEffect(() => {
    setSortKey(props?.sort?.map((column) => column?.key));
  }, [props?.sort]);

  useEffect(() => {
    setConfig(getConfig(props));
    if(props.selectedSerialNumber){
      const index = findIndex(props?.data, { 'serialNumber': props?.selectedSerialNumber });
      if (index !== -1) {
        const foundItem = props?.data.splice(index, 1)[0]; 
        props?.data.unshift(foundItem); 
      }
    }
  }, [props?.data, props?.config]);

  // On sort change or data change, following effect would execute
  useEffect(() => {
    const updatedExactMatchList = props?.data?.filter((item) =>
      props?.exactMatchSerialNo?.includes(item?.serialNumber),
    );
    const updatedNonExactMatchList = props?.data?.filter((item) =>
      props?.nonExactMatchSerialNo?.includes(item?.serialNumber),
    );
    const sortedDataExactMatch = sortItems(
      updatedExactMatchList,
      sortKey?.map((row, index) => ({ key: row, reverse: sortReverse?.[index] })),
    ).sort(
      (nextStatus, previousStatus) => Number(nextStatus?.disabled ?? false) - Number(previousStatus?.disabled ?? false),
    );
    const sortedDataNonExactMatch = sortItems(
      updatedNonExactMatchList,
      sortKey?.map((row, index) => ({ key: row, reverse: sortReverse?.[index] })),
    ).sort(
      (nextStatus, previousStatus) => Number(nextStatus?.disabled ?? false) - Number(previousStatus?.disabled ?? false),
    );
    setSortedExactMatch(sortedDataExactMatch);
    setSortedNonExactMatch(sortedDataNonExactMatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, sortKey, sortReverse]);

  useEffect(() => {
    setSelectedRows(props?.selectedRows);
  }, [props?.selectedRows]);

  const onSelectRow = (data: IDynamicListStockTableData) => {
    if (typeof config?.maxSelected === 'number') {
      let selected = [...selectedRows];
      const index = selected?.findIndex((row) => row === data);
      if (index === -1) {
        if (config?.maxSelected === 1) {
          selected = [data];
        } else if (selectedRows?.length < config?.maxSelected) {
          selected = [...selectedRows, data];
        }
      } else {
        if (config?.maxSelected === 1) {
          selected = [];
        } else {
          selected?.splice(index, 1);
        }
      }
      setSelectedRows(selected);
      if (isFunction(props.onSelectedRows)) {
        props.onSelectedRows(selected);
      }
    }
  };

  const onElementClick = (data: IDynamicListStockTableData) => {
    if (config?.selectable) {
      onSelectRow(data);
    }
    if (isFunction(props?.onElementClick)) {
      props?.onElementClick(data);
    }
  };

  const isRowSelected = (rowData: IDynamicListStockTableData) => {
    if (!config?.selectable) {
      return null;
    }
    if (props?.selectedRowsKey) {
      const key = props?.selectedRowsKey;
      return selectedRows?.some(
        (row: IDynamicListStockTableData) =>
          row?.hasOwnProperty(key) && rowData?.hasOwnProperty(key) && row?.[key] === rowData?.[key],
      );
    }
    return selectedRows?.some((row) => row === rowData);
  };

  useEffect(() => {
    props?.fetchingExactMatchLength(sortedExactMatch?.length);
    props?.fetchingNonExactMatchLength(sortedNonExactMatch?.length);
  }, [sortedExactMatch?.length, sortedNonExactMatch?.length]);

  return (
    <div className="tabsList">
      <DynamicListDisplayRecord
        isVehicleReserved={props.isVehicleReserved}
        onButtonClickDetailPage={props?.onButtonClickDetailPage}
        isLoading={props?.isLoading}
        props={props}
        config={config}
        isRowSelected={isRowSelected}
        onElementClick={onElementClick}
        toShow={props.data} // to do
        filter={props.filter}
        selectedSerialNumber={props?.selectedSerialNumber}
        removeVehicle={props?.removeVehicle}
      />
    </div>
  );
};

DynamicListStock.defaultProps = {
  config: {
    withCheckbox: true,
    selectable: false,
    maxSelected: null,
    withAccordion: false,
  },
  showContentCount: false,
  sort: [{ key: '', reverse: false }],
  selectedRows: [],
  selectedRowsKey: null,
  isNewDynamicList: false,
};
