import styled, { css } from 'styled-components';
import { fontWeightBold, white, bosColor, margin, typography, padding } from '@next-components/common-styles';

const inputField = css`
  &:disabled {
    background-color: ${white};
  }
`;

const inputLabel = css`
  font-weight: bold;
`;

export const CardInstruction = styled.h4`
  grid-area: instruction;
  font-weight: ${fontWeightBold} !important;
  font-size: 1.125rem !important;
  line-height: 1.6875rem;
  ${margin['0'].a};
`;

export const CheckBoxContainer = styled.section`
  grid-area: checkBox-container;
  text-align: left;
  position: absolute;
  left: -0.875rem;
}
  
`;
export const CardInputDescription = styled.section`
  grid-area: input-description;
  text-align: left;
  .multiSelectOption {
    label {
      line-height: 1.125rem;
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  .inputDescriptionText {
    .inputContainer {
      label {
        font-size: 1rem;
        font-style: normal;
        font-weighr: ${typography.weight.semibold};
        line-height: 1.75rem;
        display: block;
        margin-bottom: 0.25rem;
      }
    }
  }
  #inputDescription {
    ${inputField}
    resize: none;
    height: 7.5rem;
    border: 1px solid ${bosColor.neutralPlus};
    background: ${bosColor.white};
  }

  .descriptionText {
    font-weight: ${typography.weight.semibold};
    font-size: 1.125rem;
    line-height: 1.8125;
    letter-spacing: 0.02rem;
    ${margin['8'].y};
    ${margin['0'].x};
    ${padding['0'].a};
  }
`;
export const CardInputPrice = styled.section`
  grid-area: input-price;
  text-align: left;

  .inputFieldContainer {
    label {
      font-size: 1rem;
    }
    .inputAndIconContainer {
      .adornment-container {
        top: 0.75rem;
        right: 1.375rem;

        .input-unit {
          font-size: 14px;
          font-weight: ${typography.weight.extrabold};
        }
      }
    }
  }

  > label {
    ${inputLabel}
  }
  small {
    font-size: 0.875rem;
    display: block;
    color: ${bosColor.concrete};
  }

  .priceTextContainer {
    .priceText {
      font-weight: ${typography.weight.semibold};
      font-size: 1.125rem;
      line-height: 1.8125;
      letter-spacing: 0.02rem;
      ${margin['8'].y};
      ${margin['0'].x};
    }
  }

  .inputAndIconContainer {
    #inputPriceExcludingVat, #inputDealerPriceExcludingVat, #inputPrice {
      border: 1px solid ${bosColor.neutralPlus};
      background: ${bosColor.white};
    }
  }

  .inputFieldContainer {
    .inputContainer {
      label {
        line-height: 1.125rem;
        display: block;
        padding-bottom: 0.5rem;
      }
    }
    label {
      display: flex;
      line-height: 1.25rem;
    }
  }

  > div:last-child {
    ${margin['4'].t};
  }
`;

export const CardButtonSection = styled.section`
  padding-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: auto;
  align-items: flex-end;
  
  .cardButton {
    padding: 0.75rem 1.5rem;
  }
`;
