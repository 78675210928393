import * as React from 'react';
import { format } from 'date-fns';
import { AgreementInfoItem, AgreementInfoWrapper } from './AgreementInformation.styled';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { IOrder, IVehicle } from 'next-common';
import { Tag } from '@next-components/tag';
import { IVehicleStockEcomItem } from 'sales-common';

const unknownText = 'Ukjent';

export interface IAgreementInformationProps {
  order: IOrder;
  selectedVehicleData?: IVehicleStockEcomItem;
}

export const AgreementInformation = (props: IAgreementInformationProps) => {
  const { order, selectedVehicleData } = props;
  const vehicle: IVehicle = order?.vehicles?.length > 0 ? order?.vehicles?.[0] : null;

  return (
    <AgreementInfoWrapper>
      {vehicle && (
        <>
          <AgreementInfoItem>
            <InfoBlock label="Serienummer" value={vehicle?.serialNo || '-'} className="infoItem" />
          </AgreementInfoItem>
          <AgreementInfoItem>
            <InfoBlock label="T&amp;T" className="infoItem">
              {selectedVehicleData?.vehicleStatus?.trackTraceCode ? (
                <Tag className="light new rounded" value={selectedVehicleData?.vehicleStatus?.trackTraceCode} />
              ) : (
                '-'
              )}
            </InfoBlock>
          </AgreementInfoItem>
        </>
      )}
      <AgreementInfoItem>
        <InfoBlock
          label="Opprettet"
          value={order?.createdAt ? format(new Date(order?.createdAt), 'dd.MM.yyyy') : unknownText}
          className="infoItem"
        />
      </AgreementInfoItem>
      <AgreementInfoItem>
        <InfoBlock
          label="Signert"
          value={order?.signedAt ? format(new Date(order?.signedAt), 'dd.MM.yyyy') : '-'}
          className="infoItem"
        />
      </AgreementInfoItem>
    </AgreementInfoWrapper>
  );
};

