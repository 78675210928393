import {
  black,
  bosColor,
  disabled,
  fontWeightBold,
  margin,
  padding,
  typography,
} from '@next-components/common-styles';
import styled, { css } from 'styled-components';
import { zIndexes } from '../../common';

export const CustomModalWrapper = styled.div`
  color: ${black};
  ${(props) =>
    props.active &&
    css`
      &.backdrop {
        align-items: center;
        background-color: rgba(32, 33, 70, 0.8);
        display: flex;
        height: 100vh;
        justify-content: center;
        left: 0;
        min-height: 100vh;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: ${zIndexes.zIndexButton};
      }
    `};

  .custom-modal {
    position: fixed;
    ${margin['0'].y};
    ${margin['auto'].x};
    background: ${bosColor.lightGrey};
    max-height: calc(101vh - 8rem);
    min-width: 18.75rem;
    min-height: 12.5rem;
    overflow: auto;
    ${padding['32'].a};
    box-shadow: rgb(0 0 0 / 30%) 3px 3px 0.9375rem -3px;
    border-radius: 0.5rem !important;
    z-index: ${zIndexes.zIndexModal};
  }

  &.pCodeModel {
    .custom-modal {
      width: 36.375rem !important;
      min-height: auto;
      .content-container {
        gap: 1rem;
        header {
          justify-content: start;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          h3 {
            position: relative;
            top: 0.375rem;
          }
          + div {
            padding: 0;
            justify-content: flex-end;
            .modalText {
              padding-left: 2.5rem;
            }
          }
        } 
      }
    }
  }

  &.carPickerModal {
    &.loading {
      > button.close-modal-button {
        pointer-events: none;
      }
    }

    .custom-modal {
      top: 5rem;
      transform: inherit;
      left: 0;
      right: 0;
      top: 4.5rem;
      max-height: calc(101vh - 5rem);
      width: calc(100% - 2rem);
      ${padding['16'].t};
      ${padding['24'].x};
      ${padding['32'].b};

      > button.close-modal-button {
        top: 1.5rem;
        background-color: ${bosColor.lightGrey};
        widht: 1.7rem;
        display: flex;
        align-item: center;
        justify-content: center;
      }

      .inner-container {
        ${padding['0'].a};
        overflow: hidden;
      }
      .factoryBlock {
        ${margin['48'].t};
        h3 {
          ${margin['0'].t};
          margin-bottom: 0.5rem;
          font-weight: ${fontWeightBold};
          line-height: 2.25rem;
        }
        > div {
          display: flex;
          gap: 1rem;

          &.filterBlock {
            margin-bottom: 1.375rem;
            gap: 2rem;
            width: 40rem;
            > div {
              flex: 1;
            }
          }

          .btnGroup {
            display: flex;
            gap: 2rem;
          }
          > div {
            width: auto;
          }
        }
        input {
          ${padding['16'].a};
          width: 11.5625rem;
          height: 3.5rem;
          background: ${bosColor.white};
          border-radius: 0.5rem;
          border: 3px solid ${bosColor.neutralPlus};
          ${margin['16'].r};
        }
      }

      header {
        position: relative;
        ${margin['0'].b};
        ${padding['16'].b};
        border-bottom: 1px solid ${bosColor.neutralPlus};

        button.close {
          margin-top: inherit;
        }
        h2 {
          font-weight: ${typography.weight.semibold};
          font-size: 2rem;
          line-height: 3rem;
        }
      }
      .picker-info {
        display: block;
        font-size: 1rem;
        line-height: 150%;
        font-weight: ${typography.weight.semibold};
        ${margin['0'].t};
        ${margin['0'].x};
        ${margin['8'].b};
      }
      .listItemCounterContainer {
        font-weight: ${typography.weight.semibold};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${bosColor.concrete};
        margin-top: 0.625rem;
        ${margin['16'].b};
      }
      .btnGrp {
        align-items: flex-start;
        display: flex;
        button:first-child {
          ${margin['32'].r};
        }
      }

      .tableContainer .accordionContainer > div button {
        grid-template-columns: 1fr 7.5rem;
      }
    }

    &.carPickerModalSelectable {
      .custom-modal {
        top: initial;
        bottom: 0;
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
        .carListTitle {
          + div {
            ${padding['0'].x};
            ${margin['0'].b};
          }
        }
        .accordionContainer {
          > div {
            ${padding['24'].x};
          }
          .item.grid-row {
            div {
              font-size: 0.85rem;
              font-weight: ${typography.weight.semibold};
              span {
                font-weight: ${typography.weight.semibold};
                span {
                  font-size: 0.85rem;
                  background: ${bosColor.neutralGrey};
                  padding: 2px 0.75rem;
                  color: ${bosColor.black};
                  border-radius: 0.75rem;
                }
              }
            }
            .grid-item {
              padding-top: 0.625rem;
              padding-bottom: 0.625rem;
            }
          }
          /*Once need change to add tool tip*/
          .vehicleDetailContainer {
            ${padding['0'].a};
            .item > div {
              font-size: 1rem;
            }
            .header,
            .item {
              gap: 0;
            }

            .header {
              .sortable {
                font-size: 1rem;
                padding-top: 0.75rem;
                padding-bottom: 0.5rem;
                &:nth-child(4),
                &:nth-child(6) {
                  border-right: 1px solid ${bosColor.neutralPlus};
                }
              }
            }
            .item {
              &.disabled {
                > div {
                  color: ${disabled};
                }
              }
              > div {
                font-size: 1rem;
                color: ${bosColor.concrete};
                font-weight: ${typography.weight.semibold};
                div {
                  display: flex;
                  gap: 0.25rem;
                  flex-wrap: wrap;
                }
                span {
                  .tooltip {
                    top: -7rem;
                    background: none;
                    max-width: 25rem;

                    &::after {
                      content: none;
                    }
                    .tooltip-container {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      justify-content: flex-start;
                      padding: 1.25rem;
                      gap: 0.5rem;
                      background: ${bosColor.white};
                      width: 100%;
                      max-width: 22rem;
                      min-width: 12.5rem;
                      height: auto;
                      border-radius: 0.5rem;
                      z-index: 999;
                      box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.5);
                      border-radius: 0.5rem;

                      p {
                        font-weight: ${typography.weight.extrabold};
                      }
                    }
                  }

                  span {
                    font-size: 0.875rem;
                    background: ${bosColor.neutralGrey};
                    padding: 2px 0.75rem;
                    line-height: 1.25rem;
                    border-radius: 0.875rem;
                  }
                }
                &:nth-child(4),
                &:nth-child(6) {
                  border-right: 1px solid ${bosColor.neutralPlus};
                }
              }
            }
          }
        }
      }
    }

    &.carPickerModalNoSelectable {
      .custom-modal {
        .carListTitle {
          + .carListContainer {
            position: relative;
            > button {
              ${margin['32'].t};
            }

            .header {
              justify-content: inherit;
            }

            .itemRow {
              .selectable {
                .checkbox-radio:before {
                  box-sizing: content-box;
                }
              }
            }
            .tableContainer {
              .header,
              .vehicleDetailContainer .item {
                gap: 0.5rem;
                > div {
                  font-size: 0.85rem;
                }
              }
              .vehicleDetailContainer {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
              }
              .accordionContainer {
                > div {
                  button {
                    .item.itemRow {
                      gap: 0.5rem;
                    }
                  }
                }
              }
            }
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            margin: 1.375rem 0;
            font-size: 1.5rem;
            line-height: 2.25rem;
            span {
              font-size: 1.5rem;
              line-height: 2.25rem;
            }
          }

          > div {
            width: 100%;
            max-width: 18.75rem;
            .inputContainer {
              input {
                border: 2px solid ${bosColor.neutralPlus};
              }
            }
          }
        }
      }
    }
  }

  &.smModal {
    .custom-modal {
      border-radius: 0;
      width: 30rem;
      .inner-container {
        ${padding[0].a};
      }
      header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        ${margin['32'].b};
      }
    }
  }

  &.rightModal {
    .custom-modal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0.5rem;
      background: ${bosColor.white};
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
      width: 37.625rem;
      height: 100%;
      max-width: 100%;
      max-height: calc(100vh - 25rem);
      z-index: 2;
      overflow-y: auto;
      padding: 0;

      .inner-container {
        ${padding[0].a};
      }
      .content {
        p {
          font-size: 1rem;
        }
      }
      .commentHeader {
        padding-bottom: 1rem;
      }
      .close-modal-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: ${bosColor.lightGrey};
        border-radius: 62.4375rem;
        top: 1rem;
        right: 1.5rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
        &:hover:not(:disabled) {
          background-color: ${bosColor.neutralGrey};
          border-color: ${bosColor.neutralPlus};
        }
      }
    }
    .tradeInDialog {
      .inner-container {
        ${padding[32].x};
        ${padding[40].y};
      }
    }
  }

  &.commentModal {
    .custom-modal {
      z-index: 2;
      overflow-y: auto;
      padding: 0;

      .close-modal-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: ${bosColor.lightGrey};
        border-radius: 62.4375rem;
        top: 1rem;
        right: 1.5rem;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
        &:hover:not(:disabled) {
          background-color: ${bosColor.neutralGrey};
          border-color: ${bosColor.neutralPlus};
        }
      }
    }
  }

  &.inventory {
    .container-fluid {
      max-height: calc(100vh - 50rem);
      overflow: auto;
    }
  }
  &.signing-modal .custom-modal {
    border-radius: 0.5rem;
    background: ${bosColor.white};
    box-shadow: 0px 2px 16px 0px rgba(48, 48, 111, 0.08), 0px 8px 24px 2px rgba(48, 48, 111, 0.04);
    padding: 0;
    .close-modal-button svg {
      width: 2rem;
      height: 2rem;
      path {
        fill: ${black};
      }
    }
    header {
      padding: 1rem 1.5rem;
      margin-bottom: 0;
      border-bottom: 1px solid ${bosColor.neutralGrey};
      h2 {
        font-size: 1.25rem;
        font-weight: ${typography.weight.extrabold};
        line-height: 180%;
      }
    }
    .close-modal-button {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      background: ${bosColor.lightGrey};
      display: flex;
      width: 2rem;
      height: 2rem;
      padding: 4px;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
    }
  }

  &.width32 {
    .custom-modal {
      width: 32rem;
    }
  }
  &.width46 {
    .custom-modal {
      width: 46rem;
    }
  }
  &.width40 {
    .custom-modal {
      width: 40rem;
    }
  }
  /* Regarding MER & PUE new popup UI May-2023 */

  &.carPickerModal.merModal {
    z-index: ${zIndexes.zIndexModal};
    .confirmActions {
      display: flex;
      justify-content: center;
      background: ${bosColor.ultramarine};
      padding: 1rem 3rem;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 99999;
      box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08), 0px -2px 8px 0px rgba(0, 0, 0, 0.16);
      .actionBtn {
        ${margin[24].r};
        height: 3rem;
        padding: 0.75rem 1.5rem;
        &.secondary {
          border-width: 2px;
        }
        &:last-child {
          ${margin[0].r};
          color: ${bosColor.white} !important;
          :hover {
            color: initial !important;
          }
        }
      }
    }
    .custom-modal {
      max-height: calc(100vh - 6rem);
      background: #fcfcfc;
      width: 96%;
      padding: 0;
      border-radius: 0.5rem !important;
      max-width: 110rem;
      position: initial;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.15);
      .tableContainer .accordionContainer > div {
        &[open] .item.grid-row .grid-item div span.moreOptions {
          display: none;
        }
        button.btnAccordion {
          grid-template-columns: 1fr 3rem;
          border-bottom: 1px Solid ${bosColor.neutralGrey};
        }
      }

      .accordionContainer {
        > div {
          ${padding['0'].x};
        }
        .item.grid-row {
          div {
            display: flex;
            align-items: center;
            span {
              font-size: 0.875rem;
              line-height: 1.25rem;
            }
          }

          &.disabled {
            cursor: default;
            .grid-item {
              div {
                color: ${disabled};
                > span > span:first-child {
                  opacity: 0.65;
                }
                span {
                  color: ${disabled};
                }
                span.red {
                  color: ${bosColor.neutral};
                }
              }
            }
          }

          .grid-item {
            ${padding['12'].y};

            div {
              font-size: 1rem;
              color: ${bosColor.black};
              gap: 0.5rem;

              span {
                font-size: 0.875rem;
                line-height: 1.25rem;

                &.additionalText {
                  font-size: 1rem;
                }

                .tooltip {
                  top: -7rem;
                  background: none;
                  max-width: 25rem;

                  &::after {
                    content: none;
                  }
                  .tooltip-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 1.25rem;
                    gap: 0.5rem;
                    background: ${bosColor.white};
                    width: 100%;
                    max-width: 22rem;
                    min-width: 12.5rem;
                    height: auto;
                    border-radius: 0.5rem;
                    z-index: 999;
                    box-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.5);
                    border-radius: 0.5rem;

                    p {
                      font-weight: ${typography.weight.extrabold};
                    }
                  }
                }
              }
            }
          }
        }
      }

      > button.close-modal-button {
        right: 3rem;
        top: 2rem;
      }
      header {
        border-bottom: 1px solid ${bosColor.neutralGrey};
        padding: 1.5rem;

        > h3 {
          font-size: 1.25rem;
          font-weight: 800;
        }
      }
      .subTitleContainer {
        padding: 0 1.5rem;
        display: grid;
        grid-template-columns: auto 26.25rem auto;
        gap: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        align-items: end;
        h3 {
          font-size: 1.125rem;
          font-weight: 800;
        }
        .carListTitle.merModal {
          > h3 {
            margin: 0;
            margin-bottom: 0.25rem;
            font-size: 1.125rem;
          }
          .picker-info {
            font-size: 1rem;
            margin: 0;
          }
        }
      }
    }

    .tabsList {
      .tabs {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: table;
        top: 8.75rem;

        ul {
          background: ${bosColor.neutralGrey};
          justify-content: center;
          width: max-content;
          padding: 0.25rem;
          border-radius: 0.5rem;
          gap: 0;
          li {
            line-height: 150%;
            font-size: 1rem;
            font-weight: 800;
            text-align: center;
            margin-right: 0.25rem;
            border-radius: 0.25rem;
            padding: 0.5rem 1.5rem;
            &.is-active {
              background: ${bosColor.white};
              outline: 1px Solid ${bosColor.neutralPlus};
            }
            &:last-child {
              margin-right: 0;
            }
          }
          .is-active {
            background: ${bosColor.white};
          }
        }
      }
    }
  }

  @media only screen and (max-height: 1024px) {
    &.carPickerModal {
      .custom-modal {
        top: 4.5rem;
        header {
          h2 {
            font-size: 1.25rem;
            line-height: 1.2;
          }
          &:after {
            bottom: -1rem;
          }
        }
        .carListTitle {
          ${margin['8'].b};
          h3 {
            ${margin['12'].y};
            ${margin['16'].b};
            ${margin['0'].x};
            font-size: 1.1rem;
            line-height: 1.1rem;
            span {
              font-size: 1.1rem;
              line-height: 1.1rem;
            }
          }
          > div .inputContainer {
            .adornment-container {
              top: 0.5rem;
              left: 1rem;
            }

            input {
              height: 2.75rem;
            }
          }
        }
      }

      &.carPickerModalSelectable {
        .custom-modal {
          .tableContainer {
            + .item.load-more {
              ${padding['16'].t};
            }
            overflow: auto;
          }

          .accordionContainer {
            .item.grid-row {
              div {
                font-size: 1rem;
                span {
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }
      &.carPickerModalNoSelectable {
        .custom-modal {
          .carListTitle {
            + .carListContainer {
              ${padding['0'].a};
              ${margin['0'].b};

              > div {
                ${padding['16'].a};
                ${margin['16'].b};
              }

              .tableContainer {
                .accordionContainer {
                  > div {
                    button {
                      .item.itemRow {
                        ${padding['8'].y};

                        > div {
                          font-size: 0.85rem;
                          ${padding['16'].r};
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .tableContainer {
            + .item.load-more {
              ${padding['16'].t};
            }
            max-height: calc(100vh - 28rem);
            overflow: auto;
          }
        }
      }
    }
  }

  .errorMessage {
    color: ${bosColor.poppy};
  }

  @media only screen and (max-height: 768px) {
    &.carPickerModal {
      z-index: ${zIndexes.zIndexModal};
      .custom-modal {
        top: 0.5em;
        max-height: calc(101vh - 1rem);
        .tableContainer {
          max-height: calc(100vh - 24rem);
          overflow: auto;
        }
      }
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1550px) {
    &.carPickerModal {
      &.carPickerModalSelectable {
        &.merModal .custom-modal {
          .tableContainer {
          }
        }
        .custom-modal {
          .picker-info {
            font-size: 1rem;
            line-height: 150%;
          }
          .header.headerRow .headerContainer {
            .grid-row.header-top {
              grid-template-columns: 14.5rem 12.5% 6.5% 7% 8% 12% 9.5% 8% 5% 5% 1fr;
            }

            .grid-row.header-inner {
              grid-template-columns: 2.5rem 12rem 12.5% 6.5% 7% 8% 12% 9.5% 8% 5% 5% 1fr;
              div {
                font-size: 0.85rem;
              }
            }
          }
          .accordionContainer {
            > div button {
              .item.grid-row {
                grid-template-columns: 2.5rem 12rem 12.5% 6.5% 7% 8% 12% 9.5% 8% 5% 5% 1fr;
              }
            }
            .vehicleDetailContainer {
              .header,
              .item {
                grid-template-columns: 2.5rem 12rem 12.5% 6.5% 14.5% 12.5% 1fr;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 2100px) {
    &.carPickerModal {
      &.carPickerModalSelectable {
        .custom-modal {
          .picker-info {
            font-size: 1rem;
            line-height: 150%;
          }
          .header.headerRow .grid-row div {
            font-size: 1rem;
          }
          .header.headerRow .headerContainer {
            .grid-row.header-top {
              grid-template-columns: 19.5rem 14.5% 6.5% 7% 8% 14.5% 7.5% 7% 6% 6% 1fr;
            }

            .grid-row.header-inner {
              grid-template-columns: 3rem 16.5rem 14.5% 6.5% 7% 8% 14.5% 7.5% 7% 6% 6% 1fr;
            }
          }
          .accordionContainer {
            > div button {
              .item.grid-row {
                grid-template-columns: 3rem 16.5rem 14.5% 6.5% 7% 8% 14.5% 7.5% 7% 6% 6% 1fr;
                div {
                  font-size: 1rem;
                  line-height: 1.375rem;
                  span {
                    font-size: 1rem;
                  }
                }
              }
            }
            .vehicleDetailContainer {
              .header,
              .item {
                grid-template-columns: 3rem 16.5rem 14.5% 6.5% 15% 14.5% 1fr;
                div {
                  font-size: 1rem;
                  line-height: 1.375rem;
                  span {
                    font-size: 1rem;
                  }
                }
                .sortable {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;
