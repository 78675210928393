import { bosColor, typography } from '@next-components/common-styles';
import styled from 'styled-components';

export const FrameworkAgreementListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .divider {
    background: ${bosColor.neutralGrey};
    height: 1px;
    width: 100%;
  }

  .agreementDetailsList {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .divider {
      background: ${bosColor.neutralGrey};
      height: 1px;
      width: 100%;
    }
    .agreementDetailsInput {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      max-width: 11.25rem;

      .adornment-container {
        top: 13px !important;
      }

      .pivotWrapper {
        input {
          padding: 0.75rem 1rem;
        }
        .dropDownContainer {
          min-width: 4.5rem;

          .react-select__control {
            .react-select__value-container {
              padding: 0;
            }
            .react-select__indicators {
              .react-select__indicator {
                padding: 0;
              }
            }
          }
        }
      }
      .agreementDetail {
        display: flex;
        gap: 2rem;

        .agreementPrices {
          flex: 1 0 0;

          .accessoryName {
            font-size: 1rem;
            font-style: normal;
            font-weight: ${typography.weight.extrabold};
            line-height: 175%;
          }
          .price {
            font-size: 0.875rem;
            font-style: normal;
            line-height: 170%;

            &.accessoryDarsPrice {
              font-weight: ${typography.weight.semibold};
            }
            &.accessoryNetPrice {
              font-weight: ${typography.weight.extrabold};
            }
          }
        }
      }
    }
  }
`;

export const AgreementDetailStyled = styled.div`
  display: flex;
  gap: 2rem;

  .agreementPrices {
    flex: 1 0 0;

    .accessoryName {
      font-size: 1rem;
      font-style: normal;
      font-weight: ${typography.weight.extrabold} !important;
      line-height: 175%;
    }
    .price {
      font-size: 0.875rem;
      font-style: normal;
      line-height: 170%;

      &.accessoryDarsPrice {
        font-weight: ${typography.weight.semibold} !important;
      }
      &.accessoryNetPrice {
        margin-left: 0.5rem;
        font-weight: ${typography.weight.extrabold} !important;
      }
    }
  }
`;

export const FrameworkAgreementDetailsStyled = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  .agreementTitle {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
    flex: 1 0 0;
  }
  .agreementAction {
    height: 2rem;
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
    background-color: ${bosColor.poppy};
    border-color: ${bosColor.poppy};
    color: ${bosColor.white};
    font-weight: ${typography.weight.extrabold};
    border-radius: 0.25rem;
    &:hover {
      background-color: ${bosColor.poppy};
      color: ${bosColor.white};
    }
  }
`;

export const FrameworkAgreementSection = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  .agreementNumber {
    flex: 1 0 0;
  }
  .rammeavtalenummerButton {
    &:disabled {
      background: ${bosColor.neutralGrey};
      color: ${bosColor.neutralPlus};
      opacity: 1;
    }
  }
`;
