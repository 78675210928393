import * as React from 'react';
import {
  IBosVehicleOrderStatus,
  IConfiguration,
  IOrder,
  IReserveDetails,
  OrderStatus,
  OrderType,
  VehicleReserveStatus,
} from 'next-common';
import {
  OrderStatusListWrapper,
  StatusMessageListItemBody,
  StatusMessageListItemHeader,
} from '../OrderStatusList.styled';
import { format } from 'date-fns';
import { sendReserveVehicleOrderMutationParsed, useOrder } from '../../../../../../common';
import { useMutation } from '@apollo/client';
import { CancelReserveOrderModal } from '../../../../sales-common/CancelReserveOrderModal';
import { DateTimeBlock } from '../../../../sales-common/DateTimeBlock';
import { getAppendingNumericIdFromUrl, isOrderEditable } from '../../../../../../utils';
import { Button } from '@next-components/cta';
import { Icon, IconType } from '@next-components/bos-icon';
import { Accordion } from '@next-components/accordion';
import { Tooltip } from '@next-components/tooltip';
import { NoOrderStatus } from '../IfsOrder';

interface IReservedCarInfoProps {
  order: IOrder;
  reservationStatus: IBosVehicleOrderStatus;
  isMostRecent: boolean;
  configuration: IConfiguration;
  isStockSaleOrder?: boolean;
  isVehicleValidForReserveOrSign?: boolean;
  onStatusChange(status: OrderStatus): void;
  onReserveCancelled(): void;
  onLoading(): void;
  onChangeReservationStatus(details: IReserveDetails): void;
}

const isFailure = (details: IBosVehicleOrderStatus) => {
  return details?.status === 'FAILURE';
};

const headerText = (details: IBosVehicleOrderStatus) => {
  if (details?.orderType === OrderType[OrderType.CANCEL_RESERVE]) {
    return isFailure(details) ? 'Kansellering feilet' : 'Reservasjon av bil kansellert';
  }
  return isFailure(details) ? 'Reservasjon av bil feilet' : 'Bil reservert';
};

const buttonText = (details: IBosVehicleOrderStatus) => {
  return isFailure(details) ? 'Reserver på nytt' : 'Slett reservasjon';
};

export const getDynamicAccordionIcon = (param) => {
  switch (param) {
    case 'success':
      return IconType.NewNextCheckFilled;
    case 'failure':
      return IconType.NewNextCancelSolid;
    case 'warning':
      return IconType.NewNextClockSolid;
    default:
      return IconType.CircleInfo;
  }
};

export const ReservedCarInfo = (props: IReservedCarInfoProps) => {
  const [reserveCancelModalStatus, setReserveCancelModalStatus] = React.useState<boolean>(false);
  const [reserveVehicleOrderMutation] = useMutation(sendReserveVehicleOrderMutationParsed);

  const reserveOrCancelOrder = async (vehicleReserveStatus: VehicleReserveStatus) => {
    if (vehicleReserveStatus === VehicleReserveStatus.WAITING_CANCELLATION) {
      setReserveCancelModalStatus(true);
    } else {
      onReserved();
    }
  };

  const confirmReserveCancelClick = async () => {
    setReserveCancelModalStatus(false);
    props.onLoading();
    try {
      const { data } = await reserveVehicleOrderMutation({
        variables: {
          id: props.order?.id,
          status: VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION],
          customerId: props.order?.customer?.reference?.bosApiId,
        },
      });
      const order = data?.sendReserveVehicleOrder || props.order;
      props.onStatusChange(order.status);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
    props.onReserveCancelled();
  };

  const onReserved = async () => {
    props.onLoading();
    try {
      const { data } = await reserveVehicleOrderMutation({
        variables: {
          id: props.order?.id,
          status: VehicleReserveStatus[VehicleReserveStatus.WAITING],
          customerId: props.order?.customer?.reference?.bosApiId,
        },
      });
      const order = data?.sendReserveVehicleOrder || props.order;
      props.onStatusChange(order?.status);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
    props.onReserveCancelled();
  };

  //Refresh order from database
  const getOrderId = () => getAppendingNumericIdFromUrl('');
  const getViewPageProps = () => ({
    orderId: getOrderId(),
    order: null,
    vehicle: null,
    pimId: null,
  });
  const order: IOrder = useOrder(getViewPageProps());
  props.onChangeReservationStatus(order?.reserveDetails);

  const isWaitingCancellation = (order: IOrder): boolean => {
    return (
      order?.reserveDetails?.vehicleReserveStatus?.toString() ===
      VehicleReserveStatus[VehicleReserveStatus.WAITING_CANCELLATION]
    );
  };

  const renderStatusMessageListItemHeader = () => (
    <>
      <div className={`reserveHeader`}>
        <h3>{headerText(props.reservationStatus)}</h3>
      </div>
      <div className="ifsDate">
        <DateTimeBlock dateTime={props.reservationStatus?.timestamp} shortDate />
      </div>
    </>
  );

  const renderStatusMessageListItemBody = () => (
    <div className="leftContainer">
      <div className="bodyItem">
        <span className="label">Status reservasjon</span>
        <p className="value">{props.reservationStatus?.status ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">Reservert i IFS til</span>
        <p className="value">
          {props.reservationStatus?.reservationToDate
            ? format(new Date(props.reservationStatus?.reservationToDate), 'dd.MM.yyyy')
            : '-'}
        </p>
      </div>
      <div className="bodyItem">
        <span className="label">IFS kundenummer</span>
        <p className="value">{props.reservationStatus?.ifsCustomerId ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">Serienummer</span>
        <p className="value">{props.reservationStatus?.vehicleId ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">Tilbudsnummer</span>
        <p className="value">{props.reservationStatus?.quotationNumber ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">BOS API kundenr.</span>
        <Tooltip text={props.reservationStatus?.customerId}>
          <p className="value textWrap">{props.reservationStatus?.customerId ?? '-'}</p>
        </Tooltip>
      </div>
      <div className="bodyItem stretchItem">
        <span className="label">Statusmelding.</span>
        <p className="value">{props.reservationStatus?.statusMessage ?? '-'}</p>
      </div>
    </div>
  );

  return (
    <OrderStatusListWrapper>
      {props?.isMostRecent ? (
        <div className="messageWrapper">
          <Accordion
            isOpen={props.reservationStatus?.status === 'FAILURE'}
            nextAccordion
            className={props.reservationStatus?.status?.toLowerCase() ?? ''}
            labelContent={
              <div className="accordionLabelContainer">
                <div className={'iconContainer'}>
                  <Icon icon={getDynamicAccordionIcon(props.reservationStatus?.status?.toLowerCase())} />
                </div>
                <StatusMessageListItemHeader>{renderStatusMessageListItemHeader()}</StatusMessageListItemHeader>
              </div>
            }>
            <StatusMessageListItemBody>
              {renderStatusMessageListItemBody()}
              <div className="rightContainer">
                <div className="bodyItem">
                  {props.isMostRecent &&
                    !isWaitingCancellation(props.order) &&
                    props.reservationStatus?.orderType === OrderType[OrderType.RESERVE] &&
                    !isOrderEditable(props.order, props.configuration) &&
                    (props?.isStockSaleOrder) &&
                    props.isVehicleValidForReserveOrSign && (
                      <div>
                        <Button
                          colorVariant="secondary"
                          onClick={() =>
                            reserveOrCancelOrder(
                              isFailure(props.reservationStatus)
                                ? VehicleReserveStatus.WAITING
                                : VehicleReserveStatus.WAITING_CANCELLATION,
                            )
                          }>
                          {buttonText(props.reservationStatus)}
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </StatusMessageListItemBody>
          </Accordion>
        </div>
      ) : (
        <div className="messageWrapper">
          {props?.reservationStatus?.orderType === 'INITIAL' ? (
            <NoOrderStatus />
          ) : (
            <Accordion
              nextAccordion
              labelContent={
                <div className="accordionLabelContainer">
                  <div
                    className={`iconContainer ${props?.reservationStatus?.orderType === 'INITIAL' ? 'initial' : ''}`}>
                    <Icon icon={IconType.NewNextHistory} />
                  </div>
                  <StatusMessageListItemHeader>{renderStatusMessageListItemHeader()}</StatusMessageListItemHeader>
                </div>
              }>
              {props?.reservationStatus?.orderType !== 'INITIAL' && (
                <StatusMessageListItemBody>{renderStatusMessageListItemBody()}</StatusMessageListItemBody>
              )}
            </Accordion>
          )}
        </div>
      )}
      {reserveCancelModalStatus && (
        <CancelReserveOrderModal
          modalStatus={reserveCancelModalStatus}
          closeModalClick={() => setReserveCancelModalStatus(false)}
          confirmReserveCancelClick={confirmReserveCancelClick}
        />
      )}
    </OrderStatusListWrapper>
  );
};
