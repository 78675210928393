import { cloneDeep, isEmpty } from 'lodash';
import { IConfiguration, INextArticle, IOrder } from 'next-common';
import { IVehicleStockEcomItem, TaxType, findChargeTypeValue } from 'sales-common';
import { IVehicleChargeStatus } from '../../common';
import { calculateVat } from './vat';
import { VehicleConfig } from './vehicleOrder';

const updateArticlePriceForKey = (key: string = '', price: number = 0, articles: INextArticle[]) => {
  let existingArticles = cloneDeep(articles);
  return existingArticles?.map((article) => {
    if (article?.key === key) {
      return {
        ...article,
        priceExclVat: price,
      };
    }
    return article;
  });
};

export const getChargesFromVehicleStatus = (vehicleData: IVehicleStockEcomItem) => {
  const taxes = vehicleData?.vehiclePrice?.taxes || [];
  const updatedWreckage = findChargeTypeValue(taxes, TaxType?.SCRAPDEPOSIT);
  const updatedOneOffTaxWeight = findChargeTypeValue(taxes, TaxType?.TAREWEIGHT);
  const updatedCo2Tax = findChargeTypeValue(taxes, TaxType?.CO2TAX);
  const updatedNoxTax = findChargeTypeValue(taxes, TaxType?.NOXTAX);
  return { updatedWreckage, updatedOneOffTaxWeight, updatedCo2Tax, updatedNoxTax };
};

export const calculateUpdatedOneOffTax = (
  existingArticles: INextArticle[] = [],
  carType: string = null,
  extraWreckage: number = 0,
  oneOffTaxWeight: number = 0,
  co2Tax: number = 0,
  noxTax: number = 0,
) => {
  let updatedArticles = cloneDeep(existingArticles);

  //Update articles and get latest oneoffTax
  updatedArticles = updateArticlePriceForKey(VehicleConfig.SCRAPDEPOSIT, extraWreckage, updatedArticles);
  updatedArticles = updateArticlePriceForKey(VehicleConfig.TAREWEIGHT, oneOffTaxWeight, updatedArticles);
  updatedArticles = updateArticlePriceForKey(VehicleConfig.CO2TAX, co2Tax, updatedArticles);
  updatedArticles = updateArticlePriceForKey(VehicleConfig.NOXTAX, noxTax, updatedArticles);

  const updatedVatPrices = calculateVat(updatedArticles, carType);
  return {
    oneOffTax: updatedVatPrices?.oneOffTaxAndWreckage,
    totalPrice: updatedVatPrices?.salesTotalIncludingVatAndTax
  };
};

export const getUpdatedOffTaxForVehicle = (
  order: IOrder,
  configuration: IConfiguration,
  vehicleData: IVehicleStockEcomItem = null,
  existingArticles: INextArticle[] = [],
): IVehicleChargeStatus => {
  let status: IVehicleChargeStatus = {
    isTaxChange: false,
    isTaxIncreased: false,
    ExistingOneOffTax: 0,
    UpdatedOneOffTax: 0,
    UpdatedtotalPrice : 0
  };

  if (isEmpty(order?.vehicles?.[0]?.serialNo) || !configuration?.features?.varebil?.isChargesRecalculationEnabled) {
    return status;
  }

  //Updated charges values from vehicle-status collection
  let existingCharge = 0;
  let updatedCharge = 0;
  if(vehicleData) {
    //execute on view page only
    const {
      updatedWreckage = 0,
      updatedOneOffTaxWeight = 0,
      updatedCo2Tax = 0,
      updatedNoxTax = 0,
    } = getChargesFromVehicleStatus(vehicleData);
  
    const { oneOffTax, totalPrice } = calculateUpdatedOneOffTax(
      existingArticles,
      order?.pimSnapshot?.vehicleType,
      updatedWreckage,
      updatedOneOffTaxWeight,
      updatedCo2Tax,
      updatedNoxTax,
    );
    existingCharge = order?.vatCalculation?.oneOffTaxAndWreckage;
    updatedCharge = oneOffTax;
    status.UpdatedtotalPrice = totalPrice;
  } else {
    //execute on all other pages
    updatedCharge = order?.vatCalculation?.oneOffTaxAndWreckage;
    existingCharge = order?.preliminaryOneOffCharges ?? updatedCharge;
    status.UpdatedtotalPrice = order?.vatCalculation?.salesTotalIncludingVatAndTax;
  }
  const overriddenVehicleRoadTax = order?.vatCalculation?.overriddenVehicleRoadTax && order?.vatCalculation?.overriddenVehicleRoadTax >= 0 ? order?.vatCalculation?.overriddenVehicleRoadTax : updatedCharge;
  status = {
    ...status,
    isTaxChange: existingCharge !== overriddenVehicleRoadTax,
    isTaxIncreased: existingCharge < overriddenVehicleRoadTax,
    ExistingOneOffTax: existingCharge || 0,
    UpdatedOneOffTax: overriddenVehicleRoadTax || 0,
  };

  console.log('Vehicle charge status =>', JSON.stringify(status));
  return status;
};
