import React, { useEffect, useState } from 'react';
import { ICartPrices, ICartProps } from '@next-components/cart';
import { IconType } from '@next-components/bos-icon';
import { IDynamicListColumn, IDynamicListConfig } from '@next-components/dynamic-list';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { isEmpty, get } from 'lodash';
import {
  AccessoryType,
  FinancingType,
  ICalculation,
  IOrder,
  IServiceContract,
  IVehicleConfiguration,
  SantanderProductType,
  isStockSaleOrderToBeProcessed,
  getCalculationByType,
  CustomerType,
  INextArticle,
  IConfiguration,
  IBosOrderDraft,
} from 'next-common';
import { IGetServiceContract } from 'sales-common';
import {
  ICentralisedProperties,
  INotificationPageProps,
  IVehiclePageProps,
  useConfigurations,
} from '../../../../common';
import {
  financePriceFormatter,
  getOrderCustomerType,
  getStockSaleParams,
  isCash,
  isEcomOrder,
  isLeasing,
  isLoan,
  isSCIncluded,
  monthlyPriceFormatter,
  monthlyPriceFormatterBold,
} from '../../../../utils';
import { ChooseFinanceAction } from '../../sales-common/DynamicCols/ChooseFinanceAction';
import { leasingDurationFormatter, loanDurationFormatter, rangeFormatter } from './FinanceEdit.formatters';
import { EditFinance } from './FinanceEdit.styled';
import { OfferPane } from './OfferPane';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { SalesWizardButtonMenu } from '../../sales-wizard/sales-wizard-menu/SalesWizardButtonMenu';
import { FinanceAccordion } from '../../sales-common/FinanceAccordion';
import { IconButton } from '@next-components/icon-button';
import { bosColor } from '@next-components/common-styles';
import { getActiveStep, getStepData } from '../../sales-wizard/sales-wizard-menu';
import {
  getMonthlyPrice,
  getTotalPriceIncVat,
} from '../../sales-common/FinancingDialog/FinancingForm/FinancingForm.utils';
import { cloneDeep, remove } from 'lodash';

export interface IFinanceEditProps extends IVehiclePageProps, INotificationPageProps, ICentralisedProperties {
  order: IOrder;
  vehicle: IVehicleConfiguration;
  children?: React.ReactNode;
  loading: boolean;
  isCalled?: boolean;
  cartData?: ICartProps;
  mappedEquipment: IEquipmentIdAndSKU[];
  serviceContract?: IGetServiceContract;
  serviceContractLoading?: boolean;
  isSigningStep?: boolean;
  vatPrices?: ICartPrices;
  articles?: INextArticle[];

  onAddCalculation(financingType: FinancingType, input): Promise<IOrder>;

  onCalculationUpdated?(calculation: ICalculation): Promise<any>;

  onChooseOffer(calculationId: string): void;

  onDeleteCalculation(calculation: ICalculation): Promise<IOrder>;

  onUpdateCalculation?(changes: any, calculation: ICalculation): void;

  removeAlternativeLeasing?(id: string): Promise<IOrder>;

  setIsLoading?(value: boolean): void;

  getSelectedOffer?(calculationId: string): void;
  setUpdatedOrder?(order: IOrder): void;
  rootUrl?: string;
  dealerRole?: string;
  orderDraft?:IBosOrderDraft
}

export const mapCalculationToDataTableItem = (
  calculation: ICalculation,
  order: IOrder,
  onChooseOffer,
  price: number,
  isNoTires = false,
) => {
  let totalPrice = (price || 0) + 0;

  if (isLoan(calculation) || isLeasing(calculation)) {
    totalPrice = getTotalPriceIncVat(calculation?.financing) || 0;
  }
  if ((isLoan(calculation) || isCash(calculation)) && isSCIncluded(calculation?.serviceContract)) {
    totalPrice = (calculation?.serviceContract?.totalPrice || 0) + totalPrice;
  }
  const isLeasingDisable = isNoTires && calculation?.financingType === FinancingType.LEASING;
  return {
    downPayment: `${calculation?.financing?.request?.downPayment || 0} `,
    range: calculation?.financing?.request?.mileage || 0,
    durationMonths: calculation?.financing?.request?.durationMonths || 0,
    totalMonthlyCost: getMonthlyPrice(calculation?.financing) || 0,
    monthlyCostInclVatAndFee: getMonthlyPrice(calculation?.financing) || 0,
    totalCost: calculation?.financing?.totalCost || 0,
    id: calculation.id,
    serviceContractMonthlyPrice:
      (isLoan(calculation) || isCash(calculation)) && isSCIncluded(calculation?.serviceContract)
        ? '-'
        : calculation?.serviceContract?.monthlyPrice,
    serviceContractDuration: calculation?.serviceContract?.duration,
    serviceContractDrivingDistance: isLeasing(calculation)
      ? calculation?.financing?.request?.mileage || 0
      : calculation?.serviceContract?.drivingDistance,
    totalPrice,
    picker: <ChooseFinanceAction onClick={onChooseOffer} value={calculation?.id} order={order} />,
    disabled: isLeasingDisable,
  };
};

export const getPriceForCalculation = (calculation: ICalculation, financingType: FinancingType, isBusiness = false) => {
  const field = financingType === FinancingType.CASH ? 'totalCost' : 'totalCostInclVatAndFee';
  return calculation?.financing?.hasOwnProperty(field) ? calculation.financing[field] : null;
};

export const getCalculationPriceByTypeDNB = (order: IOrder, financingType: FinancingType): number => {
  const calculation = getCalculationByType(order, financingType);
  const isBusiness = order?.customer?.customerType === CustomerType.BUSINESS;
  return calculation ? getPriceForCalculation(calculation, financingType, isBusiness) : null;
};

export const getOffers = (order: IOrder, type: FinancingType, onChooseOffer, isNoTires = false): any[] => {
  if (!order?.offers) return [];
  const offerOfType = order?.offers?.filter((calculation) => calculation?.financingType === type);
  const prices = getCalculationPriceByTypeDNB(order, type);
  const price = prices || 0;
  return offerOfType.map((c) => mapCalculationToDataTableItem(c, order, onChooseOffer, price, isNoTires));
};

interface IAddFinanceButton {
  hasCashFinancingForEcomOrder?: boolean;
  onClick(type: FinancingType, input?): void;
  type: FinancingType;
  isLoading: boolean;
  disable?: boolean;
}

export interface DeleteItem {
  id?: string;
  context?: any;
  icon: IconType;
  onSelect(context?: any): void;
}

const getTypeText = (type: number, hasCashFinancingForEcomOrder: boolean): string => {
  if (hasCashFinancingForEcomOrder) {
    return 'Legg til';
  }
  switch (type) {
    case FinancingType.LEASING:
      return 'Legg til leasingforslag';
    case FinancingType.LOAN:
      return 'Legg til låneforslag';
    default:
      return 'Legg til  serviceavtale';
  }
};

const FinancingButton = (props: IAddFinanceButton) => {
  const typeText = getTypeText(props?.type, props?.hasCashFinancingForEcomOrder ?? false);
  return (
    <>
      {!props.isLoading && (
        <div className="add-calculation">
          <div onClick={() => !props.disable && props.onClick(props.type)} className="iconButtonWrapper">
            <IconButton
              ariaLabel={'add-item'}
              className={'addItem'}
              fill={bosColor.turquoise}
              icon={IconType.NewNextPlus}
              disabled={props.disable}
            />
            <span className={`text ${props?.disable ? 'disabled' : ''}`}>{typeText}</span>
          </div>
        </div>
      )}
      {props.isLoading && (
        <div className="add-calculation spinner">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export const FinanceEdit = (props: IFinanceEditProps) => {
  const updatedOrder: IOrder = props?.order;
  const stepData = getStepData(props?.activeStep, props?.orderId, props?.rootUrl,props?.isVarebilEnabled);
  const serviceContract: IGetServiceContract = props?.serviceContract;
  const updatedVehicle: IVehicleConfiguration = props?.vehicle;
  const configurations: IConfiguration = useConfigurations();

  //This code takes an array of strings, converts each string to lowercase, and then capitalizes the first letter of each string, returning a new array.
  //This is done so even if someone forget to capitalize values in env, logic works fine as it`s dependant on ENUM which requires capitalized values.
  const stopFinancialServices =
    configurations?.features?.stoppedFinancialServices?.map((elem) =>
      elem.toLowerCase().replace(/^\w/, (char) => char.toUpperCase()),
    ) || [];
  const stopDNBService: string[] = stopFinancialServices ?? [];

  const [currentCalculation, setCurrentCalculation] = useState<ICalculation>(null);
  const [isChosenOffer, setIsChosenOffer] = useState<boolean>(false);
  const [currentServiceContract, setServiceCurrentContract] = useState<IServiceContract>(null);
  const [adding, setAdding] = useState<FinancingType>();
  const [isVisible, setIsVisible] = useState(false);
  const [order, setUpdatedOrder] = useState<IOrder>(null);
  const [vehicle, setupdatedVehicle] = useState<IVehicleConfiguration>(null);
  const [selectedFinancingType, setSelectedFinancingType] = useState<FinancingType>(null);
  const [borderColor] = useState<string>(null);
  const [success] = useState<boolean>(false);
  const [isNoTires, setIsNoTires] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const params = getStockSaleParams(order);
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(
    props?.configuration,
    params?.source,
    params?.serialNo,
    params?.make,
  );

  const hasCashFinancingForEcomOrder =
    !getCalculationByType(props?.order, FinancingType.CASH) && isEcomOrder(order?.lead?.source);
  const getCalculationById = (id: string) => order?.offers?.find((calculation) => calculation?.id === id);
  const onDeleteCalculation = async (calculation?: ICalculation) => {
    if (addNew && !calculation) {
      setAddNew(!addNew);
      return;
    }

    if (!calculation) throw new Error('You must send in a calculation');
    if (calculation?.id === currentCalculation?.id){
      setIsDeleted(true);
    }
    if (typeof props.onDeleteCalculation === 'function') {
      const updateOrder = await props.onDeleteCalculation(calculation);
      await getLatestCalculation(updateOrder);
    }
  };

  const removeAlternativeLeasing = async (orderId: string) => {
    const alternateleasingOffers = getCalculationByType(order, FinancingType.LEASING);
    if(alternateleasingOffers?.[0]?.id === currentCalculation?.id){
      setIsDeleted(true); 
    }
    if (typeof props.removeAlternativeLeasing === 'function') {
      const updateOrder = await props.removeAlternativeLeasing(orderId);
      await getLatestCalculation(updateOrder);
    }
  }

  useEffect(() => {
    const tireExists = get(updatedOrder, 'accessories', [])?.some(
      (accessory) => accessory?.accessoryType?.toString() === AccessoryType[AccessoryType.TYRE],
    );
    setAdding(null);
    setIsNoTires(!tireExists);
    if (order?.chosenOffer && !order?.offers?.some((offer) => offer?.id === order?.chosenOffer?.id)) {
      getLatestCalculation(order);
    }
  }, [order]);

  useEffect(() => {
    if (!props.loading) {
      setAdding(null);
    }
  }, [props.loading]);

  useEffect(() => {
    if (isDeleted) {
      setIsVisible(false);
    }
    if (!isVisible || isDeleted) {
      setCurrentCalculation(null);
      setServiceCurrentContract(null);
    }
  }, [isVisible, isDeleted]);

  useEffect(() => {
    if (updatedOrder) {
      setUpdatedOrder(updatedOrder);
      props.getSelectedOffer(updatedOrder?.chosenOffer?.id ?? currentCalculation?.id);
    }
    if (updatedVehicle) {
      setupdatedVehicle(updatedVehicle);
    } else {
      setupdatedVehicle(null);
    }
  }, [updatedOrder, updatedVehicle]);

  useEffect(() => {
    if (updatedOrder?.chosenOffer && !isChosenOffer) {
      setIsChosenOffer(true);
      setCurrentCalculation(updatedOrder?.chosenOffer);
      setSelectedFinancingType(updatedOrder?.chosenOffer?.financingType);
      setServiceCurrentContract(updatedOrder?.chosenOffer?.serviceContract);
      setIsVisible(!!updatedOrder?.chosenOffer);
    }
  }, [updatedOrder?.chosenOffer]);

  useEffect(() => {
    if (props.isCalled) {
      props.setIsLoading(true);
      props.onChooseOffer(currentCalculation?.id);
    }
  }, [props.isCalled]);

  useEffect(() => {
    if (isAdded) {
      getLatestCalculation(updatedOrder);
      setAddNew(false);
      setIsAdded(false);
    }
  }, [isAdded]);

  const onEdit = (rowData) => {
    props.getSelectedOffer(rowData?.id);
    setIsVisible(false);
    if (!isDeleted) {
      setTimeout(() => calculationUpdation(rowData), 1);
    }
  };

  const defaultConfig: IDynamicListConfig = {
    maxSelected: 1,
    selectable: true,
    withCheckbox: true,
    withAccordion: true,
  };

  const onChooseOffer = async (calculationId: string) => {
    if (typeof props.onChooseOffer === 'function') {
      props.onChooseOffer(calculationId);
    } else {
      throw new Error('No "onChooseOffer" props defined');
    }
  };

  const getLeasingTableConfig = (): IDynamicListConfig => {
    const cols: IDynamicListColumn[] = [
      { header: 'Startleie', key: 'downPayment', formatter: financePriceFormatter },
      { header: 'Årlig kjørelengde', key: 'serviceContractDrivingDistance', formatter: rangeFormatter },
      { header: 'Avtalelengde', key: 'durationMonths', formatter: leasingDurationFormatter },
      { header: 'Serviceavtale /mnd', key: 'serviceContractMonthlyPrice', formatter: monthlyPriceFormatter },
      { header: 'Leasingavtale /mnd', key: 'totalMonthlyCost', formatter: monthlyPriceFormatter },
      { header: 'Totalpris', key: 'totalPrice', formatter: monthlyPriceFormatterBold },
    ];
    return {
      ...defaultConfig,
      cols,
    };
  };

  const getLoanTableConfig = (): IDynamicListConfig => {
    const cols: IDynamicListColumn[] = [
      { header: 'Egenkapital', key: 'downPayment', formatter: financePriceFormatter },
      { header: 'Årlig kjørelengde', key: 'serviceContractDrivingDistance', formatter: rangeFormatter },
      { header: 'Serviceavtale', key: 'serviceContractDuration', formatter: loanDurationFormatter },
      { header: 'Nedbetalingstid', key: 'durationMonths', formatter: loanDurationFormatter },
      { header: 'Serviceavtale /mnd', key: 'serviceContractMonthlyPrice', formatter: monthlyPriceFormatter },
      { header: 'Lån /mnd', key: 'monthlyCostInclVatAndFee', formatter: monthlyPriceFormatter },
      { header: 'Totalpris', key: 'totalPrice', formatter: monthlyPriceFormatterBold },
    ];
    return {
      ...defaultConfig,
      cols,
    };
  };

  const getCashTableConfig = (): IDynamicListConfig => {
    const cols: IDynamicListColumn[] = [
      { header: '', key: 'title', noSort: true },
      { header: 'Årlig kjørelengde', key: 'serviceContractDrivingDistance', formatter: rangeFormatter },
      { header: 'Serviceavtale', key: 'serviceContractDuration', formatter: loanDurationFormatter },
      {
        header: 'Serviceavtale  /mnd',
        key: 'serviceContractMonthlyPrice',
        formatter: monthlyPriceFormatter,
        noSort: true,
      },
      { header: 'Totalpris', key: 'totalPrice', formatter: monthlyPriceFormatterBold },
    ];
    return { ...defaultConfig, cols };
  };

  const leasingTableConfig = getLeasingTableConfig();
  const loanTableConfig = getLoanTableConfig();
  const cashTableConfig = getCashTableConfig();

  const updateCalculation = async (financingType: FinancingType, input): Promise<IOrder> => {
    if (typeof props.onAddCalculation === 'function') {
      const updateOrder = await props.onAddCalculation(financingType, input);
      getLatestCalculation(updateOrder);
      return updateOrder;
    }
  };

  const getLatestCalculation = async (updateOrder: IOrder) => {
    const isOffers = !isEmpty(updateOrder?.offers);
    const latestCalculationTimestamp = isOffers
      ? Math.max(...updateOrder.offers.map((offer) => offer.timestamp))
      : null;
    const latestCalculation = updateOrder?.offers?.find(
      (calculation) => calculation?.timestamp === latestCalculationTimestamp,
    );
    setIsDeleted(false);
    setSelectedFinancingType(latestCalculation?.financingType);
    setServiceCurrentContract(latestCalculation?.serviceContract);
    props.getSelectedOffer(latestCalculation?.id);
  };

  const handleAddCalculation = async (financingType: FinancingType, input) => {
    if ((input !== undefined || financingType === FinancingType.CASH) && hasCashFinancingForEcomOrder) {
      await updateCalculation(financingType, input)
        .then((response) => {
          setIsAdded(true);
        })
        .catch((error) => console.error(error));
    }
    setSelectedFinancingType(financingType);
    setAddNew(true);
    setIsVisible(true);
    props.getSelectedOffer(order?.chosenOffer?.id || null);
  };

  const onCalculationUpdated = async (calculation: ICalculation) => {
    if (calculation?.id === currentCalculation?.id) {
      setCurrentCalculation(calculation);
    }
    if (typeof props.onCalculationUpdated === 'function') {
      return props.onCalculationUpdated(calculation);
    }
    setAddNew(false);
  };

  const onServiceContractUpdated = async (updatedOrder: IOrder) => {
    if (!updatedOrder) return;
    const updatedServiceContract = updatedOrder?.offers?.find(
      (calculation) => calculation?.id === currentCalculation?.id,
    );
    setCurrentCalculation(updatedServiceContract);
    setServiceCurrentContract(updatedServiceContract?.serviceContract);
  };

  const calculationUpdation = (rowData: any) => {
    const calculation = getCalculationById(rowData?.id);
    setAddNew(false);
    setSelectedFinancingType(calculation?.financingType);
    setCurrentCalculation(calculation);
    setServiceCurrentContract(calculation?.serviceContract);
    setIsVisible(!!calculation);
  };

  const getFinanceButton = (
    financingType: FinancingType,
    isNoTires?: boolean,
    santanderProductType?: SantanderProductType,
  ) => {
    return (
      <FinancingButton
        hasCashFinancingForEcomOrder={financingType === FinancingType.CASH && hasCashFinancingForEcomOrder}
        type={financingType}
        onClick={handleAddCalculation}
        isLoading={adding === financingType}
        disable={stopDNBService?.includes(santanderProductType) || isNoTires || props.isUnavailablePimData}
      />
    );
  };

  const getAlternativeListData = () => {
    const alternativeOffers = getOffers(order, FinancingType.CASH, onChooseOffer);
    alternativeOffers?.map((offer) => {
      const changeOffer = offer;
      changeOffer.title = 'Kontant';
      return changeOffer;
    });
    return alternativeOffers;
  };

  const selectedOffer = () => {
    if (currentCalculation) {
      return [currentCalculation];
    }
    if (order?.chosenOffer) {
      return [order?.chosenOffer];
    }
    return [];
  };

  const serviceContractForLoan = (serviceContract: IGetServiceContract) => {
    const serviceContractCopy = cloneDeep(serviceContract)
    remove(serviceContractCopy?.paymentPeriodDataList, (element) => {
      return element?.month === -1 && element?.name === 'Full';
    });
    return serviceContractCopy;
  }

  const validateCurrentCalculation = async (financingType: FinancingType, input) => {
    let resetCurrentCalculation = false;
    if((financingType === FinancingType.ALTERNATIVELEASING && order?.chosenOffer?.financingType === FinancingType.LEASING) || (financingType === FinancingType.LEASING && order?.chosenOffer?.financingType === FinancingType.ALTERNATIVELEASING)){
      resetCurrentCalculation = true;
    }
    const response = await props?.onAddCalculation(financingType, input);
    const leasingOffers = getCalculationByType(order, FinancingType.LEASING);
    const alternateLeasingOffers = getCalculationByType(order, FinancingType.ALTERNATIVELEASING);
    if ((financingType === FinancingType.ALTERNATIVELEASING && !isEmpty(leasingOffers)) || (financingType === FinancingType.LEASING && !isEmpty(alternateLeasingOffers))) {
      if (currentCalculation?.financingType !== FinancingType.CASH && currentCalculation?.financingType !== FinancingType.LOAN) {
        setCurrentCalculation(null);
      }
    }
    if (!response?.chosenOffer && resetCurrentCalculation) {
      setCurrentCalculation(null);
    }
  }

  const financeDialogProps = (props: IFinanceEditProps, isLoan = false) => {
    return {
      calculation: currentCalculation,
      serviceContractDb: currentServiceContract,
      serviceContract: isLoan ? serviceContractForLoan(serviceContract) : serviceContract,
      customerType: getOrderCustomerType(props.order),
      isModal: false,
      orderId: props.order?.id,
      vehicleConfig: vehicle,
      onUpdateCalculation: typeof props.onUpdateCalculation === 'function' ? props.onUpdateCalculation : null,
      onCalculationUpdated,
      onServiceContractUpdated,
      financingType: selectedFinancingType,
      success,
      order: props.order,
      addNew,
      setUpdatedOrder: props.setUpdatedOrder,
      setAddNew,
      onAddCalculation: validateCurrentCalculation,
      isSigningStep: props.isSigningStep,
      isDeleted,
      configuration: props?.configuration,
      onDeleteCalculation,
    };
  };
  if (!updatedOrder || (isStockSaleOrder ? false : !updatedVehicle) || props.serviceContractLoading) {
    return <LoadingSpinner isModal={true} />;
  }

  const carType = order?.pimSnapshot?.vehicleType ?? '';

  return (
    <EditFinance className="edit-finance" rowBorderColor={borderColor}>
      <section className="content">
        <div className="calculations finance-option-container">
          <FinanceAccordion
            articles={props.articles}
            loading={props?.loading}
            addNew={(selectedFinancingType === FinancingType.LEASING || selectedFinancingType === FinancingType.ALTERNATIVELEASING) ? addNew : false}
            data={getOffers(order, FinancingType.LEASING, onChooseOffer, isNoTires)}
            sort={leasingTableConfig?.cols?.map((col, index) => ({ key: col?.key, reverse: index === 0 }))}
            config={leasingTableConfig}
            selectedRows={selectedOffer()}
            selectedRowsKey="id"
            label={'Leasing'}
            className="leasing"
            onElementClick={onEdit}
            stopSantandarService={stopDNBService}
            santanderProductType={SantanderProductType.LEASING}
            isNoTires={isNoTires}
            financeDialogProps={{ ...financeDialogProps(props) }}
            financingButton={getFinanceButton(FinancingType.LEASING, isNoTires, SantanderProductType.LEASING)}
            alternateFinanceButton={getFinanceButton}
            isUnavailablePimData={props.isUnavailablePimData}
            isStockSaleOrder={isStockSaleOrder}
            financingType={FinancingType.LEASING}
            removeAlternativeLeasing={removeAlternativeLeasing}
            setAddNew= {setAddNew}
          />

          <FinanceAccordion
            articles={props.articles}
            loading={props?.loading}
            addNew={selectedFinancingType === FinancingType.LOAN ? addNew : false}
            data={getOffers(order, FinancingType.LOAN, onChooseOffer)}
            sort={loanTableConfig?.cols?.map((col, index) => ({ key: col?.key, reverse: index === 0 }))}
            config={loanTableConfig}
            selectedRows={selectedOffer()}
            selectedRowsKey="id"
            label={'Lån'}
            className="loan"
            onElementClick={onEdit}
            stopSantandarService={stopDNBService}
            santanderProductType={SantanderProductType.LOAN}
            isNoTires={isNoTires}
            financeDialogProps={{ ...financeDialogProps(props, true) }}
            financingButton={getFinanceButton(FinancingType.LOAN, false, SantanderProductType.LOAN)}
            isUnavailablePimData={props.isUnavailablePimData}
            isStockSaleOrder={isStockSaleOrder}
          />
          <FinanceAccordion
            loading={props?.loading}
            addNew={selectedFinancingType === FinancingType.CASH ? addNew : false}
            data={getAlternativeListData()}
            selectedRows={selectedOffer()}
            selectedRowsKey="id"
            config={cashTableConfig}
            sort={loanTableConfig?.cols?.map((col, index) => ({ key: col?.key, reverse: index === 0 }))}
            onElementClick={onEdit}
            label={'Egenfinansiering'}
            className="cash"
            financingType={FinancingType.CASH}
            financeDialogProps={{ ...financeDialogProps(props) }}
            financingButton={hasCashFinancingForEcomOrder && getFinanceButton(FinancingType.CASH)}
            isUnavailablePimData={props.isUnavailablePimData}
            isStockSaleOrder={isStockSaleOrder}
          />
          <OfferPane
            order={order}
            vehicle={vehicle}
            cartData={props?.cartData}
            mappedEquipment={props?.mappedEquipment}
            vatPrices={props?.vatPrices}
            configuration={props?.configuration}
            isUnavailablePimData={props.isUnavailablePimData}
            articles={props.articles}
            carType={carType}
            dealerRole={props?.dealerRole}
            financeDialogProps={{ ...financeDialogProps(props) }}
            orderDraft = {props?.orderDraft}
          />
        </div>
      </section>
      <div className="footerMenu">
        <SalesWizardButtonMenu
          orderId={props.orderId}
          order={order}
          calculationId={props?.calculationId}
          activeStep={props?.activeStep}
          isUnavailablePimData={props?.isUnavailablePimData}
          menuLoading={props?.menuLoading}
          onClickSigning={props?.onClickSigning}
          onClick={props?.onClick}
          isStockSaleOrder={isStockSaleOrder}
          stepData={stepData?.[stepData?.length - 1]}
          allStepData={stepData}
          isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
          bottomButton={true}
          vehicleAvailabilityCode={props.vehicleAvailabilityCode}
          isVarebilEnabled={props.isVarebilEnabled}
          invalidPcodesValidation={props?.invalidPcodesValidation}
        />
      </div>
    </EditFinance>
  );
};
