import React from 'react';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IValidationToastNotification } from '../../common';
import { IPriceChangedFields } from '../../utils';
import { WarningStyled } from './Warning.styled';
import { v4 as uuidv4 } from 'uuid';

export interface IPriceMismatchWarningProps extends IValidationToastNotification {
  priceChangedFields: IPriceChangedFields[];
}

export const PriceMismatchWarning = (props: IPriceMismatchWarningProps) => {
  const fields = props?.priceChangedFields ?? [];
  return (
    <Messagebar severityLevel={MessagebarSeverityLevel.WARNING} title="Fant prisfeil" onClose={props?.onClose}>
      <WarningStyled>
        <ul>
          {fields?.map((price) => (
            <li key={uuidv4()}>
              {`${price?.name ?? ''}:`}{' '}
              <ul>
                <li>Gjeldende pris: {price?.currentPrice ?? 0} Kr</li>
                <li>Ny pris: {price?.newPrice ?? 0} Kr</li>
              </ul>
            </li>
          ))}
        </ul>
      </WarningStyled>
    </Messagebar>
  );
};
