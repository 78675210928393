import { defaultTo, get } from 'lodash';
import { IConjunctionReturnValue, getNewConjunctionValues } from 'sales-crystallize-common';
import {
  prepareSuggestedSkuList
} from './selectables';

export const getDisableEnableSKUList = (rulesPayload, enrichedEquipment) => {
  const disableSkusList = new Map();
  const enableSkusList = new Map();

  let disableSku: IConjunctionReturnValue[] = [];
  let enableSku: IConjunctionReturnValue[] = [];

  const { disable: disableEquipmentSkuList, enable: enableEquipmentSkuList } = getNewConjunctionValues(
    rulesPayload,
    enrichedEquipment,
  );

  disableSku = defaultTo(disableEquipmentSkuList, []);
  enableSku = defaultTo(enableEquipmentSkuList, []);

  disableSku?.map((conjunctionValue, index) =>
    disableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), prepareSuggestedSkuList(conjunctionValue)),
  );

  enableSku?.map((conjunctionValue, index) => enableSkusList.set(get(conjunctionValue, 'equipmentSku', ''), index));

  return { disableSkusMappedList: disableSkusList, enableSkusMappedList: enableSkusList, disableSku, enableSku };
};


export const isDisabledSKU = (sku: string, disableSkusList, enableSkusList, onLoadDisableSkusList) => {
  const isDisable = disableSkusList?.has(sku) ? true : enableSkusList?.has(sku) ? false : onLoadDisableSkusList?.has(sku);
  return isDisable;
};