import { IBosDropDownOptions } from '@next-components/bos-dropdown';
import { isNil } from 'lodash';
import { AccessoryCategory, getTitleByAccessoryCategory } from 'next-common';
import { getEnumKeyFromValue } from 'sales-common';

export const getAccessoryCategoryKey = (accessoryCategory: string | number | AccessoryCategory): any => {
  if (isNil(accessoryCategory)) {
    return null;
  }
  if (typeof accessoryCategory === 'number' && !isNil(AccessoryCategory[accessoryCategory])) {
    return getEnumKeyFromValue(AccessoryCategory, accessoryCategory);
  }

  if (typeof accessoryCategory === 'string' && accessoryCategory !== '') {
    const numericValue = Number(accessoryCategory);

    if (isNaN(numericValue) && !isNil(AccessoryCategory[accessoryCategory])) {
      return accessoryCategory;
    }

    if (!isNaN(numericValue) && !isNil(AccessoryCategory[numericValue])) {
      return AccessoryCategory[numericValue];
    }
  }

  return null;
};

export const getAccessoryCategoryOptions = (): IBosDropDownOptions[] => {
  return Object.keys(AccessoryCategory)
    .map((key) => {
      const value = AccessoryCategory[key as keyof typeof AccessoryCategory];
      return {
        label: getTitleByAccessoryCategory(value),
        value,
        isSelected: value === AccessoryCategory.CUSTOM_DEFINED,
        isDefaultValue: value === AccessoryCategory.CUSTOM_DEFINED,
      };
    })
    .filter((item) => item.value !== AccessoryCategory.DARS_ACCESSORY && !!item.label);
};

export const checkForAccessoryCategoryKey = (key: string): boolean => {
  let keyExist: boolean = false;
  const accCategoryKeys = Object.keys(AccessoryCategory).filter((key) => isNaN(Number(key))).map((key) => key?.split('_')?.[0]);
  accCategoryKeys.forEach((category) => {
    if (key === category) {
      keyExist = true;
    }
  });
  return keyExist;
};