import axios from 'axios';
import { appEnv, salesCommonCacheUrl, salesCommonCacheStaticAuthToken } from '../../common';
import { getCachedToken } from '../../config/authentication';

export interface IRemoveVehicleStockDetails {
  modelSeries: string;
  brand: string;
  serialNumber: number;
}
export const removeVehicleStockFromCache = async ({
  vehicleStockDetails,
}: {
  vehicleStockDetails: IRemoveVehicleStockDetails;
}) => {
  try {
    const token = getCachedToken();
    const bodyReq = {
      authToken: token,
      vehicleStockList: [vehicleStockDetails],
      operation: 'delete',
    };
    const vehicleStockData = await axios.post(`${salesCommonCacheUrl}/cache/removeCache`, bodyReq, {
      headers: {
        Authorization: `Bearer ${salesCommonCacheStaticAuthToken}`,
        'Cache-Control': 'no-cache, max-age=50, must-revalidate',
      },
    });
    let data = vehicleStockData?.data;
    if (data && data?.response === 'success') {
      console.log('Vehicle Removed successfuly');
    }
  } catch (error) {
    console.log(error);
  }
};
