import { gql } from '@apollo/client';
import {
  bosCustomerFragment,
  customerFragmentWithBos,
  findOrderByIdConcisedQuery,
  findOrderByIdWithBosCustomerQuery,
  getVehicleOrderDraftQuery,
  OrderExpiryLengthFragment,
  referenceFragment,
} from 'next-common';

export const allOrderExpiryLengthQuery = gql`
  query allOrderExpiryLength {
    allOrderExpiryLength {
      ...orderExpiryLength
    }
  }
  ${OrderExpiryLengthFragment}
`;

export const findOrderByIdWithBosCustomer = gql`
  ${findOrderByIdWithBosCustomerQuery}
`;

export const queryOrderWithLead = gql`
  query findOrderById($id: ID!) {
    findOrderById(id: $id) {
      id
      lead {
        customerId
        make
        modelSeries
      }
      customer {
        ...customer
      }
      __typename
    }
  }
  ${referenceFragment}
  ${customerFragmentWithBos}
  ${bosCustomerFragment}
  fragment vehicle on Vehicle {
    id
  }
`;

export const findOrderByIdConcised = gql`
  ${findOrderByIdConcisedQuery}
`;

export const getUniqueBrandIdentifierQuery = gql`
  query getUniqueBrandIdentifier($input: BrandInput) {
    getUniqueBrandIdentifier(input: $input) 
  }
`;

export const getVehicleOrderDraft = gql `${getVehicleOrderDraftQuery}`