import { allPricingFields } from 'next-common';

const imageFields = `
  altText
  caption
  height
  url
  variants {
    altText
    caption
    height
    url
    width
  }
  width
`;

export const combinationEquipment = `
  id
  name
  sku
  images {
    ${imageFields}
  }
  priceExcludingVat
  priceIncludingVat
  marginPercentage
  marginAmount
  category
  type
  retailPrice
  validTo
  validFrom
  vatCode
`;

export const packageEquipments = `
  id
  items{
    name
    sku
  }
`;

const vehicleModelFields = `
  id
  name
  modelCode
  make
  manufacturerComponentIdModel
  year
  isAgent
  fuelType
  images {
    ${imageFields}
  }
  vehicleType
  series
  salesArticle
  fuel {
    name
    type
  }
  powerTrain
  effect
  electricProps {
    batteryCapacity
    chargingCapacityAC
    chargingCapacityDC
    electricRange
  }
  towingCapacity
  selectableEquipment {
    id
    type
    name
    sku
    pimSku
    category
    mainCategory
    subCategory
    priceExcludingVat
    priceIncludingVat
    marginPercentage
    marginAmount
    addTo
    delete
    manufacturerComponentId
    image{
      url
      key
      variants {          
        url
        width
        key								
      }
    }
    inConjuctionWith
    notInConjuctionWith
    validTo
    validFrom
    isHighlight
    unavailableForFactoryOrder
    vatCode
    retailPrice
    description
    equipmentDescription
    equipmentImage{
        url
        key
        variants {          
          url
          width
          key								
        }	
      }
    equipmentShapeImage{
        url
        key
        variants {          
          url
          width
          key								
        }	
      }
  }
  rims {
    id
    name
    sku
    pimSku
    category
    image {
      url
      variants {
        url
        width
      }
    }
    priceExcludingVat
    priceIncludingVat
    marginPercentage
    marginAmount
    addTo
    delete
    inConjuctionWith
    notInConjuctionWith
    validTo
    validFrom
    isHighlight
    unavailableForFactoryOrder
    vatCode
    retailPrice
    description
    manufacturerComponentId
    equipmentDescription
    equipmentImage{
        url
        key
        variants {          
          url
          width
          key								
        }	
      }
    equipmentShapeImage{
        url
        key
        variants {          
          url
          width
          key								
        }	
      }
  }
  equipment {
    name
    equipment
  }
  pricing {
    deliveryCharge
    importerSupport
    oneTimeFee
    source
    wreckage
  }
  bodywork {
    name
    type
  }
`;

export const vehicleConfigurationFields = `
  id
  name
  price
  priceExcludingVat
  sku
  uniqueBrandIdentifier
  manufacturerComponentIdsalesPackage
  images {
    ${imageFields}
  }
  model {
    ${vehicleModelFields}
  }
  salesPackages {
    id
    name
    additionalPrice
    equipment
  }
  optionPackages {
    id
    name
    additionalPrice
    equipment
  }
  tires {
    id
    name
    images {
      ${imageFields}
    }
    rimManufacturer
    tireManufacturer
    nobos
    pimSku
    type
    description
    spikes
    winterWheel
    additionalPrice
    additionalPriceExcludingVat
    isRecommended
    vat
    retailPriceExcludingVat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    category
    priority
  }
  varnishes {
    id
    name
    varnishCode
    pimSku
    type
    hexCode
    additionalPrice
    additionalPriceExcludingVat
    isRecommended
    retailMargin
    unAvailableForFactoryOrder
    validTo
    validFrom
    manufacturerComponentId
    image {
      url
      key
      variants {
        url
        width
        key
      }
    }
    vatCode
    retailPrice
    marginPercentage
  }
  interiors {
    id
    name
    images {
      ${imageFields}
    }
    interiorCode
    pimSku
    type
    additionalPrice
    isRecommended
    retailMargin
    additionalPriceExcludingVat
    unAvailableForFactoryOrder
    validTo
    validFrom
    vatCode
    retailPrice
    marginPercentage
    manufacturerComponentId
  }
  combinationEquipment {
    ${combinationEquipment}
  }
  packageEquipments {
    ${packageEquipments}
  }
  rims
  accessories {
    id
    name
    price
    sku
    pimSku
    images {
      ${imageFields}
    }
    manufacturer
    customerPrice
    retailPrice
    margin
    description
    priceExcludingVat
    retailPriceExcludingVat
    isRecommended
    vat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    retailPrice
    category
    priority
  }
  pricing {
    ${allPricingFields}
    vatCode
    oneOffTaxWeight
    carPriceExclVatAndFees
    packagePriceExclVat
    packageCostPriceExclVat
    optionsCostPriceExclVat
    baseCostPriceExclVat
  }
  electricProps {
    extraEffect
    extraElectricRange
    extraBatteryCapacity
  }
  inventory {
    availableCount
    locations {
      dealerCode
      stockCount
    }
  }
  factoryEquipmentLevels {
    id
    brand
    modelId
    articleId
    salesPackage
    optionString
    oneOffTax
    activeFrom
    activeTo
    isDiscontinued
  }
`;

export const vehicleShortConfigurationFields = `
  id
  name
  price
  priceExcludingVat
  sku
  uniqueBrandIdentifier
  manufacturerComponentIdsalesPackage
  images {
    ${imageFields}
  }
  accessories {
    id
    name
    price
    sku
    pimSku
    images {
      ${imageFields}
    }
    manufacturer
    customerPrice
    retailPrice
    margin
    description
    priceExcludingVat
    retailPriceExcludingVat
    isRecommended
    vat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    retailPrice
    category
    priority
  }
  tires {
    id
    name
    images {
      ${imageFields}
    }
    rimManufacturer
    tireManufacturer
    nobos
    pimSku
    type
    description
    spikes
    winterWheel
    additionalPrice
    additionalPriceExcludingVat
    isRecommended
    vat
    retailPriceExcludingVat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    category
    priority
  }
  mappedEquipment {
    id
    sku
    pimSku
    name
    inConjunctionWith
    notInConjunctionWith
    including
    excluding
    priceExcludingVat
    priceIncludingVat
    priceConjunctionTable {
      key
      value
    }
    category
    mainCategory
    isHighlight
    marginAmount
    marginPercentage
    retailPrice
    type
    unavailableForFactoryOrder
    vatCode
  }
  model {
   ${vehicleModelFields}
  }
  salesPackages {
    id
    name
    additionalPrice
    equipment
  }
  varnishes {
    id
    name
    varnishCode
    pimSku
    type
    hexCode
    additionalPrice
    additionalPriceExcludingVat
    isRecommended
    retailMargin
    unAvailableForFactoryOrder
    validTo
    validFrom
    manufacturerComponentId
    image {
      url
      key
      variants {
        url
        width
        key
      }
    }
    vatCode
    retailPrice
    marginPercentage
    description
    equipmentImage{
      url
      key
      variants {          
        url
        width
        key								
      }	
    }
  }
  interiors {
    id
    name
    images {
      ${imageFields}
    }
    interiorCode
    pimSku
    type
    additionalPrice
    isRecommended
    retailMargin
    additionalPriceExcludingVat
    unAvailableForFactoryOrder
    validTo
    validFrom
    vatCode
    description
    manufacturerComponentId
    retailPrice
    marginPercentage
    equipmentImage{
      url
      key
      variants {          
        url
        width
        key								
      }	
    }
  }
  pricing {
     ${allPricingFields}
  }
  rims
  packageEquipments {
    items {
      name
      sku
      description
      isHighlight
      images{
        url
        key
        variants {
          url
          width
          key
        }
      }
    }
    id
  }
  salespackageDescription
    optionPackages {
    id
    name
    additionalPrice
    equipment
  }
  combinationEquipment {
    ${combinationEquipment}
  }
  electricProps {
    extraEffect
    extraElectricRange
    extraBatteryCapacity
  }
  inventory {
    availableCount
    locations {
      dealerCode
      stockCount
    }
  }
  factoryEquipmentLevels {
      id
      brand
      modelId
      articleId
      salesPackage
      optionString
      oneOffTax
      activeFrom
      activeTo
      isDiscontinued
    }
`;

export const vehicleTireAccessoriesFields = `
  tires {
    id
    name
    images {
      ${imageFields}
    }
    rimManufacturer
    tireManufacturer
    nobos
    pimSku
    type
    description
    spikes
    winterWheel
    additionalPrice
    additionalPriceExcludingVat
    isRecommended
    vat
    retailPriceExcludingVat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    category
    priority
  }
  accessories {
    id
    name
    price
    sku
    pimSku
    images {
      ${imageFields}
    }
    manufacturer
    customerPrice
    retailPrice
    margin
    description
    priceExcludingVat
    retailPriceExcludingVat
    isRecommended
    vat
    priceIncludingVatIce
    priceIncludingVatEl
    priceExcludingVatIce
    priceExcludingVatEl
    vatCode
    elWithoutVat
    retailPrice
    category
    priority
  }
`;