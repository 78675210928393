import React, { useEffect, useState } from 'react';
import { Icon, IconType } from '@next-components/bos-icon';
import { Button } from '@next-components/button';
import { InputField } from '@next-components/input-field';
import { ArticleType } from 'sales-common';
import { IAccessoryList, INextArticle, IOrder, IRammeavtale, mapVatPricesForNext } from 'next-common';
import { calculateVatForElCars, VehicleConfig } from '../../../../utils';
import {
  addNewAccessoryInList,
  calculateDiscountedPrice,
  convertToCurrency,
  convertToPercentage,
  getDeliveryCharge,
  updateAccessoryList,
  updateArticles,
} from './FrameworkAgreementUtils';
import { ICartPrices, ICartSummaryData } from '@next-components/cart';
import {
  AgreementDetailStyled,
  FrameworkAgreementDetailsStyled,
  FrameworkAgreementListStyled,
  FrameworkAgreementSection,
} from './FrameworkAgreement.styled';
import { formatNumber, InputType } from '@next-components/common-types';
import { articlesForDiscount, DropDownValues, IDiscountFieldProps, validateDiscount } from '../MarginCartUtils';
import { ICalculatePricesBasedOnArticleListResult } from 'sales-common-calculations';
import { get } from 'lodash';
import { StatusTagSeverityLevel, Tag } from '@next-components/tag';

interface IFrameworkAgreement extends IDiscountFieldProps {
  order: IOrder;
  accessoryChanges?: ICartSummaryData[];
  articles: INextArticle[];
  onUpdateArticles?(value: IAccessoryList, customerAdvantage?: boolean, discountArticles?: INextArticle[]): void;
  vatPrices: ICartPrices;
  setVatPrices(vatPrices: ICartPrices): void;
  carType: string;
  setRammeavtale?(rammeavtale: IRammeavtale): void;
}

export const FrameworkAgreement = (props: IFrameworkAgreement) => {
  const [agreementNumber, setAgreementNumber] = useState('');
  const [showAccessoryList, setShowAccessoryList] = useState(false);
  const [accessoryList, setAccessoryList] = useState<IAccessoryList[]>([]);

  const [updatedFromOrder, setUpdatedFromOrder] = useState(false);
  const [initialAccessories, setInitialAccessories] = useState(false);

  useEffect(() => {
    if (!updatedFromOrder && props?.order?.rammeavtale?.accessories.length > 0) {
      setAgreementNumber(props?.order?.rammeavtale?.agreementNumber);
      setAccessoryList(props?.order?.rammeavtale?.accessories);

      if (props.setRammeavtale) {
        props?.order?.rammeavtale?.accessories.forEach((acc) => {
          acc.discountPercentage = Number(acc.discountPercentage);
          acc.discountAmountExclVat = Number(acc.discountAmountExclVat);
        });
        props.setRammeavtale({ agreementNumber, accessories: props?.order?.rammeavtale?.accessories });
      }

      setShowAccessoryList(true);
      setUpdatedFromOrder(true);
      setInitialAccessories(true);
    } else if (!initialAccessories) {
      const deliveryCharge = getDeliveryCharge(props.order?.pimSnapshot);

      const filteredAccessories = props?.accessoryChanges?.filter((accessory) => {
        return (
          accessory?.key?.startsWith(VehicleConfig.ACCESSORY) ||
          accessory?.key?.startsWith(VehicleConfig.TYRE) ||
          accessory?.key?.startsWith('CUSTOM') ||
          accessory?.key?.startsWith('FURNISHING') ||
          accessory?.key?.startsWith('SUPERSTRUCTURE') ||
          accessory?.key?.startsWith('RECONSTRUCTION')
        );
      });

      const mappedAccessories = addNewAccessoryInList(filteredAccessories);

      const combinedAccessories = [...mappedAccessories, ...deliveryCharge];

      setAccessoryList(combinedAccessories);

      if (props.setRammeavtale) {
        combinedAccessories.forEach((acc) => {
          acc.discountPercentage = Number(acc.discountPercentage) ?? 0;
          acc.discountAmountExclVat = Number(acc.discountAmountExclVat) ?? 0;
        });
        props.setRammeavtale({ agreementNumber, accessories: combinedAccessories });
      }

      setUpdatedFromOrder(true);
      setInitialAccessories(true);
    } else {
      let filteredAccessories = props?.accessoryChanges?.filter((accessory) => {
        return (
          accessory?.key?.startsWith(VehicleConfig.ACCESSORY) ||
          accessory?.key?.startsWith(VehicleConfig.TYRE) ||
          accessory?.key?.startsWith('CUSTOM') ||
          accessory?.key?.startsWith('FURNISHING') ||
          accessory?.key?.startsWith('SUPERSTRUCTURE') ||
          accessory?.key?.startsWith('RECONSTRUCTION')
        );
      });

      let updatedAccessories = [];

      filteredAccessories.forEach((accessory) => {
        let newAccessory = {
          key: accessory.key,
          name: accessory.name,
          price: accessory.price,
          discountPercentage: accessory.percentage,
          discountedPrice: accessory.price,
          articleType: accessory?.key === VehicleConfig.TYRE ? ArticleType.WINTERTIRES : ArticleType.ACCESSORY,
          vatCode: accessory.vatCode,
        };

        const found = props?.order?.rammeavtale?.accessories.find((acc) => acc.key === accessory.key);

        if (found) {
          newAccessory.discountPercentage = String(found.discountPercentage);
          newAccessory.discountedPrice = found.discountedPrice;
        }

        updatedAccessories.push(newAccessory);
      });

      let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;

      vatCalculation = calculateVatForElCars(props.articles, props.carType);

      props?.setActiveMargin({
        ...get(props, 'activeMargin', []),
        marginValue: vatCalculation?.marginAmount,
        marginPercentage: vatCalculation?.marginPercentage,
      });

      props.setVatPrices(mapVatPricesForNext(vatCalculation) as any);

      const combinedAccessories = [
        ...updatedAccessories,
        accessoryList.find((acc) => acc.articleType === ArticleType.DELIVERY),
      ];

      setAccessoryList(combinedAccessories);

      if (props.setRammeavtale) {
        combinedAccessories.forEach((acc) => {
          acc.discountPercentage = Number(acc.discountPercentage);
          acc.discountAmountExclVat = Number(acc.discountAmountExclVat);
        });
        props.setRammeavtale({ agreementNumber, accessories: combinedAccessories });
      }
    }
  }, [props.accessoryChanges]);

  const handleAgreementNumberChange = (param: string) => {
    const unFormattedNumber = param.replace(/\s/g, '');
    const formattedNumber = unFormattedNumber.replace(/(.{3})/g, '$1 ').trim();
    setAgreementNumber(formattedNumber);
  };

  const handleValueChange = (input: string | number, accessory: IAccessoryList, index: number): void => {
    const inputType = accessory?.articleType === ArticleType.DELIVERY ? InputType.INTEGER : InputType.FLOAT;

    const discountedPrice = calculateDiscountedPrice(accessory, input, inputType);

    accessory.discountAmountExclVat = convertToCurrency(input, accessory.price, inputType);
    accessory.discountPercentage = convertToPercentage(input, accessory.price, inputType);
    accessory.discountedPrice = discountedPrice;

    if (accessory?.articleType === ArticleType.DELIVERY) {
      props.setIsValid(validateDiscount(Number(input), DropDownValues.KR));
    } else {
      props.setIsValid(validateDiscount(Number(input), DropDownValues.PERCENTAGE, 100));
    }

    const updatedAccessoryList = updateAccessoryList(accessoryList, accessory, input, index);

    setAccessoryList(updatedAccessoryList);

    if (props.setRammeavtale) {
      updatedAccessoryList.forEach((acc) => {
        acc.discountPercentage = Number(acc.discountPercentage);
        acc.discountAmountExclVat = Number(acc.discountAmountExclVat);
      });
      props.setRammeavtale({ agreementNumber, accessories: updatedAccessoryList });
    }

    const updatedArticles = updateArticles(props.articles, accessory);

    let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;

    vatCalculation = calculateVatForElCars(updatedArticles, props.carType);

    props?.setActiveMargin({
      ...get(props, 'activeMargin', []),
      marginValue: vatCalculation?.marginAmount,
      marginPercentage: vatCalculation?.marginPercentage,
    });

    props.setVatPrices(mapVatPricesForNext(vatCalculation) as any);

    props.onUpdateArticles(accessory);
  };

  const getAccessoryTag = (key: string): string => {
    if (key.startsWith('CUSTOM')) {
      return 'Egendefinert';
    }

    if (key.startsWith('FURNISHING')) {
      return 'Innredning';
    }

    if (key.startsWith('SUPERSTRUCTURE')) {
      return 'Påbygg';
    }

    if (key.startsWith('RECONSTRUCTION')) {
      return 'Ombygging';
    }

    return 'DARS';
  };

  const deleteRammeavtale = () => {
    let updatedAccessoryList = [...accessoryList];

    updatedAccessoryList.forEach((accessory) => {
      accessory.discountAmountExclVat = 0;
      accessory.discountPercentage = 0;
      accessory.discountedPrice = 0;
    });

    setAccessoryList(updatedAccessoryList);

    if (props.setRammeavtale) {
      props.setRammeavtale(null);
    }

    let updatedArticles = [...props.articles];

    updatedArticles.forEach((article) => {
      article.discountAmountExclVat = 0;
      article.discountPercentage = 0;
      if (article.key === VehicleConfig.DELIVERY_CHARGE) {
        delete article.discountPercentage;
      } else {
        delete article.discountAmountExclVat;
      }
    });

    let discountArticles = articlesForDiscount(updatedArticles, {}, props.order);

    let vatCalculation: ICalculatePricesBasedOnArticleListResult = null;

    vatCalculation = calculateVatForElCars(discountArticles, props.carType);

    props?.setActiveMargin({
      ...get(props, 'activeMargin', []),
      marginValue: vatCalculation?.marginAmount,
      marginPercentage: vatCalculation?.marginPercentage,
    });

    props.setVatPrices(mapVatPricesForNext(vatCalculation) as any);

    props.onUpdateArticles(null, true, discountArticles);

    setShowAccessoryList(false);
    setAgreementNumber(null);
  };

  return (
    <div>
      {!showAccessoryList && (
        <FrameworkAgreementSection>
          <InputField
            id="numberInput"
            label="Rammeavtalenummer"
            type={InputType.TEXT_LINE}
            onChange={(input: string) => handleAgreementNumberChange(input)}
            className="agreementNumber"
            value={agreementNumber}
          />
          <Button
            className="rammeavtalenummerButton"
            colorVariant={'secondary'}
            disabled={!agreementNumber}
            onClick={() => setShowAccessoryList(!showAccessoryList)}>
            Lagre
          </Button>
        </FrameworkAgreementSection>
      )}
      {showAccessoryList && (
        <FrameworkAgreementListStyled>
          <div className="divider"></div>
          <FrameworkAgreementDetailsStyled>
            <h3 className="agreementTitle">Rammeavtale {agreementNumber?.replace(/\s/g, '-')}</h3>
            <Button variant="secondary" className="small agreementAction" onClick={deleteRammeavtale}>
              <Icon icon={IconType.NewNextTrash} />
              Slett
            </Button>
          </FrameworkAgreementDetailsStyled>
          {accessoryList?.map((accessory, index) => {
            return (
              <div key={accessory?.key} className="agreementDetailsList">
                <AgreementDetailStyled>
                  <div className="agreementPrices">
                    <p className="accessoryName">{accessory?.name}</p>
                    {accessory?.discountedPrice && accessory.discountedPrice !== accessory.price ? (
                      <>
                        <p className="price accessoryDarsPrice">
                          Pris eks. mva:{' '}
                          <del className="price accessoryDarsPrice">{formatNumber(accessory?.price)} kr</del>
                          <span className="price accessoryNetPrice">{formatNumber(accessory?.discountedPrice)} kr</span>
                        </p>
                      </>
                    ) : (
                      <p className="price accessoryDarsPrice">Pris eks. mva: {formatNumber(accessory?.price)} kr</p>
                    )}
                    {accessory?.articleType !== ArticleType.DELIVERY && (
                      <Tag
                        className="rounded"
                        value={getAccessoryTag(accessory?.key)}
                        statusTagSeverity={StatusTagSeverityLevel.INFORMATION}
                      />
                    )}
                  </div>
                  <div className="agreementDetailsInput">
                    {accessory?.key === VehicleConfig.DELIVERY_CHARGE ? (
                      <InputField
                        id={accessory?.key}
                        label="Legg til rabatt"
                        value={accessory?.discountAmountExclVat}
                        onChange={(value) => handleValueChange(value, accessory, index)}
                        type={InputType.INTEGER}
                        endAdornment="kr"
                      />
                    ) : (
                      <InputField
                        id={accessory?.key}
                        label="Legg til rabatt"
                        value={accessory?.discountPercentage}
                        onChange={(value) => handleValueChange(value, accessory, index)}
                        maxLength={2}
                        type={InputType.INTEGER}
                        endAdornment="%"
                      />
                    )}
                  </div>
                </AgreementDetailStyled>
                <div className="divider"></div>
              </div>
            );
          })}
        </FrameworkAgreementListStyled>
      )}
    </div>
  );
};
