import { useEffect, useState } from 'react';
import { ValidationErrorCode, getErrorMessageForError } from '../../components/notifications';
import { calculateVat, ICartPricesExtended } from '../../utils';
import { usePrevious } from './usePrevious';
import { INextArticle } from 'next-common';
import { isEmpty, isEqual } from 'lodash';
import { useConfigurations } from './useConfiguration';

export interface IVatCalculationProps {
  articles: INextArticle[];
  carType?: string;
}

interface ICartPricesCustom extends ICartPricesExtended {
  error: boolean;
}

export const useVatStateCalculationHandler = (props: IVatCalculationProps) => {
  const [finalPrice, setFinalPrice] = useState<ICartPricesCustom>(null);
  const prevArticles = usePrevious(props.articles);

  const configurations = useConfigurations();

  const isRammeavtaleEnabled = configurations?.features?.rammeavtale ?? false;

  useEffect(() => {
    if (!props.articles?.length && finalPrice) {
      setFinalPrice(null);
    }
    if (props.articles?.length && !isEmpty(props?.carType)) {
      if (!isEqual(prevArticles, props.articles)) {
        const vatPrices = calculateVat(props.articles, props?.carType);
        if (!vatPrices) {
          console.warn(getErrorMessageForError(ValidationErrorCode.VAT_VALIDATION));
          setFinalPrice({ ...vatPrices, error: true });
        } else {
          if (!isRammeavtaleEnabled) {
            if (vatPrices && typeof vatPrices === 'object') {
              let finalPrices = { ...vatPrices };
              delete finalPrices.calculatedDiscountOnVehicle;
              setFinalPrice({ ...finalPrices, error: false });
            } else {
              setFinalPrice({ ...vatPrices, error: false });  
            }
          } else {
            setFinalPrice({ ...vatPrices, error: false });
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articles]);

  return { finalPrice };
};
