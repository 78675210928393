import React, { useEffect, useState } from 'react';
import {
  AccessoryCategory,
  AccessoryType,
  calculateTotalTaxOnCustomAccesories,
  getVehicleAccessoryCustomId,
  IConfiguration,
  IOrder,
  isElectricCar,
  IVehicleAccessory,
  MAKE,
} from 'next-common';
import { CustomAccessory } from '../../../common-components/CustomAccessory';
import { OrderAccordion } from '../../sales-common/OrderAccordion';
import { IAccessoryChange, ICustomAccessoryProps } from '../AccessoryTool';
import { AccessoriesStyled, AccessorySetStyled } from '../VehicleAccessoriesPage.styled';
import { SalesWizardButtonMenu } from '../../sales-wizard/sales-wizard-menu/SalesWizardButtonMenu';
import { getStepData, IStepMenuData } from '../../sales-wizard/sales-wizard-menu';
import { VatCode } from 'sales-common';
import { isValidStockSaleOrder } from '../../../../utils';
import { CustomAccessoryAdd } from '../../../common-components/CustomAccessory/CustomAccessoryAdd';
import { IPcodeValidation } from '../../sales-wizard';

interface ICustomAccessorySetProps {
  loading?: boolean;
  order: IOrder;
  accessories: Partial<ICustomAccessoryProps>[];
  changes: IAccessoryChange[];
  isCustomAccessoryOpen?: boolean;
  orderId: string;
  isUnavailablePimData?: boolean;
  calculationId?: string;
  activeStep?: number;
  menuLoading?: boolean;
  configuration?: IConfiguration;
  rootUrl?: string;
  isVehicleValidForReserveOrSign?: boolean;
  onAddAccessory(id: string, accessoryData?: Partial<ICustomAccessoryProps>): void;
  onRemoveAccessory(id: string, accessory: Partial<ICustomAccessoryProps>): void;
  onEditAccessory?(
    accessoryId: string,
    oldAccessory?: Partial<ICustomAccessoryProps>,
    accessory?: Partial<ICustomAccessoryProps>,
    accessoryType?: AccessoryType,
  ): void;
  onClick?(stepData?: IStepMenuData | any): void;
  onClickSigning?(stepData?: IStepMenuData): void;
  setIsCustomAccessoryOpen(data: boolean): void;
  vehicleAvailabilityCode?: string;
  isVarebilEnabled?: boolean;
  invalidPcodesValidation?: IPcodeValidation;
}

const emptyManualCardData: Partial<ICustomAccessoryProps> = {
  isSelected: false,
  isManualInput: true,
  isCustomIncludeMVA: false,
};
export const CustomAccessorySet = (props: ICustomAccessorySetProps) => {
  const [customAccessoryData, setCustomAccessoryData] = useState<Partial<ICustomAccessoryProps>>(emptyManualCardData);
  const [selectedAccessories, setSelectedAccessories] = useState<Partial<ICustomAccessoryProps>[]>([]);
  const isElectric = !!props?.order?.pimSnapshot?.vehicleUsageCode
    ? props?.order?.pimSnapshot?.vehicleUsageCode === 'EL'
    : isElectricCar(props?.order?.pimSnapshot, null);
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const isStockSaleOrder = isValidStockSaleOrder(props?.order, props?.configuration);
  const stepData = getStepData(props?.activeStep, props.orderId, props?.rootUrl, props.isVarebilEnabled);

  useEffect(() => {
    if (props?.order) {
      setCustomAccessoryData({
        ...emptyManualCardData,
        id: getVehicleAccessoryCustomId(props?.order, emptyManualCardData),
      });
    }
  }, [props?.order]);

  useEffect(() => {
    setSelectedAccessories(props?.accessories);
  }, [props?.accessories]);

  useEffect(() => {
    if (!props?.changes?.length) {
      setSelectedAccessories(props?.accessories);
    }
  }, [props?.changes]);

  useEffect(() => {
    if (inputOpen) {
      setInputOpen(false);
    }
  }, [props.isCustomAccessoryOpen]);

  const handleCustomeSave = async (
    id: string,
    name: string,
    price: number,
    dealerPriceExcludingVat: number,
    priceExcludingVat: number,
    accessoryCategory: string | number,
    isEdit: boolean = false,
    isIncludeMVA?: boolean,
  ): Promise<void> => {
    let customAccPriceExcludingVat: number = 0;

    if (!isIncludeMVA) {
      const customPayloadForPrice = {
        accessoryCategory,
        accessoryType: AccessoryType.CUSTOM,
        isCustomIncludeMVA: isIncludeMVA,
        price,
      };
      customAccPriceExcludingVat = price - calculateTotalTaxOnCustomAccesories([customPayloadForPrice] as IVehicleAccessory[]);
    } else {
      customAccPriceExcludingVat = price;
    }

    const vatCode: string = isIncludeMVA ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE;
    const savedData: Partial<ICustomAccessoryProps> = {
      id,
      name,
      isSelected: true,
      isManualInput: true,
      price,
      priceExcludingVat:
        accessoryCategory === AccessoryCategory.CUSTOM_DEFINED ? customAccPriceExcludingVat : priceExcludingVat,
      isCustomIncludeMVA: isIncludeMVA,
      vatCode,
      accessoryCategory: AccessoryCategory[accessoryCategory],
      retailPriceExcludingVat: dealerPriceExcludingVat,
    };
    if (isEdit) {
      const editedAccessory = {
        ...savedData,
        id: getVehicleAccessoryCustomId(props?.order, emptyManualCardData),
      };
      const newAccessoryList = selectedAccessories?.filter((accessory) => accessory?.id !== id);
      const oldAccessory = selectedAccessories?.find((accessory) => accessory?.id === id);
      newAccessoryList.push(editedAccessory);
      props.onEditAccessory(id, oldAccessory, editedAccessory);
      setSelectedAccessories(newAccessoryList);
    } else {
      const accessoriesCopy: Partial<ICustomAccessoryProps>[] = [...(selectedAccessories || [])];
      accessoriesCopy.push(savedData);
      setSelectedAccessories(accessoriesCopy);
      props.onAddAccessory(id, savedData);
      setCustomAccessoryData({
        ...emptyManualCardData,
        id: getVehicleAccessoryCustomId(props?.order, emptyManualCardData),
      });
    }
  };

  const handleRemoveAccessory = async (id: string): Promise<void> => {
    const newAccessoryList = selectedAccessories?.filter((accessory) => accessory?.id !== id);
    setSelectedAccessories(newAccessoryList);
    const removedAccessory = selectedAccessories?.find((accessory) => accessory?.id === id);
    props.onRemoveAccessory(id, removedAccessory);
  };
  return (
    <AccessorySetStyled>
      {props?.order?.lead?.make !== MAKE.Smart && (
        <OrderAccordion
          label={'Tilbehør fra ekstern leverandør'}
          isOpen={props?.isCustomAccessoryOpen}
          setIsCustomAccessoryOpen={props?.setIsCustomAccessoryOpen}
          className={
            props.isCustomAccessoryOpen && !selectedAccessories?.length && !inputOpen ? 'custom-accessory-add' : 'custom-accessory-grid-block'
          }>
          {!selectedAccessories?.length && !inputOpen ? (
            <CustomAccessoryAdd onAdd={setInputOpen} description="Legg til tilbehør fra ekstern leverandør" />
          ) : (
            <AccessoriesStyled className="custom-accessory-grid">
              {selectedAccessories?.map((accessory) => (
                <CustomAccessory
                  key={accessory?.id}
                  id={accessory?.id}
                  name={accessory?.name}
                  dealerPriceExcludingVat={accessory?.retailPriceExcludingVat}
                  priceExcludingVat={accessory?.priceExcludingVat}
                  price={accessory?.price}
                  accessoryCategory={accessory?.accessoryCategory}
                  isIncludeMVA={accessory?.isCustomIncludeMVA}
                  isSelected={accessory?.isSelected}
                  isElectric={isElectric}
                  disabled={props?.loading ?? false}
                  onRemove={handleRemoveAccessory}
                  onSave={handleCustomeSave}
                />
              ))}
              <CustomAccessory
                key={'manual'}
                id={customAccessoryData?.id}
                name={customAccessoryData?.name}
                dealerPriceExcludingVat={customAccessoryData?.retailPriceExcludingVat}
                priceExcludingVat={customAccessoryData?.priceExcludingVat}
                price={customAccessoryData?.price}
                accessoryCategory={customAccessoryData?.accessoryCategory}
                isIncludeMVA={customAccessoryData?.isCustomIncludeMVA}
                disabled={props?.loading ?? false}
                isElectric={isElectric}
                keepInputOpen={!selectedAccessories?.length}
                onSave={handleCustomeSave}
              />
            </AccessoriesStyled>
          )}
        </OrderAccordion>
      )}
      <div className="footerMenu">
        <SalesWizardButtonMenu
          orderId={props?.orderId}
          order={props?.order}
          calculationId={props?.calculationId}
          activeStep={props?.activeStep}
          isUnavailablePimData={props?.isUnavailablePimData}
          menuLoading={props?.menuLoading}
          onClick={props?.onClick}
          isStockSaleOrder={isStockSaleOrder}
          onClickSigning={props?.onClickSigning}
          bottomButton={true}
          stepData={stepData?.[stepData?.length - 1]}
          allStepData={stepData}
          isVehicleValidForReserveOrSign={props?.isVehicleValidForReserveOrSign}
          vehicleAvailabilityCode={props.vehicleAvailabilityCode}
          isVarebilEnabled={props.isVarebilEnabled}
          invalidPcodesValidation={props?.invalidPcodesValidation}
        />
      </div>
    </AccessorySetStyled>
  );
};
