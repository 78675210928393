import { get } from 'lodash';
import { IEquipmentTypes } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, usePreSelectionEquipment, useSkusPriceUpdate } from '../../../../../common';
import { eventEmitter, Events, getRimsChangeInput, prepareImageObject, PreSelectedKeyEnum, priceFormatter } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICommonRulesProps, IRimChangeProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { v4 as uuidv4 } from 'uuid';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleRims extends ICommonRulesProps {
  data: IModelPackages;
  disabled: boolean;
  customerType?: string;
  selectedRims: IEquipmentTypes;
  onRimChange: (data: IRimChangeProps) => void;
  updatePrices: () => void;
}

export const VehicleRim = (props: IVehicleRims) => {
  const { data, disabled, selectedRims, onRimChange } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { updateKeyStatus, isEnablePreSelection, isSKUPreselected, isPreSelectionDone } = usePreSelectionEquipment();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IEquipmentTypes>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const getPrice = (rim: IEquipmentTypes) => {
    return priceUpdate?.has(rim?.sku) ? Number(priceUpdate?.get(rim?.sku)) : rim?.priceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart & VAT Calculation
    if (selectedRims?.id) {
      props.updatePrices();
    }
  }, [selectedRims, priceUpdate]);

  const onChangeRim = (rim: IEquipmentTypes) => {
    if (typeof props.setCurrentlySelectedSku === "function" && typeof props.setCurrentSelectionDetails === "function") {
      if (selectedRims?.id !== rim?.id) {
        props.setCurrentlySelectedSku(rim?.sku);
      } else {
        props.setCurrentSelectionDetails({
          deSelectedSku: rim?.sku,
        });
      }
    }
    onRimChange(getRimsChangeInput(rim, priceUpdate, selectedRims?.id));
  }

  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const updatePreselection = (rim: IEquipmentTypes) => {
    if (isSKUPreselected(rim?.sku, PreSelectedKeyEnum.RIM) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.RIM)) {
      onChangeRim(rim);
      updateKeyStatus(PreSelectedKeyEnum.RIM)
    }
    return false;
  }

  useEffect(() => {
    const isDisable = isDisabled(selectedRims?.sku);
    if (!isDisable && isPrepared && selectedRims?.id) {
      const rim = data?.rims?.filter((rim) => selectedRims?.id === rim?.id)?.[0];
      if (rim) {
        onRimChange(
          getRimsChangeInput(selectedRims, priceUpdate, null, true));
      }
    }
  }, [selectedRims, isDisabled(selectedRims?.sku)]);

  const isDataAvailable = (rim: IEquipmentTypes) => {
    return get(rim, 'description');
  };

  const setCurrentData = (rim: IEquipmentTypes) => {
    setActiveDesc(rim);
    setActive(!active);
  };

  return (
    <>
      <span className="label">Felger</span>
      {data?.rims?.length <= 0 || !data ? (
        <p> Ingen Felger tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleRim">
          {data?.rims?.map((rim) => {
            const isSelected = selectedRims?.id === rim?.id;
            const customPriceTag = `${getPrice(rim) > 0 ? '+' : ''}  ${priceFormatter(getPrice(rim))}`;
            updatePreselection(rim);

            return (
              <Card
                key={uuidv4()}
                id={rim?.id}
                title={rim?.name}
                customPriceTag={customPriceTag}
                bosSwatch={prepareImageObject(rim?.image?.[0])}
                type={CardType.BosCard}
                selected={isSelected}
                isDisabled={disabled}
                displayTag
                tagValue={rim?.sku}
                updateSelected={() => onChangeRim(rim)}
                disableTag={!isDataAvailable(rim)}
                onTagClick={() => setCurrentData(rim)}
                alertMessage={{
                  typeOfAlert: MessagebarSeverityLevel.WARNING,
                  showIcon: false,
                }}
              />
            );
          })}
          <VehicleSelectableDescription data={activeDesc} active={active} onClose={() => setActive(!active)} />
        </SwatchGrid>
      )}
    </>
  );
};
