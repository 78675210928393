import { IOrderEvent } from 'next-common';
import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import { EventListHeaderStyled, EventListStyled, EventPaneStyled } from './EventPane.styled';
import { EventItem } from './EventItem';
import { EventLevel, IOrderEventItem, mapEvent } from './orderEventItem';
import { useEvents } from '../../../../common';
import { ToggleSwitch } from '@next-components/toggle-switch';

export interface IEventPaneProps {
  orderId: string;
}

const orderEvents = (events: IOrderEvent[]): IOrderEvent[] => {
  return !events || events.length < 1 ? [] : orderBy(events, ['doneAt'], ['desc']);
};

const sortSms = (events: IOrderEvent[]): IOrderEvent[] => {
  if (!events || events.length < 1) {
    return [];
  } else {
    let newEvents = [...events];
    newEvents.forEach((event, index) => {
      // @ts-expect-error
      if (event.eventType === 'SIGNING_INITIATED') {
        let nextEvent = { ...newEvents[index + 1] };
        // @ts-expect-error
        if (nextEvent && nextEvent.eventType === 'SMS_SENT_SIGNING') {
          newEvents[index + 1] = newEvents[index];
          newEvents[index] = nextEvent;
        }
      }
    });
    return newEvents.filter((newEvent)=>newEvent.doneBy !== 'Santander');
  }
};

export const EventPane = (props: IEventPaneProps) => {
  const events = useEvents(props.orderId);
  const [showAll, setShowAll] = React.useState(false);
  const [sortedEvents, setSortedEvents] = useState<IOrderEvent[]>([]);

  useEffect(() => {
    setSortedEvents(sortSms(orderEvents(events)));
  }, [events]);

  const onShowAll = () => {
    setShowAll(!showAll);
  };

  if (sortedEvents.length < 1) return null;
  return (
    <EventPaneStyled>
      <EventListHeaderStyled>
        <div className="topWrapper">
          <h3 className="title">Hendelser</h3>
          <ToggleSwitch label={'Vis kun nøkkelhendelser'} checked={showAll} toggleHandler={onShowAll} />
        </div>
      </EventListHeaderStyled>
      <EventListStyled>
        {sortedEvents
          .map(mapEvent)
          .filter((x: IOrderEventItem) => !showAll || x.eventLevel === EventLevel.Key)
          .map((event, idx, all) => (
            <EventItem key={event.doneAt} event={event} isLastEvent={idx === all.length - 1} />
          ))}
      </EventListStyled>
    </EventPaneStyled>
  );
};
