import { formatNumber } from "sales-common";
import { IRuleValues, RulesDetail } from "./RulesDetail";
import { isEmpty } from "lodash";

export interface IRulesInfo {
    ruleAddVaules: IRuleValues[];
    ruleRemoveVaules: IRuleValues[];
    ruleDefaultValues: IRuleValues[];
    price: number;
    className?: string;
};

export const RulesInfo = (props: IRulesInfo) => {
    const {
        price,
        ruleAddVaules,
        ruleRemoveVaules,
        ruleDefaultValues,
        className,
    } = props;

    return (
        <div className={`${className} rulesInfoContainer`}>
            {(!isEmpty(ruleAddVaules) || !isEmpty(ruleDefaultValues)) &&
                <div className="rulesToAdd">
                    <RulesDetail
                        header="Følgende legges til:"
                        ruleValues={[...ruleAddVaules || [], ...ruleDefaultValues || []]}
                        prefix="+"

                    />
                </div>
            }
            {!isEmpty(ruleRemoveVaules) &&
                <div className="rulesToRemove">
                    <RulesDetail
                        header="Følgende valg fjernes:"
                        ruleValues={ruleRemoveVaules}
                        prefix="-"
                    />
                </div>
            }
            <div className="priceDetail">
                <div className="priceHeader">
                    Prisjustering
                </div>
                <div className="priceDetailValue">
                    {price > 0 ? `+ ${formatNumber(price)}` : formatNumber(price)} kr
                </div>
            </div>
        </div>
    )
};