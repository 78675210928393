import { useState } from 'react';
import { CardInstruction, CardButtonSection } from './CustomAccessory.styled';
import { Button } from '@next-components/button';
import { StyledCard } from '../../sales/sales-vehicle-accessories-page/VehicleAccessoriesPage.styled';
import { IBosDropDownOptions } from '@next-components/bos-dropdown';
import { AccessoryCategory, getTitleByAccessoryCategory } from 'next-common';
import { CustomAccessoryAdd } from './CustomAccessoryAdd';
import { getEnumValueFromKey } from 'sales-common';
import { CustomAccessoryInputForm } from './CustomAccessoryInputForm';
import { getAccessoryCategoryKey, getAccessoryCategoryOptions } from '../../../utils';
import { formatPrice } from '../MarginCart';

interface ICustomAccessoryProps {
  id?: string;
  name?: string;
  price?: number;
  dealerPriceExcludingVat?: number;
  priceExcludingVat?: number;
  accessoryCategory?: AccessoryCategory;
  isIncludeMVA?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  isElectric?: boolean;
  keepInputOpen?: boolean;

  onRemove?(id: string): void;
  onSave?(
    id: string,
    name: string,
    price: number,
    dealerPriceExcludingVat: number,
    priceExcludingVat: number,
    accessoryCategory: string | number,
    isEdit?: boolean,
    isIncludeMVA?: boolean,
  ): void;
}
export const CustomAccessory = (props: ICustomAccessoryProps) => {
  const accessoryCategoryOption = getAccessoryCategoryOptions().find(
    (item) => item.value === getEnumValueFromKey(AccessoryCategory, props?.accessoryCategory),
  );
  const [customName, setCustomName] = useState<string>(props.name || '');
  const [dealerPriceExcludingVat, setDealerPriceExcludingVat] = useState<number>(props?.dealerPriceExcludingVat ?? 0);
  const [priceExcludingVat, setPriceExcludingVat] = useState<number>(props?.priceExcludingVat ?? 0);
  const [price, setPrice] = useState<number>(props?.price ?? 0);
  const [isIncludeMVA, setIsIncludeMVA] = useState<boolean>(props.isIncludeMVA || false);
  const [accessoryCategory, setAccessoryCategory] = useState<IBosDropDownOptions>(accessoryCategoryOption ?? null);
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);

  const resetStates = (): void => {
    setCustomName('');
    setDealerPriceExcludingVat(0);
    setPriceExcludingVat(0);
    setPrice(0);
    setAccessoryCategory(getAccessoryCategoryOptions().find((item) => item.value === AccessoryCategory.CUSTOM_DEFINED));
    setIsIncludeMVA(false);
  };

  const handleSave = (isEdit?: boolean): void => {
    if (typeof props.onSave === 'function') {
      const accessoryCategoryInput = accessoryCategory?.value ?? AccessoryCategory.CUSTOM_DEFINED;
      props.onSave(
        props.id,
        customName,
        price,
        dealerPriceExcludingVat,
        priceExcludingVat,
        accessoryCategoryInput,
        isEdit,
        isIncludeMVA,
      );
      resetStates();
    }
  };

  return (
    <>
      {props?.isSelected ? (
        editEnabled ? (
          <CustomAccessoryInputForm
            accessoryCategoryOptions={getAccessoryCategoryOptions()}
            customName={customName}
            editEnabled={editEnabled}
            handleSave={handleSave}
            setAccessoryCategory={setAccessoryCategory}
            setCustomName={setCustomName}
            setDealerPriceExcludingVat={setDealerPriceExcludingVat}
            setEditEnabled={setEditEnabled}
            setInputOpen={setInputOpen}
            setIsIncludeMVA={setIsIncludeMVA}
            setPriceExcludingVat={setPriceExcludingVat}
            setPrice={setPrice}
            accessoryCategory={accessoryCategory}
            dealerPriceExcludingVat={dealerPriceExcludingVat}
            disabled={props.disabled}
            id={props?.id}
            isElectric={props.isElectric}
            isIncludeMVA={isIncludeMVA}
            priceExcludingVat={priceExcludingVat}
            price={price}
            isSelected={props.isSelected}
            onRemove={props.onRemove}
            resetStates={resetStates}
          />
        ) : (
          <StyledCard isCustom={true} className="customAccessory customAccessoryCard">
            <div className="descriptionGroup">
              <CardInstruction>
                {' '}
                {getTitleByAccessoryCategory(AccessoryCategory[props?.accessoryCategory])}{' '}
              </CardInstruction>
              <p>{customName}</p>
            </div>
            <div className="pricesGroup">
              <div className='divider'></div>
              {!props?.accessoryCategory ||
              props?.accessoryCategory === getAccessoryCategoryKey(AccessoryCategory.CUSTOM_DEFINED) ? (
                <div className="priceGroupItem">
                  <p>Utsalgspris, inkl. mva</p>
                  <p className="value">{formatPrice(price)}</p>
                </div>
              ) : (
                <>
                  {' '}
                  <div className="priceGroupItem">
                    <p>Kostpris, eks. mva</p>
                    <p className="value">{formatPrice(dealerPriceExcludingVat)}</p>
                  </div>
                  <div className="priceGroupItem">
                    <p>Utsalgspris, eks. mva</p>
                    <p className="value">{formatPrice(priceExcludingVat)}</p>
                  </div>
                </>
              )}
              {props.isElectric && (
                <div className="priceGroupItem">
                  <p>Fritatt for mva</p>
                  <p className="value">{isIncludeMVA ? 'Ja' : 'Nei'} </p>
                </div>
              )}
            </div>
            <CardButtonSection>
                <Button
                  className='cardButton'
                  onClick={() => setEditEnabled(true)}
                  variant="cta"
                  colorVariant="secondary"
                  disabled={props.disabled || !props?.accessoryCategory}>
                  <span>{'Rediger'}</span>
                </Button>
                <Button
                  className="red cardButton"
                  onClick={() => props.onRemove(props.id)}
                  variant="secondary"
                  colorVariant="tertiary"
                  disabled={props.disabled}>
                  <span>{'Slett'}</span>
                </Button>
            </CardButtonSection>
          </StyledCard>
        )
      ) : inputOpen || props?.keepInputOpen ? (
        <CustomAccessoryInputForm
          accessoryCategoryOptions={getAccessoryCategoryOptions()}
          customName={customName}
          editEnabled={editEnabled}
          handleSave={handleSave}
          setAccessoryCategory={setAccessoryCategory}
          setCustomName={setCustomName}
          setDealerPriceExcludingVat={setDealerPriceExcludingVat}
          setEditEnabled={setEditEnabled}
          setInputOpen={setInputOpen}
          setIsIncludeMVA={setIsIncludeMVA}
          setPrice={setPrice}
          setPriceExcludingVat={setPriceExcludingVat}
          accessoryCategory={accessoryCategory}
          dealerPriceExcludingVat={dealerPriceExcludingVat}
          disabled={props.disabled}
          id={props?.id}
          isElectric={props.isElectric}
          isIncludeMVA={isIncludeMVA}
          priceExcludingVat={priceExcludingVat}
          price={price}
          isSelected={props.isSelected}
          onRemove={props.onRemove}
          resetStates={resetStates}
        />
      ) : (
        <CustomAccessoryAdd
          onAdd={setInputOpen}
          description="Legg til tilbehør"
          className="custom-accessory-add-card"
        />
      )}
    </>
  );
};
