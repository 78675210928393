import { get } from 'lodash';
import { IVarnish } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, usePreSelectionEquipment, useSkusPriceUpdate } from '../../../../../common';
import { calculateRetailPrice, eventEmitter, Events, getColorChangeInput, PreSelectedKeyEnum, priceFormatter, VehicleConfig } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICartDataProps, ICommonRulesProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleColor extends ICommonRulesProps {
  data: IModelPackages;
  selectedColorId: string;
  disabled: boolean;
  onColorChange: (data: ICartDataProps) => void;
  updatePrices: () => void;
}

export const VehicleColor = (props: IVehicleColor) => {
  const { data, selectedColorId, onColorChange, disabled } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { updateKeyStatus, isEnablePreSelection, isSKUPreselected, isPreSelectionDone } = usePreSelectionEquipment();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IVarnish>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const getPrice = (color: IVarnish) => {
    return priceUpdate?.has(color?.varnishCode)
      ? Number(priceUpdate?.get(color?.varnishCode))
      : color?.additionalPriceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart and Vat calculation
    if (selectedColorId) {
      props.updatePrices();
    }
  }, [selectedColorId, priceUpdate]);

  const onChangeColor = (color: IVarnish) => {
    if (typeof props.setCurrentlySelectedSku === "function" && typeof props.setCurrentSelectionDetails === "function") {
      if (selectedColorId !== color?.varnishCode) {
        props.setCurrentlySelectedSku(color?.varnishCode);
      } else {
        props.setCurrentSelectionDetails({
          deSelectedSku: color?.varnishCode
        });
      }
    }
    onColorChange(getColorChangeInput(
      color,
      priceUpdate,
      selectedColorId,
    ));
  }
  const isDisabled = (varnishCode: string) => {
    const isDisable = isDisableSKUonChange(varnishCode)
      ? true
      : isEnableSKUonChange(varnishCode)
        ? false
        : isDisableSKUonLoad(varnishCode);
    return isDisable;
  };

  const updatePreselection = (color: IVarnish) => {
    if (isSKUPreselected(color?.varnishCode, PreSelectedKeyEnum.COLOR) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.COLOR)) {
      onChangeColor(color);
      updateKeyStatus(PreSelectedKeyEnum.COLOR);
    }
    return false;
  }

  useEffect(() => {
    const isDisable = isDisabled(selectedColorId);
    if (!isDisable && isPrepared && selectedColorId) {
      const color = data?.colors?.filter((color) => selectedColorId === color?.varnishCode)?.[0];
      if (color) {
        onColorChange({
          code: selectedColorId,
          name: color?.name,
          price: getPrice(color),
          vatCode: color?.vatCode,
          isRecommended: color?.isRecommended,
          isOptimisticUpdate: true,
          configType: VehicleConfig.COLOR,
          dealerPriceExclVat: calculateRetailPrice(getPrice(color), color?.marginPercentage),
        });
      }
    }
  }, [selectedColorId, isDisabled(selectedColorId)]);

  const isDataAvailable = (color: IVarnish) => {
    return get(color, 'description');
  };

  const setCurrentData = (color: IVarnish) => {
    setActiveDesc(color);
    setActive(!active);
  };

  return (
    <>
      <span className="vehicleColor label">Farge</span>
      {data?.colors?.length <= 0 || !data ? (
        <p> Ingen farge tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleColor">
          {data?.colors?.map((color) => {
            const isSelected = selectedColorId === color?.varnishCode;
            const customPriceTag = color?.isRecommended
              ? 'Inkludert'
              : `${getPrice(color) >= 0 ? '+' : ''} ${priceFormatter(getPrice(color))}`;

            updatePreselection(color);
            return (
              <Card
                key={color?.id}
                id={color?.id}
                title={color?.name}
                bosSwatch={color?.image && color?.image?.[0]}
                customPriceTag={customPriceTag}
                type={CardType.BosCard}
                selected={isSelected}
                isDisabled={disabled}
                displayTag
                disableTag={!isDataAvailable(color)}
                alertMessage={{
                  typeOfAlert: MessagebarSeverityLevel.WARNING,
                  showIcon: false
                }}
                tagValue={color?.varnishCode}
                onTagClick={() => setCurrentData(color)}
                updateSelected={() => onChangeColor(color)}
              />
            );
          })}
          <VehicleSelectableDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}
          />
        </SwatchGrid>
      )}
    </>
  );
};
