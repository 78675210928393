import { IEquipmentTypes, VehicleEquipmentCategoryEnum } from 'next-common';
import React, { useEffect, useState } from 'react';
import { useDisableSkus, useEnableSkus, usePreSelectionEquipment, useSkusPriceUpdate } from '../../../../../common';
import { eventEmitter, Events, onListverkChangeInput, prepareImageObject, PreSelectedKeyEnum, priceFormatter } from '../../../../../utils';
import { IModelPackages } from '../../VehiclePickerPage';
import { ICommonRulesProps, IListverkChangeProps } from '../ResultGrid';
import { SwatchGrid } from '../ResultGrid.styled';
import { get, filter } from 'lodash';
import { VehicleSelectableDescription } from './VehicleSelectableDescription';
import { v4 as uuidv4 } from 'uuid';
import { Card } from '@next-components/card';
import { CardType } from '@next-components/common-types';
import { MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IVehicleListverk extends ICommonRulesProps {
  data: IModelPackages;
  disabled: boolean;
  selectedListverk: IEquipmentTypes;
  onListverkChange: (data: IListverkChangeProps) => void;
  updatePrices: () => void;
}

export const VehicleListverk = (props: IVehicleListverk) => {
  const { data, disabled, selectedListverk, onListverkChange } = props;
  const { isDisableSKUonChange, isDisableSKUonLoad } = useDisableSkus();
  const { isEnableSKUonChange } = useEnableSkus();
  const { updateKeyStatus, isEnablePreSelection, isSKUPreselected, isPreSelectionDone } = usePreSelectionEquipment();
  const priceUpdate = useSkusPriceUpdate();
  const [isPrepared, setIsPrepared] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [activeDesc, setActiveDesc] = useState<IEquipmentTypes>(null);

  useEffect(() => {
    eventEmitter.subscribe(Events.SKU_RULES_PREPARED, setIsPrepared);
    return () => {
      eventEmitter.unsubscribe(Events.SKU_RULES_PREPARED);
    };
  }, []);

  const onChangeListverk = (equipment: IEquipmentTypes) => {
    if (typeof props.setCurrentlySelectedSku === "function" && typeof props.setCurrentSelectionDetails === "function") {
      if (selectedListverk?.id !== equipment?.id) {
        props.setCurrentlySelectedSku(equipment?.sku);
      } else {
        props.setCurrentSelectionDetails({
          deSelectedSku: equipment?.sku,
        });
      }
    }
    onListverkChange(onListverkChangeInput(equipment, priceUpdate, selectedListverk?.id));
  }
  const isDisabled = (sku: string) => {
    const isDisable = isDisableSKUonChange(sku) ? true : isEnableSKUonChange(sku) ? false : isDisableSKUonLoad(sku);
    return isDisable;
  };

  const getPrice = (listverk: IEquipmentTypes) => {
    return priceUpdate?.has(listverk?.sku) ? Number(priceUpdate?.get(listverk?.sku)) : listverk?.priceExcludingVat;
  };

  useEffect(() => {
    //Update prices in cart && VAT Calculation
    if (selectedListverk?.id) {
      props.updatePrices();
    }
  }, [selectedListverk, priceUpdate]);

  const updatePreselection = (equipment: IEquipmentTypes) => {
    if (isSKUPreselected(equipment?.sku, PreSelectedKeyEnum.LISTVERK) && isEnablePreSelection && !isPreSelectionDone(PreSelectedKeyEnum.LISTVERK)) {
      onChangeListverk(equipment);
      updateKeyStatus(PreSelectedKeyEnum.LISTVERK)
    }
    return false;
  }

  const equipmentFilter: IEquipmentTypes[] = filter(data?.selectableEquipment ?? [], [
    'category',
    VehicleEquipmentCategoryEnum.Listverk,
  ]);
  useEffect(() => {
    const isDisable = isDisabled(selectedListverk?.sku);
    if (!isDisable && isPrepared && selectedListverk?.id) {
      if (selectedListverk) {
        onListverkChange(onListverkChangeInput(selectedListverk, priceUpdate, null, true));
      }
    }
  }, [selectedListverk, isDisabled(selectedListverk?.sku)]);

  const isDataAvailable = (equipment: IEquipmentTypes) => {
    return get(equipment, 'description');
  };

  const setCurrentData = (equipment: IEquipmentTypes) => {
    setActiveDesc(equipment);
    setActive(!active);
  };

  return (
    <>
      <span className="label">Listverk</span>
      {equipmentFilter?.length === 0 ? (
        <p> Ingen Listverk tilgjengelig </p>
      ) : (
        <SwatchGrid className="vehicleListverk">
          {equipmentFilter?.map((equipment) => {
            const isSelected = selectedListverk?.id === equipment?.id;
            const customPriceTag = `${getPrice(equipment) > 0 ? '+' : ''}  ${priceFormatter(
              getPrice(equipment),
            )}`;

            updatePreselection(equipment);
            return (
              <React.Fragment key={uuidv4()}>
                <Card
                  key={equipment?.id}
                  id={equipment?.id}
                  title={equipment?.name}
                  bosSwatch={prepareImageObject(equipment?.image?.[0])}
                  customPriceTag={customPriceTag}
                  type={CardType.BosCard}
                  selected={Boolean(isSelected)}
                  isDisabled={disabled}
                  alertMessage={{
                    typeOfAlert: MessagebarSeverityLevel.WARNING,
                    showIcon: false,
                  }}
                  displayTag
                  updateSelected={() => onChangeListverk(equipment)}
                  disableTag={!isDataAvailable(equipment)}
                  tagValue={equipment?.sku}
                  onTagClick={() => setCurrentData(equipment)}
                />
              </React.Fragment>
            );
          })}
          <VehicleSelectableDescription
            data={activeDesc}
            active={active}
            onClose={() => {
              setActive(!active);
            }}></VehicleSelectableDescription>
        </SwatchGrid>
      )}
    </>
  );
};
