import {
  CustomerType,
  EnumMapper,
  IBosCustomer,
  ICustomer,
  IOrder,
  mapBosApiToNextCustomerType,
  validateSSN,
} from 'next-common';

export const getOrderCustomerType = (order: IOrder): CustomerType => {
  const bosCustomerType = order?.customer?.bosCustomer?.customerType
    ? mapBosApiToNextCustomerType(order.customer.bosCustomer.customerType)
    : null;
  return bosCustomerType ?? order?.customer?.customerType ?? CustomerType.PRIVATE;
};

export const getCustomerName = (customer: IBosCustomer) => {
  if (customer?.customerType === CustomerType[CustomerType.BUSINESS]) {
    return customer?.organizationName;
  }
  if ([CustomerType[CustomerType.PRIVATE], CustomerType[CustomerType.EMPLOYEE]].includes(customer?.customerType)) {
    return `${customer?.firstName} ${customer?.lastName}`;
  }
  return null;
};

export const validateSsnOnCustomerType = (value, customer: ICustomer) => {
  if (
    // @ts-expect-error
    EnumMapper.mapEnumStringValueToEnumValue(customer, 'customerType', CustomerType).customerType ===
    CustomerType.BUSINESS
  ) {
    return !value || validateSSN(value);
  }
  return validateSSN(value);
};

export const isBusinessCustomer = (customerType: CustomerType) => customerType?.toString() === CustomerType[CustomerType.BUSINESS]

export const isIndependentDealer = (dealerRole: string) => dealerRole === "INDEPENDENT_DEALER";