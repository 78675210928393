import { bosColor, margin, padding } from '@next-components/common-styles';
import styled from 'styled-components';

export const RulesPopupWrapper = styled.div`
    &.rulesModel {
    .custom-modal {
      color: ${bosColor.black};
      ${padding[0].a};
      width: 47.625rem;
      background: ${bosColor.white};
      .modelHeader {
        h2 {
          font-size: 20px;
        }
        font-size: 20px;
        font-weight: 800;
        line-height: 36px;
        text-align: left;
        height: 4.25rem;
        ${padding[24].x};
        ${margin[0].b};
        border-bottom: 1px solid ${bosColor.neutralGrey};
      }
      .close-modal-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: 0;
        justify-content: center;
        align-items: center;
        background-color: ${bosColor.neutralGrey};
        border-radius: 62.4375rem;
        top: 1.125rem;
        right: 1.5rem;

        svg {
          width: 2rem;
          height: 2rem;
        }
        &:hover:not(:disabled) {
          background-color: ${bosColor.neutralGrey};
          border-color: ${bosColor.neutralPlus};
        }
      }
      .rulesDetailContainer {
        ${padding[24].a};
        ${padding[32].b};
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        .skuName{
          font-weight: 800;
        }
      }
        .rulesInfoContainer {
            &:not(.pt-0) {
              ${padding[32].t};
            }
            .rulesHeader {
                font-size: 16px;
                font-weight: 800;
                line-height: 28px;
                text-align: left;
            }
            .rulesDetail {
                display: flex;
                justify-content: space-between;
            }
            .rulesToRemove {
                ${padding[16].t}
            }
            .priceDetail {
                border-top: 1px solid ${bosColor.neutralPlus};
                ${margin[16].t};
                ${padding[16].y};
                font-weight: 800;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid ${bosColor.neutralPlus};
            }
        }
        .actionButtonContainer {
            ${padding[24].b}
            ${padding[24].x}
            display: flex;
            justify-content: flex-end;
            column-gap: 1rem;
            button {
                ${padding[12].y}
                ${padding[24].x}
            }
        }
    }
  }
`;