import React from 'react';
import { IBosVehicleOrderStatus, IOrder, OrderStatus } from 'next-common';
import {
  OrderStatusListWrapper,
  StatusMessageListItemBody,
  StatusMessageListItemHeader,
} from '../OrderStatusList.styled';
import { DateTimeBlock } from '../../../../sales-common/DateTimeBlock';
import { getAppendingNumericIdFromUrl } from '../../../../../../utils';
import { useOrder } from '../../../../../../common';
import { Button } from '@next-components/cta';
import { Tooltip } from '@next-components/tooltip';
import { Accordion } from '@next-components/accordion';
import { Icon, IconType } from '@next-components/bos-icon';
import { getDynamicAccordionIcon } from '../ReservedCarInfo';
import { CustomAlertPopup } from '../../../../../common-components/custom-DeletePopup';
import { IAlertPopup } from '../../../../sales-wizard';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';

export interface IIfsOrderStatusProps {
  order: IOrder;
  orderStatus: IBosVehicleOrderStatus;
  onStatusChange(status: OrderStatus): void;
  showCancelConfirmOrderModel?: boolean;
  isOrderCancellable?: boolean,
  isOrderCancelDisabled?: boolean,
  isMostRecent?: boolean;
  buttonText?: string,
  cancelOrderModalData?: IAlertPopup;
  openCancelConfirmModal?(): void;
}

export const IfsOrderStatus = (props: IIfsOrderStatusProps) => {
  const status = props.orderStatus?.status;
  const orderStatusType = props?.orderStatus?.orderStatus;
  const isCancelPendingOrder = props?.order?.status;

  //Refresh order from database and set status on screen
  const getOrderId = () => getAppendingNumericIdFromUrl('');
  const getViewPageProps = () => ({
    orderId: getOrderId(),
    order: null,
    vehicle: null,
    pimId: null,
  });
  const order: IOrder = useOrder(getViewPageProps());
  props.onStatusChange(order?.status); // set order status on screen

  const renderStatusMessageListItemHeader = () => (
    <>
      <div className={`reserveHeader`}>
        {
          orderStatusType !== 'CANCEL' ? 
            <h3>{status === 'SUCCESS' ? 'IFS ordre bekreftet' : 'IFS Ordre'}</h3>
          : 
            <h3>{status === 'SUCCESS' ? 'Annullering av ordre i IFS utført' : 'Annullering av ordre i IFS feilet'}</h3>
        }
      </div>
      <div className="ifsDate">
        <DateTimeBlock dateTime={props.orderStatus?.timestamp} shortDate />
      </div>
    </>
  );

  const renderStatusMessageListItemBody = () => (
    <div className="leftContainer">
      <div className="bodyItem">
        <span className="label">IFS ordrenummer</span>
        <p className="value">{props.orderStatus?.orderNumber ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">IFS kundenummer</span>
        <p className="value">{props.orderStatus?.ifsCustomerId ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">BOS API kundenr.</span>
        <Tooltip text={props.orderStatus?.customerId}>
          <p className="value">{props.orderStatus?.customerId ?? '-'}</p>
        </Tooltip>
      </div>
      <div className="bodyItem">
        <span className="label">Status</span>
        <p className="value">{props.orderStatus?.status ?? '-'}</p>
      </div>
      <div className="bodyItem">
        <span className="label">Statusmelding</span>
        <p className="value">{props.orderStatus?.statusMessage ?? '-'}</p>
      </div>
    </div>
  );

  return (
    <OrderStatusListWrapper>
        {
          // @ts-expect-error
          (isCancelPendingOrder === OrderStatus[OrderStatus.CANCEL_ORDER_PENDING] && props.isMostRecent) &&
          <div className="notReceived">
            <Messagebar
              messageBoxInfo
              alertIcon={IconType.CircleInfo}
              severityLevel={MessagebarSeverityLevel.INFORMATION}
              title="Annulering av ordre i IFS pågår"
            />
          </div>
        }
          <div className="messageWrapper">
            <Accordion
              isOpen={props.orderStatus?.status === 'FAILURE'}
              nextAccordion
              className={props?.isMostRecent ? props.orderStatus?.status?.toLowerCase() ?? '' : ''}
              labelContent={
                <div className="accordionLabelContainer">
                  <div className={'iconContainer'}>
                    <Icon
                      icon={
                        props?.isMostRecent
                          ? getDynamicAccordionIcon(props.orderStatus?.status?.toLowerCase())
                          : IconType.NewNextHistory
                      }
                    />
                  </div>
                  <StatusMessageListItemHeader>{renderStatusMessageListItemHeader()}</StatusMessageListItemHeader>
                </div>
              }
            >
              <StatusMessageListItemBody className="singleColumn">
                {renderStatusMessageListItemBody()}
                {(props.isOrderCancellable && props.isMostRecent) &&
                  <div className="rightContainer">
                    <div className="bodyItem">
                      <div>
                        <Button
                          colorVariant="secondary"
                          disabled={props.isOrderCancelDisabled}
                          onClick={() => props.openCancelConfirmModal()}>
                          {props.buttonText}
                        </Button>
                      </div>
                    </div>
                  </div>
                }
              </StatusMessageListItemBody>
            </Accordion>

            {props.showCancelConfirmOrderModel && (
              <CustomAlertPopup
                isOpen={props.showCancelConfirmOrderModel}
                onCloseClick={props?.openCancelConfirmModal}
                deleteOrderData={props.cancelOrderModalData}
                className="deleteAlert"
              />
            )}
          </div>
    </OrderStatusListWrapper>
  );
};
