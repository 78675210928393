import React, { useEffect } from 'react';
import { PageSteps } from '../../sales-common/NewHeader';
import { Button } from '@next-components/button';
import { Tooltip, TooltipPositionsEnum } from '@next-components/tooltip';
import { IOrder } from 'next-common';
import { OtherDealerVehicleReserveOrSignValidationMsg, VehicleContext } from '../../../../utils';
import { IStepMenuData, menuTitles, VarebilWizardSteps } from './SalesWizardMenu';
import { MissingItem } from '../../sales-common/DynamicCols/MissingItem';
import { ICentralisedProperties, isDemoOrder, isStockOrder } from '../../../../common';
import { Icon, IconType } from '@next-components/bos-icon';
import { ValidationErrorCode, getErrorMessageForError } from '../../../notifications';
import { IPcodeValidation } from '../SalesWizard';
const _ = require('lodash');

export interface ISalesWizardButtonMenu extends ICentralisedProperties {
  order?: IOrder;
  calculationId?: string;
  activeStep: number;
  isUnavailablePimData: boolean;
  orderId: string;
  menuLoading: boolean;
  isStockSaleOrder?: boolean;
  stepData?: IStepMenuData;
  allStepData?: IStepMenuData[];
  isVehicleValidForReserveOrSign?: boolean;
  vehicleAvailabilityCode?: string;
  bottomButton?: boolean;
  onClickSigning?(stepData?: IStepMenuData): void;
  onClick(stepData?: IStepMenuData | any): void;
  isChange?: boolean
  isFactoryOrder?: boolean;
  isVarebilEnabled: boolean;
  disablePCodePage?: boolean;
  invalidPcodesValidation?: IPcodeValidation;
}

const getShowButton = (isVarebilEnabled) => {
  const showButton = [
    PageSteps.START,
    PageSteps.PACKAGES,
    PageSteps.FINANACE,
  ];
  if (isVarebilEnabled) {
    showButton.splice(1, 0, PageSteps.IMPORTEROPTIONS);
  }
  return showButton;
}

export const SalesWizardButtonMenu = (props: ISalesWizardButtonMenu) => {
  const [missingItems, setMissingItems] = React.useState<string[]>([]);
  const [validationText, setValidationText] = React.useState<string>('');
  const { getVehicleData } = React.useContext(VehicleContext) ?? {};
  const isOrderStockType = isStockOrder(props?.order?.lead?.orderType);
  const isOrderDemoType = isDemoOrder(props?.order?.lead?.orderType);
  const showButton = getShowButton(props.isVarebilEnabled);
  const activeRoute = props.allStepData?.find((route) => route?.routeIsActive)?.routeName;
  const invalidPcodes = props?.invalidPcodesValidation;
  useEffect(() => {
    const items: string[] = [];
    if (!props.order?.customer?.bosCustomer) items?.push(MissingItem.Customer);
    if (!props.order?.vehicles || props.order?.vehicles?.length < 1) items?.push(MissingItem.Car);
    if (!props.calculationId && !props?.order?.chosenOffer?.id) items?.push(MissingItem.Offer);
    setMissingItems(items);
  }, [props.order, props.calculationId]);

  useEffect(() => {
    if(isOrderStockType){
      if(!props?.order?.chosenOffer?.id) {
        setValidationText(getErrorMessageForError(ValidationErrorCode.SIGNING_VEHICLE_VALIDATION))
      }
    } else if(!props?.isVehicleValidForReserveOrSign) {
        setValidationText(OtherDealerVehicleReserveOrSignValidationMsg(props.vehicleAvailabilityCode))
      } else if (invalidPcodes?.errorMessage) {
        setValidationText(invalidPcodes?.errorMessage)
      } else if(missingItems?.length) {
        setValidationText(`Velg ${missingItems?.join(' og ')} før du kan signere`)
      } else 
        setValidationText(null)
  }, [missingItems,  props.isVehicleValidForReserveOrSign]);

  const isSelectedVehicleAvailable = () => {
    if (props.isStockSaleOrder) {
      return true;
    }
    if (props?.orderId) {
      return getVehicleData()?.modelId && getVehicleData()?.variantId;
    }
    return false;
  };

  const getClassName = (): string =>
    (activeRoute === VarebilWizardSteps[VarebilWizardSteps.Finance] && missingItems?.length) ||
    (props.activeStep === PageSteps.START && !isSelectedVehicleAvailable())
      ? 'main-sign-btn'
      : 'main-btn';

  const getValueAtIndex = (menuTitles, routeName: string) => {
    if(props.activeStep === 0 && props.disablePCodePage) {
      return menuTitles.VehicleAccessories;
    } else {
      return menuTitles[routeName];
    }
  };

  const nextStepNameIndex = props.activeStep + 1;
  const route = props?.allStepData?.[nextStepNameIndex];
  const nextStepName = getValueAtIndex(menuTitles, route?.routeName);

  return (
    <div className="btn-grp">
      {showButton?.includes(props?.activeStep) && (
        <div className={`${getClassName()} nextButton`}>
          {activeRoute === VarebilWizardSteps[VarebilWizardSteps.Finance] || isOrderStockType || (activeRoute === VarebilWizardSteps[VarebilWizardSteps.VehicleAccessories] && isOrderDemoType) ? (
            <Tooltip
              text={validationText}
              enabledCondition={isOrderStockType ? !props?.order?.chosenOffer?.id : missingItems?.length > 0 || !props.isVehicleValidForReserveOrSign || invalidPcodes?.isInvalidPcodes}
              className={`small ${props.bottomButton ? 'bottomButton' : ''}`}
              tooltipPosition={props?.bottomButton ? TooltipPositionsEnum.TOP : TooltipPositionsEnum.BOTTOM}
              >
                <Button
                colorVariant={"secondary"}
                isLoading={!props?.order}
                disabled={isOrderStockType ? (props?.isChange && props?.isFactoryOrder) ? true : !props?.order?.chosenOffer?.id :
                  props?.isUnavailablePimData || missingItems?.length > 0 || !props.isVehicleValidForReserveOrSign || invalidPcodes?.isInvalidPcodes}                
                onClick={() => props?.onClickSigning(props.stepData)}
                newNextButton
              >
                Neste steg: Signering
                <Icon icon={IconType.NewNextArrowRight} />
              </Button>
            </Tooltip>
          ) : (
            <Button
              colorVariant={"secondary"}
              disabled={props?.isUnavailablePimData}
              isLoading={!props?.order ? true : props?.menuLoading}
              onClick={props?.onClick}
              newNextButton
              >
              {`Neste steg: ${nextStepName}`}
              <Icon icon={IconType.NewNextArrowRight} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
