import { IconType } from '@next-components/bos-icon';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IOrder } from 'next-common';
import { WarningStyled } from './Warning.styled';
import { IPCodeWarnFlags } from '../../common';
import { isOfferCase } from '../../utils';

export interface IPCodeInValiidDateWarningProps {
  order: IOrder;
  warning: IPCodeWarnFlags;
}

export const PCodeValidationWarning = (props: IPCodeInValiidDateWarningProps) => {
  const getInvalidPCodeWarningMessage = () => {
    if (!isOfferCase(props.order)) {
      return `Kontrakten inneholder opsjoner som ikke lenger er tilgjengelige fra importør  Du må oppdatere kontrakten og innhente ny signatur fra kunde`;
    } else {
      return `Tilbudet inneholder opsjoner som ikke lenger er tilgjengelige fra importør. Du må fjerne opsjonene fra tilbudet og informere kunde`;
    }
  };

  const getVehicleStatusMismatchWarningMessage = () => {
    if (!isOfferCase(props.order)) {
      return `Konfigurasjonen er endret på kjøretøyet i etterkant av at kontrakten ble inngått, endringen er sannsynligvis utført av ordrekontoret. Du må oppdatere kontrakten og innhente ny signatur fra kunde`;
    } else {
      return `Konfigurasjonen er endret på kjøretøyet i etterkant av at tilbudet ble gitt, endringen er sannsynligvis utført av ordrekontoret. Du må informere kunde og oppdatere tilbudet`;
    }
  };

  const getMasterAPIMismatchWarningMessage = () => {
    if (!isOfferCase(props.order)) {
      return `Kontrakten inneholder en opsjon som ikke lenger kan tilbys, du må oppdatere kontrakten og innhente ny signatur fra kunde`;
    } else {
      return `Tilbudet inneholder en opsjon som ikke lenger kan tilbys, du må informere kunde og oppdatere tilbudet`;
    }
  };

  const priceMismatchWarningMessage = () => {
    return 'Fant prismisforhold for lokale alternativer. Prisene vil bli oppdatert når neste knapp trykkes.';
  };

  return (
    <>
      {props.warning?.showDateWarn || props.warning?.showTTCodeWarn ? (
        <WarningStyled>
          <Messagebar
            messageBoxInfo
            alertIcon={IconType.Warning}
            severityLevel={MessagebarSeverityLevel.WARNING}
            title={<p dangerouslySetInnerHTML={{ __html: getInvalidPCodeWarningMessage() }} />}
          />
        </WarningStyled>
      ) : null}

      {props.warning?.showMasterAPIMismatchWarn ? (
        <WarningStyled>
          <Messagebar
            messageBoxInfo
            alertIcon={IconType.Warning}
            severityLevel={MessagebarSeverityLevel.WARNING}
            title={<p dangerouslySetInnerHTML={{ __html: getMasterAPIMismatchWarningMessage() }} />}
          />{' '}
        </WarningStyled>
      ) : null}

      {props.warning?.showVehicleStatusMismatchWarn ? (
        <WarningStyled>
          <Messagebar
            messageBoxInfo
            alertIcon={IconType.Warning}
            severityLevel={MessagebarSeverityLevel.WARNING}
            title={<p dangerouslySetInnerHTML={{ __html: getVehicleStatusMismatchWarningMessage() }} />}
          />{' '}
        </WarningStyled>
      ) : null}

      {props.warning?.isPriceMismatch ? (
        <WarningStyled>
          <Messagebar
            messageBoxInfo
            alertIcon={IconType.Warning}
            severityLevel={MessagebarSeverityLevel.WARNING}
            title={<p dangerouslySetInnerHTML={{ __html: priceMismatchWarningMessage() }} />}
          />{' '}
        </WarningStyled>
      ) : null}
    </>
  );
};
