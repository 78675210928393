import { Icon, IconType } from '@next-components/bos-icon';
import { Button } from '@next-components/cta';
import { CustomModal } from '@next-components/custom-modal';
import { useEffect, useState } from 'react';
import { CustomModalWrapper } from '../../layout/CustomModalWrapper.styled';
import { ModalActions } from '../../sales/sales-common/modal.styled';

export interface IInvalidTTCodePopup {
  isOpen: boolean;
  optionTTCode: string;
  handleClose: () => void;
}

export const InvalidTTCodePopup = (props: IInvalidTTCodePopup) => {
  const [data, setData] = useState(null);

  const handleModalClose = () => {
    props.handleClose();
  };

  useEffect(() => {
    if (props.optionTTCode) {
      setData(JSON.parse(props.optionTTCode));
    }
  }, [props.optionTTCode]);

  return (
    <CustomModalWrapper className="backdrop smModal pCodeModel" active={props.isOpen}>
      <CustomModal onCloseClick={handleModalClose} active={props.isOpen} isModelCloseAllowedOutside={false}>
        <header>
          <Icon icon={IconType.Warning} color="#edbb5a" />

          <h3>
            {data?.selected
              ? 'Det nåværende Opsjoner tillater ikke fravalg'
              : 'De gjeldende alternativene tillater ikke valg'}
          </h3>
        </header>
        <ModalActions items={2} className="commentBoxModal">
          <div className="gridListItem">
            <Button onClick={handleModalClose}>OK</Button>
          </div>
        </ModalActions>
      </CustomModal>
    </CustomModalWrapper>
  );
};
