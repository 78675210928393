import { formatNumberInput, InputType } from '@next-components/common-types';
import { IMarginInput } from 'next-common';

interface IMarginDetailsProps {
  margin: IMarginInput;
}

export const MarginCartDetails = (props: IMarginDetailsProps) => {
  const getCalculatedMarginPercentage = (): string => {
    let marginValue = props.margin?.marginPercentage || 0;
    if (marginValue >= 0 && marginValue < 1) {
      marginValue = props.margin?.marginPercentage * 100;
    } else {
      marginValue = props.margin?.marginPercentage / 100;
    }
    return formatNumberInput(marginValue?.toString(), InputType.FLOAT, 1).formatted;
  };

  const formattedMarginPercentage = props.margin?.marginPercentage && getCalculatedMarginPercentage();
  const formattedMarginValue =
    props.margin?.marginValue && formatNumberInput(props.margin?.marginValue.toString(), InputType.INTEGER).formatted;
  const formattedCampaignDiscount =
    props.margin?.campaignDiscount &&
    formatNumberInput(props.margin?.campaignDiscount.toString(), InputType.INTEGER).formatted;
  const formattedCampaignImporter =
    props.margin?.campaignImporter &&
    formatNumberInput(props.margin?.campaignImporter.toString(), InputType.INTEGER).formatted;

  return (
    <div className="valueContainer">
      <div id="campaignDiscountOverview">
        <p className="label">Rabatt</p>
        <p className="percentage label"></p>
        <p className="valueta label">{formattedCampaignDiscount ?? 0} kr</p>
      </div>

      <div id="campaignImporter">
        <p className="label">Importørstøtte</p>
        <p className="percentage label"></p>
        <p className="valueta label">{formattedCampaignImporter ?? 0} kr</p>
      </div>

      <div id="totalMarginOverview">
        <p className="label">Total margin</p>
        <p className="percentage label">{formattedMarginPercentage ?? 0} %</p>
        <p className="valueta label">{formattedMarginValue ?? 0} kr</p>
      </div>
    </div>
  );
};
