import { ApolloClient, useApolloClient } from '@apollo/client';
import { IOrderEvent } from 'next-common';
import { ERROR_POLICY } from '../constants';
import { useEffect, useState } from 'react';
import { findEventsOnOrderParsed } from '../graphql';

const queryForOrderEvents = async (client: ApolloClient<object>, orderId: string): Promise<IOrderEvent[]> => {
  if (!orderId) return null;
  try {
    const { data } = await client.query({
      query: findEventsOnOrderParsed,
      variables: {
        id: orderId,
      },
      errorPolicy: ERROR_POLICY,
      fetchPolicy: 'no-cache',
    });
    return data?.findEventsOnOrder?.events ?? [];
  } catch (error: any) {
    const errorMsg = `Could not get the events for order: "${orderId}"`;
    console.error(errorMsg, error);
    if (typeof error?.statusCode === 'number' && error.statusCode === 200) {
      throw new Error(errorMsg);
    }
  }
};

const getEvents = (
  orderId: string,
  client: ApolloClient<object>,
  onSuccess: (value: IOrderEvent[]) => Promise<void>,
): void => {
  queryForOrderEvents(client, orderId)
    .then((events) => {
      onSuccess(events);
    })
    .catch((err) => {
      const errorMsg = `Could not get the events for order id: "${orderId}"`;
      // tslint:disable-next-line:no-console
      console.error(errorMsg, err);
    });
};

export const useEvents = (orderId?: string): IOrderEvent[] => {
  const client = useApolloClient();
  const [events, setEvents] = useState<IOrderEvent[]>([]);

  const onSuccess = async (events?: IOrderEvent[]) => {
    setEvents(events);
  };

  useEffect(() => {
    getEvents(orderId, client, onSuccess);
  }, [client, orderId]);

  return events;
};
