import styled from 'styled-components';
import { bosColor, margin, typography } from '@next-components/common-styles';

export const BrukskodeModalWrapper = styled.div`
  .custom-modal {
    border-radius: 0.5rem;
    width: 37.625rem;
    overflow: visible;
    .inner-container {
      padding: 0;
    }
    .close-modal-button {
      height: 2rem;
      display: flex;
      height: 2rem;
      padding: 0;
      justify-content: center;
      align-items: center;
      background-color: ${bosColor.lightGrey};
      border-radius: 62.4375rem;
      top: 1rem;
      right: 1.5rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
      &:hover:not(:disabled) {
        background-color: ${bosColor.neutralGrey};
        border-color: ${bosColor.neutralPlus};
      }
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${bosColor.neutralGrey};
`;

export const BrukskodeModalHeader = styled.div`
  ${margin[0].a};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25.1875rem;
  align-self: stretch;
  border-bottom: 1px solid ${bosColor.neutralGrey};
  background: ${bosColor.white};
  gap: 2rem;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0;

  .headerText {
    font-size: 1.25rem;
    color: ${bosColor.black};
    font-style: normal;
    font-weight: ${typography.weight.extrabold};
    line-height: 150%;
  }
`;

export const BrukskodeModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 1.5rem;
  padding: 1.5rem;

  .brukskodeChangeAlert {
    .iconContainer {
      svg {
        padding: 2px;
        margin-top: 2px;
      }
    }
    .message-bar-body {
      .selectedCode {
        font-weight: ${typography.weight.extrabold};
      }
    }
  }
`;

export const SelectedBrukskode = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  width: 100%;

  .title {
    flex: 1 0 0;
    line-height: 175%;
    font-weight: ${typography.weight.semibold};
  }
  .code {
    border-radius: 62.4375rem;
    background: ${bosColor.neutralGrey};
    display: flex;
    height: 1.75rem;
    padding: 0.25rem 0.75rem;
    align-items: center;

    color: ${bosColor.black};
    font-size: 0.875rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 170%;
  }
`;

export const NewUserBrukskode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
  width: 100%;

  .dropdown-container {
    width: 100%;
    .brukskodeOptions {
      .react-select__control {
        .react-select__single-value {
          .distanceLabel {
            display: none;
          }
        }
      }
    }
  }
`;
export const BurkskodeOptionContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  width: ${({ isSelected }) => (isSelected ? 'calc(100% - 2rem)' : '100%')};

  span {
    margin: 0;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-weight: ${typography.weight.semibold};
    line-height: 175%;
    flex: 1 0 0;

    &.optionLabel.selected {
      font-weight: ${typography.weight.extrabold} !important;
    }
    &.distanceLabel {
      font-size: 0.875rem !important;
      text-align: right;
    }
  }
`;
export const BrukskodeActions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding-top: 1rem;

  button.brukskodeAction {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
