import { get } from 'lodash';
import React, { useEffect } from 'react';
import {
  ERROR_POLICY,
  IVehicleChargeStatus,
  vehicleOrderStatusQueryParsed,
} from '../../../../../common';
import {
  IBosVehicleOrderStatus,
  IConfiguration,
  IOrder,
  IReserveDetails,
  OrderStatus,
  isStockSaleOrderToBeProcessed
} from 'next-common';
import { useLazyQuery } from '@apollo/client';
import { hasPendingConfirmation, IfsOrderStatus, PendingConfirmation } from './IfsOrder';
import { hasPendingReservation, PendingReservation, ReservedCarInfo } from './ReservedCarInfo';
import { OrderStatusListStyled, StatusMessageEventListWrapper } from './OrderStatusList.styled';
import { LoadingSpinner } from '@next-components/loading-spinner';
import { OtherDealerVehicleReserveOrSignValidationMsg, isOrderCancellable, isOrderEditable } from '../../../../../utils';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { ToggleSwitch } from '@next-components/toggle-switch';
import { IconType } from '@next-components/bos-icon';
import { IAlertPopup } from '../../../sales-wizard';
import { OneOffTaxChangeWarning } from '../../../../notifications';

export interface IOrderStatusListProps {
  order: IOrder;
  configuration: IConfiguration;
  isVehicleValidForReserveOrSign?: boolean;
  vehicleAvailabilityCode?: string;
  isSelectedVehicleUnavailable?: boolean;
  showCancelConfirmOrderModel?:boolean;
  cancelOrderModalData?:IAlertPopup;
  vehicleChargeStatus: IVehicleChargeStatus;
  onStatusChange(status: OrderStatus): void;
  onChangeReservationStatus(details: IReserveDetails): void;
  setOrderEditButtonDisabled?(status: boolean): void;
  setPageLoading?(param: boolean): void;
  openCancelConfirmModal?():void;
}

const emptyOrderStatus = {
  orderType: '',
  customerId: '',
  dealerId: '',
  orderNumber: '',
  repurchaseContractId: '',
  status: '',
  statusMessage: '',
  vehicleId: '',
  vehicleOrderId: '',
};

export const OrderStatusList = (props: IOrderStatusListProps) => {
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [statusList, setStatusList] = React.useState<IBosVehicleOrderStatus[]>([]);
  const [last, setLast] = React.useState<IBosVehicleOrderStatus>(null);
  const [pollInterval, setPollInterval] = React.useState(null);
  const [isStockSaleOrder, setIsStockSaleOrder] = React.useState(false);

  const [getStatus, { loading }] = useLazyQuery(vehicleOrderStatusQueryParsed, {
    errorPolicy: ERROR_POLICY,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      vehicleOrderId: props.order?.id,
    },
    onCompleted: (data) => {
      const { findVehicleOrderStatusById }: { findVehicleOrderStatusById: IBosVehicleOrderStatus[] } = data;
      const list: IBosVehicleOrderStatus[] =
        findVehicleOrderStatusById?.length > 0 ? [...findVehicleOrderStatusById] : [];
      const newest = list && list.length > 0 ? list[0] : null;
      setStatusList(list);
      props?.setPageLoading(false);
      setLast(newest);
      if (!isOrderEditable(props.order, props.configuration)) {
        clearInterval(pollInterval);
      }
      if (
        hasPendingConfirmation(props.order, newest, props.configuration) ||
        hasPendingReservation(props.order, newest)
      ) {
        if (!isOrderEditable(props.order, props.configuration)) {
          const statusIntervalId = setInterval(() => getStatus(), 15000);
          setPollInterval(statusIntervalId);
        }
        props.setOrderEditButtonDisabled(true);
      } else {
        props.setOrderEditButtonDisabled(false);
      }
    },
  });

  useEffect(() => {
    if (!props.order?.id) return;
    getStatus();
    props?.setPageLoading(true);
    const isStockSaleOrder = isStockSaleOrderToBeProcessed(
      props?.configuration,
      props?.order?.source,
      props?.order?.lead?.serialNumber,
      props?.order?.lead?.make,
    );
    setIsStockSaleOrder(isStockSaleOrder);
  }, [props.order]);

  const onLoading = () => {
    setUpdating(true);
  };

  const onReserveCancelled = () => {
    setUpdating(false);
  };

  const buttonText = (order: IOrder) => {
    if(
      get(order, 'status', null) === OrderStatus[OrderStatus?.CONFIRMED] || 
      get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_PENDING] || 
      get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_SUCCESS]
    ){
      return 'Annuller ordre'
    } else if (get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_FAILED]) {
      return 'Annuller ordre på nytt'
    }
  };

  const buttonDisable = (order: IOrder) => {
    return (get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_PENDING] || get(order, 'status', null) === OrderStatus[OrderStatus?.CANCEL_ORDER_SUCCESS]); 
  }
  
  const OrderStatusListItem = (orderStatus: IBosVehicleOrderStatus, isMostRecent: boolean) => {
    switch (orderStatus.orderType) {
      case 'ORDER':
      case 'STOCK':
      case 'DEMO':
        return (
          <IfsOrderStatus
            order={props.order}
            key={orderStatus.timestamp}
            orderStatus={orderStatus}
            showCancelConfirmOrderModel={props.showCancelConfirmOrderModel}
            onStatusChange={props.onStatusChange}
            isOrderCancellable={isOrderCancellable(props.order, props.configuration)}
            isMostRecent={isMostRecent}
            buttonText={buttonText(props.order)}
            isOrderCancelDisabled={buttonDisable(props.order)}
            cancelOrderModalData={props.cancelOrderModalData}
            openCancelConfirmModal={props.openCancelConfirmModal}
          />
        );
      case 'INITIAL':
      case 'RESERVE':
      case 'CANCEL_RESERVE':
        return (
          <ReservedCarInfo
            key={orderStatus.timestamp}
            reservationStatus={orderStatus}
            order={props.order}
            isMostRecent={isMostRecent}
            onLoading={onLoading}
            onReserveCancelled={onReserveCancelled}
            onStatusChange={props.onStatusChange}
            onChangeReservationStatus={props.onChangeReservationStatus}
            configuration={props.configuration}
            isStockSaleOrder={isStockSaleOrder}
            isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign && !props.isSelectedVehicleUnavailable}
          />
        );
      case '':
        return (
          <div className="notReceived">
            <Messagebar
              messageBoxInfo
              alertIcon={IconType.CircleInfo}
              severityLevel={MessagebarSeverityLevel.INFORMATION}
              title="IFS har ikke mottatt ordren."
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <OrderStatusListStyled className={updating ? 'showLoader' : ''}>
      {updating ? (
        <LoadingSpinner isModal />
      ) : (
        <>
          <div className="topWrapper">
            <h3 className="title">Siste status fra IFS</h3>
            <ToggleSwitch
              label={`Vis tidligere statusmeldinger (${statusList?.length > 1 ? statusList?.length : 0})`}
              checked={showAll}
              toggleHandler={() => setShowAll(!showAll)}
              disabled={!(statusList && statusList.length > 1)}
            />
          </div>
          {loading && <LoadingSpinner />}
          {!isOrderEditable(props.order, props.configuration) &&
            hasPendingConfirmation(props.order, last, props.configuration) && (
              <PendingConfirmation reservationStatus={props?.order} />
            )}
          
          {!isOrderEditable(props.order, props.configuration) && hasPendingReservation(props.order, last) && (
            <PendingReservation vehicleReserveStatus={props.order?.reserveDetails?.vehicleReserveStatus} />
          )}
          {!loading && (!statusList || statusList.length < 1) && OrderStatusListItem(emptyOrderStatus, false)}
          {last && OrderStatusListItem(last, true)}
          {showAll && (
            <StatusMessageEventListWrapper className="StatusMessageListWrapper">
              <h3 className="title">Tidligere statusmeldinger</h3>
              {statusList
                ?.filter((item) => item !== last)
                .map((orderStatus: IBosVehicleOrderStatus, index: number) => (
                  <>
                    {index === statusList?.length - 2 ? (
                      <>
                        {OrderStatusListItem(orderStatus, false)}
                        {OrderStatusListItem({ ...emptyOrderStatus, orderType: 'INITIAL' }, false)}
                      </>
                    ) : (
                      OrderStatusListItem(orderStatus, false)
                    )}
                  </>
                ))}
            </StatusMessageEventListWrapper>
          )}
          {
            !props.isVehicleValidForReserveOrSign ?
            <div className="notAvailable">
              <Messagebar
                messageBoxInfo
                alertIcon={IconType.Warning}
                severityLevel={MessagebarSeverityLevel.WARNING}
                title={OtherDealerVehicleReserveOrSignValidationMsg(props.vehicleAvailabilityCode)}
              />
            </div> : ''
          }
          {
            props.vehicleChargeStatus?.isTaxChange ?
            <div>
              <OneOffTaxChangeWarning vehicleChargeStatus={props.vehicleChargeStatus} order={props.order} />
            </div> : ''
          }
        </>
      )}
    </OrderStatusListStyled>
  );
};
