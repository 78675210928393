import React, { useEffect, useState } from 'react';
import { ApolloClient } from '@apollo/client';
import { ICartPrices } from '@next-components/cart';
import { bosColor } from '@next-components/common-styles';
import { formatNumber } from '@next-components/common-types';
import { SmallLink } from '@next-components/link';
import { CustomerType, INextArticle, IOrder, IVehicleConfiguration } from 'next-common';
import { IGetServiceContract } from 'sales-common';
import { ICentralisedProperties, IVehiclePageProps, useDisableScroll } from '../../../../../common';
import { ModalState } from '../HeaderEnum';
import { PageSteps } from '../NewHeaderWrapper';
import { TradeInModal } from './TradeInModal';
import { IStepMenuData } from '../../../sales-wizard/sales-wizard-menu';
import { isSigningPage } from '../../../../../utils';

const DefaultSign = () => <>-</>;

interface ITradeInProps {
  order?: IOrder;
  activeStep?: number;
  showNoData?: boolean;
  allStepData?: IStepMenuData[]
}

export interface INewHeaderSection extends ITradeInProps, IVehiclePageProps, ICentralisedProperties {
  children?: React.ReactNode;
  showNoData: boolean;
  displayPrices: boolean;
  vehicle?: IVehicleConfiguration;
  showCustomerPopup?: boolean;
  serialNo?: string;
  class?: string;
  serviceContract?: IGetServiceContract;
  articles?: INextArticle[];
  vatPrices?: ICartPrices;
  client?: ApolloClient<object>;
  raiseNotification?(notification: JSX.Element): void;
  onCustomerType(customerType: CustomerType): void;
  setShowCustomerPopup?(value: boolean): void;
}

const TradeInInfoBox = (props: ITradeInProps) => {
  const tradeInVehicles = props?.order?.tradeInVehicles;
  const customer = props?.order?.customer;
  const totalCapital =
    tradeInVehicles?.map((tradeInVehicle) => tradeInVehicle?.capital)?.reduce((total, current) => total + current, 0) ||
    0;
  const isBusiness = customer?.bosCustomer?.customerType === CustomerType[CustomerType.BUSINESS];
  const [modal, setModal] = useState(ModalState.NONE);
  useDisableScroll(modal === ModalState.TRADEIN);

  return (
    <>
      <div>
        <label className="tradeInLabel">
          {tradeInVehicles?.length > 1 ? `Innbytte (${tradeInVehicles.length} biler) ` : 'Innbyttebil '}
          {`${isBusiness ? '(ekskl.' : '(inkl.'} mva.)`}
        </label>
        {isSigningPage(props.allStepData) ? (
          <div className="bill-edit">
            <h4 className="highlight-text">
              {tradeInVehicles?.length > 0 ? `${formatNumber(totalCapital)} kr` : '0 kr'}
            </h4>
          </div>
        ) : props.showNoData ? (
          <DefaultSign />
        ) : (
          <div className="bill-edit">
            <div className="link-btn">
              <SmallLink
                className={tradeInVehicles?.length > 0 ? 'bill-plusbtn' : 'bill-plusbtn bill-editbtn-not'}
                onLinkClick={() => setModal(ModalState.TRADEIN)}
                text={tradeInVehicles?.length > 0 ? `${formatNumber(totalCapital)} kr` : 'Legg til'}
                color={bosColor.white}
                iconColor={bosColor.white}
                iconLeft={tradeInVehicles?.length > 0 ? 'edit' : 'plus'}
              />
            </div>
          </div>
        )}
      </div>
      {modal === ModalState.TRADEIN && <TradeInModal order={props.order} modal={modal} setModal={setModal} />}
    </>
  );
};

export const SalesAndInfoSection = (props: INewHeaderSection) => {
  const [updatedOrder, setUpdatedOrder] = useState<IOrder>(null);
  useEffect(() => {
    setUpdatedOrder(props.order);
  }, [props.order]);
  useEffect(() => {
    if (!updatedOrder?.tradeInVehicles) {
      //initialize trade if with empty array if trade is not present
      setUpdatedOrder(updatedOrder=> ({
        ...updatedOrder,
        tradeInVehicles: [],
      }));
    }
  }, [updatedOrder]);
  return (
    <>
      <div className="bill-details">
        {updatedOrder?.tradeInVehicles && !updatedOrder?.isAgent && props?.activeStep !== PageSteps.START && (
          <TradeInInfoBox activeStep={props.activeStep} order={updatedOrder} showNoData={props?.showNoData} allStepData={props.allStepData} />
        )}
      </div>
    </>
  );
};
