import { IconType } from '@next-components/bos-icon';
import { typography } from '@next-components/common-styles';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { IOrder } from 'next-common';
import { formatNumber } from 'sales-common';
import { IVehicleChargeStatus } from '../../common';
import { WarningStyled } from './Warning.styled';
import { isOfferCase } from '../../utils';

export interface IOneOffTaxWarningProps {
  vehicleChargeStatus: IVehicleChargeStatus;
  order: IOrder;
}

export const OneOffTaxChangeWarning = (props: IOneOffTaxWarningProps) => {
  const formatPrice = (price: number) => {
    return `<span style="font-weight: ${typography.weight.extrabold};">${formatNumber(price)} kr</span>`;
  };

  const getWarningMessage = () => {
    const { isTaxChange, isTaxIncreased, UpdatedOneOffTax, ExistingOneOffTax } = props.vehicleChargeStatus || { isTaxChange: false, isTaxIncreased: false, UpdatedOneOffTax: 0, ExistingOneOffTax: 0 };

    if (!isTaxChange) return '';
    if (!isOfferCase(props.order)) {
      //CASE: ORDER
      if (isTaxIncreased) {
        return `Engangsavgift har økt med ${formatPrice(
          UpdatedOneOffTax - ExistingOneOffTax,
        )} og er endret fra ${formatPrice(ExistingOneOffTax)} til ${formatPrice(
          UpdatedOneOffTax,
        )}, du må lage en ny kontrakt og innhente signatur fra kunde. Finanstilbudene blir rekalkulert i Next når du åpner gjenopptar tilbudet. Eventuelle søknader i finansportalen må oppdateres.`;
      } else {
        return `Engangsavgift er redusert med ${formatPrice(
          ExistingOneOffTax - UpdatedOneOffTax,
        )} og er endret fra ${formatPrice(ExistingOneOffTax || 0)} til ${formatPrice(
          UpdatedOneOffTax,
        )}, du må lage en ny kontrakt og innhente signatur fra kunde. Finanstilbudene blir rekalkulert i Next når du åpner gjenopptar tilbudet. Eventuelle søknader i finansportalen må oppdateres.`;
      }
    } else {
      //CASE: OFFER
      if (isTaxIncreased) {
        return `Engangsavgift er økt med ${formatPrice(
          UpdatedOneOffTax - ExistingOneOffTax,
        )} og er endret fra ${formatPrice(ExistingOneOffTax)} til ${formatPrice(
          UpdatedOneOffTax,
        )}, du bør kontakte kunde og gi et oppdatert tilbud. Finanstilbudene blir rekalkulert i Next når du åpner gjenopptar tilbudet. Eventuelle søknader i finansportalen må oppdateres.`;
      } else {
        return `Engangsavgift er redusert med ${formatPrice(
          ExistingOneOffTax - UpdatedOneOffTax,
        )} og er endret fra ${formatPrice(ExistingOneOffTax || 0)} til ${formatPrice(
          UpdatedOneOffTax,
        )}, du bør kontakte kunde og gi et oppdatert tilbud. Finanstilbudene blir rekalkulert i Next når du åpner gjenopptar tilbudet. Eventuelle søknader i finansportalen må oppdateres.`;
      }
    }
  };

  return (
    <WarningStyled>
      <Messagebar
        messageBoxInfo
        alertIcon={IconType.Warning}
        severityLevel={MessagebarSeverityLevel.WARNING}
        title={<span dangerouslySetInnerHTML={{ __html: getWarningMessage() }} />}
      />
    </WarningStyled>
  );
};
