import defaultTo from 'lodash/defaultTo';
import { IAccessoryList, INextArticle, IPimSnapshot } from 'next-common';
import { ICartSummaryData } from '@next-components/cart';
import cloneDeep from 'lodash/cloneDeep';
import { InputType } from '@next-components/common-types';
import { VehicleConfig } from '../../../../utils';
import { ArticleType, VatCode } from 'sales-common';

export const getDeliveryCharge = (pimSnapshot: IPimSnapshot): IAccessoryList[] => {
  if (!pimSnapshot) return [];
  return [
    {
      key: VehicleConfig.DELIVERY_CHARGE,
      name: 'Leveringsomkostninger',
      price: defaultTo(pimSnapshot?.extraDeliveryCharge, 0),
      discountAmountExclVat: 0,
      discountedPrice: defaultTo(pimSnapshot?.extraDeliveryCharge, 0),
      articleType: ArticleType.DELIVERY,
      vatCode: pimSnapshot.vatCode as VatCode,
    },
  ];
};

export const addNewAccessoryInList = (addedAccessories: ICartSummaryData[]): IAccessoryList[] => {
  const newAccessoryList = [];
  if (addedAccessories?.length) {
    addedAccessories.forEach((accessory) => {
      newAccessoryList.push({
        key: accessory.key,
        name: accessory.name,
        price: accessory.price,
        discountPercentage: accessory.percentage ?? 0,
        discountedPrice: accessory.price,
        articleType: accessory?.key === VehicleConfig.TYRE ? ArticleType.WINTERTIRES : ArticleType.ACCESSORY,
        vatCode: accessory.vatCode,
      });
    });
  }
  return newAccessoryList;
};

export const convertToCurrency = (discount: string | number, price: number, inputType: InputType): number => {
  if (inputType === InputType.INTEGER) return Number(discount);
  return (Number(discount) * price) / 100;
};

export const convertToPercentage = (discount: string | number, price: number, inputType: InputType): number => {
  if (inputType === InputType.FLOAT) return Number(discount);
  return (Number(discount) / price) * 100;
};

export const calculateDiscountedPrice = (accessory: IAccessoryList, input: string | number, inputType: InputType) => {
  let discountedPrice = 0;
  if (inputType === InputType.INTEGER || accessory.key === VehicleConfig.DELIVERY_CHARGE) {
    discountedPrice = accessory.price - Number(input);
  } else {
    discountedPrice = accessory.price - (accessory.price * Number(input)) / 100;
  }
  return discountedPrice;
};

export const updateAccessoryList = (
  accessoryList: IAccessoryList[],
  accessory: IAccessoryList,
  discount: string | number,
  index: number,
) => {
  const accessoryListCopy = cloneDeep(accessoryList);
  let updatedAccessory: IAccessoryList;
  if (accessory.key === VehicleConfig.DELIVERY_CHARGE) {
    updatedAccessory = {
      ...accessory,
      discountAmountExclVat: Number(discount),
    };
  } else if (accessory?.key?.startsWith(VehicleConfig.ACCESSORY) || accessory?.key?.startsWith(VehicleConfig.TYRE)) {
    updatedAccessory = {
      ...accessory,
      discountPercentage: Number(discount),
    };
  }
  accessoryListCopy[index] = accessory;
  return accessoryListCopy;
};

export const updateArticles = (articles: INextArticle[], accessory: IAccessoryList) => {
  const updatedArticle = articles?.map((article) => {
    if (article.key === accessory.key) {
      article.discountAmountExclVat = accessory.discountAmountExclVat || 0;
      article.discountPercentage = accessory.discountPercentage || 0;
      if (accessory.key === VehicleConfig.DELIVERY_CHARGE) {
        delete article.discountPercentage;
      } else {
        delete article.discountAmountExclVat;
      }
      return article;
    } else {
      return article;
    }
  });
  return updatedArticle;
};
