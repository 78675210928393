import React, { useEffect, useState } from 'react';
import { FormRow } from '../FormRow';
import { findPhone, findSsn } from '../../sales-signing-page/sales-signing-contract/modals/signing.utils';
import { useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { updateCustomerInfoParsed } from '../../../../common';
import { CustomerType, EnumMapper, FinancingType, ICustomer, ICustomerInput, validatePhonenumber } from 'next-common';
import { InputType } from '@next-components/common-types';
import { validateSsnOnCustomerType } from '../../../../utils';
import { LoadingSpinner } from '@next-components/loading-spinner';

interface ICustomerInputsProps {
  customer: ICustomer;
  financingType: FinancingType;
  disabled?: boolean;

  onMobileValidityChange?(value: boolean): void;
  onSsnValidityChange?(value: boolean): void;
}

export const CustomerInputs = (props: ICustomerInputsProps) => {
  const isCash = props.financingType === FinancingType.CASH;
  const [customerDelay, setCustomerDelay] = React.useState(null);
  const [ssn, setSsn] = React.useState(findSsn(props.customer, isCash));
  const [mobile, setMobile] = React.useState(findPhone(props.customer));
  const [ssnErrorMessage, setSsnErrorMessage] = useState(null);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(null);
  const [saveCustomer] = useMutation(updateCustomerInfoParsed);
  const [changes, setChanges] = useState<ICustomerInput>(null);
  const [ssnLoading, setSsnLoading] = useState(false);

  useEffect(() => {
    if (props.onSsnValidityChange) {
      props.onSsnValidityChange(validateSsn(ssn));
    }
    if (props.onMobileValidityChange) {
      props.onMobileValidityChange(validateMobile(mobile));
    }
    if (props.customer?.customerType && (props.customer?.customerType.toString() === CustomerType[CustomerType.BUSINESS])) {
      props.onSsnValidityChange(true);
    }
  }, [props.customer]);

  useEffect(() => {
    if (!changes || isEmpty(changes)) return;
    saveCustomerWithDelay(3000);
  }, [changes]);

  const validateSsn = (value: string): boolean => {
    let isValid: boolean = false;
    if (isCash) {
      isValid = value !== null && value !== '' ? validateSsnOnCustomerType(value, props?.customer) : true;
    } else isValid = validateSsnOnCustomerType(value, props?.customer);
    setSsnErrorMessage(isValid ? null : 'Ugyldig personnummer');
    return isValid;
  };

  const validateMobile = (value: string): boolean => {
    const isValid = validatePhonenumber(value);
    setPhoneNumberErrorMessage(isValid ? null : 'Ugyldig telefonnummer');
    return isValid;
  };

  const updateCustomer = async () => {
    try {
      const variables = {
        id: props?.customer?.id,
        input: changes,
      };
      setSsnLoading(true);
      await saveCustomer({ variables });
      setSsnLoading(false);
      setChanges(null);
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      setSsnLoading(false);
    }
  };

  const saveCustomerWithDelay = (delay: number) => {
    if (customerDelay) {
      clearTimeout(customerDelay);
    }
    setCustomerDelay(
      setTimeout(() => {
        updateCustomer();
      }, delay),
    );
  };

  const onSsnChange = (ssnValue) => {
    setSsn(ssnValue);
    const isValid = validateSsn(ssnValue);
    props.onSsnValidityChange(isValid);
    if (isValid && ssnValue !== ssn) {
      setChanges({ ...changes, ssn: ssnValue });
    }
  };

  const onMobileChange = (mobileValue) => {
    setMobile(mobileValue);
    const isValid = validateMobile(mobileValue);
    props.onMobileValidityChange(isValid);
    if (isValid && mobileValue !== mobile) {
      setChanges({ ...changes, signingMobilePhone: mobileValue });
    }
  };

  return (
    <>
      {props.onMobileValidityChange && (
        <FormRow
          id="mobile"
          isEditable
          isValid={validatePhonenumber(mobile)}
          label="Mobilnummer"
          onChange={onMobileChange}
          type={InputType.PHONE_NUMBER}
          validationMessage={phoneNumberErrorMessage}
          value={mobile}
          startAdornment="+47"
        />
      )}
      {
        (props.customer?.customerType && props.customer?.customerType.toString() === CustomerType[CustomerType.BUSINESS]) ? (
          <FormRow
            id="org"
            isEditable
            isValid={true}
            disabled={true}
            label="Organisasjonsnummer"
            value={props.customer?.bosCustomer?.organizationNo}
          />
        ) : 
        ssnLoading?(
          <LoadingSpinner />
        ) : (
          props.onSsnValidityChange && (
            <FormRow
              id="ssn"
              isEditable
              disabled={props.disabled}
              isValid={isCash && (ssn === null || ssn === '') ? true : validateSsnOnCustomerType(ssn, props?.customer)}
              label="Personnummer"
              onChange={onSsnChange}
              type={InputType.PERSONAL_IDENTITY_NUMBER}
              validationMessage={ssnErrorMessage}
              value={ssn}
              placeholder={
                !isCash &&
                  // @ts-expect-error
                  EnumMapper.mapEnumStringValueToEnumValue(props?.customer, 'customerType', CustomerType).customerType !==
                  CustomerType.BUSINESS
                  ? 'Obligatorisk'
                  : ''
              }
            />
          )
        )
      }
    </>
  );
};