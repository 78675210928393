import React, { useEffect, useState } from 'react';
import {
  CustomerType,
  FinancingType,
  getOrderCustomerTypeString,
  ICalculation,
  IConfiguration,
  IOrder,
  IUser,
  IVehicleConfiguration,
  MBApiCalculationFlag,
  NextErrorCode,
  MAKE,
  INextArticles,
  UsageCodes,
  INextArticle,
} from 'next-common';
import { ApolloClient, useMutation } from '@apollo/client';
import { LoadingSpinner } from '@next-components/loading-spinner';
import {
  ICreateServiceContract,
  IGetServiceContract,
  IServiceContractCreateInput,
  VatCode,
} from 'sales-common';
import {
  addAlternateLeasingToOrderMutationParsed,
  addChosenOfferMutationParsed,
  addNewCalculationToOrderParsed,
  ERROR_POLICY,
  IFinancingInfoProps,
  INotificationPageProps,
  IVehiclePageProps,
  removeAlternateLeasingFromOrderMutationParsed,
  removeCalculationFromOrderMutationParsed,
  Routes,
  saveServiceContractFlagToOrderMutationParsed,
  updateOrderMutationParsed,
  useConfigurations,
} from '../../../common';
import { FinanceEdit } from './FinanceEdit';
import {
  NextErrorNotification,
  OneOffTaxChangeWarning,
  ServiceContractWarning,
} from '../../notifications';
import { postServiceContractPdf } from '../sales-common/ServiceContractPDF';
import { darsCreateContractMutation } from '../../../graphql';
import { ICartPrices, ICartProps, Cart, IOneOffTaxAndWreckage } from '@next-components/cart';
import { FinancePageStyled } from './FinancePage.styled';
import { MessagebarSeverityLevel } from '@next-components/messagebar';
import { IEquipmentIdAndSKU } from 'sales-crystallize-common';
import { useNavigate } from 'react-router-dom';
import { defaultTo, get } from 'lodash';
import { MarginCart } from '../../common-components/MarginCart';
import { CustomMessageBar } from '../../common-components/custom-messagebar';
import {
  filterSelectedEquipmentInCart,
  getOtherFeeTotal,
  getSelectedEquipmentInCart,
  isValidStockSaleOrder,
  updateVehicleVatCodeAndDiscount,
  VehicleConfig,
} from '../../../utils';
import { CustomModal } from '@next-components/custom-modal';
import { MBConfigModalWrapper, ModalActions, ModalInner } from '../sales-common/modal.styled';
import { Button } from '@next-components/cta';
import { Icon, IconType } from '@next-components/bos-icon';
import { bosColor } from '@next-components/common-styles';
import { IStepMenuData } from '../sales-wizard/sales-wizard-menu';
import { CustomAlertPopup } from '../../common-components/custom-DeletePopup';
import { IAlertPopup } from '../sales-wizard';
import {  useVehicleOrderDraft } from '../../../common/hooks/useVehicleOrderDraft';

export interface IFinancePageProps extends IVehiclePageProps, INotificationPageProps, IFinancingInfoProps {
  nonExactMatchCount?: number;
  exactMatchCount?: number;
  order?: IOrder;
  vehicle?: IVehicleConfiguration;
  selectedVehicles?: any[];
  user?: IUser;
  isCalled?: boolean;
  cartData?: ICartProps;
  vatPrices?: ICartPrices;
  client?: ApolloClient<object>;
  mappedEquipment?: IEquipmentIdAndSKU[];
  serviceContract?: IGetServiceContract;
  serviceContractLoading?: boolean;
  configuration?: IConfiguration;
  selectedSerialNo?: number;
  availableVehicleList?: any[];
  manufactureModelId?: string;
  offerMake?: MAKE;
  isElectricCar?: boolean;
  stepMenuData?: IStepMenuData;
  loading?: boolean;
  isShowDeleteModel?: boolean;
  isShowDeleteButton?: boolean;
  isLoading?: boolean;
  deleteOrderData?: IAlertPopup;
  onCustomerType(customerType: CustomerType): void;
  setIsLoading?(value: boolean): void;
  getSelectedOffer?(calculationId: string): void;
  setIsCalled?: (called: boolean) => void;
  setIsShowDeleteModel?: (isShowDeleteModel: boolean) => void;
  rootUrl?: string;
  dealerRole?: string;
  setArticles?: React.Dispatch<React.SetStateAction<INextArticle[]>>
}
export interface IVehicleDetailsPageProps extends IVehiclePageProps, INotificationPageProps, IFinancingInfoProps {
  pimCode: string;
  isCalled?: boolean;
  loading?: boolean
  setIsLoading?(value: boolean): void;
  setSerialNo?(serialNo: string): void;
  onCustomerType(customerType: CustomerType): void;
}

enum DarsCustomerType {
  PRIVATE = 'Privat kunde',
  BUSINESS = 'Næringskunde',
}

const isSetPayerDetails = (
  isLoanIncluded: boolean,
  orderFinancingType: FinancingType,
  chosenOffer: ICalculation,
  payerNo: string,
) =>
  (!isLoanIncluded && orderFinancingType === FinancingType.LOAN && !payerNo) ||
  (chosenOffer?.financingType === FinancingType.CASH && !payerNo);

export const createDarsServiceContractInput = (
  order: IOrder,
  vehicle: IVehicleConfiguration,
  chosenOffer: ICalculation,
): IServiceContractCreateInput => {
  let customerDetails = null;
  let payerDetails = null;
  const customer = order?.customer?.bosCustomer;
  const orderFinancingType = chosenOffer?.financingType;
  const isLoanIncluded =
    (orderFinancingType === FinancingType.LOAN || orderFinancingType === FinancingType.CASH) &&
    chosenOffer?.serviceContract?.isLoanPaymentIntervalSelected === true;
  const payerNo = isLoanIncluded
    ? `80${order?.soldByDealerId}00`
    : orderFinancingType === FinancingType.LEASING
    ? order?.customer?.bosCustomer?.payerNo
    : order?.customer?.bosCustomer?.customerNo;
  const serviceContractPriceConfig = chosenOffer?.serviceContract?.serviceContractPriceConfig;
  const isPrivateCustomer = customer.customerType.toString() === CustomerType[CustomerType.PRIVATE];

  if (!customer?.customerNo) {
    customerDetails = {
      ifsCustomerType: isPrivateCustomer ? DarsCustomerType.PRIVATE : DarsCustomerType.BUSINESS,
      firstName: isPrivateCustomer ? customer?.firstName : '',
      lastName: isPrivateCustomer ? customer?.lastName : '',
      companyName: !isPrivateCustomer ? customer?.organizationName : '',
      address: customer?.address1 ?? `${customer?.address2 || ''}`,
      mobile: customer?.phoneMobile ?? '',
      email: customer?.email ?? '',
      postNo: customer?.zipCode ?? '',
      bosId: customer?.id ?? '',
    };
  }

  if (isSetPayerDetails(isLoanIncluded, orderFinancingType, chosenOffer, payerNo)) {
    const name = isPrivateCustomer
      ? `${customer?.firstName || ''} ${customer?.lastName || ''}`
      : customer?.organizationName;
    payerDetails = {
      name,
      address: customer?.address1 ?? `${customer?.address2 || ''}`,
      email: customer?.email ?? '',
      postNo: customer?.zipCode ?? '',
      bosId: customer?.id ?? '',
    };
  }

  return {
    serialNo: chosenOffer?.vehicles?.[0]?.serialNo,
    configurationId: serviceContractPriceConfig?.configurationId,
    kmPerYear: chosenOffer?.serviceContract?.drivingDistance,
    usageFactorId: serviceContractPriceConfig?.usageFactorId,
    contractPeriodMonth: chosenOffer?.serviceContract?.duration,
    contractTypeId: chosenOffer?.serviceContract?.contractTypeId,
    paymentPeriodId: chosenOffer?.serviceContract?.paymentPeriodId,
    customerNo: order?.customer?.bosCustomer?.customerNo,
    payerNo,
    sku: defaultTo(vehicle?.sku, order?.orderReference?.pimCode),
    nextId: order?.id,
    dealerRebate: chosenOffer?.serviceContract?.dealerAmount ?? 0,
    dealerNo: order?.soldByDealerId,
    zident: order?.user?.ident,
    salesPersonName: `${order?.user?.firstName || ''} ${order?.user?.lastName || ''}`,
    customerDetails,
    payerDetails,
  };
};
export const FinancePage = (props: IFinancePageProps) => {
  const updatedOrder: IOrder = props?.order;
  const updatedVehicle: IVehicleConfiguration = props?.vehicle;
  const [order, setUpdatedOrder] = useState<IOrder>(null);
  const [vehicle, setupdatedVehicle] = useState<IVehicleConfiguration>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveOrder] = useMutation(updateOrderMutationParsed);
  const [createDarsServiceContract] = useMutation(darsCreateContractMutation, {
    context: { clientName: 'sales-common' },
    fetchPolicy: 'network-only',
    onError: () => {},
  });
  const [saveServiceContractFlagToOrder] = useMutation(saveServiceContractFlagToOrderMutationParsed);

  const [showMbAPIPopup, setShowMbAPIPopup] = useState<boolean>(false);
  const [mbAPIErrorCode, setMBAPIErrorCode] = useState<number>(null);
  const navigate = useNavigate();
  const carType = order?.pimSnapshot?.vehicleType ?? vehicle?.model?.vehicleType;
  const isStockSaleOrder = isValidStockSaleOrder(order, props?.configuration)
  const [articlesUpdated, setArticlesUpdated] = useState<boolean>(false);
  const {orderDraft,draftLoading} = useVehicleOrderDraft(props?.orderId)

  const configurations = useConfigurations();
  
  const isRammeavtaleEnabled = configurations?.features?.rammeavtale ?? false;
  
  useEffect(() => {
    const isArticleUpdated = {
      modelArticleUpdated: false,
      oneOffTaxArticleUpdated: false
    };
    if (props?.articles?.length > 0 && (props?.order?.chargesAreOverridden || props?.order?.pimSnapshot?.vehicleUsageCode) && !articlesUpdated) {
      const finalArticles = props?.articles?.map((article) => {
        if (article?.key === VehicleConfig.MODEL) {
          if(article?.vatCode === (props?.order?.pimSnapshot?.vehicleUsageCode === UsageCodes.EL ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE)){
            isArticleUpdated.modelArticleUpdated = true;
            return article;
          }
          return {
            ...article,
            vatCode: props?.order?.pimSnapshot?.vehicleUsageCode === UsageCodes.EL ? VatCode.DEDUCTABLE : VatCode.NON_DEDUCTABLE,
          };
        } else if (article?.key === VehicleConfig.TAREWEIGHT) {
          const overriddenOneOffTax = (props?.order?.vatCalculation?.overriddenVehicleRoadTax && props?.order?.vatCalculation?.overriddenVehicleRoadTax >= 0) ? (props?.order?.vatCalculation?.overriddenVehicleRoadTax - getOtherFeeTotal(props?.order?.pimSnapshot)) : null;
          if(article?.priceExclVat === (overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight)){
            isArticleUpdated.oneOffTaxArticleUpdated = true;
            return article
          }
          return {
            ...article,
            priceExclVat: overriddenOneOffTax ?? props?.order?.pimSnapshot?.oneOffTaxWeight
          };
        } else {
          return article;
        }
      });
      if(isArticleUpdated.modelArticleUpdated && isArticleUpdated.oneOffTaxArticleUpdated){
          setArticlesUpdated(true);
      }
      if(props?.setArticles) {
         props?.setArticles(finalArticles);
      }
     
    }
  }, [props?.order, props?.articles]);

  useEffect(() => {
    if (updatedOrder) {
      setUpdatedOrder(updatedOrder);
    }
    if (updatedVehicle) {
      setupdatedVehicle(updatedVehicle);
    }
    if (isStockSaleOrder) {
      setupdatedVehicle(null);
    }
  }, [updatedOrder, updatedVehicle, isStockSaleOrder]);
  /**
   * Mutations
   */
  const [addNewCalculationToOrder] = useMutation(addNewCalculationToOrderParsed);
  const [addAlternateLeasingToOrder] = useMutation(addAlternateLeasingToOrderMutationParsed);
  const [removeCalculationFromOrder] = useMutation(removeCalculationFromOrderMutationParsed);
  const [removeAlternateLeasingFromOrder] = useMutation(removeAlternateLeasingFromOrderMutationParsed);
  const [addChosenOffer] = useMutation(addChosenOfferMutationParsed, {
    errorPolicy: ERROR_POLICY,
  });

  if (!order || !props.articles?.length || props.isLoading) {
    return <LoadingSpinner isModal={true} />;
  }

  const nextStep = () => {
    const route = Routes.getSigningPage(order?.id);
    const redirectUrl = get(props?.stepMenuData, 'route') ? props?.stepMenuData?.route : route;
    props.setIsCalled(false);
    navigate(redirectUrl);
  };


  const updateServiceContractFlag = async (selected: boolean) => {
    try {
      const variables = {
        id: order?.id,
        isServiceContractSelected: selected,
      };
      await saveServiceContractFlagToOrder({ variables });
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const onChooseOffer = async (calculationId: string) => {
    if (
      (calculationId && calculationId !== order?.chosenOffer?.id && order?.vehicles?.length) ||
      props?.stepMenuData?.routeName === 'Signing'
    ) {
      setLoading(true);
      try {
        let nextArticles: INextArticles = null;
        if (order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED && props?.offerMake === MAKE.Mercedes) {
          nextArticles = {
            manufactureComponentId: props?.manufactureModelId,
            articles: props?.articles,
          };
        }
        const { data } = await addChosenOffer({
          variables: {
            orderId: order?.id,
            calculationId,
            nextArticles,
          },
        });
        if (data?.addChosenOffer?.errorCode === NextErrorCode.MB_CONFIG_API_FAILED) {
          setShowMbAPIPopup(true);
          setMBAPIErrorCode(data?.addChosenOffer?.httpCode);
          setLoading(false);
          props.setIsLoading(false);
          props.setIsCalled(false);
          return;
        }
        if (data?.addChosenOffer.__typename === 'NextError') {
          props.raiseNotification(
            <NextErrorNotification
              key="chooseOfferFail"
              orderId={order?.id}
              errorCode={data?.addChosenOffer?.errorCode}
              onClose={() => props?.clearNotifications('chooseOfferFail')}
            />,
          );
          setLoading(false);
          props.setIsLoading(false);
          return order;
        }
        // Check if chosen offer has service contract
        const isServiceContractSelected = data?.addChosenOffer?.chosenOffer?.serviceContract?.id;
        // Setting if service contract is selected or not
        if (isServiceContractSelected) {
          updateServiceContractFlag(true);
        } else {
          updateServiceContractFlag(false);
        }
        /* Create service contract in DARS, Post it to container and save order */
        if (isServiceContractSelected) {
          const createServiceContractInput: IServiceContractCreateInput = createDarsServiceContractInput(
            order,
            vehicle,
            data?.addChosenOffer?.chosenOffer,
          );
          const serviceContractResponse = await createDarsServiceContract({
            variables: {
              input: {
                ...createServiceContractInput,
              },
            },
          });

          const serviceContractResObject: ICreateServiceContract =
            serviceContractResponse?.data?.createServiceContract?.responseObject;
          if (!serviceContractResObject || !serviceContractResponse?.data) {
            setLoading(false);
            props.setIsLoading(false);
            props.raiseNotification(
              <ServiceContractWarning
                key="serviceContractWarning"
                validationErrors="Feil under opprettelse av servicekontrakt PDF."
                severityLevel={MessagebarSeverityLevel.ERROR}
                onClose={() => props?.clearNotifications('serviceContractWarning')}
              />,
            );
            return order;
          }

          const savedContract = await postServiceContractPdf(
            order?.id,
            serviceContractResObject?.id,
            serviceContractResObject?.pdfData,
          );

          const variables = {
            id: order?.id,
            input: { salesContractPdfId: serviceContractResObject?.id, serviceContractBosId: savedContract?.data },
          };
          await saveOrder({ variables });
        }
        setLoading(false);
        props.setIsLoading(false);
        nextStep();
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error);
        setLoading(false);
      }
    } else {
      props.setIsLoading(false);
      return nextStep();
    }
  };

  const handleAddCalculation = async (financingType: FinancingType, input): Promise<IOrder> => {
    setLoading(true);

    try {
      if (financingType === FinancingType.ALTERNATIVELEASING) {
        const variables = {
          orderId: order?.id,
          input: {
            ...input
          },
        };
        const { data } = await addAlternateLeasingToOrder({ variables });
        if (data?.addAlternateLeasingToOrder.__typename === 'NextError') {
          props.raiseNotification(
            <NextErrorNotification
              key="addCalculationFail"
              orderId={order?.id}
              errorCode={data?.addAlternateLeasingToOrder?.errorCode}
              onClose={() => props?.clearNotifications('addCalculationFail')}
            />,
          );
          setLoading(false);
          return order;
        }
        setLoading(false);
        return data?.addAlternateLeasingToOrder || order;
      }

      if (financingType === FinancingType.LEASING && order?.alternativeLeasing) {
        await removeAlternativeLeasing(order?.id);
        setLoading(true);
      }

      const customerType = getOrderCustomerTypeString(order || null);
      let additionalInput = input
        ? input?.reduce(
            (payload, change) => ({
              ...payload,
              [change.fieldName]: change.value,
            }),
            {},
          )
        : {};

      additionalInput.articles = props.articles;

      const variables = {
        orderId: order?.id,
        input: {
          financingType: typeof financingType === 'number' ? FinancingType[financingType] : financingType,
          customerType,
        },
        additionalInput,
      };

      const { data } = await addNewCalculationToOrder({ variables });

      if (data?.addNewCalculationToOrder.__typename === 'NextError') {
        props.raiseNotification(
          <NextErrorNotification
            key="addCalculationFail"
            orderId={order?.id}
            errorCode={data?.addNewCalculationToOrder?.errorCode}
            onClose={() => props?.clearNotifications('addCalculationFail')}
          />,
        );
        setLoading(false);
        return order;
      }
      setLoading(false);
      return data?.addNewCalculationToOrder || order;
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
      setLoading(false);
    }
  };

  const onDeleteCalculation = async (calculation: ICalculation): Promise<IOrder> => {
    setLoading(true);
    try {
      const { data } = await removeCalculationFromOrder({
        variables: { orderId: order?.id, calculationId: calculation?.id },
      });

      if (data?.removeCalculationFromOrder.__typename === 'NextError') {
        props.raiseNotification(
          <NextErrorNotification
            key="removeCalculationFail"
            orderId={order?.id}
            errorCode={data?.removeCalculationFromOrder?.errorCode}
            onClose={() => props?.clearNotifications('removeCalculationFail')}
          />,
        );
      }
      setLoading(false);
      return data?.removeCalculationFromOrder || order;
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const removeAlternativeLeasing = async (orderId: string): Promise<IOrder> => {
    setLoading(true);
    try {
      const { data } = await removeAlternateLeasingFromOrder({
        variables: { orderId },
      });

      if (data?.removeAlternateLeasingFromOrder.__typename === 'NextError') {
        props.raiseNotification(
          <NextErrorNotification
            key="removeCalculationFail"
            orderId={order?.id}
            errorCode={data?.removeAlternateLeasingFromOrder?.errorCode}
            onClose={() => props?.clearNotifications('removeCalculationFail')}
          />,
        );
      }
      setLoading(false);
      return data?.removeAlternateLeasingFromOrder || order;
    } catch (error) {
      // tslint:disable-next-line:no-console
      console.error(error);
    }
  };

  const getSelectedOffer = (calculationId: string) => {
    props.getSelectedOffer(calculationId);
  };

  const onClosePopUp = () => {
    setShowMbAPIPopup(false);
    setMBAPIErrorCode(null);
    props.setIsLoading(false);
    if (props.stepMenuData && props.stepMenuData?.routeName !== 'Signing') {
      nextStep();
    } else {
      props.setIsCalled(false);
    }
  };

  const handleClose =()=>{
    props.setIsShowDeleteModel(false)
  };

  return (
    <FinancePageStyled className="mainContainer">
      <div className="leftContainer showThreeDots">
        <div className='msgContainer'>
          {
            props.vehicleChargeStatus?.isTaxChange ?
            <div>
              <OneOffTaxChangeWarning vehicleChargeStatus={props.vehicleChargeStatus} order={props.order} />
            </div> : ''
          }
          <CustomMessageBar isFinance={true} />
        </div>
        <FinanceEdit
          articles={props.articles}
          orderId={order?.id}
          loading={loading || props.loading || draftLoading}
          onAddCalculation={handleAddCalculation}
          onChooseOffer={onChooseOffer}
          raiseNotification={props.raiseNotification}
          onDeleteCalculation={onDeleteCalculation}
          isCalled={props.isCalled}
          setIsLoading={props.setIsLoading}
          getSelectedOffer={getSelectedOffer}
          cartData={props?.cartData}
          clearNotifications={props.clearNotifications}
          mappedEquipment={props?.mappedEquipment}
          order={props?.order}
          vehicle={vehicle}
          serviceContract={props.serviceContract}
          serviceContractLoading={props.serviceContractLoading}
          setUpdatedOrder={setUpdatedOrder}
          isSigningStep={false}
          vatPrices={props?.vatPrices}
          configuration={props?.configuration}
          calculationId={props?.calculationId}
          activeStep={props?.activeStep}
          isUnavailablePimData={props?.isUnavailablePimData}
          menuLoading={props?.menuLoading}
          onClickSigning={props?.onClickSigning}
          onClick={props?.onClick}
          rootUrl={props?.rootUrl}
          isVehicleValidForReserveOrSign={props.isVehicleValidForReserveOrSign}
          vehicleAvailabilityCode={props.vehicleAvailabilityCode}
          dealerRole={props?.dealerRole}
          removeAlternativeLeasing={removeAlternativeLeasing}
          orderDraft = {orderDraft}
          isVarebilEnabled={props.isVarebilEnabled}
          invalidPcodesValidation={props?.invalidPcodesValidation}
        />
      </div>
      <div>
        <Cart
          rammeavtale={isRammeavtaleEnabled}
          exactMatchCount={props.exactMatchCount}
          nonExactMatchCount={props.nonExactMatchCount}
          make={props?.cartData?.make}
          modelSeries={props?.cartData?.modelSeries}
          prices={props?.finalPrice}
          image={props?.cartData?.image}
          data={updateVehicleVatCodeAndDiscount(filterSelectedEquipmentInCart(props.cartData?.data, getSelectedEquipmentInCart(props.cartData?.data)), props?.order?.pimSnapshot?.vehicleUsageCode, order)}
          isVehicleSelected={props?.isVehicleSelected}
          serialNo={props?.selectedSerialNo}
          selectedLocation={props?.vehicleLocation}
          selectedStatus={props?.vehicleStatus}
          isFactoryOrder={props?.isFactoryOrder}
          expectedProductionDate={props?.expectedProductionDate}
          vehicleCount={props?.availableVehicleList?.length}
          equipmentDetails={getSelectedEquipmentInCart(props.cartData?.data)}
          footerChildren={
            <MarginCart
              order={props?.order}
              vehicleConfiguration={vehicle}
              articles={props?.articles}
              vatPrices={props?.finalPrice}
              carType={carType}
              isUnavailablePimData={props.isUnavailablePimData}
              accessoryChanges={props?.cartData?.data}
            />
          }
          showAbortFactoryOrderAction={props.isShowDeleteButton}
          abortFactoryBorder={{
            buttonIcon: IconType?.NewNextTrash,
            buttonClass: 'red',
            buttonLabel: props.order?.vehicles?.[0]?.serialNo ? 'Fjern bil' : 'Avbryt fabrikkbestilling',
            buttonAction: () => {
              props.setIsShowDeleteModel(!props.isShowDeleteModel);
            },
          }}
          isElectricCar={props.isElectricCar}
          showMBApiWarning={order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED}
          oneOffTaxAndWreckageDetails= {(props?.finalPrice?.oneOffTaxAndWreckage ? {
            isEditable: false,
            taxAndWreckageValue: props?.order?.vatCalculation?.overriddenVehicleRoadTax ?? props?.finalPrice?.oneOffTaxAndWreckage,
            resetTaxAndWreckageValue: props?.order?.pimSnapshot?.vehicleRoadTax,
            inputValue: (param, doneReset) => {
            },
          }:{} as IOneOffTaxAndWreckage)}
        />
      </div>
      {loading || props.isLoading ? <LoadingSpinner isModal={true} /> : null}
      {props.isShowDeleteModel && (
        <CustomAlertPopup
          isOpen={props.isShowDeleteModel}
          onCloseClick={handleClose}
          deleteOrderData={props.deleteOrderData}
          className='deleteAlert'  
        ></CustomAlertPopup>
      )}
      {showMbAPIPopup && (
        <MBConfigModalWrapper>
          <CustomModal
            onCloseClick={() => {
              setShowMbAPIPopup(false);
              setMBAPIErrorCode(null);
            }}
            isModelCloseAllowedOutside={false}
            active={showMbAPIPopup}>
            <header>
              <Icon icon={IconType.NewNextErrorSolid} color={bosColor.poppy} />
              <h3>Engangsavgift kunne ikke kalkuleres</h3>
            </header>
            <ModalInner>
              {mbAPIErrorCode === 400 || mbAPIErrorCode === 404 ? (
                <p className="modalText">
                  Det er funnet feil med konfigureringen. Meld inn sak eller kontakt produktavdelingen.
                </p>
              ) : (
                <>
                  <p>
                    Vi har for øyeblikket problemer med et av våre systemer, så engangsavgiften i dette tilbudet er kun
                    et estimat.{' '}
                  </p>
                  <p>Kalkulering av engangsavgift må være nøyaktig før kontrakt kan signeres. Prøv igjen senere.</p>
                </>
              )}
            </ModalInner>
            <footer>
              <ModalActions items={1}>
                <Button
                  onClick={() => {
                    onClosePopUp();
                  }}>
                  OK
                </Button>
              </ModalActions>
            </footer>
          </CustomModal>
        </MBConfigModalWrapper>
      )}
    </FinancePageStyled>
  );
};

FinancePage.defaultProps = {};
