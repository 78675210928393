import {
  FinancingType,
  IOrder,
  IVehicleConfiguration,
  MBApiCalculationFlag,
  defaultLeasingInput,
  defaultLoanInput,
  isElectricCar,
  priceOverrunLeasing,
} from 'next-common';
import { formatNumber } from '@next-components/common-types';
import * as React from 'react';
import { InfoBlock } from '../../../sales-common/InfoBlock';
import { AgreementInfoItem } from '../AgreementInformation/AgreementInformation.styled';
import { ICartPrices, ICartProps } from '@next-components/cart';
import { Messagebar, MessagebarSeverityLevel } from '@next-components/messagebar';
import { getAdministrationFee, getResidualValue, getEstablishmentFee, getMonthlyPrice, getOriginalDownPayment, getRequestedNominalInterest, getNominalInterest } from '../../../sales-common/FinancingDialog/FinancingForm/FinancingForm.utils';
import { IVehicleChargeStatus } from '../../../../../common';

interface IVehicleInfoProps {
  order: IOrder;
  vehicleConfig: IVehicleConfiguration;
  cartData?: ICartProps;
  vatPrices: ICartPrices;
  vehicleChargeStatus: IVehicleChargeStatus;
}

const priceFormatter = (value): string => {
  if (Number.isNaN(value)) return value;
  const sign = value >= 0 ? '+ ' : '- ';
  return value ? `${sign}${formatNumber(Math.abs(value))} kr` : 'Inkludert';
};

export const VehicleInfo = (props: IVehicleInfoProps) => {
  const { order } = props;
  const financing = order?.chosenOffer?.financing;
  const financingType = order?.chosenOffer?.financingType;

  const missingVehicleInfo: boolean = !props.order?.pimSnapshot && !props.vehicleConfig;
  const isElectric = isElectricCar(props?.order?.pimSnapshot, props?.vehicleConfig);
  const vatCalculation = props?.vatPrices;
  const showMBApiWarning = props?.order?.externalTaxCalculation === MBApiCalculationFlag.FETCH_FAILED;

  const isLoan = financingType === FinancingType.LOAN;
  const isLeasing = financingType === FinancingType.LEASING;
  const defaultDuration = isLoan ? defaultLoanInput.durationMonths : defaultLeasingInput.durationMonths;


  const checkForNullValueForResidual = (): boolean => {
    return order?.financingType === FinancingType.LEASING && getResidualValue(financing) === 0;
  };

  return (
    <>
      {missingVehicleInfo && <h3>Bilpakke mangler</h3>}
      {props.cartData?.data?.map((option) => (
        <AgreementInfoItem key={option?.id}>
          <InfoBlock key={option?.id} label={option?.name} value={priceFormatter(option?.price)} className="infoItem" />
        </AgreementInfoItem>
      ))}
      {(isLoan || isLeasing) && props?.order?.chosenOffer && (
        <AgreementInfoItem className="flexColumn">
          <InfoBlock
            label={isLoan ? 'Egenkapital' : 'Startleie inkl. mva'}
            value={`${formatNumber(getOriginalDownPayment(isLoan, financing?.request))} kr`}
            className="infoItem"
          />
          {!isLoan && (
            <>
              {/* <InfoBlock
                label={'inkl. mva: '}
                value={`${formatNumber(getIncVatDownPayment(financing, financing?.downPayment || getOriginalDownPayment(isLoan, financing?.request) || 0))} kr`}
                className="infoItem"
              /> */}
              <InfoBlock
                label={'Årlig kjørelengde'}
                value={`${formatNumber(financing?.request?.mileage ?? defaultLeasingInput.mileage)} km`}
                className="infoItem"
              />
            </>
          )}
          <InfoBlock
            label={isLoan ? 'Nedbetalingstid' : 'Varighet'}
            value={`${financing?.request?.durationMonths ?? defaultDuration} mnd`}
            className="infoItem"
          />
          <InfoBlock label={'Rente'} value={`${formatNumber((getRequestedNominalInterest(financing) || getNominalInterest(financing) || 0), 2)} %`} className="infoItem" />
          {!isLoan && (
            <InfoBlock
              label={'Restverdi'}
              value={checkForNullValueForResidual() ? 'Mangler' : `${getResidualValue(financing)} kr`}
              className="infoItem"
            />
          )}
          <InfoBlock
            label={'Etableringsgebyr'}
            value={
              typeof getEstablishmentFee(financing) === 'number'
                ? `${formatNumber(getEstablishmentFee(financing), 1)} kr`
                : 'ukjent'
            }
            className="infoItem"
          />
          {isLoan && (
            <InfoBlock
              label={'Tinglysingsgebyr'}
              value={
                typeof financing?.documentFeeInclVat === 'number' ? `${formatNumber(financing.documentFeeInclVat, 1)} kr` : 'ukjent'
              }
              className="infoItem"
            />
          )}
          <InfoBlock
            label={'Administrasjonsgebyr'}
            value={typeof getAdministrationFee(financing) === 'number' ? `${formatNumber(getAdministrationFee(financing), 1)} kr` : 'ukjent'}
            className="infoItem"
          />
          {!isLoan && (
            <InfoBlock
              label={'Pris overkjørte km'}
              value={`${formatNumber(priceOverrunLeasing, 1)} kr / km`}
              className="infoItem"
            />
          )}
          <InfoBlock
            label={'Månedspris'}
            value={
              checkForNullValueForResidual()
                ? 'Mangler'
                : typeof getMonthlyPrice(financing) === 'number'
                ? `${formatNumber(getMonthlyPrice(financing))} kr`
                : 'ukjent'
            }
            className="infoItem"
          />
        </AgreementInfoItem>
      )}

      {props?.order?.vatCalculation && (
        <>
          <AgreementInfoItem className="flexColumn halfGap">
            <InfoBlock
              label="Sum eks. mva"
              value={`${formatNumber(vatCalculation?.salesTotalExcludingVatAndTax ?? 0)} kr`}
              className="infoItem boldText lightText"
            />
            {isElectric && (
              <InfoBlock
                label="Kjøpsbeløp over 500 000 kr som er mva. pliktig"
                value={`${formatNumber(vatCalculation?.electrifiedCarVatCalculationBasis ?? 0)} kr`}
                className="infoItem smallText"
              />
            )}
          </AgreementInfoItem>
          <AgreementInfoItem className="flexColumn">
            <InfoBlock
              label="Estimert engangsavgift/vrakpant"
              value={`+ ${formatNumber(vatCalculation?.oneOffTaxAndWreckage ?? 0)} kr`}
              updatedValue = {props.vehicleChargeStatus?.isTaxChange ? props.vehicleChargeStatus?.UpdatedOneOffTax : null}
              className="infoItem"
              showWarning={props.vehicleChargeStatus?.isTaxChange}
            />
            {showMBApiWarning && (
              <div className="mbApiWarning">
                <Messagebar
                  messageBoxInfo
                  severityLevel={MessagebarSeverityLevel.WARNING}
                  title={
                    'Engangsavgift kunne ikke kalkuleres, beløpet er kun et estimat og kan endres når avgiften kalkuleres på nytt.'
                  }
                />
              </div>
            )}
          </AgreementInfoItem>
          <AgreementInfoItem className="flexColumn">
            <InfoBlock
              label="Veiledende pris inkl. mva og avgifter"
              value={`${formatNumber(vatCalculation?.calculatedSalesTotalBeforeDiscount ?? 0)} kr`}
              className="infoItem"
            />
            <InfoBlock
              label="Rabatt"
              value={`- ${formatNumber(vatCalculation?.discount ?? 0)} kr`}
              className="infoItem"
            />
            {/* <InfoBlock
              label="Rabatt på leveringsomkostninger iht rammeavtale 000-000-00"
              value={`- ${formatNumber(vatCalculation?.calculatedDiscountOnDeliveryCost ?? 0)} kr`}
              className="infoItem"
            />
            <InfoBlock
              label="Rabatt på utstyr iht rammeavtale 000-000-00"
              value={`- ${formatNumber(vatCalculation?.calculatedDiscountOnAccessories ?? 0)} kr`}
              className="infoItem"
            /> */}
            <InfoBlock
              label="Totalpris inkl. mva. og avgifter"
              value={`${formatNumber(vatCalculation?.salesTotalIncludingVatAndTax ?? 0)} kr`}
              updatedValue = {props.vehicleChargeStatus?.isTaxChange ? props.vehicleChargeStatus?.UpdatedtotalPrice : null}
              className="infoItem boldText"
            />
          </AgreementInfoItem>
          <AgreementInfoItem className="flexColumn smallTextWrapper quarterGap lastItem">
            <InfoBlock
              label="mva. på kjøpsbeløp"
              value={
                isElectric
                  ? `${formatNumber(vatCalculation?.calculatedVatOnDeductableItems ?? 0)} kr`
                  : `${formatNumber(vatCalculation?.calculatedVatOnSalesTotal ?? 0)} kr`
              }
              className="infoItem smallText"
            />
            {isElectric && (
              <InfoBlock
                label="mva. på tilbehør"
                value={`${formatNumber(vatCalculation?.calculatedVatOnNonDeductableItems ?? 0)} kr`}
                className="infoItem smallText"
              />
            )}
          </AgreementInfoItem>
        </>
      )}
    </>
  );
};
