import { fontWeightExtraBold } from '@next-components/common-styles';
import styled from 'styled-components';
export const ImporterOptionsPageStyled = styled.div``;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20.25rem, 1fr));
  gap: 1rem 2rem;

  .title {
   font-size: 1rem;
   font-weight: ${fontWeightExtraBold} !important;
  } 
  .sub-title {
    font-size: 1rem;
  }
`;
