import { getPercentageForCalculation } from 'sales-common-calculations';
import {
  EnrichedEquipment,
  getLowestPriceConjunction,
  getNewConjunctionValues,
  getSelectedSkusToUnselectBasedOnRules,
  getUpdatedConjunctionPrices,
  IEquipmentIdAndSKU,
  IEquipmentIdAndSKUExtended,
  IMappedEquipment,
  joinCodeArrayToString,
  splitCodesToArray,
} from 'sales-crystallize-common';
import { IRulesTable } from '../../common';
import { IRuleValues } from '../../components/common-components/RulesPopup/RulesDetail';
import { difference, isNil, sumBy } from 'lodash';
import { VehicleEquipmentCategoryEnum } from 'next-common';
import { IModelPackages } from '../../components/sales/sales-vehicle-picker-page';
import { ICurrentSelections, getAndPrepareSelectedDataFromRulesTable } from './conjunction';
import { getAndPrepareSelectedDataAfterDefaultCheck } from './selectablePreselection';

export interface IVatConditions {
  electrifiedCarVatDeductionAmount: number;
  vatPercentage: number;
  removeVatPercentage: number;
}

export interface IVatRegulations {
  fromDate: string;
  toDate: string;
  vatValues: IVatConditions;
}


export const vatRegulationList: IVatRegulations[] = [
  {
    fromDate: '1969-12-31T23:00:00.000Z',
    toDate: '2022-12-31T23:00:00.000Z',
    vatValues: {
      electrifiedCarVatDeductionAmount: 600000,
      vatPercentage: 25,
      removeVatPercentage: 80,
    },
  },
  {
    fromDate: '2023-01-01T00:00:00.000Z',
    toDate: '9998-12-31T23:00:00.000Z',
    vatValues: {
      electrifiedCarVatDeductionAmount: 500000,
      vatPercentage: 25,
      removeVatPercentage: 80,
    },
  },
];


export const getCurrentDeductionVatRule = (
  currentDate: Date
): IVatRegulations => {
  let regulationResult: IVatRegulations = {
    fromDate: '',
    toDate: '',
    vatValues: {
      electrifiedCarVatDeductionAmount: 0,
      vatPercentage: 0,
      removeVatPercentage: 0,
    },
  };

  vatRegulationList.forEach((vatRegulation) => {
    const fromDate = new Date(vatRegulation.fromDate);
    const toDate = new Date(vatRegulation.toDate);

    if (fromDate <= currentDate && toDate >= currentDate) {
      regulationResult = vatRegulation;
    }
  });

  return regulationResult;
};

export enum MainCategory {
  Utstyr = 'Utstyr',
  Lakk = 'Lakk',
  Interiør = 'Interiør',
  Felger = 'Felger',
}

export const equipmentsToConsiderDeSelection: string[] = ['Felger', 'Listverk', 'Interiør', 'Lakk'];

export interface IDisableStateEquipment {
  equipment: IMappedEquipment;
  removedPrevSelectedEquipment: IMappedEquipment[];
  addedEquipmentFromCojunction: IMappedEquipment[];
  selectedEquipment?: IMappedEquipment[];
  disabledEquipment?: IMappedEquipment[];
  allEquipment: IMappedEquipment[];
}

export interface IMappedEquipmentWithConjunctionPrices extends IMappedEquipment {
  inConjunctionWithPriceExcludingVat?: number;
  inConjunctionWithPriceIncludingVat?: number;
}

const mapEquipmentToEnrichedEquipment = (equipment: IEquipmentIdAndSKU): Partial<EnrichedEquipment> => {
  return {
    //@ts-expect-error
    product: {
      sku: equipment.sku,
      equipmentSKU: equipment.sku,
    },
    equipmentInConjunctionWith: equipment.inConjunctionWith,
    equipmentNotInConjunctionWith: equipment.notInConjunctionWith,
  };
};

export const mapEquipmentsToPartialEnrichedEquipment = (
  equipments: IEquipmentIdAndSKU[],
): Partial<EnrichedEquipment>[] => {
  return equipments.map((eq) => mapEquipmentToEnrichedEquipment(eq));
};

export const getAllConjuctionAssociatedWithAtMostOneSku = (
  sku: string,
  allEquipment: IEquipmentIdAndSKU[],
  data: string[] = [],
): string[] => {
  let updatedConjunctionData = [...data];
  const foundEquipment = allEquipment.find((equip) => equip?.sku === sku);
  if (!foundEquipment) return updatedConjunctionData;

  //Adding equipmentSKU to accomodate interface requirement
  let newAllEquipment: IEquipmentIdAndSKUExtended[] = allEquipment.map((equipment) => {
    return { ...equipment, equipmentSKU: equipment.sku };
  });

  if (foundEquipment?.inConjunctionWith) {
    const splitCodes = splitCodesToArray(foundEquipment.inConjunctionWith);
    const alreadySelectedAlternative = splitCodes.filter((e) => {
      if (e?.indexOf('+') === -1) {
        return updatedConjunctionData.includes(e);
      } else {
        const splitOnPlus = e?.split('+')?.filter(Boolean);
        return splitOnPlus?.every((eq) => updatedConjunctionData.includes(eq));
      }
    });
    if (alreadySelectedAlternative?.length > 0) {
      return updatedConjunctionData;
    }
    const lowestPriceSku = getLowestPriceConjunction(splitCodes, newAllEquipment, updatedConjunctionData);
    if (lowestPriceSku.includes('+')) {
      const addSkuSplit = lowestPriceSku.split('+');
      let additionDataArr = [];
      for (const skuSplit of addSkuSplit) {
        if (!updatedConjunctionData?.includes(skuSplit)) {
          updatedConjunctionData.push(skuSplit);
          additionDataArr = additionDataArr.concat(
            getAllConjuctionAssociatedWithAtMostOneSku(skuSplit, allEquipment, updatedConjunctionData),
          );
        }
      }
      return [...new Set([...updatedConjunctionData, ...additionDataArr])];
    } else {
      if (updatedConjunctionData.includes(lowestPriceSku)) {
        return updatedConjunctionData;
      }
      updatedConjunctionData.push(lowestPriceSku);
      return getAllConjuctionAssociatedWithAtMostOneSku(lowestPriceSku, allEquipment, updatedConjunctionData);
    }
  }
  return updatedConjunctionData;
};

export const getUpdatedSelectedEquipmentAfterNotConjunctionRemoval = (
  equipment: IEquipmentIdAndSKU,
  selectedEquipment: IEquipmentIdAndSKU[],
  allEquipment: IEquipmentIdAndSKU[],
  oldPrevSelectedEquipment: IEquipmentIdAndSKU[] = [],
): {
  selectedEquipMapped: IEquipmentIdAndSKU[];
  removedPrevSelectedEquipment: IEquipmentIdAndSKU[];
} => {
  let chosenConjunctionSkus = equipment.sku + ';';
  const removedPrevSelectedEquipment: IEquipmentIdAndSKU[] = [...oldPrevSelectedEquipment];
  let copyOfSelectedEquipment = [...selectedEquipment];
  const selectedEquipmentSkus = copyOfSelectedEquipment?.map((e) => e?.sku) ?? [];
  const selectedSkus = selectedEquipment?.map((e) => e?.sku) ?? [];

  if (equipment?.inConjunctionWith) {
    const conjunctionSkus = getAllConjuctionAssociatedWithAtMostOneSku(equipment.sku, allEquipment, [
      ...selectedEquipmentSkus,
      equipment.sku,
    ]);
    const updatedSkus = conjunctionSkus.concat(selectedSkus);
    chosenConjunctionSkus = `${[...new Set(updatedSkus)]?.join(';')};`;
  } else {
    const updatedSkus = [`${equipment.sku}`].concat(selectedSkus);
    chosenConjunctionSkus = `${[...new Set(updatedSkus)]?.join(';')};`;
  }

  const { disable: disableByNotConjunction } = getNewConjunctionValues(
    chosenConjunctionSkus,
    mapEquipmentsToPartialEnrichedEquipment(allEquipment),
  );
  let disableNotConjunctionMap = disableByNotConjunction.map((equip) => equip?.sku);

  if (equipment?.notInConjunctionWith) {
    const notConjunctionSKUs = splitCodesToArray(equipment?.notInConjunctionWith);
    disableNotConjunctionMap = disableNotConjunctionMap?.concat(notConjunctionSKUs);
  }

  // Current selected equipment removal, if it's in disableNotConjunctionMap
  if (disableNotConjunctionMap.length) {
    copyOfSelectedEquipment = copyOfSelectedEquipment.filter((equip) => {
      if (disableNotConjunctionMap.includes(equip?.sku)) {
        removedPrevSelectedEquipment.push(equip);
        return false;
      }
      return true;
    });
  }

  copyOfSelectedEquipment.forEach((equip) => {
    if (equip?.notInConjunctionWith) {
      const selectNoConjunction = splitCodesToArray(equip?.notInConjunctionWith);
      disableNotConjunctionMap = disableNotConjunctionMap?.concat(selectNoConjunction);
    }
  });

  disableNotConjunctionMap = [...new Set(disableNotConjunctionMap ?? [])];

  const selectedEquipMapped: IEquipmentIdAndSKU[] = copyOfSelectedEquipment.filter((equip) => {
    if (disableNotConjunctionMap.includes(equip?.sku)) {
      removedPrevSelectedEquipment.push(equip);
      return false;
    }
    return true;
  });

  return { selectedEquipMapped, removedPrevSelectedEquipment };
};

export const getUpdatedSelectedEquipmentFromConjuntionValue = (
  equipment: IEquipmentIdAndSKU,
  selectedEquipMapped: IEquipmentIdAndSKU[],
  allEquipment: IEquipmentIdAndSKU[],
): {
  updatedSelectedEquip: IEquipmentIdAndSKU[];
  addedEquipmentFromConjunction: IEquipmentIdAndSKU[];
} => {
  let equipmentNotInConjunctionWithSplitCodes: string[] = [];
  if (equipment?.notInConjunctionWith) {
    equipmentNotInConjunctionWithSplitCodes = splitCodesToArray(equipment?.notInConjunctionWith);
  }
  const updatedSelectedEquip = [...selectedEquipMapped];
  const selectedEquipmentSkus = updatedSelectedEquip?.map((e) => e?.sku) ?? [];
  const addedEquipmentFromConjunction: IEquipmentIdAndSKU[] = [];
  const conjunctionSkus = getAllConjuctionAssociatedWithAtMostOneSku(equipment.sku, allEquipment, [
    ...selectedEquipmentSkus,
    equipment.sku,
  ]);

  new Set(conjunctionSkus).forEach((sku) => {
    const conjunctionEquipment = allEquipment?.find((data) => data?.sku === sku);
    if (!conjunctionEquipment) return;
    const foundSelectedEquip = updatedSelectedEquip?.find((equip) => equip?.sku === sku);
    if (!foundSelectedEquip && sku !== equipment?.sku && !equipmentNotInConjunctionWithSplitCodes?.includes(sku)) {
      addedEquipmentFromConjunction.push(conjunctionEquipment);
      updatedSelectedEquip.push(conjunctionEquipment);
    }
  });

  return { updatedSelectedEquip, addedEquipmentFromConjunction };
};

export const checkExistingRemovedNotConjunction = (
  equipment: IEquipmentIdAndSKU,
  currentSelectedEquipment: IEquipmentIdAndSKU[],
  prevSelectedEquipment: IEquipmentIdAndSKU[],
  allEquipment: IEquipmentIdAndSKU[],
  removedEquipment: IEquipmentIdAndSKU[],
): {
  removedFilteredEquip: IEquipmentIdAndSKU[];
  currentFilteredEquip: IEquipmentIdAndSKU[];
} => {
  const currentFilteredEquip = [...currentSelectedEquipment];
  let removedFilteredEquip = [...removedEquipment];
  if (!prevSelectedEquipment.length || !currentSelectedEquipment.length) {
    return { removedFilteredEquip, currentFilteredEquip };
  }

  const chosenSkus = joinCodeArrayToString([...currentSelectedEquipment, equipment], 'sku');

  const { disable: disableByNotConjunction } = getNewConjunctionValues(
    chosenSkus,
    mapEquipmentsToPartialEnrichedEquipment(allEquipment),
  );

  prevSelectedEquipment.forEach((equip) => {
    if (
      removedEquipment.find((removedEquip) => removedEquip.sku === equip?.sku) &&
      disableByNotConjunction.every((disableEquip) => disableEquip.sku !== equip?.sku)
    ) {
      removedFilteredEquip = removedFilteredEquip.filter((rmEquip) => {
        if (rmEquip.sku === equip?.sku) {
          return false;
        }
        return true;
      });

      currentFilteredEquip.push(equip);
    }
  });

  return { currentFilteredEquip, removedFilteredEquip };
};

export const joinString = (equipments: IEquipmentIdAndSKU[]): string => {
  const joined = equipments?.map((eq) => eq?.sku)?.join(';');

  return joined.length > 2 ? joined + ';' : '';
};

export const mapEquipmentToOptionCodeObject = (equipment: IEquipmentIdAndSKU): IEquipmentIdAndSKU => {
  return {
    id: equipment.sku,
    sku: equipment.sku,
    name: equipment.name,
    inConjunctionWith: equipment.inConjunctionWith,
    notInConjunctionWith: equipment.notInConjunctionWith,
    including: equipment.including,
    excluding: equipment.excluding,
    priceConjunctionTable: [],
  };
};

export const mapEquipmentsToOptionCodeObject = (equipments: IEquipmentIdAndSKU[]) => {
  return equipments.map((eq) => mapEquipmentToOptionCodeObject(eq));
};

const getNewSelected = (props) => {
  const { equipment, selected = [] } = props;
  if (!equipment) return selected;

  const isAlreadySelected = selected?.find((eq) => eq?.sku === equipment?.sku) ? true : false;

  const selectedWithoutNewEquipment = selected?.filter((eq) => eq?.sku !== equipment?.sku);

  const newSelected = isAlreadySelected ? selectedWithoutNewEquipment : [...selectedWithoutNewEquipment, equipment];

  const joinedString = joinString(newSelected);
  if (joinedString.length < 2) return newSelected;
  // let skusToUnselect = [];
  // try {
  //   skusToUnselect = getSKUsToUnselect(equipment.sku, mapEquipmentsToOptionCodeObject(newSelected));
  // } catch (error: any) {
  //   // eslint-disable-next-line no-console
  //   console.error('When Sku get unselected : maximum call stack', error?.message);
  // }
  // changed cause not in work in ecom
  // const unselectThese = newSelected
  // .map((newSel) => newSel?.sku)
  // .filter((sku) => skusToUnselect?.includes(sku));
  const unselectThese = [];
  if (unselectThese.length < 1) return newSelected;

  const newSelectedWithoutSkusToUnselect = newSelected?.filter((eq) => !unselectThese.includes(eq.sku));
  return newSelectedWithoutSkusToUnselect;
};

const mapToEquipmentIdAndSku = (allEquipment: IEquipmentIdAndSKU[]): IEquipmentIdAndSKU[] => {
  return allEquipment?.map((equipment) => {
    const {
      sku,
      id,
      // equipmentSku,
      name,
      inConjunctionWith,
      notInConjunctionWith,
      including,
      excluding,
      priceExcludingVat,
      priceIncludingVat,
      priceConjunctionTable,
    } = equipment;
    return {
      id,
      sku,
      name,
      inConjunctionWith,
      notInConjunctionWith,
      including,
      excluding,
      priceExcludingVat,
      priceIncludingVat,
      priceConjunctionTable,
    };
  });
};

export const getVatPercentage = (): number => {
  const currentDate = new Date();
  const vatRegulations = getCurrentDeductionVatRule(currentDate);
  const { vatPercentage } = vatRegulations.vatValues;
  return getPercentageForCalculation(vatPercentage);
};

export const calculatePriceIncludingVat = (priceExcludingVat: number, vatPercentage: number) => {
  return priceExcludingVat + priceExcludingVat * vatPercentage;
};

export const updateEquipmentConjunctionPrices = (
  selected: IMappedEquipmentWithConjunctionPrices[],
  allEquipment: IMappedEquipmentWithConjunctionPrices[],
): IEquipmentIdAndSKU[] => {
  try {
    const filteredSelected = selected?.filter((e) => e);

    const skuListString = joinCodeArrayToString(filteredSelected, 'sku');
    const mappedEquipments = mapToEquipmentIdAndSku(allEquipment);
    if (skuListString) {
      const conjunctionPrices = getUpdatedConjunctionPrices(skuListString, mappedEquipments);
      const vatPercentage = getVatPercentage();
      allEquipment = allEquipment.map(
        ({ inConjunctionWithPriceExcludingVat, inConjunctionWithPriceIncludingVat, ...object }) => object,
      );
      conjunctionPrices.forEach(({ sku, value }) => {
        const equipmentIndex = allEquipment.findIndex((eq) => eq?.sku === sku);

        allEquipment[equipmentIndex].inConjunctionWithPriceExcludingVat = Number.parseInt(value);

        allEquipment[equipmentIndex].inConjunctionWithPriceIncludingVat = calculatePriceIncludingVat(
          Number.parseInt(value) || 0,
          vatPercentage,
        );
      });
    }
    return allEquipment;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('Error in updateEquipmentConjunctionPrices', error?.message);
  }
};

export const mapFoundSkusToEquipment = (
  equipmentSkus: string[],
  allEquipment: IEquipmentIdAndSKU[],
): IEquipmentIdAndSKU[] => {
  return allEquipment.filter((eq) => equipmentSkus.includes(eq?.sku));
};

export const sortEquipmentByPriceExcludingVat = (a: IMappedEquipment, b: IMappedEquipment) => {
  const equipmentAPrice = a?.priceExcludingVat;
  const equipmentBPrice = b?.priceExcludingVat;
  return equipmentAPrice - equipmentBPrice;
};

export const updatedDisableAndAllEquipmentStates = (
  selected: IMappedEquipmentWithConjunctionPrices[],
  allEquipment: IMappedEquipmentWithConjunctionPrices[],
): {
  allEquipment: IEquipmentIdAndSKU[];
  disableMapped: IEquipmentIdAndSKU[];
} => {
  const allEquipmentUpdatedPrice = updateEquipmentConjunctionPrices(selected, allEquipment);
  const selectedString = selected.map((eq) => allEquipmentUpdatedPrice.find((e) => e.sku === eq?.sku));

  const joinedString = joinString(selectedString);
  const allEquipmentMappedToPartialEnriched = mapEquipmentsToPartialEnrichedEquipment(allEquipment);

  const { disable } = getNewConjunctionValues(joinedString, allEquipmentMappedToPartialEnriched);

  const disableMapped = mapFoundSkusToEquipment(
    disable.map((eq) => eq?.sku),
    allEquipment,
  );

  return { disableMapped, allEquipment: allEquipmentUpdatedPrice };
};

export const getPreSelectedEquipment = (
  selectedEquipment: IMappedEquipment[],
  disableEquipment: IMappedEquipment[],
  allEquipment: IMappedEquipment[],
  addedEquipmentFromCojunction: IMappedEquipment[],
): {
  selectedEquipment: IMappedEquipment[];
  updatedAddedEquipmentFromConjunction: IMappedEquipment[];
} => {
  const updatedSelectedEquipment = [...selectedEquipment];
  const updatedAddedEquipmentFromConjunction = [...addedEquipmentFromCojunction];
  const disableSkuList = disableEquipment.map((equip) => equip?.sku);
  const preRimSelected = updatedSelectedEquipment.find((equip) => equip?.mainCategory === MainCategory.Felger);
  const preInteriorSelected = updatedSelectedEquipment.find((equip) => equip?.mainCategory === MainCategory.Interiør);
  const preVarnishSelected = updatedSelectedEquipment.find((equip) => equip?.mainCategory === MainCategory.Lakk);

  // Varnish
  if (!preVarnishSelected) {
    const varnishList = allEquipment
      .filter((equip) => equip?.mainCategory === MainCategory.Lakk && !disableSkuList.includes(equip?.sku))
      .sort(sortEquipmentByPriceExcludingVat);

    updatedSelectedEquipment.push(varnishList[0]);
    updatedAddedEquipmentFromConjunction.push(varnishList[0]);
  }

  // Rims
  if (!preRimSelected) {
    const rimList = allEquipment
      .filter((equip) => equip?.mainCategory === MainCategory.Felger && !disableSkuList.includes(equip?.sku))
      .sort(sortEquipmentByPriceExcludingVat);

    updatedSelectedEquipment.push(rimList[0]);
    updatedAddedEquipmentFromConjunction.push(rimList[0]);
  }

  // Interior
  if (!preInteriorSelected) {
    const interiorList = allEquipment
      .filter((equip) => equip?.mainCategory === MainCategory.Interiør && !disableSkuList.includes(equip?.sku))
      .sort(sortEquipmentByPriceExcludingVat);

    updatedSelectedEquipment.push(interiorList[0]);
    updatedAddedEquipmentFromConjunction.push(interiorList[0]);
  }

  return {
    selectedEquipment: updatedSelectedEquipment.filter(Boolean),
    updatedAddedEquipmentFromConjunction,
  };
};

export const getUpdatedPriceFromAllEquipments = (
  allEquipment: IMappedEquipment[],
  selectedEquipments: IMappedEquipment[],
  currentEquipment: IMappedEquipment,
  removedEquipments: IMappedEquipment[],
): IDisableStateEquipment => {
  let equipment = { ...currentEquipment };
  let removedPrevSelectedEquipment = [...(removedEquipments ?? [])];
  let addedEquipmentFromCojunction = [equipment, ...(selectedEquipments ?? [])];
  for (const mainEquip of allEquipment) {
    if (mainEquip?.sku === equipment?.sku) {
      equipment = { ...mainEquip };
    }
    addedEquipmentFromCojunction = addedEquipmentFromCojunction.map((eq) => {
      if (eq?.sku === mainEquip?.sku) {
        return mainEquip;
      }
      return eq;
    });
    removedPrevSelectedEquipment = removedPrevSelectedEquipment.map((eq) => {
      if (eq?.sku === mainEquip?.sku) {
        return mainEquip;
      }
      return eq;
    });
  }
  return {
    equipment,
    removedPrevSelectedEquipment,
    addedEquipmentFromCojunction,
    allEquipment,
  };
};

export const handleDisableEquipmentSelection = (
  equipment: IEquipmentIdAndSKU,
  selectedEquipment: IEquipmentIdAndSKU[],
  allEquipment: IEquipmentIdAndSKU[],
  isConfirm = false,
  prevSelectedSameCategoryEquip: IEquipmentIdAndSKU[] = [],
): IDisableStateEquipment => {
  const { selectedEquipMapped, removedPrevSelectedEquipment } = getUpdatedSelectedEquipmentAfterNotConjunctionRemoval(
    equipment,
    selectedEquipment,
    allEquipment,
  );
  let previouslySelectedEquipments = [...removedPrevSelectedEquipment];

  // Addition of Conjunction skus.
  const { addedEquipmentFromConjunction, updatedSelectedEquip } = getUpdatedSelectedEquipmentFromConjuntionValue(
    equipment,
    selectedEquipMapped,
    allEquipment,
  );
  const updatedSelected = [...updatedSelectedEquip, equipment];
  const unselectedSkus = previouslySelectedEquipments.map((eq) => eq?.sku);

  const mappedUpdatedSelected: IEquipmentIdAndSKUExtended[] = updatedSelected.map((equipment) => {
    return { ...equipment, equipmentSKU: equipment.sku };
  });

  //Adding equipmentSKU to accomodate interface requirement
  let newEquipment: IEquipmentIdAndSKUExtended = { ...equipment, equipmentSKU: equipment.sku };

  const skusToUnselect = getSelectedSkusToUnselectBasedOnRules(unselectedSkus, mappedUpdatedSelected, [], newEquipment);

  if (skusToUnselect?.length > 0) {
    console.log('getSelectedSkusToUnselectBasedOnRules--------->', skusToUnselect);
    const foundEquipments = skusToUnselect?.map((skuString) => allEquipment.find((eq) => eq?.sku === skuString));
    previouslySelectedEquipments = previouslySelectedEquipments.concat(foundEquipments);
  }
  const filteredUpdatedSelected = updatedSelectedEquip?.filter((eq) => !skusToUnselect?.includes(eq?.sku));
  // Checking between previous and current selectedEquipment
  const { currentFilteredEquip, removedFilteredEquip } = checkExistingRemovedNotConjunction(
    equipment,
    filteredUpdatedSelected,
    selectedEquipment,
    allEquipment,
    previouslySelectedEquipments,
  );
  const selected = getNewSelected({
    equipment,
    selected: currentFilteredEquip,
    allEquipment,
  });
  const preProcessEquipment = updatedDisableAndAllEquipmentStates(selected, allEquipment);

  const { selectedEquipment: newUpdatedSelected, updatedAddedEquipmentFromConjunction } = getPreSelectedEquipment(
    selected,
    preProcessEquipment.disableMapped,
    preProcessEquipment.allEquipment,
    addedEquipmentFromConjunction,
  );

  const updatedEquipment = updatedDisableAndAllEquipmentStates(newUpdatedSelected, allEquipment);

  if (!isConfirm) {
    const removedEquip = removedFilteredEquip.concat(prevSelectedSameCategoryEquip);
    allEquipment = updateEquipmentConjunctionPrices(newUpdatedSelected, allEquipment);
    const priceUpdatedEquipmentData = getUpdatedPriceFromAllEquipments(
      allEquipment,
      updatedAddedEquipmentFromConjunction,
      equipment,
      removedEquip,
    );
    return {
      equipment: priceUpdatedEquipmentData.equipment,
      removedPrevSelectedEquipment: priceUpdatedEquipmentData.removedPrevSelectedEquipment?.filter((data) => {
        return !isNil(data?.sku);
      }),
      addedEquipmentFromCojunction: priceUpdatedEquipmentData.addedEquipmentFromCojunction?.filter((data) => {
        return !isNil(data?.sku);
      }),
      allEquipment,
    } as IDisableStateEquipment;
  }
  return {
    selectedEquipment: newUpdatedSelected,
    disabledEquipment: updatedEquipment.disableMapped,
    allEquipment: updatedEquipment.allEquipment,
  } as IDisableStateEquipment;
};

export const getEquipmentPrice = (equipment: IMappedEquipmentWithConjunctionPrices): number => {
  const priceExcludingVat = equipment?.inConjunctionWithPriceExcludingVat ?? equipment?.priceExcludingVat;

  // Consider priceExcludingVat for electric cars
  const price = priceExcludingVat;
  return price || 0;
};

export const getEquipmentOriginalPrice = (equipment: IMappedEquipmentWithConjunctionPrices): number => {
  // Consider priceExcludingVat for electric cars
  const price = equipment?.priceExcludingVat;

  return price || 0;
};

export const calculateTotalRulesPrice = (equipmentList: IMappedEquipmentWithConjunctionPrices[]) => {
  return sumBy(equipmentList, (equipment: IMappedEquipmentWithConjunctionPrices) => {
    const price = getEquipmentPrice(equipment);
    return price;
  });
};

export const mapRuleValues = (mappedEquipment: IMappedEquipment[]): IRuleValues[] => {
  const ruleValues: IRuleValues[] = [];
  mappedEquipment?.forEach((equipment) => {
    const price = getEquipmentPrice(equipment);
    if (!isNil(equipment?.sku)) {
      ruleValues.push({
        name: equipment?.name,
        price: price,
        sku: equipment?.sku,
        mainCategory: equipment?.mainCategory,
        category: equipment?.category,
      });
    }
  });

  return ruleValues;
};

export const handleDeSelection = (
  equipment: IMappedEquipment,
  selectedEquipment: IMappedEquipment[],
  allEquipment: IMappedEquipment[],
): IDisableStateEquipment => {
  let selected = getNewSelected({
    equipment,
    selected: selectedEquipment,
    allEquipment,
  });
  selected = selected?.map((eq) => allEquipment.find((e) => e?.sku === eq?.sku));

  const allEquipmentMappedToPartialEnriched = mapEquipmentsToPartialEnrichedEquipment(allEquipment);

  const joinedString = joinString(selected);
  const { disable } = getNewConjunctionValues(joinedString, allEquipmentMappedToPartialEnriched);
  const disableMapped = mapFoundSkusToEquipment(
    disable?.map((eq) => eq?.sku),
    allEquipment,
  );
  const removeFilteredEquipment = [];
  const filteredSelectedEquipment = selected?.filter((eq) => {
    if (!disableMapped?.find((equipment) => equipment?.sku === eq?.sku)) {
      return true;
    }
    removeFilteredEquipment?.push(eq);
    return false;
  });

  if (
    !removeFilteredEquipment?.find((removedEquipment) => {
      return removedEquipment?.sku === equipment?.sku;
    })
  ) {
    removeFilteredEquipment?.push(equipment);
  }

  let previouslySelectedEquipments = [...removeFilteredEquipment];
  const unselectedSkus = previouslySelectedEquipments?.map((eq) => eq?.sku);
  const mappedFilteredSelectedEquipment = mapToEquipmentIdAndSku(filteredSelectedEquipment);
  const mappedUpdatedSelected: IEquipmentIdAndSKUExtended[] = mappedFilteredSelectedEquipment?.map((equipment) => {
    return { ...equipment, equipmentSKU: equipment.sku };
  });

  let skusToUnselect: string[];
  if (equipment) {
    let mapEq: IEquipmentIdAndSKUExtended = { ...equipment, equipmentSKU: equipment.sku };
    skusToUnselect = getSelectedSkusToUnselectBasedOnRules(
      unselectedSkus,
      mappedUpdatedSelected,
      [],
      mapEq
    );
  } else {
    skusToUnselect = getSelectedSkusToUnselectBasedOnRules(unselectedSkus, mappedUpdatedSelected);
  }

  if (skusToUnselect?.length > 0) {
    // eslint-disable-next-line no-console
    console.log('getSelectedSkusToUnselectBasedOnRules--------->', skusToUnselect);
    const foundEquipments = skusToUnselect?.map((skuString) => allEquipment?.find((eq) => eq?.sku === skuString));
    previouslySelectedEquipments = previouslySelectedEquipments?.concat(foundEquipments);
  }

  allEquipment = updateEquipmentConjunctionPrices(previouslySelectedEquipments, allEquipment);
  const priceUpdatedEquipmentData = getUpdatedPriceFromAllEquipments(
    allEquipment,
    [],
    equipment,
    previouslySelectedEquipments,
  );

  const removedPrevSelectedEquipment = priceUpdatedEquipmentData.removedPrevSelectedEquipment?.filter((data) => {
    return !isNil(data?.sku);
  });

  return {
    equipment,
    removedPrevSelectedEquipment,
    addedEquipmentFromCojunction: [],
    allEquipment,
  } as IDisableStateEquipment;
};

export const filterOutSelectedEquipments = (
  equipmentCategoryField = '',
  category = '',
  equipmentType: string,
  selectedEquipment: IMappedEquipment[],
) => {
  let selectedEquipmentWithoutExtraCategorySelected: IMappedEquipment[] = [];
  const prevSelectedEquipmentToRemove: IMappedEquipment[] = [];
  if (equipmentType === MainCategory.Lakk) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment?.filter((eq) => {
      if (eq.mainCategory === MainCategory.Lakk) {
        prevSelectedEquipmentToRemove.push(eq);
      }
      return eq?.mainCategory !== MainCategory.Lakk;
    });
  } else if (equipmentType === MainCategory.Utstyr) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment?.filter((eq) => {
      if (eq?.category === VehicleEquipmentCategoryEnum.Listverk) {
        prevSelectedEquipmentToRemove.push(eq);
      }
      return eq?.mainCategory !== VehicleEquipmentCategoryEnum.Listverk;
    });
  } else if (equipmentType === MainCategory.Utstyr) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment?.filter((eq) => {
      if (eq?.category === VehicleEquipmentCategoryEnum.Taklakk) {
        prevSelectedEquipmentToRemove.push(eq);
      }
      return eq?.category !== VehicleEquipmentCategoryEnum.Taklakk;
    });
  } else if (equipmentType === MainCategory.Interiør) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment?.filter((eq) => {
      if (eq?.mainCategory === MainCategory.Interiør) {
        prevSelectedEquipmentToRemove.push(eq);
      }
      return eq?.mainCategory !== MainCategory.Interiør;
    });
  } else if (equipmentType === MainCategory.Felger) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment?.filter((eq) => {
      if (eq?.mainCategory === MainCategory.Felger) {
        prevSelectedEquipmentToRemove.push(eq);
      }
      return eq?.mainCategory !== MainCategory.Felger;
    });
  } else if (equipmentCategoryField && category) {
    selectedEquipmentWithoutExtraCategorySelected = selectedEquipment.filter((eq) => {
      return eq[equipmentCategoryField] !== category;
    });
  }
  return {
    selectedEquipmentWithoutExtraCategorySelected,
    prevSelectedEquipmentToRemove,
  };
};

export const createRulesTableData = (
  equipmentSelection: IDisableStateEquipment,
  selectedEquipment: IMappedEquipment,
): IRulesTable => {
  const popupPrice: number =
    calculateTotalRulesPrice(equipmentSelection?.addedEquipmentFromCojunction) -
    calculateTotalRulesPrice(equipmentSelection?.removedPrevSelectedEquipment);
  const ruleAddVaules: IRuleValues[] = mapRuleValues(equipmentSelection?.addedEquipmentFromCojunction) || [];
  const ruleRemoveVaules: IRuleValues[] = mapRuleValues(equipmentSelection?.removedPrevSelectedEquipment) || [];
  const selectableName: string = selectedEquipment?.name;

  return {
    popupPrice,
    ruleAddVaules,
    ruleRemoveVaules,
    selectableName,
  };
};

export const addDefaultsInRulesTable = (
  currentSelections: ICurrentSelections,
  currentVariantData: IModelPackages[],
  rulesTableData: IRulesTable,
  onloadDisableSku?: Map<string, number>,
  mappedEquipments?: IMappedEquipment[],
): IRulesTable => {
  //Get selected equipment as per rules
  const { equipmentIds, rimIds, colorId, interiorId, listverkData, taklakkData, rulesPayload } =
    getAndPrepareSelectedDataFromRulesTable(rulesTableData, currentSelections, currentVariantData);

  //Get default selected equipment after rules
  currentSelections = {
    selectedEquipmentIds: equipmentIds,
    selectedRims: rimIds,
    selectedColorId: colorId,
    selectedInteriorId: interiorId,
    selectedListverk: listverkData,
    selectedTaklakk: taklakkData,
  };
  const { rulesPayload: rulesPayloadAfterPreselection } = getAndPrepareSelectedDataAfterDefaultCheck(
    currentSelections,
    currentVariantData,
    rulesPayload,
    onloadDisableSku,
  );

  //Check Differance....
  const defaultSkus = difference(rulesPayloadAfterPreselection, rulesPayload);

  if (defaultSkus?.length) {
    const selectedEquipments: IMappedEquipment[] = [];
    rulesPayload?.forEach((sku) => {
      selectedEquipments?.push(mappedEquipments?.find((equip) => equip?.sku === sku));
    });

    //Check for price update
    const allEquipmentUpdatedPrice = updateEquipmentConjunctionPrices(selectedEquipments, mappedEquipments);
    const defaultEquipmentsWithPriceUpdate = defaultSkus.map((sku) =>
      allEquipmentUpdatedPrice.find((e) => e.sku === sku),
    );
    const popupPrice: number = rulesTableData?.popupPrice + calculateTotalRulesPrice(defaultEquipmentsWithPriceUpdate);
    const ruleDefaultValues: IRuleValues[] = mapRuleValues(defaultEquipmentsWithPriceUpdate) || [];

    return {
      ...rulesTableData,
      ruleDefaultValues,
      popupPrice,
    };
  } else return rulesTableData;
};
