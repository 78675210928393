import { bosColor } from '@next-components/common-styles';
import { FinancingType } from 'next-common';
import styled, { css } from 'styled-components';

export const OrderAccordionWrapper = styled.div<{ financingType: FinancingType }>`
  width: 100%;

  .newNext,
  .newNext[open],
  .newNext[open]:hover {
    button.btnAccordion:not(.search-button) {
      border: none;
    }
  }
  .newNext[disabled] {
    cursor: not-allowed;
    button.btnAccordion:not(.search-button) {
      cursor: inherit;
    }
  }
  &:has(.newNext.leasingTabs) {
    .content[open].nextAccordion {
      overflow: unset;
    }
  }
  .newNext.leasingTabs {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .custom-accessory-grid-block {
    .nextAccordion {
      &.content[open] {
        padding: 0;
      }
    }
  }
  .custom-accessory-add {
    .nextAccordion {
      padding: 2.5rem 1rem !important;
      border: 1px solid ${bosColor.neutralGrey};
    }
  }

  ${({ financingType }) =>
    financingType === FinancingType.CASH &&
    css`
      .nextAccordion.content[open] {
        overflow: unset;
        .calculation-type.cash {
          .tableContainer {
            overflow: unset;
          }
        }
      }
    `}
`;
