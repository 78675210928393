import styled, { css } from 'styled-components';
import { black, bosColor, typography } from '@next-components/common-styles';

interface StyledFormProps {
  name: string;
  isEditable: boolean;
  onSubmit: () => void;
}

export const StyledForm = styled.form<StyledFormProps>`
  display: grid;
  gap: 1.5rem;

  .formTitle {
    margin: 0;
    font-size: 1.125rem;
    line-height: 175%;
    font-weight: ${typography.weight.extrabold};
  }

  &.fullHeight {
    height: 100%;
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formRowContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: flex-start;
    & > div {
      .fieldWrapper {
        & + div {
          margin: 0;
          flex-direction: row;
          justify-content: flex-end;
        }
        .downPaymentWrapper {
          flex-direction: row;
          justify-content: flex-end;

          span > div {
            flex-direction: column;
          }
        }
      }
    }
  }
  .formSummaryContainer {
    display: grid;
    gap: 0.75rem;
    padding-top: 1.5rem;
    border-top: 1px solid ${bosColor.neutralGrey};
    .interestRow {
      display: flex;
    }

    .smallFont {
      line-height: 175%;
      font-weight: ${typography.weight.semibold};
      font-size: 1rem;
      color: ${bosColor.black};
      > div {
        cursor: pointer;
      }
    }

    > div {
      justify-content: space-between;
      width: 100%;
      display: grid;
      grid-template-columns: 6fr 6fr;
      margin: 0;
    }
  }

  width: 100%;
  min-height: inherit;
  height: auto;
  .loanleasingtitle {
    font-size: 1.125rem;
    font-weight: ${typography.weight.extrabold};
    line-height: 175%;
  }
  ${(props) =>
    props.isEditable &&
    css`
      margin-bottom: 0;
      padding: 0 0.625rem;
    `}

  ${(props) =>
    props.name === 'loan-update' &&
    css`
      height: auto;
      font-weight: 600;
      .serviceAggrementSummaryWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        justify-content: flex-end;
        width: 100%;
        .serviceAggrementSummary {
          margin: 0;
          flex-direction: row;
          justify-content: flex-end;
          label {
            flex: 1 0 0;
          }
          label,
          p {
            line-height: 175%;
          }
        }
      }
      .formRowContainer {
        display: grid;
        grid-template-columns: auto;
      }
    `}

    ${(props) =>
    props.name === 'leasing-update' &&
    css`
      height: auto;
      font-weight: 600;
      .serviceAggrementSummaryWrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        justify-content: flex-end;
        width: 100%;
        .serviceAggrementSummary {
          margin: 0;
          flex-direction: row;
          justify-content: flex-end;
          label {
            flex: 1 0 0;
          }
          label,
          p {
            line-height: 175%;
          }
        }
      }
    `}
  
  .form-row.base-position {
    align-items: baseline;
    flex-direction: column;
    display: grid;
    gap: 0.25rem;
  }

  .errorMessage {
    width: 167%;
    text-align: inherit;
    font-weight: 700;
    padding-top: 5px;
  }

  .form-row {
    &.interest {
      div {
        margin: 0;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
    &.narrowWidth {
      width: 17.125rem;
    }
    flex-direction: column;
    display: grid;
    gap: 0.25rem;
    .inputContainer {
      .inputAndIconContainer {
        input {
          font-size: 1rem;
          line-height: 175%;
          &:hover {
            border-color: ${bosColor.neutralPlus};
          }
        }
        .adornment-container {
          right: 1.375rem;
          .input-unit {
            font-size: 0.875rem;
          }
        }
      }
      #inputField:focus {
        border: 3px solid rgb(25, 25, 25);
      }
    }
    .dropdown-container {
      div.react-select__control {
        height: 3.4rem;
      }
    }
    .smallFont {
      font-weight: ${typography.weight.semibold};
      font-size: 1rem;
      line-height: 175%;
      color: ${bosColor.black};
    }
    #downPayment {
      font-weight: 600;
    }
    &.unknown-field {
      p,
      label {
        color: red;
      }
    }

    &.large {
      margin: 1rem 0;
    }

    input {
      font-size: 1.125rem;
    }

    p,
    h1 {
      text-align: right;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      line-height: 165%;
    }

    .dropDownContainer {
      min-width: 5rem;
    }

    #text-style > div > div {
      overflow: hidden;
    }

    .dropdown-container + div {
      display: flex;
      justify-content: flex-end;
      a {
        cursor: pointer;
        color: ${bosColor.black};
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: ${typography.weight.semibold};
        line-height: 140%;
        border-bottom: 2px solid ${bosColor.turquoise};
      }
    }
  }

  #inputDropDown > div > div:nth-child(2) > div {
    padding: 2px;
  }
  .button {
    border-radius: 0.5rem;
    height: 3rem;
    width: 7.5rem;

    .text {
      font-size: 1.125rem;
    }
  }

  p {
    color: ${black};
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${(props) =>
      props.isEditable &&
      css`
        margin-top: 2.2rem;
      `}

    .next-modal {
      .content {
        display: grid;
        grid-column-gap: 3rem;
        gap: 0;
        padding-top: 1rem;
        p {
          color: ${black};
        }
      }
      header .popup-close {
        margin-right: 0;
      }
    }
  }
  .confirmation-checkbox > div {
    input {
      padding: 0px;
    }
    label {
      margin-bottom: 0;
    }
  }
  .dropDownContainer #inputDropDown div:first-of-type {
    padding-right: 0px;
    grid-template-columns: 1fr;
  }
`;
