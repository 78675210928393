import {
  IConfiguration,
  IOrder,
  IVehicleConfiguration,
  MAKE,
  isStockSaleOrderToBeProcessed,
} from 'next-common';
import {
  IImageDetails,
  IMBVehicleImageChunk,
  IVehicleStatusOption,
  Make,
  MongoEvents,
} from 'sales-common';
import { cloneDeep, get } from 'lodash';
import { format, getYear, isDate } from 'date-fns';
import {
  IVehicleStock,
  IVehicleStockForSellAllCars,
  IVehicleStockSellAllCars,
  validBrandForSelectables,
  ITilleggSelectable
} from '../../common';
import { vehicleSegregationWorker } from '../../components/sales/sales-wizard/vehicleSegregationWorker';
import { createOptions } from './option';
import { isValidProductionDateFormat } from './vehicleOrder';
import { getStatusByTTCode } from './vehicle';
import { IDynamicListStockTableData } from '../../components/common-components/DynamicListStock';
import { getVehicleAvailabityStatus, isDemoVehicle, isFreeVehicle } from './vehicleStatus';

export const getMappedVehicleObject = (key: string, data: IVehicleStock, vehicleList: IVehicleConfiguration[]) => {
  const options = createOptions(vehicleList);
  const vehicleUpdate = new Map();
  vehicleUpdate.set(key, [data]);
  return vehicleSegregationWorker(vehicleList, vehicleUpdate, options);
};

enum OPTION_TYPE {
  COLOR = 'COLOR',
  INTERIOR = 'UPHOLSTERY',
}

export class VehicleStock {
  private stock: Map<string, IVehicleStock[]> = null;
  private group: string = '';
  static instance: VehicleStock;

  constructor() {
    this.stock = new Map();
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new VehicleStock();
    }

    return this.instance;
  }

  hasStock = (key: string) => this.stock?.has(key);

  getStock = (key: string) => this.stock?.get(key);

  setStock = (key: string, value: IVehicleStock[]) => this.stock?.set(key, value);

  getStockInstance = () => this.stock;

  updateEntireStock = (mappedStock: Map<string, IVehicleStock[]>) => {
    this.stock = mappedStock;
  };

  setGroup = (value: string) => (this.group = value);

  getGroup = () => this.group;

  clearStock = () => {
    this.stock?.clear();
    this.group = '';
  };

  sizeOfStock = () => this.stock?.size;

  updateVehicleStockOnSubscription = (
    key: string,
    event: string,
    dataObj: IVehicleStock,
    vehicleList: IVehicleConfiguration[],
  ) => {
    if (this.hasStock(key)) {
      const existingVehicle = this.getStock(key);
      const serialNumber = dataObj?.serialNumber;
      const isVehicleIndexFound = existingVehicle?.findIndex?.((vehicle) => vehicle?.serialNumber === serialNumber);

      let mappedVehicleObject = null;
      if (validBrandForSelectables.includes(dataObj?.brand as MAKE)) {
        //Implement data segregation for the updated vehicle object
        mappedVehicleObject = getMappedVehicleObject(key, dataObj, vehicleList || []);
        mappedVehicleObject = mappedVehicleObject?.get(key)?.[0];
      } else {
        mappedVehicleObject = dataObj;
      }
      switch (event) {
        case MongoEvents.INSERT: {
          if (isVehicleIndexFound < 0) this.setStock(key, [...existingVehicle, mappedVehicleObject]);
          break;
        }
        case MongoEvents.UPDATE: {
          if (isVehicleIndexFound >= 0) {
            existingVehicle[isVehicleIndexFound] = mappedVehicleObject;
            this.setStock(key, existingVehicle);
          } else {
            this.setStock(key, [...existingVehicle, mappedVehicleObject]);
          }
          break;
        }
        case MongoEvents.DELETE: {
          const updatedVehicles = existingVehicle?.filter((vehicle) => vehicle?.serialNumber !== serialNumber);
          this.setStock(key, updatedVehicles);
          break;
        }
        default:
          break;
      }
    }
  };
}

const vehicleStock = VehicleStock.getInstance();

/* Only one instance of vehicle-stock would be used in the entire application at time */
export default vehicleStock;

export const getStockSaleParams = (order: IOrder) => {
  return {
    source: get(order, 'lead.source', ''),
    serialNo: get(order, 'vehicles[0].serialNo', null),
    make: get(order, 'lead.make', ''),
  };
};

export const updateVehicleDataForSellAllCarsOnSubscription = (
  event: string,
  dataObj: IVehicleStock,
  existingVehicleStockDataForSellAllCars: IVehicleStockSellAllCars[],
  updateNewVehicleStockDataForSellAllCars: (data: IVehicleStockSellAllCars[]) => void,
) => {
  const serialNumber = dataObj?.serialNumber;
  if (serialNumber && existingVehicleStockDataForSellAllCars?.length) {
    let isVehicleFoundInStockIndex = existingVehicleStockDataForSellAllCars?.findIndex?.(
      (vehicle) => vehicle?.serialNumber === serialNumber,
    );
    switch (event) {
      case MongoEvents.INSERT: {
        break;
      }
      case MongoEvents.UPDATE: {
        const updatedStock = cloneDeep(existingVehicleStockDataForSellAllCars);
          let vehicleStatus = getVehicleAvailabityStatus(dataObj);
          const optionsData = vehicleStockOptionsMapper(dataObj?.vehicleStatus?.vehicleStatus?.option, existingVehicleStockDataForSellAllCars?.[0]?.brand);
          const updeteDataObj = {
            ...mapVehicleStockData(dataObj),
            fargeFormatter: optionsData?.fargeFormatter,
            interiorFormatter: optionsData?.interiorFormatter,
            varnish: optionsData?.varnish,
            interior: optionsData?.interior,
            exteriorSelectableFormatter: optionsData?.exteriorSelectableFormatter,
            equipmentsSelectableFormatter: optionsData?.equipmentsSelectableFormatter,
          };
          const vehicleImage = getVehicleImageForSaleAllCars(dataObj?.vehicleImages?.images);
          const updatedVehicleStockObj = {
            ...updeteDataObj,
            status: vehicleStatus,
            totalPrice: dataObj?.vehicleStatus?.totalCampaignPrice,
            image: { ...vehicleImage },
          };

        if (isVehicleFoundInStockIndex === -1) {
          updatedStock.push(updatedVehicleStockObj);
          updateNewVehicleStockDataForSellAllCars(updatedStock);
        }
        if (isVehicleFoundInStockIndex > -1) {
          updatedStock[isVehicleFoundInStockIndex] = updatedVehicleStockObj;
          updateNewVehicleStockDataForSellAllCars(updatedStock);
        }
        break;
      }
      case MongoEvents.DELETE: {
        const updatedStock = existingVehicleStockDataForSellAllCars?.filter(
          (vehicle) => vehicle?.serialNumber !== serialNumber,
        );
        updateNewVehicleStockDataForSellAllCars(updatedStock);
        break;
      }
      default:
        break;
    }
  }
};

export const mapVehicleStockData = (
  stock: IDynamicListStockTableData | IVehicleStockSellAllCars,
): IVehicleStockSellAllCars => {
  return {
    brand: stock?.brand,
    modelSeries: stock?.modelSeries,
    modelId: stock?.modelId,
    modelDesc: stock?.modelDesc,
    colorId: stock?.colorId,
    colorDesc: stock?.colorDesc,
    serialNumber: stock?.serialNumber,
    productionDate: stock?.productionDate,
    location: stock?.location,
    locationId: stock?.locationId,
    locationName: stock?.locationName,
    trackTraceCode: stock?.trackTraceCode,
    tt: stock?.trackTraceCode,
    interiorCode: stock?.interiorCode,
    noCode: stock?.noCode,
    optionCode: stock?.optionCode,
    vinNumber: stock?.vinNumber,
    transferRequest: stock?.transferRequest,
    transferFrom: stock?.transferFrom,
    transferTo: stock?.transferTo,
    availabilityCode: stock?.availabilityCode,
    farge: `${stock?.colorDesc} ${stock?.colorId ? `(${stock.colorId})` : ``}`,
    prod: isValidProductionDateFormat(stock?.productionDate)
      ? format(new Date(stock?.productionDate), 'dd.MM.yyyy')
      : '',
    statusName: getStatusByTTCode(stock?.trackTraceCode, MAKE.Mercedes),
  };
};

export const mapperForSellAllCars = (vehicleStock: IVehicleStockForSellAllCars[]): IVehicleStockSellAllCars[] => {
  let sellAllCarsData: IVehicleStockSellAllCars[] = [];
  vehicleStock?.forEach((item: IDynamicListStockTableData, index: number) => {
    let vehicleStatus = '';
    if (isDemoVehicle(item)) {
      vehicleStatus = 'DE';
    }

    if (isFreeVehicle(item)) {
      vehicleStatus = 'LA';
    }
    const optionsData = vehicleStockOptionsMapper(item?.vehicleStatus?.vehicleStatus?.option, item?.brand);
    const vehicleImage = getVehicleImageForSaleAllCars(item?.vehicleImages?.images);
    sellAllCarsData.push({
      ...mapVehicleStockData(item),
      totalPrice: item?.vehicleStatus?.totalCampaignPrice,
      status: vehicleStatus,
      image: { ...vehicleImage },
    });
    sellAllCarsData[index].applicationCode = item?.vehicleStatus?.vehicleStatus?.applicationCode;
    sellAllCarsData[index].partNo = item?.vehicleStatus?.vehicleStatus?.partNo;
    sellAllCarsData[index].oemOrderNo = item?.vehicleStatus?.vehicleStatus?.oemOrderNo;
    sellAllCarsData[index].fargeFormatter = optionsData?.fargeFormatter;
    sellAllCarsData[index].interiorFormatter = optionsData?.interiorFormatter;
    sellAllCarsData[index].varnish = optionsData?.varnish;
    sellAllCarsData[index].interior = optionsData?.interior;
    sellAllCarsData[index].exteriorSelectableFormatter = optionsData?.exteriorSelectableFormatter;
    sellAllCarsData[index].equipmentsSelectableFormatter = optionsData?.equipmentsSelectableFormatter;
  });

  return sellAllCarsData;
};

/**
 * The function `vehicleStockOptionsMapper` processes vehicle status options data to categorize and
 * format them for display.
 * @param {IVehicleStatusOption[]} optionData - It looks like the code snippet you provided is a
 * function called `vehicleStockOptionsMapper` that processes `optionData` related to vehicle status
 * options. The function filters and organizes the data into different categories such as
 * `withOptionType`, `withOutoptionType`, `equipmentsList`,
 * @returns The function `vehicleStockOptionsMapper` returns an object with the following properties:
 */
const vehicleStockOptionsMapper = (optionData: IVehicleStatusOption[], brand: string) => {
  let withOptionType: IVehicleStatusOption[] = [];
  let withOutoptionType: IVehicleStatusOption[] = [];
  let equipmentsList: IVehicleStatusOption[] = [];
  withOutoptionType = optionData?.filter((item) => item.optionType !== 'OPTION');
  withOptionType = optionData?.filter((item) => item.optionType === 'OPTION');
  equipmentsList = optionData?.filter(
    (item) => {
      if (brand === Make.Kia) {
        return item.optionType === 'OPTION' && !item?.isStandard;
      } else { 
        return item.optionType === 'OPTION' && !item?.isStandard && item?.optionPriceRetailCustomer > 0
      }
    }
  );
  const [varnish, interior] = findColorInteriorFromOptions(optionData);
  const interiorData = [
    {
      id: interior?.optionId,
      optionId: interior?.optionId,
      name: interior?.optionDescription,
      optionDescription: interior?.optionDescription,
      sku: interior?.optionId,
      type: 'interior',
    },
  ];
  const varnishData = [
    {
      id: varnish?.optionId,
      optionId: varnish?.optionId,
      name: varnish?.optionDescription,
      optionDescription: varnish?.optionDescription,
      sku: varnish?.optionId,
      type: 'varnish',
    },
  ];
  const fargeFormatter = vehicleStockDataFormatter(
    withOutoptionType?.filter((item) => item?.optionType === OPTION_TYPE.COLOR),
  );

  const interiorFormatter = vehicleStockDataFormatter(interiorData);
  const exteriorSelectableFormatter = vehicleStockDataFormatter(equipmentsList);
  const equipmentsSelectableFormatter = vehicleStockDataFormatter(withOptionType);

  return {
    fargeFormatter: fargeFormatter,
    interiorFormatter: interiorFormatter,
    exteriorSelectableFormatter: exteriorSelectableFormatter,
    equipmentsSelectableFormatter: equipmentsSelectableFormatter,
    varnish: varnishData,
    interior: interiorData,
  };
};

/**
 * The function `vehicleStockDataFormatter` formats vehicle stock data into a specific structure.
 * @param {IVehicleStatusOption[]} optionData - The `vehicleStockDataFormatter` function takes an array
 * of `IVehicleStatusOption` objects as input in the `optionData` parameter. It then formats this data
 * into an array of `ITilleggSelectable` objects and returns the formatted data. Each
 * `IVehicleStatusOption` object contains
 * @returns An array of objects of type ITilleggSelectable is being returned. Each object contains the
 * properties sku, isSelected, isUnavailable, and name, which are populated based on the input
 * IVehicleStatusOption array provided to the vehicleStockDataFormatter function.
 */
const vehicleStockDataFormatter = (optionData: IVehicleStatusOption[]): ITilleggSelectable[] => {
  let formatterData: ITilleggSelectable[] = [];
  optionData?.forEach((item: IVehicleStatusOption) => {
    formatterData.push({
      sku: item?.optionId,
      isSelected: false,
      isUnavailable: false,
      name: item?.optionDescription,
    });
  });

  return formatterData;
};

export const isValidStockSaleOrder = (order: IOrder, configuration: IConfiguration) => {
  const params = getStockSaleParams(order);
  const isStockSaleOrder = isStockSaleOrderToBeProcessed(configuration, params?.source, params?.serialNo, params?.make);
  return isStockSaleOrder;
};

/**
 * The function `getVehicleImageForSaleAllCars` returns the image details for a vehicle if available,
 * otherwise it returns an empty object.
 * @param {IMBVehicleImageChunk} vehicleImages - IMBVehicleImageChunk - an object containing vehicle
 * images for sale. It may have a property named EXT000 which holds an image details object.
 * @returns If the property `EXT000` exists in the `vehicleImages` object, the function will return the
 * image details stored in that property. Otherwise, it will return an empty `IImageDetails` object
 * with `fileName` and `url` properties set to empty strings.
 */
const getVehicleImageForSaleAllCars = (vehicleImages: IMBVehicleImageChunk): IImageDetails => {
  if (vehicleImages?.EXT050) {
    return vehicleImages?.EXT050;
  } else {
    return {
      fileName: null,
      url: null,
    };
  }
};

// NOTE : Will REVMOE THIS ONCE next-backends-common issue fix
const isVarnishFound = (option: IVehicleStatusOption) => {
  return option?.optionType === OPTION_TYPE.COLOR;
};

const isInteriorFound = (option: IVehicleStatusOption) => {
  return option?.optionType === OPTION_TYPE.INTERIOR;
};

export const isSalesPackageFound = (option: IVehicleStatusOption, uniqueIdentifiers: string[]): boolean => {
  return uniqueIdentifiers?.includes(option?.optionId) && !option?.isStandard;
};

export const findColorInteriorFromOptions = (options: IVehicleStatusOption[]) => {
  let varnish: IVehicleStatusOption = null;
  let interior: IVehicleStatusOption = null;

  options?.forEach((option) => {
    if (isVarnishFound(option)) {
      varnish = option;
    }
    if (isInteriorFound(option)) {
      interior = option;
    }
  });
  return [varnish, interior];
};

const mapVehicleInputFromVehicleStock = (selectedVehicles: IVehicleStock) => {
  const prodDate = isDate(new Date(selectedVehicles?.productionDate))
    ? getYear(new Date(selectedVehicles?.productionDate))
    : null;
  const model = {
    code: null,
    description: null,
    name: selectedVehicles?.modelDesc,
    type: null,
    version: null,
    year: prodDate,
  };
  return {
    make: selectedVehicles?.brand,
    model,
    serialNo: selectedVehicles?.serialNumber?.toString(),
    series: selectedVehicles?.modelSeries,
    vin: selectedVehicles?.vinNumber,
    ttCode: selectedVehicles?.trackTraceCode,
  };
};

export const prepareVehicleInputForStock = (selectedVehicles: IVehicleStock) =>
  selectedVehicles ? [mapVehicleInputFromVehicleStock(selectedVehicles)] : [];

export const filterByVehicleType = (vehicleStockList, configuration: IConfiguration) => {
  const validVehicleType = configuration?.features?.vehicleTypeFilter;
  return vehicleStockList?.filter(vehicle => {
    const applicationCode = vehicle?.vehicleStatus?.vehicleStatus?.applicationCode;
    return validVehicleType?.includes(applicationCode);
  });
};